export {}; // hack to avoid TypeScript errors in case of no exports

/** HOW TO ADD FEATURE FLAGS

  1. Export the feature flag from this file. Please, follow the pattern:
    `export const FF_EXAMPLE = 'use_example';`

  2. Where you want to use the new feature flag, use `useFeatureFlags` hook and get `featureEnabled`
    from it. Call `featureEnabled` with the previous exported feature flag (from this file):
    `featureEnabled(FF_EXAMPLE) ? <NewComponent /> : null`

    IMPORTANT: This file should NOT be named import (like `import * as name`), because it prevents
    the build from crashing in case the feature flag is removed, but not its usage places. In this
    way, we avoid forgetting unused code around the app.
*/

export const LIST_PROGRAMS_FF = 'enable list of programs as admin';
export const VIEW_COMPANY_DETAILS_FF = 'enable view company details';
export const LIST_PLANS_FF = 'enable list of plans as admin';
export const PROGRAMS_DASHBOARD_FF = 'enable programs dashboard as admin';
