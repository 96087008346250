import program1Image from '../../assets/images/programs/1.svg';
import program2Image from '../../assets/images/programs/2.svg';
import program3Image from '../../assets/images/programs/3.svg';
import program4Image from '../../assets/images/programs/4.svg';
import program5Image from '../../assets/images/programs/5.svg';
import program6Image from '../../assets/images/programs/6.svg';
import program7Image from '../../assets/images/programs/7.svg';
import program8Image from '../../assets/images/programs/8.svg';
import program9Image from '../../assets/images/programs/9.svg';
import program10Image from '../../assets/images/programs/10.svg';
import program11Image from '../../assets/images/programs/11.svg';
import program12Image from '../../assets/images/programs/12.svg';

export const ProgramImages = {
  1: program1Image,
  2: program2Image,
  3: program3Image,
  4: program4Image,
  5: program5Image,
  6: program6Image,
  7: program7Image,
  8: program8Image,
  9: program9Image,
  10: program10Image,
  11: program11Image,
  12: program12Image,
};

export const ProgramUrls = {
  1: 'https://sinir.gov.br/perfis/logistica-reversa/logistica-reversa/agrotoxicos-seus-residuos-e-embalagens/',
  2: 'https://sinir.gov.br/perfis/logistica-reversa/logistica-reversa/baterias-de-chumbo-acido/',
  3: 'https://sinir.gov.br/perfis/logistica-reversa/logistica-reversa/eletroeletronicos-e-seus-componentes/',
  4: 'https://sinir.gov.br/perfis/logistica-reversa/logistica-reversa/embalagens-de-aco/',
  5: 'https://sinir.gov.br/perfis/logistica-reversa/logistica-reversa/embalagens-plasticas-de-oleos-lubrificantes/',
  6: 'https://sinir.gov.br/perfis/logistica-reversa/logistica-reversa/embalagens-em-geral/',
  7: 'https://sinir.gov.br/perfis/logistica-reversa/logistica-reversa/lampadas-fluorescentes-de-vapor-de-sodio-e-mercurio-e-de-luz-mista/',
  8: 'https://sinir.gov.br/perfis/logistica-reversa/logistica-reversa/medicamentos-seus-residuos-e-embalagens/',
  9: 'https://sinir.gov.br/perfis/logistica-reversa/logistica-reversa/oleos-lubrificantes-usados-ou-contaminhados-oluc/',
  10: 'https://sinir.gov.br/perfis/logistica-reversa/logistica-reversa/pilhas-e-baterias/',
  11: 'https://sinir.gov.br/perfis/logistica-reversa/logistica-reversa/pneus-inserviveis/',
  12: 'https://sinir.gov.br/perfis/logistica-reversa/logistica-reversa/latas-de-aluminio-para-bebidas/',
};
export const ProgramNamesUrl = [
  'agrotoxicos-seus-residuos-e-embalagens',
  'baterias-de-chumbo-acido',
  'eletroeletronicos-e-seus-componentes',
  'embalagens-de-aco',
  'embalagens-plasticas-de-oleos-lubrificantes',
  'embalagens-em-geral',
  'lampadas-fluorescentes-de-vapor-de-sodio-e-mercurio-e-de-luz-mista',
  'medicamentos-seus-residuos-e-embalagens',
  'oleos-lubrificantes-usados-ou-contaminhados-oluc',
  'pilhas-e-baterias',
  'pneus-inserviveis',
  'latas-de-aluminio-para-bebidas',
];
