import { useEffect, useState } from 'react';

import Content from './content';

import ErrorHandler from '../../../../../../common-components/error-handler';

import { getTotalOfCompaniesIndicator } from '../../../../../../modules/indicators/service';
import HttpError from '../../../../../../cross-cutting/errors/http-error';
import {
  DashboardFilters,
  GetTotalOfCompaniesIndicatorResponse,
} from '../../../../../../modules/indicators/types';

export default function RegisteredCompanies({ filters }: { filters: DashboardFilters }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<HttpError>();
  const [companiesCounting, setCompaniesCounting] = useState<
    GetTotalOfCompaniesIndicatorResponse | undefined
  >();

  useEffect(() => {
    // fetch registered companies
    const fetchRegisteredCompanies = async () => {
      try {
        setLoading(true);
        const response = await getTotalOfCompaniesIndicator({ ...filters });
        setCompaniesCounting(response);
      } catch (err) {
        if (err instanceof HttpError) {
          setError(err);
        }
      }
      setLoading(false);
    };

    fetchRegisteredCompanies();
  }, [filters]);

  return (
    <ErrorHandler error={error}>
      <Content filters={filters} loading={loading} companiesCounting={companiesCounting} />
    </ErrorHandler>
  );
}
