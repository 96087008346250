import { Space } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { PublicProgram } from '../../../modules/programs/types';
import { ProgramImages, ProgramUrls } from '../../../modules/programs/enums';

import SelectProgramCard from './select-program-card';

type SelectProgramProps = {
  programs: PublicProgram[];
  onSelectProgram: (checked: CheckboxChangeEvent) => void;
  currentSelectedPrograms: number[];
};

function SelectProgram({ programs, onSelectProgram, currentSelectedPrograms }: SelectProgramProps) {
  return (
    <Space size={24} direction="horizontal" wrap>
      {programs.map((program: PublicProgram) => (
        <SelectProgramCard
          onChange={onSelectProgram}
          checked={currentSelectedPrograms.includes(program.id)}
          title={program.name}
          key={program.id}
          image={ProgramImages[program.id as keyof typeof ProgramImages]}
          programUrl={ProgramUrls[program.id as keyof typeof ProgramUrls]}
          programId={program.id}
        />
      ))}
    </Space>
  );
}

export default SelectProgram;
