import numberFormatter from './number-formatter';

const formatWeight = (value: number, symbol = 'Kg', thousandSeparator = ',', minimumDigits = 3) => {
  const formatNumber = numberFormatter(thousandSeparator === '.' ? 'pt-BR' : 'en-US', {
    minimumFractionDigits: minimumDigits,
    maximumFractionDigits: 4,
  });

  const renderSymbol = symbol ? ` ${symbol}` : '';

  return `${formatNumber(value)}${renderSymbol}`;
};

export default formatWeight;
