import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import BreadcrumbItem from '../../../../common-components/breadcrumbs/breadcrumb-item';
import Loader from '../../../../common-components/loader';
import PageWithAddons from '../../../../common-components/page-with-addons';
import CompanyTabs from '../tabs';

import { TABS } from '../enums';

import { GetOneCompanyResponse } from '../../../../modules/company/service';
import routes from '../../../../routes';

import i18n from './i18n';
import styles from './index.module.css';

type ContentProps = {
  company?: GetOneCompanyResponse;
  loading: boolean;
};

export default function Content({ company, loading }: ContentProps) {
  const { search } = useLocation();

  const companyPlanId = new URLSearchParams(search).get('companyPlanId');
  const currentTabKeyParam = new URLSearchParams(search).get('redirectToTab');

  const [currentTabKey, setCurrentTabKey] = useState(currentTabKeyParam || TABS.REGISTRATION_DATA);

  const onChangeTab = (newTab: string) => setCurrentTabKey(newTab);

  return (
    <PageWithAddons
      pageProps={{
        title: company?.legalName || '',
        browserTabName: i18n.ptBR.BROWSER_TAB_NAME(company?.legalName),
        breadcrumbs: [
          <BreadcrumbItem
            key="listReverseLogisticSystems"
            route={routes.LIST_PROGRAMS}
            title={i18n.ptBR.BREADCRUMB_LIST_PROGRAMS}
          />,
          <BreadcrumbItem
            key="viewCompanyDetails"
            route={routes.VIEW_COMPANY_DETAILS.replace(/:id/, company?.id || '')}
            title={company?.legalName || ''}
          />,
        ],
        childrenClassName: styles.childrenClassName,
      }}
      tabsProps={{
        activeKey: currentTabKey,
        children: CompanyTabs({ company, companyPlanId }),
        onTabClick: onChangeTab,
      }}
    >
      {loading && <Loader />}
    </PageWithAddons>
  );
}
