import { useCallback, useContext, useEffect } from 'react';
import type { Dispatch, SetStateAction } from 'react';

import FeatureFlagsContext from '../../contexts/feature-flags';
import type { FeatureFlagNames, FeatureFlagsObject } from '../../contexts/feature-flags';
import { FEATURE_FLAGS_STORAGE_KEY } from '../../../enums';

type FeatureFlagsContextType = {
  featureFlags: FeatureFlagsObject;
  setFeatureFlags: Dispatch<SetStateAction<FeatureFlagsObject>>;
};

const useFeatureFlags = () => {
  const { featureFlags, setFeatureFlags } =
    useContext<FeatureFlagsContextType>(FeatureFlagsContext);

  // update storage value with Context value (when user changes)
  useEffect(() => {
    const storageValue = localStorage.getItem(FEATURE_FLAGS_STORAGE_KEY);
    const stringifiedFeatureFlags = JSON.stringify(featureFlags);

    if (storageValue !== stringifiedFeatureFlags) {
      localStorage.setItem(FEATURE_FLAGS_STORAGE_KEY, stringifiedFeatureFlags);
    }
  }, [featureFlags]);

  const featureEnabled = useCallback(
    (name: FeatureFlagNames) => {
      if (typeof featureFlags[name] === 'undefined') {
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console
          console.error(`Unhandled feature flag with name: ${name}`);
        }

        return false;
      }

      return !!featureFlags[name];
    },
    [featureFlags],
  );

  const toggleFeature = useCallback(
    (name: FeatureFlagNames) => {
      setFeatureFlags((prev) => ({
        ...prev,
        [name]: !prev[name],
      }));
    },
    [setFeatureFlags],
  );

  return { featureFlags, featureEnabled, toggleFeature } as const;
};

export default useFeatureFlags;
