import dayjs from 'dayjs';

import Page from '../../../../../common-components/page';
import Loader from '../../../../../common-components/loader';
import Form from '../../../../../common-components/form';
import Divider from '../../../../../common-components/divider';
import Button from '../../../../../common-components/button';
import Plan from '../plan';

import i18n from './i18n';
import styles from './index.module.css';
import type { CompanyPlanDetails } from '../../../../../modules/plans/types';
import { FORM_FIELDS } from '../enums';
import routes from '../../../../../routes';

type Props = {
  companyPlanId: string;
  loading: boolean;
  companyPlan?: CompanyPlanDetails;
};

export default function Content({ companyPlanId, loading, companyPlan }: Props) {
  return (
    <Page
      browserTabName={i18n.ptBR.BROWSER_TAB_NAME}
      className={styles.pageContainer}
      childrenClassName={styles.content}
    >
      <h1 className={styles.pageTitle}>{i18n.ptBR.PAGE_TITLE}</h1>

      {loading || !companyPlan ? (
        <Loader />
      ) : (
        <>
          <div className={styles.companyPlanInfoPanel}>
            <h2 className={styles.companyPlanName}>{companyPlan.name}</h2>

            <div className={styles.companyPlanInfosWrapper}>
              <div className={styles.companyPlanInfo}>
                <span className={styles.companyPlanInfoLabel}>
                  {i18n.ptBR.COMPANY_PLAN_INFO.STATUS}
                </span>
                <span className={styles.companyPlanInfoValue}>
                  {i18n.ptBR.PLAN_STATUS[companyPlan.planStatusId]}
                </span>
              </div>
              {companyPlan.reportedAt ? (
                <div className={styles.companyPlanInfo}>
                  <span className={styles.companyPlanInfoLabel} style={{ textAlign: 'center' }}>
                    {i18n.ptBR.COMPANY_PLAN_INFO.REPORT_DATE}
                  </span>
                  <span className={styles.companyPlanInfoValue}>
                    {dayjs(companyPlan.reportedAt).format('L')}
                  </span>
                </div>
              ) : null}
              <div className={styles.companyPlanInfo}>
                <span className={styles.companyPlanInfoLabel} style={{ textAlign: 'center' }}>
                  {i18n.ptBR.COMPANY_PLAN_INFO.TYPE}
                </span>
                <span className={styles.companyPlanInfoValue}>
                  {i18n.ptBR.COMPANY_PLAN_TYPES[companyPlan.companyPlanTypeId]}
                </span>
              </div>
              <div className={styles.companyPlanInfo}>
                <span className={styles.companyPlanInfoLabel} style={{ textAlign: 'end' }}>
                  {i18n.ptBR.COMPANY_PLAN_INFO.YEAR}
                </span>
                <span className={styles.companyPlanInfoValue}>{companyPlan.fiscalYear}</span>
              </div>
            </div>
          </div>

          <Form variant="ghost" requiredMark={false}>
            <section className={styles.evidencesSection}>
              <h3 className={styles.evidencesSectionTitle}>
                {i18n.ptBR.GOAL_EVIDENCES_SECTION.TITLE}
              </h3>

              {companyPlan.plans.map((plan) => (
                <div key={plan.id} className={styles.plan}>
                  <Plan fieldsPrefix={FORM_FIELDS.PLANS_PREFIX} plan={plan} />
                </div>
              ))}
            </section>

            <Divider type="line" className={styles.bottomDivider} />

            <div className={styles.formButtonsContainer}>
              <Button
                type="text"
                text={i18n.ptBR.RETURN_BUTTON}
                href={routes.VIEW_COMPANY_PLAN.replace(':id', companyPlanId)}
                className={styles.returnButton}
              />
            </div>
          </Form>
        </>
      )}
    </Page>
  );
}
