import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Content from './content';
import ErrorHandler from '../../error-handler';

import { logout } from '../../../modules/authentication/service';
import routes from '../../../routes';
import HttpError from '../../../cross-cutting/errors/http-error';
import { getTotalAndFreeStorageIndicator } from '../../../modules/indicators/service';
import { GetTotalAndFreeStorageIndicatorResponse } from '../../../modules/indicators/types';

export default function NewHeader() {
  const [error, setError] = useState<HttpError>();
  const [totalAndFreeStorageIndicator, setTotalAndFreeStorageIndicator] =
    useState<GetTotalAndFreeStorageIndicatorResponse>();
  const navigate = useNavigate();
  const handleClickLogout = async () => {
    try {
      await logout();
      navigate(routes.LOGIN);
      window.location.reload();
    } catch (err) {
      if (err instanceof HttpError) {
        setError(err);
      }
    }
  };
  useEffect(() => {
    // fetch total and free storage
    const fetchTotalAndFreeStorageIndicator = async () => {
      try {
        const response = await getTotalAndFreeStorageIndicator();
        setTotalAndFreeStorageIndicator(response);
      } catch (err) {
        if (err instanceof HttpError) {
          setError(err);
        }
      }
    };

    fetchTotalAndFreeStorageIndicator();
  }, []);

  return (
    <ErrorHandler error={error}>
      <Content
        totalAndFreeStorageIndicator={totalAndFreeStorageIndicator}
        onClickLogout={handleClickLogout}
      />
    </ErrorHandler>
  );
}
