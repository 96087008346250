import Modal from '../../../../../../common-components/modal';
import DraggerUpload from '../../../../../../common-components/dragger-upload';
import Button from '../../../../../../common-components/button';
import Icon from '../../../../../../common-components/icon';
import Divider from '../../../../../../common-components/divider';
import Alert from '../../../../../../common-components/alert';
import Table from '../../../../../../common-components/table';
import columns from './columns';

import convertBoldTextToJsx from '../../../../../../cross-cutting/utils/convert-bold-text-to-jsx';

import { DOCUMENT_TEMPLATE_NAMES } from '../../../../../../modules/plans/enums';
import { PlanGetOneCompanyPlan } from '../../../../../../modules/plans/types';
import pevsTemplate from '../../../../../../assets/documents/TEMPLATE_PEVS.xlsx';

import i18n from './i18n';
import { getOneAttachment } from '../../../../../../modules/attachment/service';

type ProgramDetailsModalProps = {
  selectedProgram?: PlanGetOneCompanyPlan;
  visible: boolean;
  onCancel: () => void;
  shouldRenderGeographicGoal: boolean;
};
function ProgramDetailsModal({
  selectedProgram,
  visible,
  onCancel,
  shouldRenderGeographicGoal,
}: ProgramDetailsModalProps) {
  const handleDownloadFile = async (file: { id: string; name: string; type: string }) => {
    await getOneAttachment(file.id, { download: true });
  };

  const unitOfMeasure = selectedProgram?.program.fiscalYear?.goalUnitOfMeasureId;
  const goal = selectedProgram?.program.fiscalYear.goal;

  return (
    <Modal
      destroyOnClose
      visible={visible}
      title={selectedProgram?.program.name}
      onCancel={onCancel}
      footer={
        <Button
          style={{ marginLeft: 'auto' }}
          text={i18n.ptBR.BUTTONS.RETURN}
          onClick={onCancel}
          newDesign
        />
      }
      closable
      closeIcon={<Icon name="closeIcon" size={40} />}
    >
      {selectedProgram?.program.fiscalYear?.fiscalYear && (
        <Alert
          message={
            <span>
              {convertBoldTextToJsx(
                i18n.ptBR.FIELDS.INFORMATION.TITLE(selectedProgram?.program.fiscalYear.fiscalYear),
              )}
            </span>
          }
          type="admin"
          iconName="alertCircle"
          style={{ marginBottom: '10px' }}
        />
      )}

      <Table<{
        id: number;
        name: string;
        overdueAmount: null | string;
        totalWeightSold: string;
        weightGoal: string;
      }>
        rowKey="id"
        newDesign
        dataSource={selectedProgram?.programWasteTypes || []}
        columns={columns({ unitOfMeasure, goal })}
        listTitle={<span style={{ fontSize: '20px' }}>{i18n.ptBR.FIELDS.METRICS.TITLE}</span>}
        totalItems={selectedProgram?.programWasteTypes.length}
        pagination={false}
      />

      {shouldRenderGeographicGoal && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '35px',
            }}
          >
            <span style={{ fontWeight: '600', fontSize: '20px' }}>
              {i18n.ptBR.FIELDS.GEOGRAPHIC_GOAL.TITLE}
            </span>
            <a download={DOCUMENT_TEMPLATE_NAMES.PEVS} href={pevsTemplate}>
              <Button
                icon={<Icon name="download" />}
                text={i18n.ptBR.PEVS_TEMPLATES.BUTTON}
                newDesign
              />
            </a>
          </div>

          <DraggerUpload
            loading={false}
            formats={['xls']}
            multiple={false}
            style={{ marginBottom: '10px' }}
            maxCount={1}
            fileData={[
              {
                id: selectedProgram?.geographicGoalAttachment?.uploadedFileId || '',
                name: selectedProgram?.geographicGoalAttachment?.uploadedFile.originalName || '',
                type: selectedProgram?.geographicGoalAttachment?.uploadedFile.extension || '',
                status: 'done',
              },
            ]}
            onlyDisplayList
            onDownload={handleDownloadFile}
            showUploadList={{
              showDownloadIcon: true,
              downloadIcon: <Icon name="download" color="#1351B4" />,
              showRemoveIcon: false,
            }}
            onRemove={() => {}}
            onChange={() => {}}
            onFileChange={() => {}}
          />
        </>
      )}

      <div style={{ display: 'flex', flexDirection: 'column', justifySelf: 'flex' }}>
        <Divider type="line" style={{ margin: '40px 0px 20px 0px' }} />
        <span style={{ fontSize: '20px', fontWeight: '600' }}>
          {i18n.ptBR.FIELDS.DIVULGATION.TITLE}
        </span>
        <span style={{ fontWeight: '600', marginTop: '10px' }}>
          {i18n.ptBR.FIELDS.DIVULGATION.DESCRIPTION}
        </span>
        <span style={{ fontSize: '17px' }}>{selectedProgram?.reverseLogisticsWebsite}</span>
      </div>
      <Divider type="line" style={{ marginTop: '40px' }} />
    </Modal>
  );
}

export default ProgramDetailsModal;
