const apiDomain = process.env.REACT_APP_API_DOMAIN || '';
const apiRoot = process.env.REACT_APP_API_ROOT || '/';

let apiBaseUrl = `${apiDomain}${apiRoot}`;
const apiBaseUrlLastChar = apiBaseUrl.slice(-1);

if (apiBaseUrlLastChar === '/') {
  apiBaseUrl = apiBaseUrl.slice(0, -1);
}

const config = {
  PUBLIC_URL: process.env.PUBLIC_URL || '/',
  API_BASE_URL: apiBaseUrl,
};

export default config;
