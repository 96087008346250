import { GOAL_UNITS_OF_MEASUREMENT } from '../../../../../../../modules/plans/enums';
import { ProgramNamesUrl } from '../../../../../../../modules/programs/enums';

export default {
  PROGRAMS_NAME: {
    [ProgramNamesUrl[0]]: 'Agrotóxicos, seus Resíduos e Embalagens',
    [ProgramNamesUrl[1]]: 'Baterias de Chumbo Ácido',
    [ProgramNamesUrl[2]]: 'Eletroeletrônicos e Seus Componentes',
    [ProgramNamesUrl[3]]: 'Embalagens de Aço',
    [ProgramNamesUrl[4]]: 'Embalagens Plásticas de Óleos Lubrificantes',
    [ProgramNamesUrl[5]]: 'Embalagens em Geral',
    [ProgramNamesUrl[6]]: 'Lâmpadas Fluorescentes, de Vapor de Sódio e Mercúrio e de Luz Mista',
    [ProgramNamesUrl[7]]: 'Medicamentos, seus Resíduos e Embalagens',
    [ProgramNamesUrl[8]]: 'Óleos Lubrificantes Usados ou Contaminados (OLUC)',
    [ProgramNamesUrl[9]]: 'Pilhas e Baterias',
    [ProgramNamesUrl[10]]: 'Pneus Inservíveis',
    [ProgramNamesUrl[11]]: 'Latas de Alumínio para Bebidas',
  },
  BUTTONS: {
    RETURN: 'Voltar',
  },
  PEVS_TEMPLATES: {
    BUTTON: 'Baixar template dos PEVS',
  },
  FIELDS: {
    METRICS: {
      TITLE: 'Metas',
    },
    GEOGRAPHIC_GOAL: {
      TITLE: 'Meta geográfica • Pontos de coleta',
    },
    DIVULGATION: {
      TITLE: 'Ações de divulgação e Educação Ambiental',
      DESCRIPTION: 'Link da página na internet com a divulgação da Logística Reversa',
    },
    INFORMATION: {
      TITLE: (year: string | number) => `*Informação.* Ano base ${year}`,
    },
  },

  LIST_FIELDS: {
    RESIDUE_OBJECT: {
      LABEL: 'Resíduo Objeto do Sistema',
    },
    KG_QUANTITY: {
      LABEL: 'Qnt. em Ton',
      ERRORS: {
        MIN_REQUIRED: 'A meta declarada deve ser igual ou maior que a meta exigida',
      },
    },
    REQUIRED_GOAL: {
      LABEL: 'Mínima exigida',
      ERRORS: {
        MIN_REQUIRED: 'A meta declarada deve ser igual ou maior que a meta exigida',
      },
    },
    KG_QUANTITY_COLLECTED: {
      LABEL: 'Qnt. (ton)coletada',
    },
    FINAL_GOAL: {
      LABEL: 'Final declarada',
    },
    ACTIONS: {
      LABEL: 'Ações',
    },
  },
  FORM_FIELDS: {
    RESIDUE_OBJECT: {
      LABEL: 'Resíduo Objeto do Sistema',
      PLACEHOLDER: 'Selecione o objeto',
    },
    TON_QUANTITY: {
      LABEL: 'Quantidade (ton) colocada no mercado no ano base',
    },
    REQUIRED_GOAL: {
      LABEL: 'Meta exigida',
    },
    DECLARED_GOAL: {
      LABEL: 'Quantidade (ton) a ser coletada',
    },
    FINAL_GOAL: {
      LABEL: 'Minha meta final',
    },
  },
  GOAL_UNIT_OF_MEASURE: {
    [GOAL_UNITS_OF_MEASUREMENT.TON_PER_YEAR]: 't/ano',
  },
};
