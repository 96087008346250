import { PLAN_STATUS_ID } from '../../../../../modules/plans/enums';

export default {
  PAGE_TITLE: 'Lista de Planos de Empresa / Individual',

  SYSTEM_TITLE: 'Sistema de Logística Reversa',
  SECTION_TITLE: 'Planos',

  COLUMNS: {
    LABELS: {
      PLAN: 'Plano',
      YEAR: 'Ano',
      STATUS: 'Status',
      ACTIONS: 'Ações',
    },
    PLAN: {
      CURRENT_YEAR: 'Vigente',
    },
    STATUS: {
      [PLAN_STATUS_ID.INCOMPLETE]: 'Incompleto',
      [PLAN_STATUS_ID.NOT_SENT]: 'Não enviado',
      [PLAN_STATUS_ID.SENT]: 'Enviado',
    },
  },
  BUTTONS: {
    NEW_COMPANY_PLAN: 'Novo Plano',
    VIEW_COMPANY_PLAN: 'Detalhes',
    EDIT_COMPANY_PLAN: 'Editar',
    VIEW_DECLARATION: 'Visualizar declaração',
  },
};
