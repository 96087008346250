import React, { CSSProperties, ReactElement } from 'react';

import styles from './index.module.css';
import colors from '../../colors';
import Icon from '../icon';
import icons from '../icon/icons';

type Props = {
  text?: string;
  icon?: keyof typeof icons | ReactElement;
  color?: string;
  backgroundColor?: string;
  className?: string;
  style?: CSSProperties;
  onClose?: () => void;
};

function Tag({ text, icon, color, backgroundColor, className, style: propStyle, onClose }: Props) {
  const style = {
    ...propStyle,
    color: color || colors.cobalt,
    backgroundColor: backgroundColor || colors.sky,
  };

  return (
    <div className={`${styles.tagContainer} ${className}`} style={style}>
      {icon &&
        (React.isValidElement(icon) ? (
          <div className={styles.tagIcon}>{icon}</div>
        ) : (
          <Icon name={icon} className={styles.tagIcon} />
        ))}

      {text}

      {onClose && (
        <button type="button" className={styles.closeButton} onClick={onClose}>
          <Icon name="xCircle" color={color} />
        </button>
      )}
    </div>
  );
}

export default Tag;
