export default {
  PAGE_TITLE: 'Editar Plano',

  SUCCESS_NOTIFICATION: {
    MESSAGE: 'Plano atualizado com sucesso.',
  },
  BUTTONS: {
    VIEW_COMPANY_PLAN: 'Ver Plano',
  },
  ACCEPT_TERMS_NOTIFICATION: 'Você precisa aceitar os termos de uso para editar um plano.',
  FILE_SENDED: 'Arquivo enviado com sucesso',

  SELECT_FISCAL_YEAR: {
    MESSAGE: 'Selecione o ano fiscal',
    DESCRIPTION: 'Para configurar o sistema, selecione um ano fiscal',
  },
  FILE_SIZE_NOTIFICATION: (fileSize: number) =>
    `O arquivo selecionado precisa ter no máximo ${fileSize}kbs`,
};
