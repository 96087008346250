type GenericObject = Record<string, unknown>;

const objectGetByPath = (object: unknown, path: string): unknown => {
  if (!path) {
    return object;
  }

  if (!object) {
    return undefined;
  }

  const [firstKey, ...restKeys] = path.split('.');

  return objectGetByPath((object as GenericObject)[firstKey], restKeys.join('.'));
};

const objectGet = (
  object: GenericObject,
  getter: string | number | readonly (string | number)[] | ((object: GenericObject) => unknown),
) => {
  switch (typeof getter) {
    case 'function': {
      return getter(object);
    }

    case 'string': {
      return objectGetByPath(object, getter);
    }

    case 'number': {
      return object[getter];
    }

    default: {
      return objectGetByPath(object, getter.join('.'));
    }
  }
};

export default objectGet;
