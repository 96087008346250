import { CSSProperties } from 'react';

import styles from './index.module.css';

type SystemCardProps = {
  title: string;
  image: string;
  style?: CSSProperties;
};
function SystemCard({ title, image, style }: SystemCardProps) {
  return (
    <div
      className={styles.container}
      style={{
        background: 'white',
        ...style,
      }}
    >
      <img src={image} alt={title} />
      <div className={styles.textContainer}>
        <span className={styles.title}>{title}</span>
      </div>
    </div>
  );
}

export default SystemCard;
