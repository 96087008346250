import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form } from 'antd';
import BigNumber from 'bignumber.js';

import ErrorHandler from '../../../../common-components/error-handler';
import HttpError from '../../../../cross-cutting/errors/http-error';
import { HTTP_STATUS_CODES } from '../../../../enums';
import { createCompanyPlanDeclaration, getOneCompanyPlan } from '../../../../modules/plans/service';
import type { CompanyPlanDetails } from '../../../../modules/plans/types';
import i18n from './content/i18n';
import Content from './content';
import { API_ERRORS } from './enums';
import type { FormValues } from './types';
import routes from '../../../../routes';

export default function CreateDeclaration() {
  const { id: companyPlanId } = useParams<'id'>();
  const navigate = useNavigate();
  const [formInstance] = Form.useForm();

  const [loading, setLoading] = useState(true);
  const [sendDeclarationLoading, setSendDeclarationLoading] = useState(false);
  const [error, setError] = useState<HttpError>();
  const [companyPlan, setCompanyPlan] = useState<CompanyPlanDetails>();
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);

  const handleFormSubmit = async () => {
    await formInstance.validateFields();
    setConfirmationModalVisible(true);
  };

  const handleCancelConfirmation = () => {
    setConfirmationModalVisible(false);
  };

  const handleConfirm = () => {
    formInstance.submit();
  };

  const handleFinish = async (formValues: FormValues) => {
    if (!companyPlanId) return;
    setSendDeclarationLoading(true);

    try {
      const declarations = Object.entries(formValues.plans).map(([planId, value]) => {
        const { wasteTypes, evidenceUpload } = value;

        const payloadWasteTypes = Object.entries(wasteTypes).map(
          ([wasteTypeId, { recycledAmount }]) => {
            const recycledAmountAsTon = new BigNumber(recycledAmount ?? 0).times(1000).toNumber();
            return {
              programWasteTypeId: wasteTypeId,
              amountCollected: recycledAmountAsTon ?? 0,
            };
          },
        );

        return {
          planId,
          wasteTypes: payloadWasteTypes,
          uploadId: evidenceUpload[0],
        };
      });

      await createCompanyPlanDeclaration(companyPlanId, {
        declarations,
      });
      navigate(routes.VIEW_COMPANY_PLAN.replace(':id', companyPlanId));
    } catch (err) {
      if (err instanceof HttpError) {
        setError(err);
      }
    }
    setSendDeclarationLoading(false);
  };

  useEffect(() => {
    const fetchCompanyPlan = async (id: string) => {
      try {
        const response = await getOneCompanyPlan(id);
        setCompanyPlan(response);
      } catch (err) {
        if (err instanceof HttpError) {
          setError(err);
        }
      }
      setLoading(false);
    };
    if (companyPlanId) {
      fetchCompanyPlan(companyPlanId);
    }
  }, [companyPlanId]);

  return (
    <ErrorHandler
      error={error}
      options={[
        {
          code: HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY,
          types: {
            [API_ERRORS.COMPANY_PLAN_NOT_APPROVED]: {
              title: i18n.ptBR.API_ERRORS.COMPANY_PLAN_NOT_APPROVED.TITLE,
              description: i18n.ptBR.API_ERRORS.COMPANY_PLAN_NOT_APPROVED.DESCRIPTION,
            },
            [API_ERRORS.UPLOADED_FILE_NOT_FOUND]: {
              title: i18n.ptBR.API_ERRORS.UPLOADED_FILE_NOT_FOUND.TITLE,
              description: i18n.ptBR.API_ERRORS.UPLOADED_FILE_NOT_FOUND.DESCRIPTION,
            },
            [API_ERRORS.PLAN_ALREADY_HAS_DECLARATION]: {
              title: i18n.ptBR.API_ERRORS.PLAN_ALREADY_HAS_DECLARATION.TITLE,
              description: i18n.ptBR.API_ERRORS.PLAN_ALREADY_HAS_DECLARATION.DESCRIPTION,
              severity: 'warning',
            },
            [API_ERRORS.DEADLINE_HAS_PASSED]: {
              title: i18n.ptBR.API_ERRORS.DEADLINE_HAS_PASSED.TITLE,
              description: i18n.ptBR.API_ERRORS.DEADLINE_HAS_PASSED.DESCRIPTION,
            },
          },
        },
      ]}
    >
      {companyPlanId ? (
        <Content
          formInstance={formInstance}
          companyPlanId={companyPlanId}
          loading={loading}
          companyPlan={companyPlan}
          confirmationModalVisible={confirmationModalVisible}
          onFormSubmit={handleFormSubmit}
          onCancelConfirmation={handleCancelConfirmation}
          onConfirm={handleConfirm}
          sendDeclarationLoading={sendDeclarationLoading}
          onFinish={handleFinish}
        />
      ) : null}
    </ErrorHandler>
  );
}
