import { Space } from 'antd';

import DashboardCard from '../../../dashboard-card';
import LineGraph from '../../../../../../../common-components/graphs/line';

import { DashboardFilters } from '../../../../../../../modules/indicators/types';
import { ProgramNamesUrl } from '../../../../../../../modules/programs/enums';

import i18n from './i18n';

export default function Content({
  loading,
  filters,
  graphData,
}: {
  filters: DashboardFilters;
  loading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  graphData: any;
}) {
  const selectedFilters = () => {
    const programName = filters?.programId
      ? i18n.ptBR.PROGRAM[ProgramNamesUrl[filters.programId - 1]]
      : undefined;

    const companyType = filters?.companyTypeId && i18n.ptBR.COMPANY_TYPE[filters?.companyTypeId];
    const region = filters?.region && i18n.ptBR.REGION[filters?.region];

    return [programName, filters?.year?.toString(), companyType, region];
  };

  return (
    <DashboardCard
      iconName="lineGraph"
      title={i18n.ptBR.INDICATOR.TITLE}
      loading={loading}
      selectedFilters={selectedFilters()}
    >
      <Space size={20} direction="horizontal" style={{ marginTop: '58px' }} align="center" wrap>
        <div style={{ height: '362px', width: '637px', backgroundColor: '#fff' }}>
          <LineGraph data={graphData} />
        </div>
      </Space>
    </DashboardCard>
  );
}
