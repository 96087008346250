import { REGION } from '../../../../../../enums';
import { COMPANY_PLAN_TYPE_ID } from '../../../../../../modules/plans/enums';
import { ProgramNamesUrl } from '../../../../../../modules/programs/enums';

export default {
  PAGE_TITLE: 'Declarações Anuais de Coleta',

  SECTION_TITLE: 'Declarações Anuais de Coleta',

  COLUMNS: {
    LABELS: {
      LEGAL_NAME: 'Razão Social',
      CNPJ: 'CNPJ',
      PLAN_TYPE: 'Tipo',
      STATE: 'Estado',
      YEAR: 'Ano referente',
      ACTIONS: 'Ações',
    },
    STATES: {
      AC: 'Acre',
      AL: 'Alagoas',
      AP: 'Amapá',
      AM: 'Amazonas',
      BA: 'Bahia',
      CE: 'Ceará',
      ES: 'Espírito Santo',
      GO: 'Goiás',
      MA: 'Maranhão',
      MT: 'Mato Grosso',
      MS: 'Mato Grosso do Sul',
      MG: 'Minas Gerais',
      PA: 'Pará',
      PB: 'Paraíba',
      PR: 'Paraná',
      PE: 'Pernambuco',
      PI: 'Piauí',
      RJ: 'Rio de Janeiro',
      RN: 'Rio Grande do Norte',
      RS: 'Rio Grande do Sul',
      RO: 'Rondônia',
      RR: 'Roraima',
      SC: 'Santa Catarina',
      SP: 'São Paulo',
      SE: 'Sergipe',
      TO: 'Tocantins',
      DF: 'Distrito Federal',
    },
  },

  PROGRAMS_NAME: {
    [ProgramNamesUrl[0]]: 'Agrotóxicos, seus Resíduos e Embalagens',
    [ProgramNamesUrl[1]]: 'Baterias de Chumbo Ácido',
    [ProgramNamesUrl[2]]: 'Eletroeletrônicos e Seus Componentes',
    [ProgramNamesUrl[3]]: 'Embalagens de Aço',
    [ProgramNamesUrl[4]]: 'Embalagens Plásticas de Óleos Lubrificantes',
    [ProgramNamesUrl[5]]: 'Embalagens em Geral',
    [ProgramNamesUrl[6]]: 'Lâmpadas Fluorescentes, de Vapor de Sódio e Mercúrio e de Luz Mista',
    [ProgramNamesUrl[7]]: 'Medicamentos, seus Resíduos e Embalagens',
    [ProgramNamesUrl[8]]: 'Óleos Lubrificantes Usados ou Contaminados (OLUC)',
    [ProgramNamesUrl[9]]: 'Pilhas e Baterias',
    [ProgramNamesUrl[10]]: 'Pneus Inservíveis',
    [ProgramNamesUrl[11]]: 'Latas de Alumínio para Bebidas',
  },
  FILTERS: {
    FISCAL_YEAR: {
      LABEL: 'Ano referente',
      PLACEHOLDER: 'Selecione o ano referente',
    },
    LEGAL_NAME_OR_CNPJ: {
      PLACEHOLDER: 'Busque por um CNPJ ou Razão Social',
    },
    REGION: {
      LABEL: 'Região',
      PLACEHOLDER: 'Selecione a região',
      OPTIONS: {
        [REGION.NORTH]: 'Norte',
        [REGION.NORTHEAST]: 'Nordeste',
        [REGION.MIDWEST]: 'Centro-Oeste',
        [REGION.SOUTHEAST]: 'Sudeste',
        [REGION.SOUTH]: 'Sul',
      },
    },
    PLAN_TYPE: {
      LABEL: 'Tipo',
      PLACEHOLDER: 'Selecione o tipo',
      OPTIONS: {
        [COMPANY_PLAN_TYPE_ID.INDIVIDUAL]: 'Individual',
        [COMPANY_PLAN_TYPE_ID.COLLECTIVE]: 'Coletivo',
      },
    },
  },
};
