import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Content from './content';

import { logout } from '../../../modules/authentication/service';
import routes from '../../../routes';
import HttpError from '../../../cross-cutting/errors/http-error';
import ErrorHandler from '../../error-handler';
import { getOneComplianceCertificate } from '../../../modules/company/service';

export default function Header() {
  const [error, setError] = useState<HttpError>();
  const [complianceCertificateLoading, setComplianceCertificateLoading] = useState(false);
  const navigate = useNavigate();
  const handleClickLogout = async () => {
    try {
      await logout();
      navigate(routes.LOGIN);
      window.location.reload();
    } catch (err) {
      if (err instanceof HttpError) {
        setError(err);
      }
    }
  };

  const handleClickGenerateComplianceCertificate = async () => {
    setComplianceCertificateLoading(true);
    try {
      const complianceCertificate = await getOneComplianceCertificate();

      const url = URL.createObjectURL(complianceCertificate);
      window.open(url);
    } catch (err) {
      if (err instanceof HttpError) {
        setError(err);
      }
    }
    setComplianceCertificateLoading(false);
  };
  return (
    <ErrorHandler error={error}>
      <Content
        onClickGenerateComplianceCertificate={handleClickGenerateComplianceCertificate}
        onClickLogout={handleClickLogout}
        complianceCertificateLoading={complianceCertificateLoading}
      />
    </ErrorHandler>
  );
}
