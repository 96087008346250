import { ReactNode } from 'react';

import styles from './index.module.css';

export default function Field({
  title,
  value,
  mask,
}: {
  title: string;
  value: string | number | ReactNode;
  mask?: (value: string | number) => string;
}) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: 'fit-content' }}>
      <span className={styles.title}>{title}</span>
      {typeof value === 'string' || typeof value === 'number' ? (
        <span className={styles.value}>{mask ? mask(value) : value}</span>
      ) : (
        value
      )}
    </div>
  );
}
