import { useState } from 'react';
import Content from './content';
import ErrorHandler from '../../../../common-components/error-handler';
import { DashboardFilters } from '../../../../modules/indicators/types';

export default function Dashboard() {
  const [filters, setFilters] = useState({});
  const handleFilter = (formValues: DashboardFilters) => {
    setFilters((prevValues) => ({
      ...prevValues,
      programId: formValues.programId,
      companyTypeId: formValues.companyTypeId,
      region: formValues.region,
      year: formValues.year,
    }));
  };

  return (
    <ErrorHandler>
      <Content handleFilter={handleFilter} filters={filters} />
    </ErrorHandler>
  );
}
