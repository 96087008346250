import React, { useState } from 'react';
import { Radio, Divider, Input as AntdInput, RadioChangeEvent, Space, Form } from 'antd';

import Input from '../../../common-components/input';
import Icon from '../../../common-components/icon';
import { FormItem } from '../../../common-components/form';
import Checkbox from '../../../common-components/checkbox';
import Button from '../../../common-components/button';
import Modal from '../../../common-components/modal';
import Alert from '../../../common-components/alert';
import PickList from '../../../common-components/pick-list';
import USER_TERMS_OF_SERVICE from '../../../assets/documents/Termo_de_Uso_Sistema_Logistica_Reversa_final.pdf';
import USER_TERMS_OF_PRIVACY from '../../../assets/documents/Politica_de_privacidade_Sistema-Logistica-Reversa_final.pdf';

import isValidCNPJ from '../../../cross-cutting/utils/isValidCNPJ';
import { NewAccountProps } from '../../../modules/user/service';
import masks from '../../../cross-cutting/utils/mask/masks';
import { FIELDS, COMPANY_TYPE_ID, BRAZILIAN_DISTRICTS } from './enums';
import styles from './index.module.css';
import i18n from './i18n';

const adaptStates = () => {
  return BRAZILIAN_DISTRICTS.map((state) => {
    return {
      key: state,
      value: state,
    };
  });
};

const validatedPasswordRules = (
  min: boolean,
  number: boolean,
  letter: boolean,
  special: boolean,
) => {
  const renderPasswordValidateMessage = (
    className: string,
    message: string,
    icon: React.ReactNode,
  ) => {
    return (
      <div className={className}>
        {icon}
        <span>{message}</span>
      </div>
    );
  };

  const passLengthText = i18n.ptBR.FIELDS.PASSWORD.VALIDATION_MESSAGES.INVALID_PASSWORD_LENGTH;
  const noNumberText = i18n.ptBR.FIELDS.PASSWORD.VALIDATION_MESSAGES.MISSING_NUMBER;
  const noLetterText = i18n.ptBR.FIELDS.PASSWORD.VALIDATION_MESSAGES.MISSING_LETTER;
  const noSpecialText = i18n.ptBR.FIELDS.PASSWORD.VALIDATION_MESSAGES.MISSING_SPECIAL_CHARACTER;

  const errorIcon = <Icon name="xCircle" className={styles.icon} />;
  const sucessIcon = <Icon name="alertCircle" className={styles.icon} />;

  return (
    <Space size={2} direction="vertical" align="start" style={{ marginTop: '8px' }}>
      {!min
        ? renderPasswordValidateMessage(styles.passwordError, passLengthText, errorIcon)
        : renderPasswordValidateMessage(styles.passwordSuccess, passLengthText, sucessIcon)}
      {!number
        ? renderPasswordValidateMessage(styles.passwordError, noNumberText, errorIcon)
        : renderPasswordValidateMessage(styles.passwordSuccess, noNumberText, sucessIcon)}
      {!letter
        ? renderPasswordValidateMessage(styles.passwordError, noLetterText, errorIcon)
        : renderPasswordValidateMessage(styles.passwordSuccess, noLetterText, sucessIcon)}
      {!special
        ? renderPasswordValidateMessage(styles.passwordError, noSpecialText, errorIcon)
        : renderPasswordValidateMessage(styles.passwordSuccess, noSpecialText, sucessIcon)}
    </Space>
  );
};

export default function RegistrationForm({
  onFinish,
  finishing,
}: {
  onFinish: (values: NewAccountProps) => void;
  finishing: boolean;
}) {
  const [companyType, setCompanyType] = useState();
  const [completeRegisterModalVisible, setCompleteRegisterModalVisible] = useState(false);
  const [form] = Form.useForm();

  const onChangeCompanyType = (e: RadioChangeEvent) => {
    setCompanyType(e.target.value);
  };

  return (
    <Form form={form} layout="vertical">
      <div>
        <FormItem
          name={FIELDS.COMPANY_TYPE}
          label={i18n.ptBR.FIELDS.COMPANY_TYPE_ID.LABEL}
          rules={[{ required: true, message: i18n.ptBR.FIELDS.COMPANY_TYPE_ID.VALIDATION_MESSAGE }]}
        >
          <Radio.Group
            value={companyType}
            onChange={onChangeCompanyType}
            style={{ fontSize: '12px' }}
          >
            <Radio value={COMPANY_TYPE_ID.MANUFACTURER} className={styles.radioButton}>
              <span>{i18n.ptBR.FIELDS.COMPANY_TYPE_ID.VALUES.MANUFACTURER}</span>
            </Radio>
            <Radio value={COMPANY_TYPE_ID.MANUFACTURER_MANAGER} className={styles.radioButton}>
              <span>{i18n.ptBR.FIELDS.COMPANY_TYPE_ID.VALUES.MANUFACTURER_MANAGER}</span>
            </Radio>
          </Radio.Group>
        </FormItem>
        <FormItem
          name={FIELDS.CNPJ}
          label={i18n.ptBR.FIELDS.CNPJ.LABEL}
          rules={[
            {
              required: true,
            },
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.resolve();
                }

                if (!isValidCNPJ(value)) {
                  return Promise.reject(
                    new Error(i18n.ptBR.FIELDS.CNPJ.VALIDATION_MESSAGES.INVALID_CNPJ),
                  );
                }

                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input mask={masks.ptBR.registrationNumber[1]} />
        </FormItem>
        <FormItem
          name={FIELDS.LEGAL_NAME}
          label={i18n.ptBR.FIELDS.LEGAL_NAME.LABEL}
          rules={[{ required: true }]}
        >
          <Input maxLength={255} />
        </FormItem>
        <FormItem
          name={FIELDS.CNAE}
          label={i18n.ptBR.FIELDS.CNAE.LABEL}
          rules={[{ required: true }]}
        >
          <Input maxLength={45} />
        </FormItem>
        <FormItem
          name={FIELDS.PHONE}
          label={i18n.ptBR.FIELDS.PHONE.LABEL}
          rules={[{ required: true }]}
        >
          <Input mask={masks.ptBR.phone} maxLength={45} />
        </FormItem>
      </div>

      <div style={{ marginTop: '40px' }}>
        <span className={styles.sectionTitle}>{i18n.ptBR.SECTIONS.ADDRESS}</span>

        <FormItem
          style={{ marginTop: '16px' }}
          label={i18n.ptBR.FIELDS.POSTAL_CODE.LABEL}
          name={[FIELDS.ADDRESS_FIELDS, FIELDS.ADDRESS.POSTAL_CODE]}
          rules={[{ required: true }]}
        >
          <Input mask={masks.ptBR.postalCode} />
        </FormItem>
        <FormItem
          label={i18n.ptBR.FIELDS.STREET.LABEL}
          name={[FIELDS.ADDRESS_FIELDS, FIELDS.ADDRESS.STREET]}
          rules={[{ required: true }]}
        >
          <Input maxLength={255} />
        </FormItem>
        <FormItem
          label={i18n.ptBR.FIELDS.NUMBER.LABEL}
          name={[FIELDS.ADDRESS_FIELDS, FIELDS.ADDRESS.NUMBER]}
          rules={[{ required: true }]}
        >
          <Input maxLength={45} />
        </FormItem>
        <FormItem
          label={i18n.ptBR.FIELDS.NEIGHBORHOOD.LABEL}
          name={[FIELDS.ADDRESS_FIELDS, FIELDS.ADDRESS.NEIGHBORHOOD]}
          rules={[{ required: true }]}
        >
          <Input maxLength={255} />
        </FormItem>
        <FormItem
          label={i18n.ptBR.FIELDS.COMPLEMENT.LABEL}
          name={[FIELDS.ADDRESS_FIELDS, FIELDS.ADDRESS.COMPLEMENT]}
        >
          <Input maxLength={255} />
        </FormItem>
        <FormItem
          label={i18n.ptBR.FIELDS.STATE.LABEL}
          name={[FIELDS.ADDRESS_FIELDS, FIELDS.ADDRESS.STATE]}
          rules={[{ required: true }]}
        >
          <PickList
            options={adaptStates()}
            className=""
            disabled={false}
            loading={false}
            placeholder={i18n.ptBR.FIELDS.STATE.PLACEHOLDER}
            size="large"
          />
        </FormItem>
        <FormItem
          label={i18n.ptBR.FIELDS.CITY.LABEL}
          name={[FIELDS.ADDRESS_FIELDS, FIELDS.ADDRESS.CITY]}
          rules={[{ required: true }]}
        >
          <Input maxLength={255} />
        </FormItem>
      </div>

      <Divider />

      <FormItem
        label={i18n.ptBR.FIELDS.EMAIL.LABEL}
        name={FIELDS.EMAIL}
        rules={[{ required: true }, { type: 'email' }]}
      >
        <Input maxLength={255} />
      </FormItem>

      <FormItem
        noStyle
        shouldUpdate={(prevValues, newValues) => {
          return prevValues[FIELDS.PASSWORD] !== newValues[FIELDS.PASSWORD];
        }}
      >
        {({ getFieldValue }) => {
          const password = getFieldValue(FIELDS.PASSWORD) ?? false;

          const hasMinLength = password.length >= 8;
          const hasOneLetter = password && /(?=.*[A-Za-z])/.test(password);
          const hasOneNumber = password && /(?=.*\d)/.test(password);
          const hasOneSpecialChar = password && /(?=.*[@$!%*#?&])/.test(password);

          return (
            <FormItem
              label={i18n.ptBR.FIELDS.PASSWORD.LABEL}
              name={FIELDS.PASSWORD}
              help={validatedPasswordRules(
                hasMinLength,
                hasOneNumber,
                hasOneLetter,
                hasOneSpecialChar,
              )}
              className={styles.passwordField}
              rules={[
                { required: true },
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.resolve();
                    }

                    if (value.length < 8) {
                      return Promise.reject(
                        new Error(
                          i18n.ptBR.FIELDS.PASSWORD.VALIDATION_MESSAGES.INVALID_PASSWORD_LENGTH,
                        ),
                      );
                    }

                    if (!hasOneLetter) {
                      return Promise.reject(
                        new Error(i18n.ptBR.FIELDS.PASSWORD.VALIDATION_MESSAGES.MISSING_LETTER),
                      );
                    }

                    if (!hasOneNumber) {
                      return Promise.reject(
                        new Error(i18n.ptBR.FIELDS.PASSWORD.VALIDATION_MESSAGES.MISSING_NUMBER),
                      );
                    }

                    if (!hasOneSpecialChar) {
                      return Promise.reject(
                        new Error(
                          i18n.ptBR.FIELDS.PASSWORD.VALIDATION_MESSAGES.MISSING_SPECIAL_CHARACTER,
                        ),
                      );
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <AntdInput.Password minLength={8} maxLength={64} />
            </FormItem>
          );
        }}
      </FormItem>

      <FormItem
        label={i18n.ptBR.FIELDS.CONFIRM_PASSWORD.LABEL}
        name={FIELDS.REPEAT_PASSWORD}
        rules={[
          {
            required: true,
            message: i18n.ptBR.FIELDS.CONFIRM_PASSWORD.VALIDATION_MESSAGES.REQUIRED,
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value) {
                return Promise.resolve();
              }

              if (value !== getFieldValue(FIELDS.PASSWORD)) {
                return Promise.reject(
                  new Error(
                    i18n.ptBR.FIELDS.CONFIRM_PASSWORD.VALIDATION_MESSAGES.INVALID_CONFIRM_PASSWORD,
                  ),
                );
              }

              return Promise.resolve();
            },
          }),
        ]}
      >
        <AntdInput.Password />
      </FormItem>

      <div
        style={{
          marginTop: '30px',
          color: 'var(--green-dark)',
          textDecoration: 'underline',
          display: 'flex',
          gap: '8px',
          fontWeight: 500,
        }}
      >
        <a href={USER_TERMS_OF_SERVICE} download style={{ color: 'var(--green-dark)' }}>
          <span>{i18n.ptBR.FIELDS.USER_TERMS.SERVICE.LABEL}</span>
        </a>
        |
        <a href={USER_TERMS_OF_PRIVACY} download style={{ color: 'var(--green-dark)' }}>
          <span>{i18n.ptBR.FIELDS.USER_TERMS.PRIVACY.LABEL}</span>
        </a>
      </div>

      <FormItem
        name={FIELDS.TERMS_OF_SERVICE}
        valuePropName="checked"
        rules={[
          {
            required: true,
            message: '',
          },
          () => ({
            validator(_, value) {
              if (!value) {
                return Promise.reject(
                  new Error(i18n.ptBR.FIELDS.USER_TERMS.VALIDATION_MESSAGES.MUST_SELECT_SERVICE),
                );
              }

              return Promise.resolve();
            },
          }),
        ]}
      >
        <Checkbox value>{i18n.ptBR.FIELDS.USER_TERMS.SERVICE.VALUE}</Checkbox>
      </FormItem>

      <FormItem
        name={FIELDS.PRIVACY_POLICY}
        valuePropName="checked"
        rules={[
          {
            required: true,
            message: '',
          },
          () => ({
            validator(_, value) {
              if (!value) {
                return Promise.reject(
                  new Error(i18n.ptBR.FIELDS.USER_TERMS.VALIDATION_MESSAGES.MUST_SELECT_PRIVACY),
                );
              }

              return Promise.resolve();
            },
          }),
        ]}
      >
        <Checkbox value>{i18n.ptBR.FIELDS.USER_TERMS.PRIVACY.VALUE}</Checkbox>
      </FormItem>

      <Button
        type="default"
        onClick={() => {
          form.validateFields().then(() => {
            setCompleteRegisterModalVisible(true);
          });
        }}
        loading={finishing}
        text={i18n.ptBR.SUBMIT_BUTTON}
        disabled={finishing}
        block
      />

      <Modal
        title={i18n.ptBR.CONFIRM_MODAL.TITLE}
        footer={
          <Space direction="horizontal" align="end" size={16}>
            <Button
              type="text"
              text={i18n.ptBR.CONFIRM_MODAL.RETURN_BUTTON}
              onClick={() => setCompleteRegisterModalVisible(false)}
            />
            <Button
              type="default"
              onClick={() => {
                onFinish(form.getFieldsValue());
              }}
              loading={finishing}
              text={i18n.ptBR.CONFIRM_BUTTON}
              disabled={finishing}
              block
            />
          </Space>
        }
        visible={completeRegisterModalVisible}
        onCancel={() => setCompleteRegisterModalVisible(false)}
      >
        <div style={{ marginTop: '24px', marginBottom: '8px' }}>
          <span>{i18n.ptBR.CONFIRM_MODAL.MESSAGE}</span>
        </div>
        <Alert iconName="alertCircle" message={i18n.ptBR.CONFIRM_MODAL.ALERT} type="warning" />
      </Modal>
    </Form>
  );
}
