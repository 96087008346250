import { Progress, Space } from 'antd';
import BigNumber from 'bignumber.js';

import DashboardCard from '../../../dashboard-card';

import {
  DashboardFilters,
  GetTotalOfCompaniesIndicatorResponse,
} from '../../../../../../../modules/indicators/types';
import { ProgramNamesUrl } from '../../../../../../../modules/programs/enums';
import routes from '../../../../../../../routes';
import { COMPANY_TYPE_ID } from '../../../../../../user-registration/form/enums';
import i18n from './i18n';
import styles from './index.module.css';
import convertBoldTextToJsx from '../../../../../../../cross-cutting/utils/convert-bold-text-to-jsx';

export default function Content({
  loading,
  companiesCounting,
  filters,
}: {
  filters: DashboardFilters;
  loading: boolean;
  companiesCounting?: GetTotalOfCompaniesIndicatorResponse;
}) {
  const totalManufacturerCompanies = companiesCounting?.find(
    (total) => total.companyTypeId === COMPANY_TYPE_ID.MANUFACTURER,
  )?.total;
  const totalManufacturerManagerCompanies = companiesCounting?.find(
    (total) => total.companyTypeId === COMPANY_TYPE_ID.MANUFACTURER_MANAGER,
  )?.total;

  const totalOfCompanies = new BigNumber(totalManufacturerCompanies || 0)
    .plus(totalManufacturerManagerCompanies || 0)
    .toNumber();

  const selectedFilters = () => {
    const programName = filters?.programId
      ? i18n.ptBR.PROGRAM[ProgramNamesUrl[filters.programId - 1]]
      : undefined;

    const companyType = filters?.companyTypeId && i18n.ptBR.COMPANY_TYPE[filters?.companyTypeId];
    const region = filters?.region && i18n.ptBR.REGION[filters?.region];

    return [programName, filters?.year?.toString(), companyType, region];
  };

  return (
    <DashboardCard
      headerButtonRoute={routes.VIEW_PROGRAM_COMPANIES.replace(
        ':id',
        ProgramNamesUrl[(filters?.programId && filters.programId - 1) || 0],
      )}
      iconName="user"
      title={i18n.ptBR.INDICATOR.TITLE}
      loading={loading}
      selectedFilters={selectedFilters()}
    >
      <Space size={20} direction="horizontal" style={{ marginTop: '58px' }} align="center" wrap>
        <Progress
          type="circle"
          strokeWidth={15}
          width={158}
          strokeLinecap="square"
          strokeColor="var(--blue-warm-vivid)"
          trailColor="#C5D4EB"
          format={() => companiesCounting?.length && totalOfCompanies}
          percent={new BigNumber(totalManufacturerCompanies || 0)
            .div(totalOfCompanies)
            .times(100)
            .toNumber()}
          className={styles.progress}
        />

        <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <p style={{ display: 'flex' }}>
            <div
              className={styles.indicatorLabelItem}
              style={{
                backgroundColor: '#0C326F',
              }}
            />
            {convertBoldTextToJsx(
              i18n.ptBR.INDICATOR.MANUFACTURER(totalManufacturerCompanies || '0'),
            )}
          </p>
          <p style={{ display: 'flex' }}>
            <div
              className={styles.indicatorLabelItem}
              style={{
                backgroundColor: '#C5D4EB',
              }}
            />
            {convertBoldTextToJsx(
              i18n.ptBR.INDICATOR.MANUFACTURER_MANAGER(totalManufacturerManagerCompanies || '0'),
            )}
          </p>
        </div>
      </Space>
    </DashboardCard>
  );
}
