import { Space } from 'antd';
import { useState } from 'react';

import Page from '../../../common-components/page';
import Alert from '../../../common-components/alert';
import Form from '../form';
import SelectProgram from '../select-program';
import Modal from '../../../common-components/modal';
import Button from '../../../common-components/button';
import Divider from '../../../common-components/divider';

import i18n from './i18n';
import styles from './index.module.css';
import { PublicProgram } from '../../../modules/programs/types';
import { NewAccountProps } from '../../../modules/user/service';
import ScrollToTop from '../../../cross-cutting/utils/scroll-to-top';
import routes from '../../../routes';

const REGISTRATION_STEPS = {
  selectProgram: 'selectProgram',
  form: 'form',
};

export default function Content({
  handleFinish,
  programs,
  loading,
  onSelectProgram,
  currentSelectedPrograms,
  finishing,
}: {
  handleFinish: (values: NewAccountProps) => void;
  programs: PublicProgram[];
  loading: boolean;
  onSelectProgram: (checked: boolean, programId: number) => void;
  currentSelectedPrograms: number[];
  finishing: boolean;
}) {
  const [step, setStep] = useState(REGISTRATION_STEPS.selectProgram);
  const [isModalVisible, setModalVisible] = useState(false);

  const onClickContinue = () => {
    if (currentSelectedPrograms.length === 0) {
      setModalVisible(true);
    } else {
      setStep(REGISTRATION_STEPS.form);
    }
  };

  function renderContent() {
    // TODO: adicionar spinner
    if (loading) return <span style={{ padding: '20px' }}>Carregando...</span>;

    switch (step) {
      case REGISTRATION_STEPS.selectProgram:
        return (
          <div style={{ marginBottom: '80px' }}>
            <span className={styles.formDescription} style={{ color: 'var(--dark-green)' }}>
              {i18n.ptBR.FORM_TITLE.SELECT_PROGRAM}
            </span>

            <Alert
              message={i18n.ptBR.ALERT_MESSAGE}
              type="success"
              iconName="alertCircle"
              style={{ marginTop: '32px' }}
            />
            <Space direction="vertical" size={16} style={{ marginTop: '32px' }}>
              <SelectProgram
                programs={programs}
                onSelectProgram={({ target }) => onSelectProgram(target.checked, target.value)}
                currentSelectedPrograms={currentSelectedPrograms}
              />
            </Space>
            <Divider type="line" color="black" style={{ marginTop: '45px' }} />
            <Space
              align="center"
              size={8}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '40px',
              }}
            >
              <Button type="text" text={i18n.ptBR.RETURN_TO_LOGIN_BUTTON} href={routes.LOGIN} />
              <Button type="default" onClick={onClickContinue} text={i18n.ptBR.CONTINUE_BUTTON} />
            </Space>
          </div>
        );
      case REGISTRATION_STEPS.form:
        return (
          <Space direction="vertical" size={16} style={{ marginBottom: '16px' }} align="center">
            <ScrollToTop />
            <span className={styles.formDescription}>{i18n.ptBR.FORM_TITLE.DESCRIPTION}</span>
            <Form onFinish={handleFinish} finishing={finishing} />

            <Button
              type="text"
              onClick={() => setStep(REGISTRATION_STEPS.selectProgram)}
              text={i18n.ptBR.RETURN_BUTTON}
            />
          </Space>
        );
      default:
        return null;
    }
  }

  return (
    <Page
      browserTabName={i18n.ptBR.PAGE_TITLE}
      childrenClassName={styles.pageContainer}
      style={{
        background: step === REGISTRATION_STEPS.selectProgram ? 'var(--green)' : '',
      }}
    >
      <span className={styles.formTitle}>{i18n.ptBR.FORM_TITLE.REGISTER}</span>

      {renderContent()}

      <Modal
        title={i18n.ptBR.MODAL.TITLE}
        visible={isModalVisible}
        onCancel={() => setModalVisible(false)}
        footer={
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="default"
              onClick={() => setModalVisible(false)}
              text={i18n.ptBR.MODAL.CLOSE_BUTTON}
            />
          </div>
        }
      >
        <Alert type="error" message={i18n.ptBR.MODAL.MINIMUM_PROGRAMS} />
      </Modal>
    </Page>
  );
}
