export const COMPANY_PLAN_TYPE_ID = {
  INDIVIDUAL: 1,
  COLLECTIVE: 2,
} as const;

export const PLAN_STATUS_ID = {
  INCOMPLETE: 1,
  NOT_SENT: 2,
  SENT: 3,
} as const;

export const GOAL_UNITS_OF_MEASUREMENT = {
  PERCENTAGE: 1,
  TON_PER_YEAR: 2,
} as const;

export const PLAN_STATUS_URL_ID = {
  PLAN_SENT: 'planSent',
  DECLARATION_SENT: 'declarationSent',
} as const;

export const VALID_CNPJ_FILE_FORMATS = ['xls', 'xlsx'];
export const VALID_PEVS_FILE_FORMATS = ['xls', 'xlsx'];
export const VALID_COMMUNICATION_DOCUMENT_FILE_FORMATS = ['pdf'];

export const DOCUMENT_TEMPLATE_NAMES = {
  CNPJ: 'TEMPLATE_CNPJs_EntidadesGestoras',
  REVERSE_LOGISTICS_REPORT: 'TEMPLATE_REPORT_LR',
  PEVS: 'TEMPLATE_PEVS',
};
