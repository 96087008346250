import { TabPane, TabTitle } from '../../../../common-components/tabs';
import { TABS } from '../enums';
import AssociatedSystems from './associated-systems';
import RegistrationData from './registration-data';
import ReverseLogisticsPlans from './reverse-logistics-plans';
import Declarations from './declarations';

import i18n from '../content/i18n';
import { GetOneCompanyResponse } from '../../../../modules/company/service';

export default function CompanyTabs({
  company,
  companyPlanId,
}: {
  company?: GetOneCompanyResponse;
  companyPlanId: string | null;
}) {
  return [
    <TabPane
      key={TABS.REGISTRATION_DATA}
      tab={<TabTitle text={i18n.ptBR.TABS.REGISTRATION_DATA.TITLE} icon="user" />}
      destroyInactiveTabPane
    >
      <RegistrationData company={company} />
    </TabPane>,
    <TabPane
      key={TABS.ASSOCIATED_SYSTEMS}
      tab={<TabTitle text={i18n.ptBR.TABS.ASSOCIATED_SYSTEMS.TITLE} icon="handshake" />}
      destroyInactiveTabPane
    >
      <AssociatedSystems company={company} />
    </TabPane>,

    <TabPane
      key={TABS.REVERSE_LOGISTICS_PLANS}
      tab={<TabTitle text={i18n.ptBR.TABS.REVERSE_LOGISTICS_PLANS.TITLE} icon="clipboard" />}
      destroyInactiveTabPane
    >
      <ReverseLogisticsPlans company={company} companyPlanIdParam={companyPlanId} />
    </TabPane>,
    <TabPane
      key={TABS.DECLARATIONS}
      tab={<TabTitle text={i18n.ptBR.TABS.DECLARATIONS.TITLE} icon="clipboardCheck" />}
      destroyInactiveTabPane
    >
      <Declarations selectedDeclarationParam={companyPlanId} company={company} />
    </TabPane>,
  ];
}
