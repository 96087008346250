import dayjs from 'dayjs';

const validYearsOptions = () => {
  const initialYear = 2021;
  const finalYear = dayjs().year();
  let currentYear = initialYear;
  const yearList = [];

  while (currentYear <= finalYear) {
    yearList.push(currentYear);
    currentYear += 1;
  }
  return yearList;
};

export default validYearsOptions;
