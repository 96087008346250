import { useContext } from 'react';
import { UserContext } from '../context';

const useUser = () => {
  const { user, setUser } = useContext(UserContext);

  return { user, setUser } as const;
};

export default useUser;
