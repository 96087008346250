export default {
  DESCRIPTION: 'Saiba mais',
  CONFIGURED: 'Configurado',
  NOT_CONFIGURED: 'Não configurado',
  BUTTONS: {
    CONFIGURE: 'Configurar',
    VIEW: 'Visualizar',
  },

  GOALS: {
    QUANTITATIVE_GOAL: 'Meta quantitativa',
    GEOGRAPHIC_GOAL: 'Meta geográfica',
  },
};
