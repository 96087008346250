const baseRoutes = {
  HOME: '/',
  NOT_FOUND: '/not-found',

  // USER MANUAL
  USER_MANUAL: '/static/media/user-manual',
};

const accessRoutes = {
  LOGIN: '/login',
  SIGN_UP: '/sign-up',
  PASSWORD_RECOVERY: '/password-recovery',
};

const privateRoutes = {
  NEW_PLAN: '/new-plan',
  LIST_PLANS: '/plans',
  ADMIN_LIST_PLANS: '/:id/plans',
  VIEW_COMPANY_PLAN: '/view-company-plan/:id',
  EDIT_COMPANY_PLAN: '/edit-company-plan/:id',
  CREATE_DECLARATION: '/company-plan/:id/create-declaration',
  VIEW_DECLARATION: '/company-plan/:id/view-declaration',
  LIST_PROGRAMS: '/reverse-logistics-programs',
  VIEW_PROGRAM_COMPANIES: '/reverse-logistics-programs/:id/companies',
  VIEW_COMPANY_DETAILS: '/view-company/:id',
  LIST_DECLARATIONS: '/:id/declarations',
  DASHBOARD: '/dashboard',
  PLANS_AND_DECLARATIONS_REPORT: '/plans-and-declarations-report',
};

export default {
  ...baseRoutes,
  ...accessRoutes,
  ...privateRoutes,
};
