import {
  COMPANY_PLAN_TYPE_ID,
  GOAL_UNITS_OF_MEASUREMENT,
  PLAN_STATUS_ID,
} from '../../../../../../modules/plans/enums';

export default {
  BROWSER_TAB_NAME: 'Declaração anual de coleta | Sinir+',

  PAGE_TITLE: 'Declaração anual de coleta',

  COMPANY_PLAN_TYPES: {
    [COMPANY_PLAN_TYPE_ID.INDIVIDUAL]: 'Individual',
    [COMPANY_PLAN_TYPE_ID.COLLECTIVE]: 'Coletivo',
  },

  PLAN_STATUS: {
    [PLAN_STATUS_ID.INCOMPLETE]: 'Incompleto',
    [PLAN_STATUS_ID.NOT_SENT]: 'Não enviado',
    [PLAN_STATUS_ID.SENT]: 'Enviado',
  },

  GOAL_UNITS_OF_MEASUREMENT: {
    [GOAL_UNITS_OF_MEASUREMENT.PERCENTAGE]: '%',
    [GOAL_UNITS_OF_MEASUREMENT.TON_PER_YEAR]: ' ton/ano',
  },

  WEIGHT_BASED_GOAL_UNIT_OF_MEASUREMENT: 'Ton',
  WEIGHT_BASED_GOAL_UNIT_OF_MEASUREMENT_LESS_THAN_THOUSAND: 'Kg',

  COMPANY_PLAN_INFO: {
    STATUS: 'Status',
    REPORT_DATE: 'Entregue em',
    TYPE: 'Tipo',
    YEAR: 'Ano',
  },

  GOAL_EVIDENCES_SECTION: {
    TITLE: 'Evidência de comprovação da meta',
    BASE_YEAR: (year: number) => `Ano base ${year}`,
  },

  TEMPLATE_FILE_DOWNLOAD: 'Baixar template de comprovação da meta',

  DATA_VERACITY_TERMS: 'Visualizar Termos de Ciência e Responsabilidade',

  FORM: {
    ALERT_MESSAGE: 'Campos com * são obrigatórios.',
    FIELDS: {
      GOAL: {
        LABEL: 'Meta declarada',
      },
      RECYCLED_AMOUNT: {
        LABEL: 'Quanto foi coletado em Ton',
        VALIDATION_MESSAGE: 'Quanto foi coletado em Ton',
      },
      GOAL_PROGRESS: {
        LABEL: 'Você atingiu',
        MARKUP: (percentage: string) => `${percentage} da meta`,
      },
      EVIDENCE_UPLOAD: {
        LABEL: 'Upload de evidência',
        DOWNLOAD_BUTTON: 'Download',
        VALIDATION_MESSAGE: 'Selecione uma evidência',
      },
      COMMITTED: {
        LABEL:
          'Declaro que as informações acima prestadas são verdadeiras, e assumo a inteira responsabilidade pelas mesmas.',
        VALIDATION_MESSAGE: 'Confirme que você assume a responsabilidade pelas informações',
      },
      ACCEPTED_TERMS: {
        LABEL: 'Li o termo de ciência e responsabilidade acima.',
        VALIDATION_MESSAGE:
          'Confirme que você leu o termo de ciência e assume a responsabilidade pelas informações',
      },
    },
  },

  RETURN_BUTTON: 'Voltar',
  SUBMIT_BUTTON: 'Enviar declaração',

  CONFIRMATION_MODAL: {
    TITLE: 'Deseja enviar a declaração?',
    DESCRIPTION:
      'Ao confirmar o envio de sua Declaração, as informações fornecidas serão registradas e não poderão ser modificadas.',
    ALERT_MESSAGE: 'Essa ação é irreversível!',
    BUTTONS: {
      CANCEL: 'Cancelar',
      CONFIRM: 'Sim, enviar declaração',
    },
  },

  API_ERRORS: {
    COMPANY_PLAN_NOT_APPROVED: {
      TITLE: 'Oops! Esse plano não está aprovado',
      DESCRIPTION: 'Só é possível enviar uma declaração de um plano que esteja aprovado.',
    },
    UPLOADED_FILE_NOT_FOUND: {
      TITLE: 'Oops! Parece que tivemos um problema com algum dos uploads',
      DESCRIPTION:
        'Você pode tentar novamente removendo e adicionando os anexos ou entrar em contato com o nosso suporte.',
    },
    PLAN_ALREADY_HAS_DECLARATION: {
      TITLE: 'Esse plano já possui uma declaração associada',
      DESCRIPTION:
        'Você pode conferir o andamento do processo na página com os detalhes do plano. Caso a declaração associada esteja incorreta você pode entrar em contato com o nosso suporte.',
      severity: 'warning',
    },
    DEADLINE_HAS_PASSED: {
      TITLE: 'Oops! Prazo de entrega excedido',
      DESCRIPTION: 'Infelizmente o prazo de entrega da declaração já passou.',
    },
  },
};
