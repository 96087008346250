import { Table as AntdTable, TableColumnType, TablePaginationConfig, TableProps } from 'antd';
import { FilterValue } from 'antd/lib/table/interface';
import { ReactNode, useMemo } from 'react';

import Cell from './cell';
import Loader from '../loader';

import styles from './index.module.css';
import objectGet from '../../cross-cutting/utils/object-get';

import i18n from './i18n';

type Column<DataType> = TableColumnType<DataType>;

export type CustomTableProps<DataType> = Omit<TableProps<DataType>, 'dataSource' | 'rowKey'> & {
  dataSource: DataType[];
  rowKey: keyof DataType extends string ? keyof DataType : string;
  columns: Column<DataType>;
  pageSize?: number;
  totalItems?: number;
  currentPage?: number;
  onPageChange?: (newPage: number) => void;
  onOrdinationChange?: (
    sorter: { columnKey: string; order: string } | { columnKey: string; order: string }[],
  ) => void;
  listTitle?: string | ReactNode;
  newDesign?: boolean;
};

type GenericObject = Record<string, unknown>;

function addFormattingToRawText<DataType>(columns: Column<DataType>[]) {
  const parsedColumns = columns.map((column, columnIndex) => {
    if (!column.dataIndex) {
      return column;
    }

    const { dataIndex, ...restColumn } = column;
    const newColumn = restColumn;
    newColumn.render = (_, record) => {
      return (
        <Cell style={{ paddingLeft: columnIndex === 0 ? '0' : undefined }}>
          {objectGet(record as GenericObject, dataIndex) as string}
        </Cell>
      );
    };
    return newColumn;
  });

  return parsedColumns;
}

function Table<DataType extends Record<string, unknown>>({
  columns,
  loading,
  pagination,
  className = '',
  pageSize,
  totalItems,
  currentPage,
  onPageChange,
  newDesign,
  onOrdinationChange,
  listTitle,
  ...restProps
}: CustomTableProps<DataType>) {
  const parsedColumns = useMemo(() => addFormattingToRawText(columns), [columns]);

  if (newDesign) {
    return (
      <>
        <div className={styles.listTitle}>
          {typeof listTitle === 'string' ? <span>{listTitle}</span> : listTitle}
          <span className={!totalItems ? styles.resetMargin : ''} style={{ marginLeft: '5px' }}>
            {totalItems ? `(${totalItems})` : ' (0)'}
          </span>
        </div>
        <AntdTable
          pagination={
            pagination && {
              position: ['bottomRight'],
              pageSize,
              total: totalItems,
              current: currentPage,
              onChange: onPageChange,
            }
          }
          onChange={(
            _pagination: TablePaginationConfig,
            _filter: Record<string, FilterValue | null>,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            sorter: any,
          ) => onOrdinationChange?.(sorter)}
          columns={parsedColumns}
          locale={{
            emptyText: i18n.ptBR.NO_DATA,
          }}
          loading={loading}
          {...restProps}
        />
      </>
    );
  }

  return (
    <AntdTable
      pagination={{
        hideOnSinglePage: true,
        position: ['bottomCenter'],
        pageSize,
        total: totalItems,
        current: currentPage,
        onChange: onPageChange,
      }}
      onHeaderRow={() => ({
        className: styles.header,
      })}
      onRow={() => ({
        className: styles.row,
      })}
      {...restProps}
      columns={parsedColumns}
      loading={{
        indicator: <Loader className={styles.loading} />,
        spinning: !!loading,
      }}
      className={`${styles.container} ${className}`}
    />
  );
}

export type { Column as TableColumnType };
export { Cell as TableCell };
export default Table;
