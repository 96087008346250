import type { ReactNode } from 'react';

import Modal from '../modal';
import Button from '../button';

import styles from './index.module.css';
import i18n from './i18n';
import colors from '../../colors';

type Severity = 'error' | 'warning' | 'success';

type FeedbackModalProps = {
  visible: boolean;
  children: ReactNode;
  title: ReactNode;
  footer?: ReactNode;
  severity: Severity;
  onCancel: () => void;
};

export const COLORS_BY_SEVERITY: Record<Severity, { foreground: string; background: string }> = {
  error: {
    background: colors.feedbackDangerLight,
    foreground: colors.feedbackDangerDark,
  },
  warning: {
    background: colors.feedbackWarningLight,
    foreground: colors.feedbackWarningDark,
  },
  success: {
    background: colors.feedbackSuccessLight,
    foreground: colors.feedbackSuccessDark,
  },
};

function FeedbackModal({
  visible,
  onCancel,
  footer = <Button type="text" text={i18n.ptBR.CLOSE_BUTTON} onClick={onCancel} />,
  title,

  severity,
  children,
}: FeedbackModalProps) {
  const color = COLORS_BY_SEVERITY[severity];

  return (
    <Modal
      title={title ? <div className={styles.title}>{title}</div> : null}
      footer={footer ? <div className={styles.footer}>{footer}</div> : null}
      visible={visible}
      onCancel={onCancel}
      className={styles.modal}
      style={{ borderColor: color.foreground }}
      width="540px"
    >
      {children}
    </Modal>
  );
}

export default FeedbackModal;
