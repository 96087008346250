import dataAccess from '../../cross-cutting/data-access';

export const generateCsrfToken = async () => {
  try {
    const response = await dataAccess.post('/authentication/csrf');
    return response.data as string;
  } catch (err) {
    return Promise.reject(err);
  }
};

type LoginPayload = {
  username: string;
  password: string;
};
export const login = async (payload: LoginPayload) => {
  try {
    const response = await dataAccess.post('/authentication/login', payload);
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const logout = async () => {
  try {
    const response = await dataAccess.post('/authentication/logout');
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const refreshAuthentication = async () => {
  try {
    const response = await dataAccess.post('/authentication/refresh');
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const requestPasswordRecoveryCode = async (payload: { email: string }) => {
  try {
    const response = await dataAccess.post('/authentication/recover-account', payload);
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

type ResetPasswordPayload = {
  code: string;
  email: string;
  newPassword: string;
};

export const requestResetPassword = async (payload: ResetPasswordPayload) => {
  try {
    const response = await dataAccess.post('/authentication/reset-password', payload);
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
};
export const validateRecoveryCode = async (payload: { code: number; email: string }) => {
  try {
    const response = await dataAccess.post('/authentication/validate-recovery-code', payload);
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
};
