import { REGION } from '../../../../../enums';
import { ProgramNamesUrl } from '../../../../../modules/programs/enums';
import { COMPANY_TYPE_ID } from '../../../../user-registration/form/enums';

export default {
  SECTION_TITLE: 'Sistemas de Logística Reversa',
  NO_DATA: 'Desculpe, não há dados a serem exibidos',
  LIST_TITLE: 'Empresas e Entidades Gestoras Cadastradas',
  BROWSER_TAB_NAME: (programName: string) =>
    `Lista de Empresas e Entidades Gestoras Cadastradas no sistema de ${programName}`,
  PROGRAMS_NAME: {
    [ProgramNamesUrl[0]]: 'Agrotóxicos, seus Resíduos e Embalagens',
    [ProgramNamesUrl[1]]: 'Baterias de Chumbo Ácido',
    [ProgramNamesUrl[2]]: 'Eletroeletrônicos e Seus Componentes',
    [ProgramNamesUrl[3]]: 'Embalagens de Aço',
    [ProgramNamesUrl[4]]: 'Embalagens Plásticas de Óleos Lubrificantes',
    [ProgramNamesUrl[5]]: 'Embalagens em Geral',
    [ProgramNamesUrl[6]]: 'Lâmpadas Fluorescentes, de Vapor de Sódio e Mercúrio e de Luz Mista',
    [ProgramNamesUrl[7]]: 'Medicamentos, seus Resíduos e Embalagens',
    [ProgramNamesUrl[8]]: 'Óleos Lubrificantes Usados ou Contaminados (OLUC)',
    [ProgramNamesUrl[9]]: 'Pilhas e Baterias',
    [ProgramNamesUrl[10]]: 'Pneus Inservíveis',
    [ProgramNamesUrl[11]]: 'Latas de Alumínio para Bebidas',
  },

  COLUMNS: {
    LABELS: {
      LEGAL_NAME: 'Razão Social',
      CNPJ: 'CNPJ',
      STATE: 'Estado',
      COMPANY_TYPE: 'Tipo',
      ACTIONS: 'Ações',
    },

    STATES: {
      AC: 'Acre',
      AL: 'Alagoas',
      AP: 'Amapá',
      AM: 'Amazonas',
      BA: 'Bahia',
      CE: 'Ceará',
      ES: 'Espírito Santo',
      GO: 'Goiás',
      MA: 'Maranhão',
      MT: 'Mato Grosso',
      MS: 'Mato Grosso do Sul',
      MG: 'Minas Gerais',
      PA: 'Pará',
      PB: 'Paraíba',
      PR: 'Paraná',
      PE: 'Pernambuco',
      PI: 'Piauí',
      RJ: 'Rio de Janeiro',
      RN: 'Rio Grande do Norte',
      RS: 'Rio Grande do Sul',
      RO: 'Rondônia',
      RR: 'Roraima',
      SC: 'Santa Catarina',
      SP: 'São Paulo',
      SE: 'Sergipe',
      TO: 'Tocantins',
      DF: 'Distrito Federal',
    },
    COMPANY_TYPE: {
      [COMPANY_TYPE_ID.MANUFACTURER_MANAGER]: 'Entidade Gestora / Coletivo',
      [COMPANY_TYPE_ID.MANUFACTURER]: 'Empresa / Individual',
    },
  },
  FILTERS: {
    COMPANY_TYPE_ID: {
      LABEL: 'Tipo',
      PLACEHOLDER: 'Selecione o tipo',
    },
    LEGAL_NAME_OR_CNPJ: {
      PLACEHOLDER: 'Busque por um CNPJ ou Razão Social',
    },
    REGION: {
      LABEL: 'Região',
      PLACEHOLDER: 'Selecione a região',
      OPTIONS: {
        [REGION.NORTH]: 'Norte',
        [REGION.NORTHEAST]: 'Nordeste',
        [REGION.MIDWEST]: 'Centro-Oeste',
        [REGION.SOUTHEAST]: 'Sudeste',
        [REGION.SOUTH]: 'Sul',
      },
    },
  },
};
