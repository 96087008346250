import { useContext } from 'react';

import { UserContext } from '../../modules/user/context';
import userHaveAccess from './user-have-access';

type AllowUserAccessProps = {
  toResource: string | string[];
  toPerform: string | string[] | string[][];
  onAllow: React.ReactElement;
  onDeny?: React.ReactElement;
};
function AllowUserAccess({ toResource, toPerform, onAllow, onDeny }: AllowUserAccessProps) {
  const { user } = useContext(UserContext);

  const userPermissions = user?.profile.resources;

  if (!userHaveAccess(userPermissions, toResource, toPerform)) {
    return onDeny || null;
  }

  return onAllow;
}

export { default as userHaveAccess } from './user-have-access';

export default AllowUserAccess;
