export default {
  PAGE_TITLE: 'Novo Plano',

  ALERT_MESSAGE:
    'Campos com * são obrigatórios. Você pode salvar e continuar depois sem preencher tudo mas lembre-se que para enviar o plano é obrigatório preencher todos os campos.',

  FILE_SENDED: 'Arquivo enviado com sucesso',
  SUCCESS_NOTIFICATION: {
    MESSAGE: 'Plano criado com sucesso',
    BUTTON: 'Ver Plano',
  },
  SELECT_FISCAL_YEAR: {
    MESSAGE: 'Selecione o ano fiscal',
    DESCRIPTION: 'Para configurar o sistema, selecione um ano fiscal',
  },
  PREVIOUS_PLAN_ALERT: 'O plano do ano anterior a esse ainda não foi enviado',
  FILE_SIZE_NOTIFICATION: (fileSize: number) =>
    `O arquivo selecionado precisa ter no máximo ${fileSize}kbs`,
};
