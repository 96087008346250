import { useEffect, useState } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import Content from './content';
import ErrorHandler from '../../../common-components/error-handler';

import HttpError from '../../../cross-cutting/errors/http-error';
import { getAllCompanyPlans } from '../../../modules/plans/service';
import { CompanyPlan } from '../../../modules/plans/types';
import { LIST_PAGE_SIZE } from './enums';
import { PLAN_STATUS_URL_ID } from '../../../modules/plans/enums';

import routes from '../../../routes';

export default function ListPlans() {
  const { search } = useLocation();

  const code = new URLSearchParams(search).get('code');

  const origin = new URLSearchParams(search).get('origin');

  const navigate = useNavigate();

  const [error, setError] = useState<HttpError>();
  const [loading, setLoading] = useState(true);
  const [companyPlans, setCompanyPlans] = useState<CompanyPlan[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    async function fetchCompanyPlans() {
      setLoading(true);
      try {
        let sanitizedCode;
        if (typeof code === 'string') {
          sanitizedCode = code;
        }
        const response = await getAllCompanyPlans({
          code: sanitizedCode,
          page,
          limit: LIST_PAGE_SIZE,
        });

        setCompanyPlans(response.companyPlans);
        setTotalCount(response.totalCount);

        if (origin === PLAN_STATUS_URL_ID.PLAN_SENT) {
          navigate(routes.VIEW_COMPANY_PLAN.replace(':id', response.companyPlans[0].id || ''));
        }
        if (origin === PLAN_STATUS_URL_ID.DECLARATION_SENT) {
          navigate(routes.VIEW_DECLARATION.replace(':id', response.companyPlans[0].id || ''));
        }
      } catch (err) {
        if (err instanceof HttpError) {
          setError(err);
        }
      }
      setLoading(false);
    }

    fetchCompanyPlans();
  }, [page, code, origin, navigate]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <ErrorHandler error={error}>
      <Content
        companyPlans={companyPlans}
        loading={loading}
        currantPage={page}
        totalCount={totalCount}
        pageSize={LIST_PAGE_SIZE}
        onPageChange={handlePageChange}
      />
    </ErrorHandler>
  );
}
