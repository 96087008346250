import { useEffect, useState } from 'react';

import { generateCsrfToken } from '../service';
import { CSRF_TOKEN_HEADER } from './enums';
import dataAccess from '../../../cross-cutting/data-access';
import HttpError from '../../../cross-cutting/errors/http-error';

const getCsrfToken = () => {
  return dataAccess.instance.defaults.headers.common[CSRF_TOKEN_HEADER] as string | undefined;
};

const setGlobalCsrfTokenHeader = (token: string) => {
  dataAccess.instance.defaults.headers.common[CSRF_TOKEN_HEADER] = token;
};

const useCsrf = () => {
  const [csrfToken, setCsrfToken] = useState(getCsrfToken());
  const [error, setError] = useState<HttpError | null>(null);

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const csrfTokenResponse = await generateCsrfToken();
        setGlobalCsrfTokenHeader(csrfTokenResponse);
        setCsrfToken(csrfTokenResponse);
      } catch (err) {
        if (err instanceof HttpError) {
          setError(err);
        }
      }
    };

    if (!csrfToken) {
      fetchCsrfToken();
    }
  }, [csrfToken]);

  return [csrfToken, error] as const;
};

export default useCsrf;
