import { PROGRAM_STATUS } from '../../enums';

export default {
  VIEW_BUTTON: 'Visualizar',

  STATUS: {
    [PROGRAM_STATUS.CONFIGURED]: 'Configurado',
    [PROGRAM_STATUS.NOT_CONFIGURED]: 'Não configurado',
  },

  METRIC_ITEMS: {
    QUANTITY: 'Meta quantitativa',
    GEOGRAPHICAL: 'Meta geográfica',
  },
};
