import { Helmet } from 'react-helmet';
import ptBR from 'antd/lib/locale/pt_BR';
import 'dayjs/locale/pt-br';
import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

import DevTools from './dev-tools';
import ErrorHandler from './common-components/error-handler';
import FeatureFlagsProvider from './cross-cutting/contexts/feature-flags/provider';
import { UserContextProvider } from './modules/user/context';
import Router from './modules/routing';
import Loader from './common-components/loader';

import useCsrf from './modules/authentication/use-csrf';

import './css-reset.css';
import './index.css';

dayjs.extend(LocalizedFormat);

const devTools = process.env.REACT_APP_DEV_TOOLS === 'true' ? <DevTools /> : null;

function App() {
  const [csrfToken, generateCsrfError] = useCsrf();

  return (
    <>
      <Helmet>
        <html lang="pt-BR" />
      </Helmet>
      <ErrorHandler>
        <ErrorHandler error={generateCsrfError}>
          <FeatureFlagsProvider>
            <UserContextProvider>
              <ConfigProvider locale={ptBR}>
                {csrfToken ? (
                  <Router />
                ) : (
                  <Loader style={{ height: '100vh' }} data-testid="globalLoading" />
                )}
              </ConfigProvider>
              {devTools}
            </UserContextProvider>
          </FeatureFlagsProvider>
        </ErrorHandler>
      </ErrorHandler>
    </>
  );
}
export default App;
