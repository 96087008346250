import { Form as AntdForm, FormInstance, FormProps } from 'antd';
import { ReactNode } from 'react';

type ValidateFieldsError = {
  errorFields: {
    name: string | string[];
    errors: string[];
  }[];
};

const makeScrollToFirstError = (form: FormInstance) => {
  const scrollToFirstError = ({ errorFields }: ValidateFieldsError) => {
    form.scrollToField(errorFields[0]?.name);
  };
  return scrollToFirstError;
};

type Props<Values> = FormProps<Values> & {
  children: ReactNode;
  variant?: 'primary' | 'ghost';
};

function Form<Values = unknown>({
  layout = 'vertical',
  requiredMark = 'optional',
  scrollToFirstError = true,
  variant = 'primary',
  ...restProps
}: Props<Values>) {
  return (
    <AntdForm
      {...restProps}
      layout={layout}
      requiredMark={requiredMark}
      scrollToFirstError={scrollToFirstError}
      // custom `data-` attributes
      data-form-component-variant={variant}
    />
  );
}

export { makeScrollToFirstError };
export { default as FormItem } from './form-item';

export default Form;
