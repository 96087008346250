import { TablePaginationConfig } from 'antd';
import { FilterValue } from 'antd/lib/table/interface';

import PageWithAddons from '../../../../../common-components/page-with-addons';
import columns from './columns';
import BreadcrumbItem from '../../../../../common-components/breadcrumbs/breadcrumb-item';
import Input from '../../../../../common-components/input';
import PickList from '../../../../../common-components/pick-list';

import i18n from './i18n';
import { CompanyPlan } from '../../../../../modules/plans/types';
import routes from '../../../../../routes';
import { FilterType, Sorter } from '../../types';
import { ProgramNamesUrl } from '../../../../../modules/programs/enums';
import { FilterItem } from '../../../../../common-components/filter';
import { FIELDS } from '../enums';
import { REGION } from '../../../../../enums';
import { COMPANY_PLAN_TYPE_ID } from '../../../../../modules/plans/enums';
import validYearsOptions from '../../../../../cross-cutting/utils/get-years-from-project-start';
import styles from './index.module.css';

type Props = {
  companyPlans: CompanyPlan[];
  loading: boolean;
  currentPage: number;
  totalCount: number;
  pageSize: number;
  onPageChange: (newPage: number) => void;
  onFilter: (formValues: FilterType) => void;
  programId?: string;
  onOrdinationChange: (sorter: Sorter | Sorter[]) => void;
};

export default function Content({
  companyPlans,
  loading,
  currentPage,
  totalCount,
  pageSize,
  onPageChange,
  onFilter,
  programId,
  onOrdinationChange,
}: Props) {
  const regionFilterOptions = [
    {
      key: REGION.NORTHEAST,
      value: i18n.ptBR.FILTERS.REGION.OPTIONS[REGION.NORTHEAST],
    },
    {
      key: REGION.NORTH,
      value: i18n.ptBR.FILTERS.REGION.OPTIONS[REGION.NORTH],
    },
    {
      key: REGION.MIDWEST,
      value: i18n.ptBR.FILTERS.REGION.OPTIONS[REGION.MIDWEST],
    },
    {
      key: REGION.SOUTHEAST,
      value: i18n.ptBR.FILTERS.REGION.OPTIONS[REGION.SOUTHEAST],
    },
    {
      key: REGION.SOUTH,
      value: i18n.ptBR.FILTERS.REGION.OPTIONS[REGION.SOUTH],
    },
  ];
  const planTypeFilterOptions = [
    {
      key: COMPANY_PLAN_TYPE_ID.INDIVIDUAL,
      value: i18n.ptBR.FILTERS.PLAN_TYPE.OPTIONS[COMPANY_PLAN_TYPE_ID.INDIVIDUAL],
    },
    {
      key: COMPANY_PLAN_TYPE_ID.COLLECTIVE,
      value: i18n.ptBR.FILTERS.PLAN_TYPE.OPTIONS[COMPANY_PLAN_TYPE_ID.COLLECTIVE],
    },
  ];

  const validYearsFilterOptions = validYearsOptions().map((value) => {
    return {
      key: value,
      value,
    };
  });

  const programName =
    i18n.ptBR.PROGRAMS_NAME[programId as typeof ProgramNamesUrl[keyof typeof programId]];

  const tableFilters = (
    <FilterItem noStyle name={FIELDS.LEGAL_NAME_OR_CNPJ}>
      <Input
        placeholder={i18n.ptBR.FILTERS.LEGAL_NAME_OR_CNPJ.PLACEHOLDER}
        style={{ border: '0px', background: '#F8F8F8', width: '300px' }}
      />
    </FilterItem>
  );
  const filters = [
    <FilterItem
      key={FIELDS.PLAN_TYPE}
      label={i18n.ptBR.FILTERS.PLAN_TYPE.LABEL}
      name={FIELDS.PLAN_TYPE}
    >
      <PickList
        allowClear
        placeholder={i18n.ptBR.FILTERS.PLAN_TYPE.PLACEHOLDER}
        options={planTypeFilterOptions}
      />
    </FilterItem>,
    <FilterItem
      key={FIELDS.FISCAL_YEAR}
      label={i18n.ptBR.FILTERS.FISCAL_YEAR.LABEL}
      name={FIELDS.FISCAL_YEAR}
    >
      <PickList
        allowClear
        placeholder={i18n.ptBR.FILTERS.FISCAL_YEAR.PLACEHOLDER}
        options={validYearsFilterOptions}
      />
    </FilterItem>,
    <FilterItem key={FIELDS.REGION} label={i18n.ptBR.FILTERS.REGION.LABEL} name={FIELDS.REGION}>
      <PickList
        allowClear
        placeholder={i18n.ptBR.FILTERS.REGION.PLACEHOLDER}
        options={regionFilterOptions}
      />
    </FilterItem>,
  ];
  return (
    <PageWithAddons
      filters={filters}
      enableNavigationModal
      navigationModalRoute={routes.ADMIN_LIST_PLANS}
      tableFilters={tableFilters}
      tableProps={{
        columns: columns(),
        dataSource: companyPlans,
        rowKey: 'id',
        listTitle: i18n.ptBR.PAGE_TITLE,
        newDesign: true,
        totalItems: totalCount,
        currentPage,
        pageSize,
        loading,
        pagination: {
          disabled: false,
        },
        onChange: (
          _pagination: TablePaginationConfig,
          _filter: Record<string, FilterValue | null>,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          sorter: any,
        ) => onOrdinationChange(sorter),
        onPageChange,
      }}
      pageProps={{
        browserTabName: i18n.ptBR.PAGE_TITLE,
        newDesign: true,
        childrenClassName: styles.childrenClassName,

        title: programName,
        breadcrumbs: [
          <BreadcrumbItem
            key={routes.ADMIN_LIST_PLANS}
            title={programName}
            route={routes.ADMIN_LIST_PLANS.replace(
              ':id',
              programId as typeof ProgramNamesUrl[keyof typeof programId],
            )}
          />,
        ],
      }}
      onFilter={onFilter}
    />
  );
}
