/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */

import { ComponentPropsWithoutRef } from 'react';
import styles from './index.module.css';

type LineProps = ComponentPropsWithoutRef<'hr'> & {
  type: 'line';
  className?: string;
  color?: string;
};

type DotProps = ComponentPropsWithoutRef<'span'> & {
  type: 'dot';
  className?: string;
  color?: string;
};
type DividerProps = LineProps | DotProps;

function Divider({ type, color, className = '', ...props }: DividerProps) {
  switch (type) {
    case 'dot': {
      return (
        <span
          style={{ backgroundColor: color }}
          className={`${styles.dot} ${className}`}
          {...props}
        >
          •
        </span>
      );
    }
    case 'line': {
      return (
        <hr
          style={{ backgroundColor: color }}
          className={`${styles.line} ${className}`}
          {...props}
        />
      );
    }
    default: {
      throw new Error('Invalid divider type');
    }
  }
}

export default Divider;
