import {
  COMPANY_PLAN_TYPE_ID,
  GOAL_UNITS_OF_MEASUREMENT,
  PLAN_STATUS_ID,
} from '../../../../../../modules/plans/enums';

export default {
  BROWSER_TAB_NAME: 'Declaração anual de coleta | Sinir+',

  PAGE_TITLE: 'Declaração anual de coleta',

  COMPANY_PLAN_TYPES: {
    [COMPANY_PLAN_TYPE_ID.INDIVIDUAL]: 'Individual',
    [COMPANY_PLAN_TYPE_ID.COLLECTIVE]: 'Coletivo',
  },

  EVIDENCE_GOAL: 'Evidência de comprovação da meta',

  PLAN_STATUS: {
    [PLAN_STATUS_ID.INCOMPLETE]: 'Incompleto',
    [PLAN_STATUS_ID.NOT_SENT]: 'Não enviado',
    [PLAN_STATUS_ID.SENT]: 'Enviado',
  },

  GOAL_UNITS_OF_MEASUREMENT: {
    [GOAL_UNITS_OF_MEASUREMENT.PERCENTAGE]: '%',
    [GOAL_UNITS_OF_MEASUREMENT.TON_PER_YEAR]: ' ton/ano',
  },

  WEIGHT_BASED_GOAL_UNIT_OF_MEASUREMENT: ' Ton',
  WEIGHT_BASED_GOAL_UNIT_OF_MEASUREMENT_LESS_THAN_THOUSAND: 'Kg',

  COMPANY_PLAN_INFO: {
    STATUS: 'Status',
    REPORT_DATE: 'Entregue em',
    TYPE: 'Tipo',
    YEAR: 'Ano',
  },

  GOAL_EVIDENCES_SECTION: {
    TITLE: 'Evidência de comprovação da meta',
    BASE_YEAR: (year: number) => `Ano base ${year}`,
  },

  FORM: {
    FIELDS: {
      GOAL: {
        LABEL: 'Meta declarada',
      },
      RECYCLED_AMOUNT: {
        LABEL: 'Quanto foi coletado em Ton',
        VALIDATION_MESSAGE: 'Quanto foi coletado em Ton',
      },
      GOAL_PROGRESS: {
        LABEL: 'Você atingiu',
        MARKUP: (percentage: string) => `${percentage} da meta`,
      },
      EVIDENCE_UPLOAD: {
        DOWNLOAD_BUTTON: 'Download',
      },
    },
  },

  RETURN_BUTTON: 'Voltar',

  API_ERRORS: {},
};
