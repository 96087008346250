import { Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ReactNode, useState } from 'react';

import Page, { PageProps } from '../page';
import Icon from '../icon';
import Divider from '../divider';
import Modal from '../modal';
import PickList from '../pick-list';
import Form, { FormItem } from '../form';
import Button from '../button';

import Table, { CustomTableProps } from '../table';
import { FilterBox } from '../filter';
import styles from './index.module.css';
import i18n from './i18n';
import { ProgramNamesUrl } from '../../modules/programs/enums';
import routes from '../../routes';
import enums from '../../enums';
import { MODAL_FORM_FIELDS } from './enums';
import Tabs, { CustomTabsProps } from '../tabs';

type ModalNavigateThroughSystemsType = {
  onClose: () => void;
  isVisible: boolean;
  navigationModalRoute?: string;
};

function ModalNavigateThroughSystems({
  onClose,
  isVisible,
  navigationModalRoute,
}: ModalNavigateThroughSystemsType) {
  const navigate = useNavigate();

  const onFinish = ({ route }: { route: string }) => {
    navigate(
      navigationModalRoute?.replace(/:id/, route) ||
        routes.VIEW_PROGRAM_COMPANIES.replace(/:id/, route),
    );
    onClose();
  };

  const formattedSystemTypesOptions = ProgramNamesUrl.map((id) => ({
    key: id,
    value: i18n.ptBR.PROGRAMS_NAME[id],
  }));

  return (
    <Modal
      className={styles.modal}
      onCancel={onClose}
      visible={isVisible}
      footer={null}
      title={i18n.ptBR.NAVIGATE_MODAL.TITLE}
      destroyOnClose
    >
      <Form onFinish={onFinish}>
        <FormItem
          name={MODAL_FORM_FIELDS.ROUTE}
          label={i18n.ptBR.NAVIGATE_MODAL.LABEL}
          rules={[{ required: true }]}
          required
          style={{ width: '100%', padding: '32px 0px' }}
        >
          <PickList
            options={formattedSystemTypesOptions}
            placeholder={i18n.ptBR.NAVIGATE_MODAL.PLACEHOLDER}
          />
        </FormItem>

        <Space size={26} style={{ width: '100%', justifyContent: 'flex-end' }}>
          <Button
            htmlType="button"
            text={i18n.ptBR.NAVIGATE_MODAL.BUTTONS.CANCEL}
            type="stroke"
            onClick={onClose}
          />
          <Button htmlType="submit" text={i18n.ptBR.NAVIGATE_MODAL.BUTTONS.SUBMIT} type="default" />
        </Space>
      </Form>
    </Modal>
  );
}

type PageWithAddonsProps<TableDataType> = {
  tableProps?: CustomTableProps<TableDataType>;
  tableFilters?: ReactNode | ReactNode[];
  pageProps: PageProps;
  filters?: ReactNode[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFilter?: (values: any) => void;
  children?: ReactNode;
  enableNavigationModal?: boolean;
  tabsProps?: CustomTabsProps;
  navigationModalRoute?: string;
};

export default function PageWithAddons<TableDataType extends Record<string, unknown>>({
  tableProps,
  pageProps,
  filters,
  onFilter,
  children,
  enableNavigationModal,
  tabsProps,
  tableFilters,
  navigationModalRoute,
}: PageWithAddonsProps<TableDataType>) {
  const [isNavigateModalVisible, setIsNavigateModalVisible] = useState(false);
  return (
    <Page
      newDesign
      {...pageProps}
      title={
        enableNavigationModal ? (
          <div
            onKeyDown={(e) => {
              if (
                e.keyCode === enums.KEYBOARD_CHAR_CODES.ENTER ||
                e.keyCode === enums.KEYBOARD_CHAR_CODES.SPACE_BAR
              ) {
                setIsNavigateModalVisible(true);
              }
            }}
            onClick={() => setIsNavigateModalVisible(true)}
            role="button"
            tabIndex={0}
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'baseline',
              width: 'fit-content',
            }}
          >
            {pageProps.title}
            <Icon name="chevronDown" size="20px" style={{ marginLeft: '18px' }} />
          </div>
        ) : (
          pageProps.title
        )
      }
    >
      {filters && filters.length > 0 && (
        <FilterBox filters={filters} onFilter={onFilter || (() => {})} />
      )}

      {tableProps && (
        <>
          <Divider type="line" />
          <div className={styles.listFiltersContainer}>
            {onFilter && (
              <Form
                onFinish={onFilter}
                style={{ display: 'flex', alignItems: 'center', margin: '0px' }}
              >
                {tableFilters}

                <Button
                  style={{ marginLeft: '10px' }}
                  htmlType="submit"
                  type="text"
                  text={<Icon name="search" color="#1351B4" size={30} />}
                  newDesign
                />
              </Form>
            )}
          </div>
          <Table {...tableProps} />
        </>
      )}

      {children}

      {tabsProps && <Tabs {...tabsProps} />}

      <ModalNavigateThroughSystems
        navigationModalRoute={navigationModalRoute}
        onClose={() => {
          setIsNavigateModalVisible(false);
        }}
        isVisible={isNavigateModalVisible}
      />
    </Page>
  );
}
