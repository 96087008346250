import Icon from '../../../common-components/icon';
import Divider from '../../../common-components/divider';
import Button from '../../../common-components/button';

import i18n from './i18n';
import styles from './index.module.css';
import { Plan } from '../../../modules/programs/types';

type CardProps = {
  title: string;
  image: string;
  plan?: Plan;
  isViewPage?: boolean;
  onConfigure?: () => void;
  shouldRenderGeographicGoal: boolean;
};
function programWasteTypeIsNotFilled(element: {
  programWasteTypeId: number;
  totalWeightSold: string | null;
  weightGoal: string | null;
}) {
  return element.totalWeightSold || element.weightGoal;
}

function Card({
  title,
  image,
  plan,
  isViewPage,
  onConfigure,
  shouldRenderGeographicGoal,
}: CardProps) {
  const isQuantityGoalAchieved = plan?.programWasteTypes?.some(programWasteTypeIsNotFilled);

  const isGeographicGoalAchieved =
    plan?.geographicGoalAttachment?.uploadedFileId || plan?.geographicGoalAttachmentId;

  const isConfigured = shouldRenderGeographicGoal
    ? isQuantityGoalAchieved && isGeographicGoalAchieved
    : isQuantityGoalAchieved;

  return (
    <div style={{ height: '484px' }} className={styles.container}>
      <img src={image} alt={title} />
      <div className={styles.textContainer}>
        {isConfigured ? (
          <p className={styles.configuredText}>{i18n.ptBR.CONFIGURED}</p>
        ) : (
          <p className={styles.notConfiguredText}>{i18n.ptBR.NOT_CONFIGURED}</p>
        )}
        <span className={styles.title}>{title}</span>

        <div style={{ margin: '30px 0' }}>
          <div
            style={{ color: isQuantityGoalAchieved ? 'var(--gray-2)' : 'var(--red)' }}
            className={styles.goalContainer}
          >
            <Icon size={16} name={isQuantityGoalAchieved ? 'checkCircle' : 'xCircle'} />
            <span>{i18n.ptBR.GOALS.QUANTITATIVE_GOAL}</span>
          </div>
          {shouldRenderGeographicGoal && (
            <div
              style={{ color: isGeographicGoalAchieved ? 'var(--gray-2)' : 'var(--red)' }}
              className={styles.goalContainer}
            >
              <Icon size={16} name={isGeographicGoalAchieved ? 'checkCircle' : 'xCircle'} />
              <span>{i18n.ptBR.GOALS.GEOGRAPHIC_GOAL}</span>
            </div>
          )}
        </div>
        <Divider type="line" />
        {isViewPage ? (
          <Button
            style={{
              width: '100%',
              marginTop: '30px',
            }}
            text={i18n.ptBR.BUTTONS.VIEW}
            type="stroke"
            onClick={() => onConfigure?.()}
          />
        ) : (
          <Button
            style={{
              width: '100%',
              marginTop: '30px',
            }}
            text={i18n.ptBR.BUTTONS.CONFIGURE}
            onClick={() => onConfigure?.()}
          />
        )}
      </div>
    </div>
  );
}

export default Card;
