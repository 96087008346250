export const MAX_CODE_LENGTH = 8;

export const FORM_FIELDS = {
  RECOVERY_CODE_NUMBER: 'recoveryCode',
};

export const UNPROCESSABLE_ENTITY_ERROR_TYPES = {
  PREVIOUS_RECOVERY_CODE_STILL_VALID: 'previous_recovery_code_still_valid',
  ACCOUNT_RECOVERY_CODE: {
    INVALID: 'account_recovery_code.invalid',
    ATTEMPT_LIMIT: 'account_recovery_code.attempt_limit',
  },
};
