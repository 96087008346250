import type { FeatureFlagNames, FeatureFlagsObject } from '.';

const getDefaultValue = (
  operatingFeatureFlags: FeatureFlagNames[],
  storageValue: FeatureFlagsObject,
) => {
  const defaultFeatureFlags: FeatureFlagsObject = {};
  operatingFeatureFlags.forEach((operatingFeatureFlagName) => {
    // TypeScript does not recognize that `forEach` won't run if there are no feature flags at
    // the enum
    (defaultFeatureFlags as Record<string, boolean>)[operatingFeatureFlagName] =
      storageValue[operatingFeatureFlagName] || false;
  });

  return defaultFeatureFlags;
};

export default getDefaultValue;
