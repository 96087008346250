import { useEffect, useState } from 'react';

import Content from './content';
import ErrorHandler from '../../../../../common-components/error-handler';

import type { PlanGetOneCompanyPlan } from '../../../../../modules/plans/types';
import HttpError from '../../../../../cross-cutting/errors/http-error';
import { getOneAttachment } from '../../../../../modules/attachment/service';
import downloadFile from '../../../../../cross-cutting/utils/download-file';

export type UploadedFileData = {
  id: string;
  name: string;
  type: string;
  file: Blob;
  status: 'done';
};

type Props = {
  fieldsPrefix: string;
  plan: PlanGetOneCompanyPlan;
};

export default function Plan({ fieldsPrefix, plan }: Props) {
  const [error, setError] = useState<HttpError>();
  const [uploadFileData, setUploadFileData] = useState<UploadedFileData[]>([]);

  const handleDownloadFile = async (file: UploadedFileData) => {
    downloadFile({
      file: file.file,
      type: file.type,
      originalName: file.name,
    });
  };

  useEffect(() => {
    const fetchAttachmentDetails = async (uploadedFileId: string) => {
      try {
        const response = await getOneAttachment(uploadedFileId);
        setUploadFileData([
          {
            id: response.id,
            name: response.originalName,
            type: response.type,
            file: response.file,
            status: 'done',
          },
        ]);
      } catch (err) {
        if (err instanceof HttpError) {
          setError(err);
        }
      }
    };

    if (plan.declaration?.declarationEvidenceAttachment.uploadedFileId) {
      fetchAttachmentDetails(plan.declaration.declarationEvidenceAttachment.uploadedFileId);
    }
  }, [plan]);

  return (
    <ErrorHandler error={error}>
      <Content
        fieldsPrefix={fieldsPrefix}
        plan={plan}
        uploadFileData={uploadFileData}
        onDownloadFile={handleDownloadFile}
      />
    </ErrorHandler>
  );
}
