import { Progress, Space } from 'antd';
import BigNumber from 'bignumber.js';

import DashboardCard from '../../../dashboard-card';

import {
  DashboardFilters,
  GetTotalOfCompaniesThatReachedTheGoalIndicator,
} from '../../../../../../../modules/indicators/types';
import { ProgramNamesUrl } from '../../../../../../../modules/programs/enums';
import i18n from './i18n';
import styles from './index.module.css';
import convertBoldTextToJsx from '../../../../../../../cross-cutting/utils/convert-bold-text-to-jsx';

export default function Content({
  loading,
  companiesCounting,
  filters,
}: {
  filters: DashboardFilters;
  loading: boolean;
  companiesCounting?: GetTotalOfCompaniesThatReachedTheGoalIndicator;
}) {
  const totalCompaniesThatReachedTheGoal =
    companiesCounting?.find((total) => total.fulfilled)?.totalOfCompanies || 0;
  const totalCompaniesDidntReachedTheGoal =
    companiesCounting?.find((total) => !total.fulfilled)?.totalOfCompanies || 0;

  const totalCompanies = new BigNumber(totalCompaniesDidntReachedTheGoal)
    .plus(totalCompaniesThatReachedTheGoal)
    .toNumber();

  const selectedFilters = () => {
    const programName = filters?.programId
      ? i18n.ptBR.PROGRAM[ProgramNamesUrl[filters.programId - 1]]
      : undefined;

    const companyType = filters?.companyTypeId && i18n.ptBR.COMPANY_TYPE[filters?.companyTypeId];
    const region = filters?.region && i18n.ptBR.REGION[filters?.region];

    return [programName, filters?.year?.toString(), companyType, region];
  };
  return (
    <DashboardCard
      selectedFilters={selectedFilters()}
      iconName="medal"
      title={i18n.ptBR.INDICATOR.TITLE}
      loading={loading}
    >
      <Space size={20} direction="horizontal" style={{ marginTop: '58px' }} align="center" wrap>
        <Progress
          type="circle"
          strokeWidth={15}
          width={158}
          strokeLinecap="square"
          strokeColor="#0C326F"
          trailColor="#EEEEEE"
          gapDegree={80}
          gapPosition="bottom"
          className={styles.progress}
          percent={new BigNumber(totalCompaniesThatReachedTheGoal)
            .div(totalCompanies || 0)
            .times(100)
            .decimalPlaces(2)
            .toNumber()}
        />

        <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <p style={{ display: 'flex' }}>
            <div
              className={styles.indicatorLabelItem}
              style={{
                backgroundColor: '#EEEEEE',
              }}
            />
            {convertBoldTextToJsx(i18n.ptBR.INDICATOR.TOTAL_COMPANIES(totalCompanies))}
          </p>
          <p style={{ display: 'flex' }}>
            <div
              className={styles.indicatorLabelItem}
              style={{
                backgroundColor: '#0C326F',
              }}
            />
            {convertBoldTextToJsx(
              i18n.ptBR.INDICATOR.TOTAL_COMPANIES_THAT_REACHED_THE_GOAL(
                totalCompaniesThatReachedTheGoal,
              ),
            )}
          </p>
        </div>
      </Space>
    </DashboardCard>
  );
}
