export const FIELDS = {
  COMPANY_TYPE: 'companyTypeId',
  CNPJ: 'cnpj',
  LEGAL_NAME: 'legalName',
  CNAE: 'mainCnae',
  PHONE: 'phone',

  EMAIL: 'email',
  PASSWORD: 'password',
  REPEAT_PASSWORD: 'repeatPassword',

  TERMS_OF_SERVICE: 'acceptedTermsOfService',
  PRIVACY_POLICY: 'acceptedPrivacyPolicy',

  ADDRESS_FIELDS: 'address',
  ADDRESS: {
    POSTAL_CODE: 'postalCode',
    STREET: 'streetName',
    NUMBER: 'number',
    COMPLEMENT: 'complement',
    NEIGHBORHOOD: 'neighborhood',
    CITY: 'city',
    STATE: 'stateAbbreviation',
  },
};

export const COMPANY_TYPE_ID = {
  MANUFACTURER: 1,
  MANUFACTURER_MANAGER: 2,
} as const;

export const BRAZILIAN_DISTRICTS = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
] as const;
