import type { ReactNode } from 'react';

import FeedbackModal, { COLORS_BY_SEVERITY } from '../../feedback-modal';
import Alert from '../../alert';
import Icon from '../../icon';

import styles from './index.module.css';
import i18n from './i18n';
import Button from '../../button';

type Props = {
  visible: boolean;
  title: ReactNode;
  description: ReactNode;
  severity: 'error' | 'warning';
  onCancel: () => void;
};

function ErrorModal({ visible, title, description, severity, onCancel }: Props) {
  const color = COLORS_BY_SEVERITY[severity];

  return (
    <FeedbackModal
      title={
        <div className={styles.title}>
          <div
            className={styles.iconContainer}
            style={{ backgroundColor: color.background, color: color.foreground }}
          >
            <Icon name="alertCircle" />
          </div>
          {title}
        </div>
      }
      footer={<Button type="text" text={i18n.ptBR.CLOSE} onClick={onCancel} />}
      visible={visible}
      onCancel={onCancel}
      severity={severity}
    >
      <div style={{ padding: '40px 0 64px' }}>
        <Alert type={severity} message={description} className={styles.message} />
      </div>
    </FeedbackModal>
  );
}

export default ErrorModal;
