import dataAccess from '../../cross-cutting/data-access';
import { Program, PublicProgram } from './types';

type PublicProgramsResponse = {
  programs: PublicProgram[];
  totalCount: number;
};

export const getAllPrograms = async () => {
  try {
    const response = await dataAccess.get(`/public/programs`);
    return response.data as PublicProgramsResponse;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getUserPrograms = async () => {
  try {
    const response = await dataAccess.get(`/user/programs`);
    return response.data as Program[];
  } catch (err) {
    return Promise.reject(err);
  }
};
