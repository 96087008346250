import { ReactNode, useMemo, useState } from 'react';

import UserContext from './context';
import type { GetUserResponse } from '../service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function UserContextProvider({ children, mockValue }: { children: ReactNode; mockValue?: any }) {
  const [user, setUser] = useState<GetUserResponse>();

  const value = useMemo(
    () => ({
      user,
      setUser,
    }),
    [user, setUser],
  );

  return <UserContext.Provider value={mockValue || value}>{children}</UserContext.Provider>;
}

export default UserContextProvider;
