import { ColumnsType } from 'antd/lib/table';
import Dayjs from 'dayjs';
import Button from '../../../../../../common-components/button';
import Icon from '../../../../../../common-components/icon';

import { CompanyPlan } from '../../../../../../modules/plans/types';
import i18n from './i18n';

export default (onSelectDeclaration: (id: string) => void): ColumnsType<CompanyPlan> => {
  return [
    {
      title: i18n.ptBR.COLUMNS.LABELS.YEAR,
      dataIndex: 'fiscalYear',
      key: 'fiscalYear',
    },

    {
      title: i18n.ptBR.COLUMNS.LABELS.SENT_DATE,
      key: 'sentDate',
      width: '15%',
      render: (_, { reportedAt }) => {
        return Dayjs(reportedAt).format('L');
      },
    },
    {
      title: i18n.ptBR.COLUMNS.LABELS.ACTIONS,
      key: 'actions',
      width: '15%',
      render: (_, { id }) => {
        return (
          <Button
            type="text"
            icon={<Icon name="eyeView" />}
            onClick={() => onSelectDeclaration(id)}
          />
        );
      },
    },
  ];
};
