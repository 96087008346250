import { useState } from 'react';
import PropTypes from 'prop-types';
import { Upload as AntdUpload } from 'antd';

import enums from './enums';
import Content from './content';

const mimeTypesByExtension = {
  zip: 'application/zip',
  rar: 'application/x-rar-compressed',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  xml: 'application/xml',
  pdf: 'application/pdf',
};

const getFileExtension = (fileName) => {
  const splitFileName = fileName.split('.');

  const hasExtension = splitFileName.length > 1;

  if (!hasExtension) {
    return null;
  }

  const extension = splitFileName.pop();
  const extesionToLowerCase = extension.toLowerCase();

  return extesionToLowerCase;
};

function Upload({
  text,
  formats,
  maxSize,
  onChange,
  onFileChange,
  onRemove,
  onDownload,
  fileList,
  maxCount,
  className,
  multiple,
  children,
  loading,
  disableUploadButton,
  ...props
}) {
  const [error, setError] = useState(null);
  const sanitizedFileList = fileList.filter(Boolean);

  const validateMaxCount = () => {
    if (fileList.length >= maxCount) {
      const err = new Error();
      err.type = enums.ERRORS.MAX_COUNT;
      err.details = maxCount;
      throw err;
    }
  };

  const validateFormat = (fileName) => {
    const extension = getFileExtension(fileName);

    const isValid = extension && formats.includes(extension.toLowerCase());

    if (!isValid) {
      const err = new Error();
      err.type = enums.ERRORS.INVALID_FORMAT;
      err.details = formats.join(', ');
      throw err;
    }
  };

  const validateSize = (fileSize) => {
    if (fileSize > maxSize) {
      const err = new Error();
      err.type = enums.ERRORS.MAX_SIZE_EXCEEDED;
      err.details = `${maxSize / 1024 / 1024} MB`;
      throw err;
    }
  };

  const beforeUpload = (originalFile) => {
    try {
      // validations
      validateMaxCount();
      validateFormat(originalFile.name);
      validateSize(originalFile.size);

      return false;
    } catch (err) {
      setError(err);

      return AntdUpload.LIST_IGNORE;
    }
  };

  const handleChange = async ({ file: uploadedFile, fileList: originalFileList }) => {
    if (uploadedFile.status === 'removed') {
      const newFileList = fileList.filter((f) => f.uid !== uploadedFile.uid);
      await onChange(newFileList);
      return;
    }

    // files from `originalFileList` have some extra attributes that
    // allows them to be displayed more easily
    const modifiedFile = originalFileList.find((f) => f.uid === uploadedFile.uid);
    if (!modifiedFile) {
      return;
    }

    const originalFile = modifiedFile.originFileObj;
    const extension = getFileExtension(originalFile.name);

    const newFile = { ...modifiedFile };

    newFile.originFileObj = new File([originalFile], originalFile.name, {
      type: mimeTypesByExtension[extension],
      lastModified: originalFile.lastModified,
    });
    // uid is lost when file is copied in this way
    Object.defineProperty(newFile.originFileObj, 'uid', { value: originalFile.uid });

    await onChange([...fileList, newFile]);
    await onFileChange(newFile);
  };

  return (
    <Content
      text={text}
      error={error}
      beforeUpload={beforeUpload}
      onChange={handleChange}
      onRemove={onRemove}
      onDownload={onDownload}
      fileList={sanitizedFileList}
      maxCount={maxCount}
      className={className}
      multiple={multiple}
      disableUploadButton={disableUploadButton}
      loading={loading}
      {...props}
    >
      {children}
    </Content>
  );
}

export default Upload;

Upload.propTypes = {
  text: PropTypes.string,
  formats: PropTypes.arrayOf(PropTypes.string).isRequired,
  maxSize: PropTypes.number,
  onChange: PropTypes.func,
  onFileChange: PropTypes.func,
  onRemove: PropTypes.func,
  onDownload: PropTypes.func,
  maxCount: PropTypes.number,
  fileList: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        uid: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        lastModified: PropTypes.number.isRequired,
        lastModifiedDate: PropTypes.instanceOf(Date).isRequired,
        originFileObj: PropTypes.shape({
          uid: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
          lastModified: PropTypes.number.isRequired,
          size: PropTypes.number.isRequired,
        }).isRequired,
        percent: PropTypes.number.isRequired,
        size: PropTypes.number.isRequired,
      }),
      PropTypes.shape({
        url: PropTypes.string.isRequired,
      }),
    ]),
  ),
  className: PropTypes.string,
  multiple: PropTypes.bool,
  children: PropTypes.node,
  disableUploadButton: PropTypes.bool,
  loading: PropTypes.bool,
};

Upload.defaultProps = {
  text: undefined,
  maxSize: 10485760, // 10 MB
  onChange: () => {},
  onFileChange: () => {},
  onRemove: () => {},
  fileList: [],
  className: '',
  maxCount: undefined,
  multiple: false,
  children: null,
  disableUploadButton: false,
  loading: false,
};
