import { useEffect } from 'react';

import UserManual from '../../assets/documents/Manual_do_Usuario_-_SINIR_LR_-_PERIODO_ELEITORAL.pdf';
import Loader from '../../common-components/loader';

export default function DownloadUserManual() {
  // hack to download on pageload
  useEffect(() => {
    const userManual = document.createElement('a');
    userManual.href = UserManual;
    document.body.appendChild(userManual);
    userManual.click();
    document.body.removeChild(userManual);
  }, []);

  return <Loader style={{ height: '100vh' }} />;
}
