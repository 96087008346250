import globalEnums from '../../../enums';

const getResourceKey = (resource) => {
  return Object.keys(globalEnums.RESOURCES).find(
    (resourceKey) => globalEnums.RESOURCES[resourceKey] === resource,
  );
};

const userHaveAccess = (userPermissions, toResource, toPerform) => {
  const isToResourceAnArray = Array.isArray(toResource);

  const resourcesToAccess = [].concat(toResource);
  const actionsMatrix = [].concat(toPerform);

  if (isToResourceAnArray && resourcesToAccess.length !== actionsMatrix.length) {
    throw new Error('The number of items in `toResource` and `toPerform` must be the same');
  }

  const haveAllPermissions = resourcesToAccess.every((resource, resourceIndex) => {
    const resourceKey = getResourceKey(resource);
    if (!resourceKey) {
      throw new Error(`Invalid value supplied to \`toResource\`: ${resource}`);
    }

    const validActions = Object.values(globalEnums.ACTIONS[resourceKey]);

    const actionsOfResource = isToResourceAnArray ? actionsMatrix[resourceIndex] : actionsMatrix;

    const actions = [].concat(actionsOfResource);

    actions.forEach((action) => {
      if (!validActions.includes(action)) {
        throw new Error(`Invalid value supplied to \`toPerform\`: ${action}`);
      }
    });

    const havePermission = actions.every((action) => {
      return userPermissions[resource]?.[action];
    });

    return havePermission;
  });

  return haveAllPermissions;
};

export default userHaveAccess;
