import {
  COMPANY_PLAN_TYPE_ID,
  GOAL_UNITS_OF_MEASUREMENT,
} from '../../../../../../../modules/plans/enums';

export default {
  PLAN_DETAILS_FIELDS: {
    TITLE: 'Detalhes',
    SENT_DATE: 'Entregue em',
    DECLARED_AT: 'Declarado em',
    PLAN_TYPE: {
      LABEL: 'Tipo',
      VALUES: {
        [COMPANY_PLAN_TYPE_ID.COLLECTIVE]: 'Coletivo',
        [COMPANY_PLAN_TYPE_ID.INDIVIDUAL]: 'Individual',
      },
    },
    YEAR: 'Ano',
  },
  FIELDS: {
    INFORMATION: {
      TITLE: (year: string | number) => `*Informação.* Ano base ${year}`,
    },
  },
  BUTTONS: {
    RETURN: 'Voltar',
  },
  FORM_FIELDS: {
    COLLECTED_IN_TON: {
      LABEL: 'Quanto foi coletado em Ton',
    },
    REQUIRED_GOAL: {
      LABEL: 'Meta declarada',
    },
    GOAL_PROGRESS: {
      LABEL: 'Você atingiu',
      MARKUP: (percentage: string) => `${percentage} da meta`,
    },
  },
  LIST_FIELDS: {
    GOAL_ACHIEVED: {
      LABEL: 'Você atingiu',
    },

    KG_QUANTITY_COLLECTED: {
      LABEL: 'Qnt. (ton)coletada',
    },
  },
  GOAL_UNITS_OF_MEASUREMENT: {
    [GOAL_UNITS_OF_MEASUREMENT.PERCENTAGE]: '%',
    [GOAL_UNITS_OF_MEASUREMENT.TON_PER_YEAR]: ' t/ano',
  },
};
