import { useEffect, useState } from 'react';

import Content from './content';

import ErrorHandler from '../../../../../common-components/error-handler';

import HttpError from '../../../../../cross-cutting/errors/http-error';
import {
  getCompanyPrograms,
  GetCompanyProgramsResponse,
  GetOneCompanyResponse,
} from '../../../../../modules/company/service';

export default function AssociatedSystems({ company }: { company?: GetOneCompanyResponse }) {
  const [loading, setLoading] = useState(false);
  const [programs, setPrograms] = useState<GetCompanyProgramsResponse | undefined>();
  const [error, setError] = useState<HttpError | undefined>();

  useEffect(() => {
    const fetchCompanySystems = async () => {
      setLoading(true);
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const response = await getCompanyPrograms(company!.id);
        setPrograms(response);
      } catch (err) {
        if (err instanceof HttpError) {
          setError(err);
        }
      }

      setLoading(false);
    };

    if (company?.id) fetchCompanySystems();
  }, [company, company?.id]);

  return (
    <ErrorHandler error={error}>
      <Content programs={programs} loading={loading} />
    </ErrorHandler>
  );
}
