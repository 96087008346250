import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ErrorHandler from '../../../common-components/error-handler';
import HttpError from '../../../cross-cutting/errors/http-error';
import { getOneCompany, GetOneCompanyResponse } from '../../../modules/company/service';
import Content from './content';

export default function ViewCompany() {
  const [company, setCompany] = useState<GetOneCompanyResponse | undefined>();
  const [error, setError] = useState<HttpError | undefined>();
  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  useEffect(() => {
    const fetchDetails = async () => {
      setLoading(true);
      try {
        if (!id) throw new Error('id is required');
        const response = await getOneCompany(id);

        setCompany(response);
      } catch (err) {
        if (err instanceof HttpError) {
          setError(err);
        }
      }

      setLoading(false);
    };

    fetchDetails();
  }, [id]);

  return (
    <ErrorHandler error={error}>
      <Content company={company} loading={loading} />
    </ErrorHandler>
  );
}
