import { useRef } from 'react';
import { Input as AntdInput, InputRef as AntdInputRef } from 'antd';
import type { InputProps as AntdInputProps } from 'antd';

import styles from './index.module.css';
import { applyMaskPattern, getAlmostIdealMask } from '../../cross-cutting/utils/mask';

function sanitizeInputValue(inputValue: string) {
  return inputValue.replace(/[\D\W]/g, '');
}

export type InputProps = AntdInputProps & {
  /** antd's Form uses `id` to get the input value, so it's best to avoid using it
   * so we don't overwrite it by mistake */
  id?: never;
  /** Mask only works inside a Form */
  mask?: string | string[];
  placeholderChar?: string;
};

export default function Input({
  mask,
  placeholderChar = '_',
  className = '',
  disabled,
  onChange = () => {},
  ...restProps
}: InputProps) {
  const ref = useRef<AntdInputRef | null>(null);

  const setCursorPosition = (cursorPosition: number) => {
    if (ref.current?.input) {
      ref.current.input.selectionStart = cursorPosition;
      ref.current.input.selectionEnd = cursorPosition;
    }
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!mask) {
      await onChange(event);
      return;
    }

    const inputNewValue = event.target.value;
    const sanitizedValue = sanitizeInputValue(inputNewValue) || '';

    const idealMask = getAlmostIdealMask(mask, sanitizedValue);
    const maskedValue = applyMaskPattern(sanitizedValue, idealMask, { placeholderChar });

    await onChange({
      ...event,
      target: {
        ...event.target,
        value: maskedValue,
      },
    });

    const cursorPosition = applyMaskPattern(sanitizedValue, idealMask).length;
    setCursorPosition(cursorPosition);
  };

  return (
    <AntdInput
      {...restProps}
      ref={ref}
      className={`${styles.input} ${className}`}
      disabled={disabled}
      onChange={handleChange}
    />
  );
}

export { default as InputPassword } from './password';
