import axios, { AxiosRequestConfig } from 'axios';

import config from '../config';
import reauthentication from './reauthentication';
import HttpError from '../errors/http-error';
import isObject from '../utils/is-object';
import formatBlobAsJson from '../utils/format-blob-as-json';

const formatObjectForQueryParams = <T>(paramBaseName: string, object: Record<string, T>) => {
  const newParams: Record<string, T> = {};

  Object.entries(object).forEach((entry) => {
    const [attributeName, newParamValue] = entry;
    const newParamKey = `${paramBaseName}[${attributeName}]`;

    newParams[newParamKey] = newParamValue;
  });

  return newParams;
};

const instance = axios.create({
  baseURL: config.API_BASE_URL,
  timeout: 120000,
  withCredentials: true,
});

instance.interceptors.request.use((options) => {
  const { params = {} } = options;

  let newParams = { ...params };

  Object.entries(params).forEach((entry) => {
    const [key, value] = entry;

    if (isObject(value)) {
      delete newParams[key];
      newParams = {
        ...newParams,
        ...formatObjectForQueryParams(key, value as Record<string, unknown>),
      };
    }
  });

  return {
    ...options,
    params: newParams,
  };
});

instance.interceptors.response.use(
  (response) => Promise.resolve(response),
  reauthentication.interceptor(instance),
);

instance.interceptors.response.use(
  (response) => Promise.resolve(response),
  async ({ response, request }) => {
    let statusCode = 0;
    let details = [];

    if (response) {
      let jsonResponseData = response.data;

      if (response.data instanceof Blob) {
        jsonResponseData = await formatBlobAsJson(response.data);
      }

      statusCode = response.status;
      details = jsonResponseData.errors;
    } else if (request) {
      statusCode = 503;
    }

    const err = new HttpError(statusCode, details);

    return Promise.reject(err);
  },
);

export type { OrderBy } from './types';

export default {
  instance,
  get: (url: string, options?: AxiosRequestConfig) => instance.get(url, options),
  post: (url: string, data?: unknown, options?: AxiosRequestConfig) =>
    instance.post(url, data, options),
  put: (url: string, data?: unknown, options?: AxiosRequestConfig) =>
    instance.put(url, data, options),
  delete: (url: string, options?: AxiosRequestConfig) => instance.delete(url, options),
  reauthenticate: () => reauthentication.tryReauthentication(instance),
};
