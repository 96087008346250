/* eslint-disable react/jsx-props-no-spreading */
import { Modal as AntdModal, ModalProps } from 'antd';
import { ReactNode } from 'react';

import i18n from './i18n';
import styles from './index.module.css';

type Props = ModalProps & {
  title: ReactNode;
  visible: boolean;
  onCancel: () => void;
  isLoading?: boolean;
  children: React.ReactNode;
  footer?: React.ReactNode;
  width?: string;
  className?: string;
};

function Modal({
  title,
  visible,
  onCancel,
  isLoading,
  children,
  footer,
  width,
  className = '',
  closable,
  ...props
}: Props) {
  function renderContent() {
    if (isLoading) {
      return <div style={{ padding: '30px 0px' }}>{i18n.ptBR.LOADING}</div>;
    }

    return children;
  }

  return (
    <AntdModal
      {...props}
      title={title}
      visible={visible}
      onCancel={onCancel}
      className={`${styles.modal} ${className}`}
      footer={footer}
      width={width || '50%'}
      closable={closable || false}
      cancelText={i18n.ptBR.BUTTONS.CANCEL}
    >
      {renderContent()}
    </AntdModal>
  );
}

export default Modal;
