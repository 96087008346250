import { UNPROCESSABLE_ENTITY_ERROR_TYPES } from '../../enums';

export default {
  TITLE: 'Recuperar senha',
  TEXT: 'Insira o seu e-mail e enviaremos um código para você recuperar o acesso à sua conta.',

  FORM_FIELDS: {
    EMAIL: {
      LABEL: 'E-mail',
      PLACEHOLDER: 'Insira seu e-mail',
      ERROR_MESSAGE: 'Por favor, insira um e-mail válido',
    },
  },
  BUTTONS: {
    SUBMIT: 'Enviar código',
    CANCEL: 'Cancelar',
  },
  UNPROCESSABLE_ENTITY_ERROR_MESSAGES: {
    [UNPROCESSABLE_ENTITY_ERROR_TYPES.PREVIOUS_RECOVERY_CODE_STILL_VALID]: {
      TITLE: 'Não foi possível solicitar outro código',
      DESCRIPTION: (timeToExpire: string) =>
        `Um código solicitado anteriormente ainda está válido. Caso não tenha recebido tente gerar um novo daqui ${timeToExpire} minutos.`,
    },
  },
};
