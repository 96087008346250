import { ReactComponent as AlertCircle } from '../../assets/icons/alert-circle.svg';
import { ReactComponent as AlertTriangle } from '../../assets/icons/alert-triangle.svg';
import { ReactComponent as Avatar } from '../../assets/icons/avatar.svg';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as Award } from '../../assets/icons/award.svg';
import { ReactComponent as Bars } from '../../assets/icons/bars.svg';
import { ReactComponent as CheckCircle } from '../../assets/icons/check-circle.svg';
import { ReactComponent as NewCheckCircle } from '../../assets/icons/new-check-circle.svg';
import { ReactComponent as ChevronDown } from '../../assets/icons/chevron-down.svg';
import { ReactComponent as ChevronUp } from '../../assets/icons/chevron-up.svg';
import { ReactComponent as Clipboard } from '../../assets/icons/clipboard.svg';
import { ReactComponent as ClipboardCheck } from '../../assets/icons/clipboard-check.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-icon.svg';
import { ReactComponent as Download } from '../../assets/icons/download.svg';
import { ReactComponent as Edit3 } from '../../assets/icons/edit-3.svg';
import { ReactComponent as Eye } from '../../assets/icons/eye.svg';
import { ReactComponent as EyeView } from '../../assets/icons/eye-view.svg';
import { ReactComponent as FileText } from '../../assets/icons/file-text.svg';
import { ReactComponent as HandShake } from '../../assets/icons/hand-shake.svg';
import { ReactComponent as Home } from '../../assets/icons/home.svg';
import { ReactComponent as InfoFilled } from '../../assets/icons/info-filled.svg';
import { ReactComponent as LineGraph } from '../../assets/icons/line-graph.svg';
import { ReactComponent as Medal } from '../../assets/icons/medal.svg';
import { ReactComponent as Minus } from '../../assets/icons/minus.svg';
import { ReactComponent as MinusSquare } from '../../assets/icons/minus-square.svg';
import { ReactComponent as MoreHorizontal } from '../../assets/icons/more-horizontal.svg';
import { ReactComponent as NextCrumb } from '../../assets/icons/next-crumb.svg';
import { ReactComponent as Pdf } from '../../assets/icons/pdf.svg';
import { ReactComponent as Plus } from '../../assets/icons/plus.svg';
import { ReactComponent as PlusSquare } from '../../assets/icons/plus-square.svg';
import { ReactComponent as RecyclingSymbol } from '../../assets/icons/recycling-symbol.svg';
import { ReactComponent as Search } from '../../assets/icons/search.svg';
import { ReactComponent as Upload } from '../../assets/icons/upload.svg';
import { ReactComponent as User } from '../../assets/icons/user.svg';
import { ReactComponent as Trash } from '../../assets/icons/trash.svg';
import { ReactComponent as XCircle } from '../../assets/icons/x-circle.svg';

const icons = {
  alertCircle: <AlertCircle />,
  alertTriangle: <AlertTriangle />,
  avatar: <Avatar />,
  arrowLeft: <ArrowLeft />,
  arrowRight: <ArrowRight />,
  award: <Award />,
  bars: <Bars />,
  checkCircle: <CheckCircle />,
  newCheckCircle: <NewCheckCircle />,
  chevronDown: <ChevronDown />,
  chevronUp: <ChevronUp />,
  clipboard: <Clipboard />,
  clipboardCheck: <ClipboardCheck />,
  closeIcon: <CloseIcon />,
  download: <Download />,
  edit3: <Edit3 />,
  eye: <Eye />,
  eyeView: <EyeView />,
  fileText: <FileText />,
  handshake: <HandShake />,
  home: <Home />,
  infoFilled: <InfoFilled />,
  lineGraph: <LineGraph />,
  medal: <Medal />,
  minus: <Minus />,
  minusSquare: <MinusSquare />,
  moreHorizontal: <MoreHorizontal />,
  nextCrumb: <NextCrumb />,
  pdf: <Pdf />,
  plus: <Plus />,
  plusSquare: <PlusSquare />,
  recyclingSymbol: <RecyclingSymbol />,
  search: <Search />,
  upload: <Upload />,
  user: <User />,
  trash: <Trash />,
  xCircle: <XCircle />,
};

export default icons;
