import { UNPROCESSABLE_ENTITY_ERROR_TYPES } from '../../enums';

export default {
  TITLE: 'Recuperar senha',
  TEXT: (email: string) =>
    `Cheque seu e-mail! O código para redefinir sua senha foi enviado para *${email}*`,

  FORM_FIELDS: {
    RECOVERY_CODE: {
      LABEL: 'Código',
      PLACEHOLDER: 'Insira o código',
      ERROR_MESSAGE: 'Por favor, insira um código válido',
    },
  },
  BUTTONS: {
    SUBMIT: 'Enviar código',
    RESEND: 'Reenviar código',
    TEXT: 'Não recebeu o código? Reenvie agora',
  },

  UNPROCESSABLE_ENTITY_ERROR_MESSAGES: {
    [UNPROCESSABLE_ENTITY_ERROR_TYPES.PREVIOUS_RECOVERY_CODE_STILL_VALID]: {
      TITLE: 'Não foi possível alterar sua senha',
      DESCRIPTION: (timeToExpire: string) =>
        `Um código solicitado anteriormente ainda está válido. Caso não tenha recebido tente gerar um novo daqui ${timeToExpire} minutos.`,
    },
    [UNPROCESSABLE_ENTITY_ERROR_TYPES.ACCOUNT_RECOVERY_CODE.INVALID]: {
      TITLE: 'Não foi possível alterar sua senha',
      DESCRIPTION: () => 'O código de recuperação da senha está inválido ou não está mais ativo',
    },
    [UNPROCESSABLE_ENTITY_ERROR_TYPES.ACCOUNT_RECOVERY_CODE.ATTEMPT_LIMIT]: {
      TITLE: 'Não foi possível alterar sua senha',
      DESCRIPTION: () =>
        'Você atingiu o limite de tentativas de recuperação de senha para o código atual',
    },
  },
};
