export default {
  ERRORS: {
    INVALID_FORMAT: {
      TITLE: 'Formato do arquivo inválido',
      DESCRIPTION: (details) => `Os formatos permitidos são: ${details}`,
    },
    MAX_SIZE_EXCEEDED: {
      TITLE: 'Tamanho máximo excedido',
      DESCRIPTION: (details) => `O arquivo deve ser menor que ${details}`,
    },
    MAX_COUNT: {
      TITLE: 'Limite de arquivos atingido',
      DESCRIPTION: (details) => `Só podem ser selecionados no máximo ${details} arquivos`,
    },
  },
};
