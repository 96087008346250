const stringContainsIgnoringAccents = (text, inputValue, index = 0) => {
  const contains =
    inputValue.localeCompare(String(text).substring(index, inputValue.length), 'pt-BR', {
      sensitivity: 'base',
    }) === 0;

  if (contains) {
    return true;
  }

  const nextIndex = index + 1;

  if (inputValue.length + nextIndex > String(text).length) {
    return false;
  }

  return stringContainsIgnoringAccents(text, inputValue, nextIndex);
};

const matchChildrenWithInputValue = (inputValue, option) => {
  return stringContainsIgnoringAccents(option.children, inputValue);
};

export default matchChildrenWithInputValue;
