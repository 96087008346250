import { Space } from 'antd';
import Button from '../../../../../../common-components/button';
import Divider from '../../../../../../common-components/divider';
import Icon from '../../../../../../common-components/icon';

import { PROGRAM_STATUS } from '../enums';

import styles from './index.module.css';
import i18n from './i18n';

type CardProps = {
  programName: string;
  programImage: string;
  programId: string;
  programStatus: typeof PROGRAM_STATUS[keyof typeof PROGRAM_STATUS];
  onClick: (programId: string) => void;
  isGeographicGoalAchieved: boolean;
  isQuantityGoalAchieved: boolean;
  shouldRenderGeographicGoal?: boolean;
};

export default function Card({
  programName,
  programImage,
  programId,
  onClick,
  programStatus,
  isQuantityGoalAchieved,
  isGeographicGoalAchieved,
  shouldRenderGeographicGoal = true,
}: CardProps) {
  return (
    <div className={styles.cardContainer}>
      <img src={programImage} alt={programName} style={{ height: '140px' }} />

      <div className={`${styles.tag} ${styles.tagConfigured}`}>
        {i18n.ptBR.STATUS[programStatus]}
      </div>

      <div className={styles.cardTitle}>{programName}</div>

      <Space direction="vertical" size={16}>
        <div className={styles.metricItem}>
          <Icon
            color={isQuantityGoalAchieved ? '#2670E8' : 'var(--red)'}
            size={24}
            name={isQuantityGoalAchieved ? 'checkCircle' : 'xCircle'}
          />
          <span>{i18n.ptBR.METRIC_ITEMS.QUANTITY}</span>
        </div>
        {shouldRenderGeographicGoal && (
          <div className={styles.metricItem}>
            <Icon
              color={isGeographicGoalAchieved ? '#2670E8' : 'var(--red)'}
              size={24}
              name={isGeographicGoalAchieved ? 'checkCircle' : 'xCircle'}
            />
            <span>{i18n.ptBR.METRIC_ITEMS.GEOGRAPHICAL}</span>
          </div>
        )}
      </Space>

      <Divider type="line" />

      <Button
        text={i18n.ptBR.VIEW_BUTTON}
        icon={<Icon name="eyeView" />}
        onClick={() => onClick(programId)}
        type="default"
        newDesign
      />
    </div>
  );
}
