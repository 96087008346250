import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Serie } from '@nivo/line';

import Content from './content';

import ErrorHandler from '../../../../../../common-components/error-handler';

import { getCollectedVolumeIndicator } from '../../../../../../modules/indicators/service';
import HttpError from '../../../../../../cross-cutting/errors/http-error';
import {
  DashboardFilters,
  GetTotalAmountDeclaredIndicatorResponse,
} from '../../../../../../modules/indicators/types';
import { COMPANY_TYPE_ID } from '../../../../../user-registration/form/enums';

export default function CollectedVolumeIndicator({ filters }: { filters: DashboardFilters }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<HttpError>();
  const [graphData, setGraphData] = useState<GetTotalAmountDeclaredIndicatorResponse | undefined>();

  useEffect(() => {
    // fetch collected volume indicator
    const fetchCollectedVolumeIndicator = async () => {
      try {
        setLoading(true);

        const initialYear = filters.year ? filters.year - 3 : dayjs().year() - 3;
        const finalYear = filters.year ? filters.year : dayjs().year();

        const customFilters = {
          startYear: initialYear,
          endYear: finalYear,
          ...filters,
        };

        delete customFilters.year;

        const response = await getCollectedVolumeIndicator({
          ...customFilters,
        });

        setGraphData(response);
      } catch (err) {
        if (err instanceof HttpError) {
          setError(err);
        }
      }
      setLoading(false);
    };

    fetchCollectedVolumeIndicator();
  }, [filters]);

  const formatGraphData = (data?: GetTotalAmountDeclaredIndicatorResponse) => {
    const manager: Serie & { color: string } = {
      id: 'Gestoras',
      color: 'hsla(217, 81%, 39%, 1)',
      data:
        data
          ?.find((item) => item.companyTypeId === COMPANY_TYPE_ID.MANUFACTURER_MANAGER)
          ?.declaredAmounts.map((amount) => ({
            y: amount.amountCollected,
            x: amount.fiscalYear,
          })) || [],
    };

    const companies: Serie & { color: string } = {
      id: 'Empresas',
      color: 'hsla(216, 49%, 85%, 1)',
      data:
        data
          ?.find((item) => item.companyTypeId === COMPANY_TYPE_ID.MANUFACTURER)
          ?.declaredAmounts.map((amount) => ({
            y: amount.amountCollected,
            x: amount.fiscalYear,
          })) || [],
    };

    switch (filters.companyTypeId) {
      case COMPANY_TYPE_ID.MANUFACTURER:
        return [companies];
      case COMPANY_TYPE_ID.MANUFACTURER_MANAGER:
        return [manager];
      default:
        return [manager, companies];
    }
  };

  return (
    <ErrorHandler error={error}>
      <Content filters={filters} loading={loading} graphData={formatGraphData(graphData)} />
    </ErrorHandler>
  );
}
