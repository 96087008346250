import { ColumnsType } from 'antd/lib/table';

import i18n from './i18n';
import { Company } from '../../../../modules/company/types';
import cnpjFormatter from '../../../../cross-cutting/utils/cnpjFormatter';
import Button from '../../../../common-components/button';
import Icon from '../../../../common-components/icon';

import routes from '../../../../routes';
import useFeatureFlags from '../../../../cross-cutting/utils/use-feature-flags';
import { VIEW_COMPANY_DETAILS_FF } from '../../../../feature-flags';
import { TABS } from '../../view-company/enums';

export default (): ColumnsType<Company> => {
  const { featureEnabled } = useFeatureFlags();

  return [
    {
      title: i18n.ptBR.COLUMNS.LABELS.LEGAL_NAME,
      key: 'legalName',
      sorter: {
        multiple: 1,
        compare: (a, b) => a.legalName.localeCompare(b.legalName),
      },
      render: (_, { legalName }) => {
        return <span data-testid="legalName">{legalName}</span>;
      },
    },
    {
      title: i18n.ptBR.COLUMNS.LABELS.CNPJ,
      key: 'cnpj',
      render: (_, { cnpj }) => {
        return <span data-testid="cnpj">{cnpjFormatter(cnpj)}</span>;
      },
    },
    {
      title: i18n.ptBR.COLUMNS.LABELS.STATE,
      key: 'state',
      render: (_, { address }) => {
        return (
          <span data-testid="state">
            {i18n.ptBR.COLUMNS.STATES[address.stateAbbreviation as never]}
          </span>
        );
      },
    },
    {
      title: i18n.ptBR.COLUMNS.LABELS.COMPANY_TYPE,
      key: 'companyType',
      render: (_, { companyTypeId }) => {
        return (
          <span data-testid="companyType">
            {i18n.ptBR.COLUMNS.COMPANY_TYPE[companyTypeId as never]}
          </span>
        );
      },
    },
    {
      title: i18n.ptBR.COLUMNS.LABELS.ACTIONS,
      key: 'actions',
      render: (_, { id }) => {
        return featureEnabled(VIEW_COMPANY_DETAILS_FF) ? (
          <Button
            href={routes.VIEW_COMPANY_DETAILS.replace(/:id/, id).concat(
              `?redirectToTab=${TABS.REVERSE_LOGISTICS_PLANS}`,
            )}
            icon={<Icon name="eyeView" />}
            type="text"
          />
        ) : null;
      },
    },
  ];
};
