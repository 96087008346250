export const APP_SUPPORT_CONTACT_EMAIL = 'sinir@mma.gov.br';

export const FEATURE_FLAGS_STORAGE_KEY = 'featureFlags';

export const HTTP_STATUS_CODES = {
  NETWORK_ERROR: 0,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  UNPROCESSABLE_ENTITY: 422,
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
};

export const UNPROCESSABLE_ENTITY_ERROR_TYPES = {
  CREDENTIALS_ALREADY_REGISTERED: 'credentials_already_registered',
  PROGRAM_NOT_FOUND: 'company.program.not_found',
  FISCAL_YEAR_INVALID: 'fiscal_year.invalid',
  COMPANY_PLAN_FISCAL_YEAR_ALREADY_REGISTERED: 'company_plan.fiscal_year.already_registered',
  COMPANY_PLAN_MISSING_INFO: 'company_plan.missing_info',
  COMPANY_PLAN_PREVIOUS_COMPANY_PLAN_WAS_NOT_SENT:
    'company_plan.previous_company_plan_was_not_sent',
  COMPANY_PLAN_ASSOCIATED_COMPANIES_ATTACHMENT_LEGAL_NAME_MISSING:
    'company_plan.associated_companies_attachment.legal_name.missing',
  COMPANY_PLAN_ASSOCIATED_COMPANIES_ATTACHMENT_LEGAL_NAME_GREATER_THAN_ALLOWED:
    'company_plan.associated_companies_attachment.legal_name.greater_than_allowed',
  COMPANY_PLAN_ASSOCIATED_COMPANIES_ATTACHMENT_CNPJ_MISSING:
    'company_plan.associated_companies_attachment.cnpj.missing',
  COMPANY_PLAN_ASSOCIATED_COMPANIES_ATTACHMENT_CNPJ_INVALID:
    'company_plan.associated_companies_attachment.cnpj.invalid',
  COMPANY_PLAN_ALREADY_SENT: 'company_plan.already_sent',
  ATTACHMENT_ASSOCIATED_WITH_RESOURCE: 'attachment.associated_with_resource',
  COMPANY_PLAN_PREVIOUS_COMPANY_PLAN_DECLARATIONS_WERE_NOT_SENT:
    'company_plan.previous_company_plan.declarations.were_not_sent',
  COMPLIANCE_CERTIFICATE_FISCAL_YEAR_NOT_FOUND: 'compliance_certificate.fiscal_year.not_found',
  FISCAL_YEAR_REPORT_PERIOD_HAS_NOT_STARTED: 'fiscal_year.report_period.hasnt_started',
  FISCAR_YEAR_DECLARATION_DEADLINE_HAS_PASSED: 'fiscal_year.declaration_deadline.has_passed',
};

export const TON_DECIMAL_PRECISION = 3;
export const TON_FIELD_STEP = 1.0;

export default {
  KEYBOARD_CHAR_CODES: {
    ENTER: 13,
    ESC: 27,
    SPACE_BAR: 32,
  },
  RESOURCES: {
    ATTACHMENT: 'attachment',
    COMPANY_PLAN: 'company-plan',
    COMPLIANCE_CERTIFICATE: 'compliance-certificate',
    DECLARATION: 'declaration',
  },
  ACTIONS: {
    ATTACHMENT: {
      CREATE: 'create',
      GET_ONE: 'getOne',
      DELETE: 'delete',
    },
    COMPANY_PLAN: {
      GET_ALL: 'getAll',
      CREATE: 'create',
      GET_ONE: 'getOne',
      SEND: 'send',
      UPDATE: 'update',
    },
    COMPLIANCE_CERTIFICATE: {
      DOWNLOAD: 'download',
    },
    DECLARATION: {
      CREATE: 'create',
    },
  } as const,
};
export const REGION = {
  NORTH: 1,
  NORTHEAST: 2,
  MIDWEST: 3,
  SOUTHEAST: 4,
  SOUTH: 5,
} as const;
export const LEGISLATION_LINK =
  'http://www.planalto.gov.br/ccivil_03/_Ato2019-2022/2022/Decreto/D10936.htm';

export const PROGRAMS_IDS = {
  AGROTOXIC_ITS_RESIDUES_AND_PACKAGES: 1,
  BATERIES_OF_ACID_LEAD: 2,
  ELETRONICS_AND_ITS_COMPONENTS: 3,
  STEEL_PACKAGES: 4,
  PLASTIC_PACKAGES_OF_LUBRICANT_OILS: 5,
  PACKAGES_IN_GENERAL: 6,
  FLUORESCENT_SODIUM_AND_MERCURY_MIXED_LIGHT_LAMPS: 7,
  MEDICAMENTS_ITS_RESIDUES_AND_PACKAGES: 8,
  USED_OR_CONTAMINATED_LUBRICANT_OILS: 9,
  BATTERIES: 10,
  UNSERVICIABLE_TIRES: 11,
  ALUMINIUM_CANS_FOR_DRINKS: 12,
};
