import { BaseSyntheticEvent, CSSProperties, ReactNode } from 'react';
import { Button as AntdButton, ButtonProps } from 'antd';
import { Link } from 'react-router-dom';

import styles from './index.module.css';
import { ButtonTypes } from './enums';

export type BaseButtonProps = Omit<ButtonProps, 'className' | 'size' | 'type'> & {
  text?: ReactNode;
  title?: string;
  type?: 'default' | 'stroke' | 'text';
  icon?: React.ReactElement | null;
  iconPosition?: 'left' | 'right';
  className?: null | string;
  href?: string | { pathname: string; state: unknown };
  htmlType?: 'button' | 'submit' | 'reset';
  onClick?: (e: BaseSyntheticEvent) => void;
  block?: boolean;
  loading?: boolean;
  disabled?: boolean;
  'data-testid'?: string;
  style?: CSSProperties;
  newDesign?: boolean;
};

function Button({
  text,
  title,
  type = 'default',
  'data-testid': dataTestId,
  icon = null,
  iconPosition = 'left',
  className = null,
  href,
  htmlType = 'button',
  onClick = () => {},
  block = false,
  loading = false,
  disabled = false,
  style = {},
  newDesign,
  ...props
}: BaseButtonProps) {
  const iconSize = '20px';

  const decoratorStyles = {
    padding: !text ? '0' : undefined,
  };

  const iconStyles = {
    width: iconSize,
    height: iconSize,
    flexShrink: 0,
  };

  const handleClick = (e: BaseSyntheticEvent) => {
    onClick(e);
    e.target.blur();
  };

  const showIcon = icon && !loading;

  let buttonTypeStyle;

  switch (type) {
    case ButtonTypes.DEFAULT:
      buttonTypeStyle = newDesign ? styles.newDesignDefault : styles.default;
      break;
    case ButtonTypes.STROKE:
      buttonTypeStyle = newDesign ? styles.newDesignStroke : styles.stroke;
      break;
    case ButtonTypes.TEXT:
      buttonTypeStyle = newDesign ? styles.newDesignText : styles.text;
      break;
    default:
      buttonTypeStyle = '';
  }

  const button = (
    <AntdButton
      htmlType={htmlType}
      style={{ ...decoratorStyles, ...style }}
      className={
        newDesign
          ? `${styles.buttonNewDesign} ${buttonTypeStyle} ${className || ''}`
          : `${styles.button} ${buttonTypeStyle} ${className || ''}`
      }
      onClick={handleClick}
      block={block}
      loading={loading}
      disabled={disabled}
      title={title}
      data-testid={dataTestId}
      {...props}
    >
      {showIcon && iconPosition === 'left' ? <i style={iconStyles}>{icon}</i> : null}
      {text || null}
      {showIcon && iconPosition === 'right' ? <i style={iconStyles}>{icon}</i> : null}
    </AntdButton>
  );

  if (href) {
    return <Link to={href}>{button}</Link>;
  }
  return button;
}

export default Button;
