import { createContext } from 'react';

import type { GetUserResponse } from '../service';

type ContextType = {
  user?: GetUserResponse;
  setUser?: (newValue: GetUserResponse) => void;
};

const UserContext = createContext<ContextType>({});

export default UserContext;
