import BigNumber from 'bignumber.js';

import Collapsible from '../../../../../../common-components/collapsible';
import DraggerUpload from '../../../../../../common-components/dragger-upload';
import DecimalInput from '../../../../../../common-components/decimal-input';
import { FormItem } from '../../../../../../common-components/form';
import Button from '../../../../../../common-components/button';
import ProgressBar from '../../../../../../common-components/progress-bar';

import i18n from '../../content/i18n';
import styles from './index.module.css';
import { EVIDENCE_UPLOAD_VALID_FORMATS, FORM_FIELDS } from '../../enums';
import { TON_FIELD_STEP } from '../../../../../../enums';
import type { PlanGetOneCompanyPlan } from '../../../../../../modules/plans/types';
import { GOAL_UNITS_OF_MEASUREMENT } from '../../../../../../modules/plans/enums';
import { FormValues } from '../../types';
import numberFormatter from '../../../../../../cross-cutting/utils/number-formatter';
import colors from '../../../../../../colors';
import type { UploadedFileData } from '..';
import formatWeight from '../../../../../../cross-cutting/utils/format-weight';

const formatPercentage = (decimal: number) => {
  const percentage = new BigNumber(decimal)
    .times(100)
    .decimalPlaces(2, BigNumber.ROUND_HALF_EVEN)
    .toNumber();

  const formattedPercentage = numberFormatter('pt-BR')(percentage);
  return `${formattedPercentage}${
    i18n.ptBR.GOAL_UNITS_OF_MEASUREMENT[GOAL_UNITS_OF_MEASUREMENT.PERCENTAGE]
  }`;
};

type Props = {
  fieldsPrefix: string;
  plan: PlanGetOneCompanyPlan;
  uploadFileData: UploadedFileData[];
  onDownloadFile: (file: UploadedFileData) => void;
};

function Content({ fieldsPrefix, plan, uploadFileData, onDownloadFile }: Props) {
  const { name, fiscalYear } = plan.program;

  const hasGoal = !!fiscalYear.goal && Number(fiscalYear.goal) !== 0;

  return (
    <Collapsible title={name} defaultOpen forceChildrenRender>
      <div>
        {plan.declaration?.declarationHasProgramWasteTypes.map((planDeclaration) => {
          const wasteType = plan.programWasteTypes.find(
            ({ id }) => id === planDeclaration.programWasteTypeId,
          );

          if (!wasteType) {
            return null;
          }

          const unitOfMeasure = fiscalYear.goalUnitOfMeasureId;

          const isTon = unitOfMeasure === GOAL_UNITS_OF_MEASUREMENT.TON_PER_YEAR;

          const amountCollected = new BigNumber(planDeclaration.amountCollected)
            .div(1000)
            .toNumber();

          return (
            <div key={wasteType.id} className={styles.wasteTypeContainer}>
              <div className={styles.wasteTypeContainerHeader}>
                <span className={styles.wasteTypeContainerTitle}>{wasteType.name}</span>
              </div>

              <div className={styles.wasteTypeContainerBody}>
                {hasGoal && unitOfMeasure ? (
                  <FormItem
                    label={i18n.ptBR.FORM.FIELDS.GOAL.LABEL}
                    className={styles.wasteTypeField}
                    style={{ minWidth: '140px' }}
                  >
                    <span
                      className={`${styles.wasteTypeFieldValueContainer} ${styles.wasteTypeFieldValue}`}
                    >
                      {formatWeight(
                        new BigNumber(wasteType.weightGoal ?? 0).div(1000).toNumber(),
                        +wasteType.weightGoal >= 1000 ? 'ton' : undefined,
                        '.',
                      )}
                    </span>
                  </FormItem>
                ) : null}
                <FormItem
                  name={[
                    fieldsPrefix,
                    plan.id,
                    FORM_FIELDS.WASTE_TYPES_PREFIX,
                    wasteType.id,
                    FORM_FIELDS.RECYCLED_AMOUNT,
                  ]}
                  label={
                    <>
                      {i18n.ptBR.FORM.FIELDS.RECYCLED_AMOUNT.LABEL}
                      <span className={styles.requiredMark}>*</span>
                    </>
                  }
                  className={styles.wasteTypeField}
                  initialValue={amountCollected}
                  style={{ width: '100%' }}
                >
                  <DecimalInput
                    decimalPlaces={3}
                    step={TON_FIELD_STEP}
                    disabled
                    thousandSeparator="."
                    unitSymbol={
                      isTon
                        ? i18n.ptBR.GOAL_UNITS_OF_MEASUREMENT[
                            GOAL_UNITS_OF_MEASUREMENT.TON_PER_YEAR
                          ]
                        : 'ton'
                    }
                    unitSymbolPosition="right"
                  />
                </FormItem>
                {hasGoal ? (
                  <FormItem
                    label={i18n.ptBR.FORM.FIELDS.GOAL_PROGRESS.LABEL}
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues[fieldsPrefix]?.[plan.id]?.[FORM_FIELDS.WASTE_TYPES_PREFIX]?.[
                        wasteType.id
                      ]?.[FORM_FIELDS.RECYCLED_AMOUNT] !==
                      currentValues[fieldsPrefix]?.[plan.id]?.[FORM_FIELDS.WASTE_TYPES_PREFIX]?.[
                        wasteType.id
                      ]?.[FORM_FIELDS.RECYCLED_AMOUNT]
                    }
                    className={styles.wasteTypeField}
                    style={{ minWidth: '140px' }}
                  >
                    {({ getFieldValue }) => {
                      const recycledAmount = getFieldValue([
                        fieldsPrefix,
                        plan.id,
                        FORM_FIELDS.WASTE_TYPES_PREFIX,
                        wasteType.id,
                        FORM_FIELDS.RECYCLED_AMOUNT,
                      ]) as FormValues['plans'][string]['wasteTypes'][string]['recycledAmount'];

                      const goalProgressDecimal = new BigNumber(
                        new BigNumber(recycledAmount).times(1000),
                      )
                        .div(wasteType.weightGoal)
                        .toNumber();

                      return recycledAmount !== 0 ? (
                        <div
                          className={styles.wasteTypeFieldValueContainer}
                          style={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                          }}
                        >
                          <span
                            className={styles.wasteTypeFieldValue}
                            style={{ marginBottom: '8px' }}
                          >
                            {i18n.ptBR.FORM.FIELDS.GOAL_PROGRESS.MARKUP(
                              formatPercentage(goalProgressDecimal),
                            )}
                          </span>
                          <ProgressBar
                            progress={new BigNumber(goalProgressDecimal).times(100).toNumber()}
                            color={
                              goalProgressDecimal < 1
                                ? colors.feedbackDanger
                                : colors.feedbackSuccessDark
                            }
                          />
                        </div>
                      ) : null;
                    }}
                  </FormItem>
                ) : null}
              </div>
            </div>
          );
        })}

        <DraggerUpload
          onlyDisplayList
          fileData={uploadFileData}
          onDownload={onDownloadFile}
          formats={EVIDENCE_UPLOAD_VALID_FORMATS}
          showUploadList={{
            showRemoveIcon: false,
            showDownloadIcon: true,
            downloadIcon: (
              <Button type="text" text={i18n.ptBR.FORM.FIELDS.EVIDENCE_UPLOAD.DOWNLOAD_BUTTON} />
            ),
          }}
          className={styles.upload}
        />
      </div>
    </Collapsible>
  );
}

export default Content;
