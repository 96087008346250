import { Link } from 'react-router-dom';
import { CSSProperties, useState } from 'react';

import Button from '../../button';

import useOnClickOutside from '../../use-onclick-outside';
import styles from './index.module.css';
import Icon from '../../icon';
import icons from '../../icon/icons';
import { PLACEMENT } from './enums';

export type Item = {
  text: string;
  title?: string;
  description?: string;
  tag?: string;
  icon?: keyof typeof icons;
  href?: string;
  key: string;
  onClick?: () => void;
  disabled?: boolean;
  highlighted?: boolean;
};

type ButtonMenuProps = {
  className?: null | string;
  expandIcon?: React.ReactElement | null;
  items: Item[];
  style?: CSSProperties;
  placement?: 'left' | 'right';
};

export default function ButtonMenu({
  expandIcon,
  items,
  className = null,
  style = {},
  placement = 'left',
}: ButtonMenuProps) {
  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);

  const { ref } = useOnClickOutside(() => setIsSubMenuVisible(false));

  const getItems = (receivedItems: Item[]) => {
    return receivedItems.map((item, i) => {
      let buttonItem = (
        <div
          role="button"
          tabIndex={0}
          onKeyDown={(event) => {
            if (event.key === 'Enter') item?.onClick?.();
          }}
          onClick={item.onClick}
          key={item.key}
          title={item?.title}
          className={`${styles.menuItems} ${i > 0 ? styles.dividerLine : ''} ${
            item?.highlighted ? styles.highlightedItem : ''
          }`}
        >
          {item?.icon ? (
            <div>
              <Icon name={item.icon} size="16" />
            </div>
          ) : null}
          <span
            className={`${styles.menuItemsText} ${item?.description ? styles.hasDescription : ''}`}
          >
            {item?.text}
          </span>
          {item?.tag ? (
            <span
              className={`${styles.menuItemsTag} ${item?.description ? styles.hasDescription : ''}`}
            >
              {item?.tag}
            </span>
          ) : null}
          {item?.description ? (
            <span className={styles.menuItemsDescription}>{item.description}</span>
          ) : null}
        </div>
      );
      if (item?.href) {
        buttonItem = <Link to={item.href}>{buttonItem}</Link>;
      }
      if (item?.disabled) {
        return (
          <div className={styles.disabled} key="button">
            {buttonItem}
          </div>
        );
      }
      return buttonItem;
    });
  };

  const isSomeItemHighlighted = items.some((item) => item.highlighted);

  const menuButton = (
    <div
      className={`${styles.container} ${isSomeItemHighlighted ? styles.hasHighlightedItem : ''}`}
      ref={ref}
    >
      <Button
        type="default"
        icon={expandIcon || <Icon name="moreHorizontal" size={16} className={styles.expandIcon} />}
        className={`${styles.menuButton} ${className}`}
        style={{ ...style }}
        onClick={() => setIsSubMenuVisible(!isSubMenuVisible)}
        disabled={!items.length}
      />
      <div
        className={`${styles.subMenuContainer} ${
          placement === PLACEMENT.RIGHT ? styles.placementRight : ''
        }`}
        style={!isSubMenuVisible ? { display: 'none' } : undefined}
      >
        {getItems(items)}
      </div>
    </div>
  );
  return menuButton;
}
