import { useState } from 'react';
import ErrorHandler from '../../../common-components/error-handler';
import HttpError from '../../../cross-cutting/errors/http-error';
import { PROGRAMS_IDS } from '../../../enums';
import { getPlansAndDeclarationsReport } from '../../../modules/reports/service';
import Content from './content';

export default function PlansAndDeclarations() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<HttpError>();

  const handleGenerateReport = async (values: {
    programId: number;
    year: { key: number; value: number };
  }) => {
    setLoading(true);
    try {
      let programIds;

      switch (values.programId) {
        case 0: {
          programIds = Object.values(PROGRAMS_IDS);
          break;
        }
        default:
          programIds = [values.programId];
      }

      await getPlansAndDeclarationsReport({
        programIds,
        year: values.year.key,
      });
    } catch (err) {
      if (err instanceof HttpError) {
        setError(err);
      }
    }

    setLoading(false);
  };

  return (
    <ErrorHandler error={error}>
      <Content onClickGenerateReport={handleGenerateReport} loading={loading} />
    </ErrorHandler>
  );
}
