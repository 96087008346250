export default {
  PAGE_TITLE: 'Visualizar Plano',
  SUCCESS_NOTIFICATION: 'Plano enviado com sucesso',

  VALIDATE_MESSAGES: {
    ACCEPT_TERMS: {
      REQUIRED: 'Você deve aceitar os termos para continuar',
    },
  },
};
