import { COMPANY_TYPE_ID } from '../../../../user-registration/form/enums';

export default {
  BROWSER_TAB_NAME: (companyName = '') => `Detalhes da ${companyName}`,
  BREADCRUMB_LIST_PROGRAMS: 'Sistemas de Logística Reversa',

  TABS: {
    REGISTRATION_DATA: {
      TITLE: 'Dados cadastrais',
      COMPANY: {
        TITLE: 'Dados da Empresa',
        FIELDS: {
          TYPE: 'Tipo',
          CNPJ: 'CNPJ',
          MAIN_CNAE: 'CNAE Principal',
        },
      },
      ADDRESS: {
        TITLE: 'Endereço',
        FIELDS: {
          POSTAL_CODE: 'CEP',
          STREET_NAME: 'Logradouro',
          NUMBER: 'Número',
          NEIGHBORHOOD: 'Bairro',
          CITY: 'Cidade',
          STATE: 'UF',
        },
      },
      CONTACT: {
        TITLE: 'Contato',
        FIELDS: {
          PHONE: 'Telefone',
        },
      },
    },
    ASSOCIATED_SYSTEMS: {
      TITLE: 'Sistemas Associados',
    },
    REVERSE_LOGISTICS_PLANS: {
      TITLE: 'Planos de Logística Reversa',
    },
    DECLARATIONS: {
      TITLE: 'Declarações',
    },
  },

  COMPANY_TYPES: {
    [COMPANY_TYPE_ID.MANUFACTURER]: 'Individual',
    [COMPANY_TYPE_ID.MANUFACTURER_MANAGER]: 'Gestora',
  },
};
