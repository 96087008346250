import { ReactNode } from 'react';

import Button from '../../../../../common-components/button';
import Icon from '../../../../../common-components/icon';
import icons from '../../../../../common-components/icon/icons';
import Loader from '../../../../../common-components/loader';

import i18n from '../content/i18n';
import styles from './index.module.css';

type DashboardCardProps = {
  iconName: keyof typeof icons;
  title: string;
  children: ReactNode | ReactNode[];
  loading: boolean;
  headerButtonRoute?: string;
  selectedFilters?: (string | undefined)[];
};
function DashboardCard({
  iconName,
  title,
  children,
  loading,
  headerButtonRoute,
  selectedFilters,
}: DashboardCardProps) {
  const content = loading ? (
    <Loader />
  ) : (
    <>
      <div className={styles.header}>
        <div style={{ display: 'flex', gap: '5px', flexDirection: 'column' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
            <Icon size={16} name={iconName} />
            <span>{title}</span>
          </div>
          <span
            style={{
              fontWeight: '400',
              fontSize: '12px',
              marginLeft: '32px',
            }}
          >
            {selectedFilters
              ?.filter((selectedFilter) => selectedFilter)
              .map((selectedFilter, index) =>
                index !== 0 ? ` | ${selectedFilter} ` : selectedFilter,
              )}
          </span>
        </div>

        {headerButtonRoute ? (
          <Button
            text={i18n.ptBR.BUTTONS.SEE_ALL}
            title={i18n.ptBR.BUTTONS.SEE_ALL}
            href={headerButtonRoute}
            type="text"
            style={{ fontWeight: '600', color: '#1351B4' }}
          />
        ) : (
          ''
        )}
      </div>
      {children}
    </>
  );
  return <div className={styles.container}>{content}</div>;
}

export default DashboardCard;
