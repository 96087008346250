export default {
  PAGE_TITLE: 'Ocorreu um Erro!',
  ATTENTION: 'Atenção',
  BUTTONS: {
    RETURN: 'Voltar',
  },
  GENERIC_ERROR: {
    TITLE: 'Oops! Parece que um erro inesperado aconteceu!',
    DESCRIPTION:
      'Você pode tentar realizar a ação novamente, ou entrar em contato com o nosso suporte técnico.',
  },
} as const;
