import { REGION } from '../../../../../../enums';
import { COMPANY_PLAN_TYPE_ID } from '../../../../../../modules/plans/enums';
import { ProgramNamesUrl } from '../../../../../../modules/programs/enums';

export default {
  PAGE_TITLE: 'Dashboard',

  SECTION_TITLE: 'Dashboard',
  BUTTONS: { SEE_ALL: 'Ver Todas' },

  FILTERS: {
    PROGRAMS_NAME: {
      LABEL: 'Sistema de Logística Reversa',
      PLACEHOLDER: 'Selecione o sistema',
      [ProgramNamesUrl[0]]: 'Agrotóxicos, seus Resíduos e Embalagens',
      [ProgramNamesUrl[1]]: 'Baterias de Chumbo Ácido',
      [ProgramNamesUrl[2]]: 'Eletroeletrônicos e Seus Componentes',
      [ProgramNamesUrl[3]]: 'Embalagens de Aço',
      [ProgramNamesUrl[4]]: 'Embalagens Plásticas de Óleos Lubrificantes',
      [ProgramNamesUrl[5]]: 'Embalagens em Geral',
      [ProgramNamesUrl[6]]: 'Lâmpadas Fluorescentes, de Vapor de Sódio e Mercúrio e de Luz Mista',
      [ProgramNamesUrl[7]]: 'Medicamentos, seus Resíduos e Embalagens',
      [ProgramNamesUrl[8]]: 'Óleos Lubrificantes Usados ou Contaminados (OLUC)',
      [ProgramNamesUrl[9]]: 'Pilhas e Baterias',
      [ProgramNamesUrl[10]]: 'Pneus Inservíveis',
      [ProgramNamesUrl[11]]: 'Latas de Alumínio para Bebidas',
    },
    FISCAL_YEAR: {
      LABEL: 'Ano referente',
      PLACEHOLDER: 'Selecione o ano referente',
    },
    REGION: {
      LABEL: 'Região',
      PLACEHOLDER: 'Selecione a região',
      OPTIONS: {
        [REGION.NORTH]: 'Norte',
        [REGION.NORTHEAST]: 'Nordeste',
        [REGION.MIDWEST]: 'Centro-Oeste',
        [REGION.SOUTHEAST]: 'Sudeste',
        [REGION.SOUTH]: 'Sul',
      },
    },
    PLAN_TYPE: {
      LABEL: 'Tipo',
      PLACEHOLDER: 'Selecione o tipo',
      OPTIONS: {
        [COMPANY_PLAN_TYPE_ID.INDIVIDUAL]: 'Individual',
        [COMPANY_PLAN_TYPE_ID.COLLECTIVE]: 'Coletivo',
      },
    },
  },
};
