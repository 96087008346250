import { useEffect, useState } from 'react';

import Content from './content';
import ErrorHandler from '../../../common-components/error-handler';
import Page from '../../../common-components/page';

import HttpError from '../../../cross-cutting/errors/http-error';
import { getAllPrograms } from '../../../modules/programs/service';
import { PublicProgram } from '../../../modules/programs/types';
import i18n from './i18n';
import styles from './index.module.css';

export default function ListSystems() {
  const [loading, setLoading] = useState(false);
  const [programs, setPrograms] = useState<PublicProgram[]>();
  const [error, setError] = useState<HttpError | null>(null);

  useEffect(() => {
    async function fetchPrograms() {
      setLoading(true);
      try {
        const response = await getAllPrograms();

        setPrograms(response.programs);
      } catch (err) {
        if (err instanceof HttpError) {
          setError(err);
        }
      }
      setLoading(false);
    }

    fetchPrograms();
  }, []);

  return (
    <ErrorHandler error={error}>
      <Page
        childrenClassName={styles.childrenClassName}
        newDesign
        browserTabName={i18n.ptBR.TAB_NAME}
      >
        <Content programs={programs} loading={loading} />
      </Page>
    </ErrorHandler>
  );
}
