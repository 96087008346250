export default {
  PAGE_TITLE: 'Declarações',

  SYSTEM_TITLE: 'Declarações Anuais de Coleta',

  LIST_TITLE: 'Declarações Anuais de Coleta',
  COLUMNS: {
    LABELS: {
      YEAR: 'Ano',
      SENT_DATE: 'Data de envio',
      ACTIONS: 'Ações',
    },
  },
};
