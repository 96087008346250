export const FORM_FIELDS = {
  PLAN_NAME: 'planName',
  CNPJS_UPLOAD: 'cnpjsUpload',
  FISCAL_YEAR: 'fiscalYear',

  NAME: 'name',
  SURNAME: 'surname',
  CPF: 'cpf',
  EMAIL: 'email',
  PHONE: 'phone',

  ACCEPT_TERMS: 'acceptTerms',
};
