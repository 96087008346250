export default {
  PAGE_TITLE: 'Lista de Planos de Empresa / Individual',

  SYSTEM_TITLE: 'Sistema de Logística Reversa',
  SECTION_TITLE: 'Planos',

  LIST_TITLE: 'Planos de Logística Reversa',
  COLUMNS: {
    LABELS: {
      PLAN: 'Plano',
      YEAR: 'Ano',
      SENT_DATE: 'Data de envio',
      LAST_UPDATE: 'Última atualização',
      STATUS: 'Status',
      ACTIONS: 'Ações',
    },
    PLAN: {
      CURRENT_YEAR: 'Vigente',
    },
  },
};
