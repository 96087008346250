export default {
  BROWSER_TAB_NAME: 'Login | Sinir+',

  TITLE: 'Login',
  HELP_TEXT: 'Insira seu e-mail e senha para acessar o sistema de Logística Reversa',

  FORM_FIELDS: {
    EMAIL: {
      LABEL: 'E-mail',
      VALIDATION_MESSAGE: 'E-mail inválido',
    },
    PASSWORD: {
      LABEL: 'Senha',
      VALIDATION_MESSAGE: 'Senha inválida',
    },
  },

  SUBMIT_BUTTON: 'Entrar',
  RECOVERY_PASSWORD_BUTTON: 'Esqueceu a senha?',

  SIGN_UP: {
    HELP_TEXT: 'Ainda não possui acesso? Crie sua conta!',
    LINK: 'Criar conta',
  },

  ERRORS: {
    UNAUTHORIZED: {
      TITLE: 'E-mail ou senha incorretos',
      DESCRIPTION: 'Caso você tenha esquecido a senha recupere seu acesso em *esqueci a senha*',
    },
  },
} as const;
