import { COMPANY_PLAN_TYPE_ID } from '../../../../../modules/plans/enums';

export default {
  PAGE_TITLE: 'Novo plano',
  PLAN_COMPANY_DETAILS: {
    TYPE: {
      TITLE: 'Tipo',
      VALUES: {
        [COMPANY_PLAN_TYPE_ID.INDIVIDUAL]: 'Individual',
        [COMPANY_PLAN_TYPE_ID.COLLECTIVE]: 'Coletivo',
      },
    },
    YEAR: 'Ano',
  },
  RESPONSIBLE_DETAILS: {
    TITLE: 'Responsável pelo cadastramento do plano de Logística Reversa',
    CPF: 'CPF',
    EMAIL: 'E-mail',
    PHONE: 'Telefone',
    NOT_INFORMED: 'Não informado',
  },
  FORM_FIELDS: {
    CNPJS_UPLOAD: {
      LABEL: 'CNPJ das empresas associadas (Matriz e filiais)',
    },

    COMMUNICATION_PLAN_UPLOAD: {
      LABEL: 'Plano de comunicação',
      INVESTED_VALUE: 'Previsão de investimento no ano',
      NOT_INFORMED: 'Não informado',
    },
    DOWNLOAD_TEMPLATE: {
      LABEL: 'Documento para comprovação de meta',
      BUTTON: 'Baixar template',
      ALERT:
        'Faça o download do template e preencha o documento ao longo do ano. Você deverá anexar a versão final do documento no momento de enviar a declaração anual do(s) seu(s) sistema(s) de Logística Reversa.',
    },
    INFO: {
      TITLE: 'Responsável pelo cadastramento do plano de Logística Reversa',
      NAME: {
        LABEL: 'Nome',
      },
      SURNAME: {
        LABEL: 'Sobrenome',
      },
      CPF: {
        LABEL: 'CPF',
      },
      EMAIL: {
        LABEL: 'E-mail',
      },
      PHONE: {
        LABEL: 'Telefone',
      },
    },
    ACCEPT_TERMS: {
      LABEL: 'Preenchi o plano e quero enviar',
    },
  },

  TEMPLATE_NAMES: {
    ASSOCIATED_COMPANIES: 'Empresas associadas',
  },
  BUTTONS: {
    RETURN: 'Voltar',
    SUBMIT: 'Preencher declaração',
    EDIT: 'Editar plano',
    SEND: 'Enviar plano',
  },

  VALIDATE_MESSAGES: {
    ACCEPT_TERMS: {
      REQUIRED: 'Você deve aceitar os termos para continuar',
    },
  },
};
