import { Navigate, Route, Routes } from 'react-router-dom';

import WithAuthentication from '../authentication/with-authentication';
import routes from '../../routes';

// company plans routes
import NewPlan from '../../pages/plans/new-plan';
import ViewCompanyPlan from '../../pages/plans/view-company-plan';
import ListPlans from '../../pages/plans/list-plans';
import AdminListPlans from '../../pages/admin/plans/list-plans';
import EditCompanyPlan from '../../pages/plans/edit-company-plan';
import CreateDeclaration from '../../pages/plans/view-company-plan/create-declaration';
import ViewDeclaration from '../../pages/plans/view-company-plan/view-declaration';
// program routes
import ListPrograms from '../../pages/programs/list-programs';

import ViewCompany from '../../pages/companies/view-company';
import ListDeclarations from '../../pages/admin/declarations/list-declarations';
// USER MANUAL -> DIRECT DOWNLOAD/VIEW USED IN EMAILS
import DownloadUserManual from './download-user-manual';

// REPORTS
import PlansAndDeclarations from '../../pages/reports/plans-and-declarations';

// public routes
import UserLogin from '../../pages/user-login';
import UserRegistration from '../../pages/user-registration';
import ListCompanies from '../../pages/companies/list-companies';
import UserPasswordRecovery from '../../pages/user-password-recovery';

import i18n from './i18n';
import ErrorScreen from '../../common-components/error-handler/error-screen';
import ScrollToTop from '../../cross-cutting/utils/scroll-to-top';
import useFeatureFlags from '../../cross-cutting/utils/use-feature-flags';
import {
  LIST_PROGRAMS_FF,
  VIEW_COMPANY_DETAILS_FF,
  LIST_PLANS_FF,
  PROGRAMS_DASHBOARD_FF,
} from '../../feature-flags';
import Dashboard from '../../pages/admin/programs/dashboard';

export default function MainRouter() {
  const { featureEnabled } = useFeatureFlags();

  return (
    <>
      <ScrollToTop />

      <Routes>
        {/* public routes */}
        <Route path={routes.LOGIN} element={<UserLogin />} />
        <Route path={routes.SIGN_UP} element={<UserRegistration />} />
        <Route path={routes.PASSWORD_RECOVERY} element={<UserPasswordRecovery />} />
        <Route path={routes.USER_MANUAL} element={<DownloadUserManual />} />

        {/* private routes */}
        <Route element={<WithAuthentication />}>
          {featureEnabled(LIST_PLANS_FF) ? (
            <Route path={routes.HOME} element={<Navigate to={routes.DASHBOARD} replace />} />
          ) : (
            <Route path={routes.HOME} element={<Navigate to={routes.LIST_PLANS} replace />} />
          )}
          <Route path={routes.NEW_PLAN} element={<NewPlan />} />
          <Route path={routes.VIEW_COMPANY_PLAN} element={<ViewCompanyPlan />} />
          <Route path={routes.EDIT_COMPANY_PLAN} element={<EditCompanyPlan />} />
          <Route path={routes.CREATE_DECLARATION} element={<CreateDeclaration />} />
          <Route path={routes.VIEW_DECLARATION} element={<ViewDeclaration />} />
          <Route path={routes.VIEW_PROGRAM_COMPANIES} element={<ListCompanies />} />

          {featureEnabled(LIST_PLANS_FF) ? (
            <Route path={routes.ADMIN_LIST_PLANS} element={<AdminListPlans />} />
          ) : (
            <Route path={routes.LIST_PLANS} element={<ListPlans />} />
          )}
          {featureEnabled(LIST_PROGRAMS_FF) ? (
            <Route path={routes.LIST_PROGRAMS} element={<ListPrograms />} />
          ) : null}
          {featureEnabled(VIEW_COMPANY_DETAILS_FF) ? (
            <Route path={routes.VIEW_COMPANY_DETAILS} element={<ViewCompany />} />
          ) : null}
          {featureEnabled(LIST_PROGRAMS_FF) ? (
            <Route path={routes.LIST_DECLARATIONS} element={<ListDeclarations />} />
          ) : null}
          {featureEnabled(PROGRAMS_DASHBOARD_FF) ? (
            <Route path={routes.DASHBOARD} element={<Dashboard />} />
          ) : null}

          <Route path={routes.PLANS_AND_DECLARATIONS_REPORT} element={<PlansAndDeclarations />} />
        </Route>

        <Route
          path="*"
          element={<ErrorScreen errors={[{ title: i18n.ptBR.NOT_FOUND_ERROR.TITLE }]} />}
        />
      </Routes>
    </>
  );
}
