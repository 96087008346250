import { ReactNode, useState, CSSProperties } from 'react';
import colors from '../../colors';
import Divider from '../divider';
import Icon from '../icon';
import icons from '../icon/icons';

import styles from './index.module.css';

type Props = {
  children: ReactNode;
  title?: ReactNode;
  description?: ReactNode;
  style?: CSSProperties;
  className?: string;
  defaultOpen?: boolean;
  forceChildrenRender?: boolean;
  openIcon?: keyof typeof icons;
  closeIcon?: keyof typeof icons;
  childrenClassname?: string;
};
export default function Collapsible({
  title,
  description,
  children,
  style = {},
  className = '',
  defaultOpen = false,
  openIcon,
  closeIcon,
  forceChildrenRender = false,
  childrenClassname = '',
}: Props) {
  const [expanded, setExpanded] = useState(defaultOpen);

  const shouldRenderChildren = expanded || forceChildrenRender;

  return (
    <div className={`${styles.collapsible} ${className}`} style={{ ...style }}>
      <button
        className={`${styles.toggle} ${!expanded ? styles.toggleClosed : null}`}
        type="button"
        onClick={() => setExpanded(!expanded)}
      >
        <header className={styles.headerContent}>
          {title}
          {description ? <Divider type="dot" className={styles.divider} /> : null}
          {description}
          <Icon
            className={styles.icon}
            name={expanded ? closeIcon || 'minusSquare' : openIcon || 'plusSquare'}
            color={colors.blueDark}
          />
        </header>
      </button>

      {shouldRenderChildren && (
        <div
          className={`${styles.childrenContainer} ${childrenClassname}`}
          style={{
            display: !expanded && forceChildrenRender ? 'none' : undefined,
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
}
