/* eslint-disable @typescript-eslint/no-explicit-any */
import { InputNumber, InputNumberProps } from 'antd';

import styles from './index.module.css';
import numberFormatter from '../../cross-cutting/utils/number-formatter';

const removeNonDigits = (value: string) => {
  return value.replace(/\D/g, '');
};

const splitNumberByDecimalPlaces = (value: string, places: number) => {
  const integer = value.slice(0, value.length - places);
  const decimal = value.slice(-1 * places);
  return [integer, decimal];
};

const fillWithZeros = (value: string, minLength: string | number) => {
  const strValue = `${value}`;
  const valueLength = strValue.length;

  let newValue = strValue;
  for (let i = valueLength; i < minLength; i += 1) {
    newValue = `0${newValue}`;
  }

  return newValue;
};

const addUnitSymbol = (text: string, symbol: string | undefined, position = 'left') => {
  if (!symbol) {
    return text;
  }

  switch (position) {
    case 'left':
      return `${symbol} ${text}`;
    case 'right':
      return `${text} ${symbol}`;
    default:
      throw new Error("Invalid 'position' value");
  }
};

type DecimalInputProps = {
  unitSymbol?: string;
  unitSymbolPosition?: 'left' | 'right';
  decimalPlaces?: number;
  className?: string;
  min?: number;
  thousandSeparator?: ',' | '.';
};
function DecimalInput({
  unitSymbol,
  className = '',
  unitSymbolPosition = 'left',
  decimalPlaces = 2,
  min = 0,
  thousandSeparator = '.',
  ...props
}: InputNumberProps & DecimalInputProps) {
  const formatNumber = numberFormatter(thousandSeparator === '.' ? 'pt-BR' : 'en-US', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
    currency: undefined,
    minimumIntegerDigits: undefined,
    style: undefined,
  });

  const parseValue = (value: string | undefined) => {
    const valueStr = `${value}`;
    const cleanNumberStr = removeNonDigits(valueStr);
    const filledValueStr = fillWithZeros(cleanNumberStr, decimalPlaces + 1);

    const [integer, decimal] = splitNumberByDecimalPlaces(filledValueStr, decimalPlaces);
    const numberStr = `${+integer}.${decimal}`;

    return numberStr;
  };

  const formatValue = (value: any) => {
    const valueFormatted = formatNumber(value);
    const valueWithUnitSymbol = addUnitSymbol(valueFormatted, unitSymbol, unitSymbolPosition);
    return valueWithUnitSymbol;
  };

  return (
    <InputNumber
      {...props}
      min={min}
      parser={parseValue}
      formatter={formatValue}
      className={`${styles.input} ${className}`}
    />
  );
}

export default DecimalInput;
