import { FormItemProps } from 'antd';
import { FormItem } from '../form';

type FilterItemProps = {
  displayValue?: () => void;
  getDisplayValueFromProp?: string;
} & FormItemProps;
export function FilterItem({ displayValue, getDisplayValueFromProp, ...props }: FilterItemProps) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <FormItem {...props} />;
}

export { default as FilterBox } from './filter-box';
