export const FORM_FIELDS = {
  PASSWORD: 'password',
  REPEAT_PASSWORD: 'repeatPassword',
};

export const UNPROCESSABLE_ENTITY_ERROR_TYPES = {
  ACCOUNT_RECOVERY_CODE: {
    INVALID: 'account_recovery_code.invalid',
    ATTEMPT_LIMIT: 'account_recovery_code.attempt_limit',
  },
};
