import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Content from './content';
import ErrorHandler from '../../common-components/error-handler';

import HttpError from '../../cross-cutting/errors/http-error';
import convertBoldTextToJsx from '../../cross-cutting/utils/convert-bold-text-to-jsx';
import { login } from '../../modules/authentication/service';
import { HTTP_STATUS_CODES } from '../../enums';
import { FormData } from './enums';
import i18n from './content/i18n';
import routes from '../../routes';

export default function UserLogin() {
  const navigate = useNavigate();

  const [error, setError] = useState<HttpError | null>(null);
  const [loading, setLoading] = useState(false);

  const handleFinish = async (formData: FormData) => {
    setLoading(true);
    try {
      await login({
        username: formData.email,
        password: formData.password,
      });
      navigate(routes.HOME);
    } catch (err) {
      if (err instanceof HttpError) {
        setError(err);
      }
      setLoading(false);
    }
  };

  return (
    <ErrorHandler
      error={error}
      options={[
        {
          code: HTTP_STATUS_CODES.UNAUTHORIZED,
          title: i18n.ptBR.ERRORS.UNAUTHORIZED.TITLE,
          description: convertBoldTextToJsx(i18n.ptBR.ERRORS.UNAUTHORIZED.DESCRIPTION),
          severity: 'error',
          render: false,
        },
      ]}
    >
      <Content loading={loading} onFinish={handleFinish} />
    </ErrorHandler>
  );
}
