const formatBlobAsJson = async (blob: Blob) => {
  const stringifiedData = await blob.text();

  if (blob.type === 'application/json') {
    const parsedData = JSON.parse(stringifiedData) as JSON;
    return parsedData;
  }

  return stringifiedData;
};

export default formatBlobAsJson;
