export default {
  PAGE_TITLE: 'Cadastro de usuário',

  FORM_TITLE: {
    REGISTER: 'Cadastrar',
    DESCRIPTION: 'Informe os dados para realizar o cadastro da conta',
    SELECT_PROGRAM: 'Selecione os sistemas de Logística Reversa que você faz parte ou representa',
  },

  ALERT_MESSAGE: 'Você não poderá trocar seus sistemas de logística reversa',

  MODAL: {
    TITLE: 'Atenção!',
    MINIMUM_PROGRAMS: 'Você precisa selecionar pelo menos um sistema de logística reversa',
    CLOSE_BUTTON: 'Fechar',
  },

  RETURN_TO_LOGIN_BUTTON: 'Cancelar e voltar para o login',
  CONTINUE_BUTTON: 'Continuar',
  RETURN_BUTTON: 'Voltar',
};
