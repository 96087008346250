import dataAccess from '../../cross-cutting/data-access';

import { COMPANY_TYPE_ID } from '../../pages/user-registration/form/enums';
import type { UserCompanyType, UserType } from './types';

export type NewAccountProps = {
  companyTypeId: typeof COMPANY_TYPE_ID[keyof typeof COMPANY_TYPE_ID];
  legalName: string;
  mainCnae: string;
  phone: string;
  cnpj: string;
  address: {
    postalCode: string;
    streetName: string;
    number: string;
    neighborhood: string;
    stateAbbreviation: string;
    city: string;
    complement: string;
  };
  email: string;
  password: string;
  repeatPassword: string;
  acceptedTermsOfService: boolean;
  acceptedPrivacyPolicy: boolean;
  programIds: number[];
};

export const createAccount = async (values: NewAccountProps) => {
  try {
    const response = await dataAccess.post(`/authentication/sign-up`, values);
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export type GetUserResponse = {
  firstName: string;
  surname: string;
  userType: UserType;
  company?: {
    id: string;
    legalName: string;
    cnpj: string;
    mainCnae: string;
    phone: string;
    companyTypeId: UserCompanyType;
    acceptedTos: boolean;
    acceptedPrivacyPolicy: boolean;
    address: {
      id: string;
      postalCode: string;
      streetName: string;
      number: number;
      neighborhood: string;
      stateAbbreviation: string;
      city: string;
      complement: string;
      companyId: string;
    };
  };
  profile: {
    name: string;
    companyType: UserCompanyType;
    resources: {
      [resource: string]: {
        [action: string]: boolean;
      };
    };
  };
};
export const getUser = async () => {
  try {
    const response = await dataAccess.get('/user/profile');
    return response.data as GetUserResponse;
  } catch (err) {
    return Promise.reject(err);
  }
};
