import { ReactNode, useState, CSSProperties } from 'react';

import Icon from '../icon';

import styles from './index.module.css';

type Props = {
  children: ReactNode;
  title?: ReactNode;
  style?: CSSProperties;
  className?: string;
  defaultOpen?: boolean;
  forceChildrenRender?: boolean;
};
export default function CollapsibleMenu({
  title,
  children,
  style = {},
  className = '',
  defaultOpen = false,
  forceChildrenRender = false,
}: Props) {
  const [expanded, setExpanded] = useState(defaultOpen);

  const shouldRenderChildren = expanded || forceChildrenRender;

  return (
    <div className={`${styles.collapsible} ${className}`} style={{ ...style }}>
      <button className={`${styles.toggle} `} type="button" onClick={() => setExpanded(!expanded)}>
        <header className={styles.headerContent}>
          {title}
          <Icon
            className={styles.icon}
            name={expanded ? 'chevronDown' : 'chevronUp'}
            color="#1351B4"
          />
        </header>
      </button>

      {shouldRenderChildren && (
        <div
          className={styles.childrenContainer}
          style={{
            display: !expanded && forceChildrenRender ? 'none' : undefined,
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
}
