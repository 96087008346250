/* eslint-disable react/jsx-props-no-spreading */
import { Tabs as AntdTabs, TabsProps } from 'antd';

import styles from './index.module.css';

export type CustomTabsProps = {
  children: React.ReactNode | React.ReactNode[];
  destroyInactiveTabPanes?: boolean;
  className?: string;
} & TabsProps;

function Tabs({ children, destroyInactiveTabPanes, className = '', ...props }: CustomTabsProps) {
  return (
    <AntdTabs
      {...props}
      className={`${styles.tabs} ${className}`}
      destroyInactiveTabPane={destroyInactiveTabPanes}
    >
      {children}
    </AntdTabs>
  );
}

export { default as TabPane } from './tab-pane';
export { default as TabTitle } from './tab-title';

export default Tabs;
