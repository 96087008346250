import { Link } from 'react-router-dom';
import { Space } from 'antd';

import Loader from '../../../../common-components/loader';

import { PublicProgram } from '../../../../modules/programs/types';
import ProgramCard from '../program-card';
import { ProgramImages, ProgramNamesUrl } from '../../../../modules/programs/enums';
import routes from '../../../../routes';

type ContentProps = {
  loading: boolean;
  programs?: PublicProgram[];
};

export default function Content({ loading, programs }: ContentProps) {
  if (loading) return <Loader />;

  return (
    <Space size={24} direction="horizontal" wrap style={{ justifyContent: 'center' }}>
      {programs?.map((program: PublicProgram) => (
        <Link
          style={{ color: '#333333' }}
          key={program.id}
          to={routes.VIEW_PROGRAM_COMPANIES.replace(':id', ProgramNamesUrl[program.id - 1])}
        >
          <ProgramCard
            title={program.name}
            key={program.id}
            image={ProgramImages[program.id as keyof typeof ProgramImages]}
          />
        </Link>
      ))}
    </Space>
  );
}
