import { Col, Row, Form, FormInstance, notification } from 'antd';
import BigNumber from 'bignumber.js';

import Button from '../../../../common-components/button';
import { FormItem } from '../../../../common-components/form';
import Combobox from '../../../../common-components/combobox';
import Table from '../../../../common-components/table';
import Icon from '../../../../common-components/icon';
import DecimalInput from '../../../../common-components/decimal-input';

import styles from './index.module.css';
import { FORM_FIELDS } from '../enums';
import i18n from './i18n';
import { GOAL_UNITS_OF_MEASUREMENT } from '../../../../modules/plans/enums';
import formatWeight from '../../../../cross-cutting/utils/format-weight';

export type ResidueType = {
  quantity: number;
  declaredGoal: number | string;
  residue: {
    id: number;
    name: string;
  };
};

type ResidueListProps = {
  residueOptions?: { id: string | number; name: string }[];
  form?: FormInstance;
  goal?: {
    fiscalYear: number;
    goal: number;
    goalBaseYear: number;
    goalUnitOfMeasureId: typeof GOAL_UNITS_OF_MEASUREMENT[keyof typeof GOAL_UNITS_OF_MEASUREMENT];
  };
  isViewPage?: boolean;
};

const formatPercentage = (decimal: number) => {
  const percentage = new BigNumber(decimal)
    .times(100)
    .decimalPlaces(2, BigNumber.ROUND_HALF_EVEN)
    .toNumber();
  return percentage;
};

export default function ResidueList({ residueOptions, goal, form, isViewPage }: ResidueListProps) {
  const handleRemoveResidue = (id: number) => {
    const currentResidues = form?.getFieldValue(FORM_FIELDS.RESIDUE_LIST);

    const newResidues = currentResidues.filter((item: ResidueType) => item.residue.id !== id);

    form?.setFieldsValue({
      [FORM_FIELDS.RESIDUE_LIST]: [...newResidues],
    });
  };

  const handleAddResidue = () => {
    const newResidue: ResidueType = form?.getFieldValue(FORM_FIELDS.NEW_RESIDUE);
    const currentResidues = form?.getFieldValue(FORM_FIELDS.RESIDUE_LIST) ?? [];

    // convert to ton
    const declaredValueAsTon = new BigNumber(newResidue.declaredGoal).times(1000).toNumber();
    const quantityAsTon = new BigNumber(newResidue.quantity).times(1000).toNumber();

    // percentage  validations
    const isPercentageLower = new BigNumber(declaredValueAsTon).isLessThan(
      new BigNumber(quantityAsTon).times(new BigNumber(goal?.goal || 1)),
    );

    if (goal?.goalUnitOfMeasureId === GOAL_UNITS_OF_MEASUREMENT.PERCENTAGE && isPercentageLower) {
      return;
    }

    // ton validations
    const isTonLower = new BigNumber(declaredValueAsTon).isLessThan(new BigNumber(goal?.goal || 1));

    if (goal?.goalUnitOfMeasureId === GOAL_UNITS_OF_MEASUREMENT.TON_PER_YEAR && isTonLower) {
      return;
    }

    // shows notification if a field value is missing
    if (
      (goal?.goalUnitOfMeasureId === GOAL_UNITS_OF_MEASUREMENT.PERCENTAGE && !quantityAsTon) ||
      !newResidue?.residue?.name ||
      !declaredValueAsTon
    ) {
      notification.error({
        message: i18n.ptBR.NOTIFICATIONS.NEED_ALL_RESIDUE_FIELDS.MESSAGE,
        description: i18n.ptBR.NOTIFICATIONS.NEED_ALL_RESIDUE_FIELDS.DESCRIPTION,
      });

      return;
    }

    // shows notification if residue is already listed
    if (
      currentResidues.length > 0 &&
      currentResidues.find((item: ResidueType) => item.residue.id === newResidue.residue.id)
    ) {
      notification.error({
        message: i18n.ptBR.NOTIFICATIONS.RESIDUE_ALREADY_EXISTS.MESSAGE,
        description: i18n.ptBR.NOTIFICATIONS.RESIDUE_ALREADY_EXISTS.DESCRIPTION,
      });

      return;
    }

    form?.setFieldsValue({
      [FORM_FIELDS.RESIDUE_LIST]: [
        ...currentResidues,
        {
          ...newResidue,
          declaredGoal: declaredValueAsTon,
          quantity: quantityAsTon,
        },
      ],
    });

    form?.resetFields([
      [FORM_FIELDS.NEW_RESIDUE, FORM_FIELDS.RESIDUE_NAME],
      [FORM_FIELDS.NEW_RESIDUE, FORM_FIELDS.QUANTITY],
      [FORM_FIELDS.NEW_RESIDUE, FORM_FIELDS.DECLARED_GOAL],
    ]);
  };

  const totalInput = Form.useWatch([FORM_FIELDS.NEW_RESIDUE, FORM_FIELDS.QUANTITY], form) ?? 0;

  const isTon = goal?.goalUnitOfMeasureId === GOAL_UNITS_OF_MEASUREMENT.TON_PER_YEAR;
  const isPercentage = goal?.goalUnitOfMeasureId === GOAL_UNITS_OF_MEASUREMENT.PERCENTAGE;

  return (
    <>
      {!isViewPage && (
        <div className={styles.box}>
          <Row gutter={[16, 0]} align="bottom">
            <Col span={24}>
              <FormItem
                name={[FORM_FIELDS.NEW_RESIDUE, FORM_FIELDS.RESIDUE_NAME]}
                label={i18n.ptBR.FORM_FIELDS.RESIDUE_OBJECT.LABEL}
                required
              >
                <Combobox
                  optionKey="id"
                  data={residueOptions ?? []}
                  placeholder={i18n.ptBR.FORM_FIELDS.RESIDUE_OBJECT.PLACEHOLDER}
                  className={styles.pickList}
                  format="name"
                />
              </FormItem>
            </Col>
            {isPercentage && (
              <Col span={goal ? 6 : 12}>
                <FormItem
                  name={[FORM_FIELDS.NEW_RESIDUE, FORM_FIELDS.QUANTITY]}
                  required
                  initialValue={0}
                  label={i18n.ptBR.FORM_FIELDS.TON_QUANTITY.LABEL}
                  rules={[
                    {
                      validator: (_, value) => {
                        // if value in ton is lower then required goal, throws error
                        if (
                          isTon &&
                          value < goal.goal &&
                          form?.getFieldValue(FORM_FIELDS.RESIDUE_LIST)?.length === 0
                        ) {
                          return Promise.reject(
                            new Error(i18n.ptBR.LIST_FIELDS.REQUIRED_GOAL.ERRORS.MIN_REQUIRED),
                          );
                        }

                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <DecimalInput decimalPlaces={3} />
                </FormItem>
              </Col>
            )}
            {goal && (
              <Col span={isTon ? 8 : 6}>
                <FormItem label={i18n.ptBR.FORM_FIELDS.REQUIRED_GOAL.LABEL} required shouldUpdate>
                  {() => {
                    const value = isPercentage ? formatPercentage(goal.goal ?? 0) : goal.goal;

                    return (
                      <DecimalInput
                        disabled
                        value={value}
                        unitSymbol={
                          isTon
                            ? i18n.ptBR.GOAL_UNIT_OF_MEASURE[GOAL_UNITS_OF_MEASUREMENT.TON_PER_YEAR]
                            : '%'
                        }
                        unitSymbolPosition="right"
                        decimalPlaces={isPercentage ? 0 : 3}
                      />
                    );
                  }}
                </FormItem>
              </Col>
            )}
            <Col span={(!goal && 12) || isTon ? 8 : 6}>
              <FormItem
                name={[FORM_FIELDS.NEW_RESIDUE, FORM_FIELDS.DECLARED_GOAL]}
                label={i18n.ptBR.FORM_FIELDS.DECLARED_GOAL.LABEL}
                required
                initialValue={0}
                shouldUpdate
                rules={[
                  {
                    validator: (_, value) => {
                      // inputValue is the declared goal (how much will be handled by the company)
                      const inputValueAsTon = new BigNumber(value).times(1000).toNumber();
                      // total input is the full quantity placed on the market in the year
                      const totalInputAsTon = new BigNumber(totalInput).times(1000).toNumber();

                      // if value in percentage is lower then goal, throws error
                      const isPercentageLower = new BigNumber(inputValueAsTon).isLessThan(
                        new BigNumber(totalInputAsTon).times(new BigNumber(goal?.goal || 1)),
                      );
                      if (isPercentage && isPercentageLower) {
                        return Promise.reject(
                          new Error(i18n.ptBR.LIST_FIELDS.REQUIRED_GOAL.ERRORS.MIN_REQUIRED),
                        );
                      }

                      // if value in ton is lower then required goal, throws error
                      const isTonLower = new BigNumber(inputValueAsTon).isLessThan(
                        new BigNumber(goal?.goal || 1).times(1000),
                      );

                      if (isTon && isTonLower && inputValueAsTon !== 0) {
                        return Promise.reject(
                          new Error(i18n.ptBR.LIST_FIELDS.REQUIRED_GOAL.ERRORS.MIN_REQUIRED),
                        );
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <DecimalInput decimalPlaces={3} decimalSeparator="," stringMode />
              </FormItem>
            </Col>
            {goal && (
              <Col span={isTon ? 8 : 6}>
                <FormItem label={i18n.ptBR.FORM_FIELDS.FINAL_GOAL.LABEL} required shouldUpdate>
                  {({ getFieldValue }) => {
                    let declaredGoal =
                      getFieldValue([FORM_FIELDS.NEW_RESIDUE, FORM_FIELDS.DECLARED_GOAL]) ?? 0;

                    const totalPercent = new BigNumber(totalInput ?? 0).div(100).toNumber();
                    const finalPercent = new BigNumber(declaredGoal ?? 0)
                      .div(totalPercent > 0 ? totalPercent : 1)
                      .div(100)
                      .toNumber();

                    if (isTon) {
                      declaredGoal = new BigNumber(declaredGoal ?? 0)
                        .decimalPlaces(3, BigNumber.ROUND_HALF_EVEN)
                        .toFixed(3);
                    }

                    return (
                      <DecimalInput
                        unitSymbol={
                          isTon
                            ? i18n.ptBR.GOAL_UNIT_OF_MEASURE[GOAL_UNITS_OF_MEASUREMENT.TON_PER_YEAR]
                            : '%'
                        }
                        unitSymbolPosition="right"
                        decimalPlaces={isPercentage ? 0 : 3}
                        disabled
                        readOnly
                        value={isPercentage ? formatPercentage(finalPercent || 0) : declaredGoal}
                      />
                    );
                  }}
                </FormItem>
              </Col>
            )}
          </Row>

          <Button text={i18n.ptBR.BUTTONS.ADD} onClick={handleAddResidue} block />
        </div>
      )}

      <Form.Item hidden name={FORM_FIELDS.RESIDUE_LIST} />
      <Form.Item shouldUpdate>
        {({ getFieldValue }) => {
          const residues = getFieldValue(FORM_FIELDS.RESIDUE_LIST);

          return (
            <Table
              rowKey="residue"
              dataSource={residues}
              className={styles.residuesRow}
              columns={[
                {
                  title: i18n.ptBR.LIST_FIELDS.RESIDUE_OBJECT.LABEL,
                  render: (_, data: ResidueType) => data.residue.name,
                  width: '40%',
                },
                isPercentage
                  ? {
                      title: i18n.ptBR.LIST_FIELDS.KG_QUANTITY.LABEL,
                      render: (_, data: ResidueType) => {
                        const formattedTotalQuantity = new BigNumber(
                          new BigNumber(data.quantity ?? 0).div(1000),
                        )
                          .decimalPlaces(3)
                          .toNumber();

                        return formatWeight(formattedTotalQuantity, 'ton', '.');
                      },
                    }
                  : {},
                {
                  title: i18n.ptBR.FORM_FIELDS.REQUIRED_GOAL.LABEL,
                  render: () => {
                    if (!goal) return 'N/A';

                    const value = isPercentage
                      ? `${formatPercentage(+goal.goal || 0)}%`
                      : formatWeight(
                          goal.goal,
                          isTon
                            ? i18n.ptBR.GOAL_UNIT_OF_MEASURE[GOAL_UNITS_OF_MEASUREMENT.TON_PER_YEAR]
                            : 'ton',
                          isTon ? '.' : undefined,
                        );

                    return value;
                  },
                },
                {
                  title: i18n.ptBR.LIST_FIELDS.KG_QUANTITY_COLLECTED.LABEL,
                  render: (_, data: ResidueType) => {
                    let finalGoal: number | string = new BigNumber(data.declaredGoal ?? 0)
                      .div(1000)
                      .toNumber();

                    if (!goal) return formatWeight(finalGoal, 'ton', '.');

                    const totalPercent = new BigNumber(data.quantity ?? 0).div(100).toNumber();
                    const finalPercent = new BigNumber(data.declaredGoal ?? 0)
                      .div(totalPercent)
                      .div(100)
                      .toNumber()
                      .toFixed(2);

                    if (isTon) {
                      finalGoal = new BigNumber(finalGoal ?? 0)
                        .decimalPlaces(3, BigNumber.ROUND_HALF_EVEN)
                        .toFixed(3);
                    }

                    return isPercentage
                      ? `${formatPercentage(+finalPercent || 0)}%`
                      : formatWeight(
                          +finalGoal,
                          isTon
                            ? i18n.ptBR.GOAL_UNIT_OF_MEASURE[GOAL_UNITS_OF_MEASUREMENT.TON_PER_YEAR]
                            : 'ton',
                          '.',
                        );
                  },
                },
                {
                  title: i18n.ptBR.LIST_FIELDS.FINAL_GOAL.LABEL,
                  render: (_, data) => {
                    let finalGoal: number | string = new BigNumber(+data.declaredGoal ?? 0)
                      .div(1000)
                      .decimalPlaces(3)
                      .toNumber();

                    if (!goal)
                      return formatWeight(
                        finalGoal,
                        isTon
                          ? i18n.ptBR.GOAL_UNIT_OF_MEASURE[GOAL_UNITS_OF_MEASUREMENT.TON_PER_YEAR]
                          : 'ton',
                        '.',
                      );

                    const totalPercent = new BigNumber(data.quantity ?? 0).div(100).toNumber();
                    const final = new BigNumber(data.declaredGoal ?? 0)
                      .div(totalPercent)
                      .div(100)
                      .toNumber()
                      .toFixed(2);

                    if (isTon) {
                      finalGoal = new BigNumber(finalGoal ?? 0)
                        .decimalPlaces(3, BigNumber.ROUND_HALF_EVEN)
                        .toNumber();
                    }

                    return isPercentage
                      ? `${formatPercentage(+final || 0)}%`
                      : formatWeight(
                          finalGoal,
                          isTon
                            ? i18n.ptBR.GOAL_UNIT_OF_MEASURE[GOAL_UNITS_OF_MEASUREMENT.TON_PER_YEAR]
                            : 'ton',
                          '.',
                        );
                  },
                },

                !isViewPage
                  ? {
                      title: i18n.ptBR.LIST_FIELDS.ACTIONS.LABEL,
                      render: (_, data: ResidueType) => (
                        <Button
                          icon={<Icon name="trash" color="var(--blue-dark)" />}
                          onClick={() => handleRemoveResidue(data.residue.id)}
                          type="text"
                          className={styles.trashButton}
                          title={i18n.ptBR.BUTTONS.REMOVE}
                        />
                      ),
                    }
                  : {},
              ]}
            />
          );
        }}
      </Form.Item>
    </>
  );
}
