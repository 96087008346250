import { CSSProperties } from 'react';
import styles from './index.module.css';
import icons from './icons';

export type IconProps = {
  name: keyof typeof icons;
  className?: string;
  style?: CSSProperties;
  size?: number | string;
  color?: string;
};
function Icon({ name: propName, className = '', style = {}, size, color }: IconProps) {
  return (
    <i
      style={{ ...style, width: size, height: size, fill: color }}
      className={`${styles.icon} ${className}`}
    >
      {icons[propName]}
    </i>
  );
}

export default Icon;
