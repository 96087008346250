import { OriginFileObj } from '../../common-components/dragger-upload';
import dataAccess from '../../cross-cutting/data-access';
import downloadFile from '../../cross-cutting/utils/download-file';

type UploadAttachmentsResponse = {
  id: string;
  fieldname: string;
  extension: string;
  originalName: string;
  mimetype: string;
  fieldId: string;
}[];
export const uploadAttachments = async (files: OriginFileObj | OriginFileObj[]) => {
  const filesArray = Array.isArray(files) ? files : [files];

  const formData = new FormData();
  const fileMetadata: { id: string }[] = [];

  filesArray.forEach((file) => {
    formData.append(`files[${file.uid}]`, file, file.name);
    fileMetadata.push({ id: file.uid });
  });
  formData.append('metadata', JSON.stringify(fileMetadata));

  const requestOptions = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  try {
    const response = await dataAccess.post('/attachments', formData, requestOptions);
    return response.data as UploadAttachmentsResponse;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getOneAttachment = async (fileId: string, options?: { download: boolean }) => {
  try {
    const response = await dataAccess.get(`/attachments/${fileId}`, {
      responseType: 'blob',
    });

    const file = response.data;
    const type = response.headers['content-type'] ?? '';
    const originalName =
      response.headers['content-disposition']?.split('filename=')[1] ?? 'missing-file-name';

    if (options?.download) {
      downloadFile({ file, type, originalName });
    }

    return {
      id: fileId,
      originalName,
      type,
      file,
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const removeAttachment = async (fileId: string) => {
  try {
    await dataAccess.delete(`/attachments/${fileId}`);

    return true;
  } catch (error) {
    return Promise.reject(error);
  }
};
