import { ColumnsType } from 'antd/lib/table';
import BigNumber from 'bignumber.js';
import ProgressBar from '../../../../../../common-components/progress-bar';

import formatWeight from '../../../../../../cross-cutting/utils/format-weight';
import { GOAL_UNITS_OF_MEASUREMENT } from '../../../../../../modules/plans/enums';

import styles from './index.module.css';
import i18n from './i18n';
import colors from '../../../../../../colors';
import numberFormatter from '../../../../../../cross-cutting/utils/number-formatter';

type ProgramWasteType = {
  id: number;
  name: string;
  overdueAmount: null | string;
  totalWeightSold: string;
  weightGoal: string;
};
const formatPercentage = (decimal: number) => {
  const percentage = new BigNumber(decimal)
    .times(100)
    .decimalPlaces(2, BigNumber.ROUND_HALF_EVEN)
    .toNumber();

  const formattedPercentage = numberFormatter('pt-BR')(percentage);
  return `${formattedPercentage}${
    i18n.ptBR.GOAL_UNITS_OF_MEASUREMENT[GOAL_UNITS_OF_MEASUREMENT.PERCENTAGE]
  }`;
};

export default ({
  unitOfMeasure,
  goal,
  amountCollected,
}: {
  unitOfMeasure?: typeof GOAL_UNITS_OF_MEASUREMENT[keyof typeof GOAL_UNITS_OF_MEASUREMENT];
  goal?: string;
  amountCollected?: number;
}): ColumnsType<ProgramWasteType> => {
  return [
    goal && unitOfMeasure
      ? {
          title: i18n.ptBR.FORM_FIELDS.REQUIRED_GOAL.LABEL,
          width: '20%',
          key: 'requiredGoal',

          render: (data) => {
            return (
              <span data-testid="requiredGoal">
                {formatWeight(new BigNumber(data.weightGoal ?? 0).div(1000).toNumber(), 'ton', '.')}
              </span>
            );
          },
        }
      : {},
    amountCollected
      ? {
          title: i18n.ptBR.FORM_FIELDS.COLLECTED_IN_TON.LABEL,
          key: 'collectedInTon',
          render: () => {
            return (
              <span data-testid="collectedInTon">{formatWeight(amountCollected, 'ton', '.')}</span>
            );
          },
        }
      : {},
    goal && amountCollected
      ? {
          width: '350px',
          title: i18n.ptBR.FORM_FIELDS.GOAL_PROGRESS.LABEL,
          key: 'goalProgress',
          render: (data) => {
            const goalProgressDecimal = new BigNumber(new BigNumber(amountCollected).times(1000))
              .div(data.weightGoal)
              .toNumber();
            return (
              <div data-testid="goalProgress">
                <span className={styles.wasteTypeFieldValue} style={{ marginBottom: '8px' }}>
                  {i18n.ptBR.FORM_FIELDS.GOAL_PROGRESS.MARKUP(
                    formatPercentage(goalProgressDecimal),
                  )}
                </span>
                <ProgressBar
                  progress={new BigNumber(goalProgressDecimal).times(100).toNumber()}
                  color={
                    goalProgressDecimal < 1 ? colors.feedbackDanger : colors.feedbackSuccessDark
                  }
                />
              </div>
            );
          },
        }
      : {},
  ];
};
