import React from 'react';
import isObject from '../is-object';

type UnknownObject = Record<string, unknown>;

const removeUndefinedValues = (object: UnknownObject) => {
  const newObject: UnknownObject = {};

  Object.entries(object).forEach(([key, value]) => {
    if (isObject(value) && !React.isValidElement(value as UnknownObject)) {
      newObject[key] = removeUndefinedValues(value as UnknownObject);
    } else if (typeof value !== 'undefined') {
      newObject[key] = value;
    }
  });

  return newObject;
};

export default removeUndefinedValues;
