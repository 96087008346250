import { Col, Row } from 'antd';

import BreadcrumbItem from '../../../../../common-components/breadcrumbs/breadcrumb-item';
import { FilterItem } from '../../../../../common-components/filter';
import PageWithAddons from '../../../../../common-components/page-with-addons';
import PickList from '../../../../../common-components/pick-list';

// indicators
import RegisteredCompanies from '../indicators/registered-companies';
import CompaniesThatReachedTheGoal from '../indicators/companies-that-reached-the-goal';
import CollectedVolume from '../indicators/collected-volume';

import { DashboardFilters } from '../../../../../modules/indicators/types';
import validYearsOptions from '../../../../../cross-cutting/utils/get-years-from-project-start';
import { REGION } from '../../../../../enums';
import { COMPANY_PLAN_TYPE_ID } from '../../../../../modules/plans/enums';
import { ProgramNamesUrl } from '../../../../../modules/programs/enums';
import routes from '../../../../../routes';
import { FIELDS } from '../enums';

import i18n from './i18n';
import styles from './index.module.css';

export default function Content({
  filters,
  handleFilter,
}: {
  filters: DashboardFilters;
  handleFilter: (formValues: DashboardFilters) => void;
}) {
  const regionFilterOptions = [
    {
      key: REGION.NORTHEAST,
      value: i18n.ptBR.FILTERS.REGION.OPTIONS[REGION.NORTHEAST],
    },
    {
      key: REGION.NORTH,
      value: i18n.ptBR.FILTERS.REGION.OPTIONS[REGION.NORTH],
    },
    {
      key: REGION.MIDWEST,
      value: i18n.ptBR.FILTERS.REGION.OPTIONS[REGION.MIDWEST],
    },
    {
      key: REGION.SOUTHEAST,
      value: i18n.ptBR.FILTERS.REGION.OPTIONS[REGION.SOUTHEAST],
    },
    {
      key: REGION.SOUTH,
      value: i18n.ptBR.FILTERS.REGION.OPTIONS[REGION.SOUTH],
    },
  ];
  const planTypeFilterOptions = [
    {
      key: COMPANY_PLAN_TYPE_ID.INDIVIDUAL,
      value: i18n.ptBR.FILTERS.PLAN_TYPE.OPTIONS[COMPANY_PLAN_TYPE_ID.INDIVIDUAL],
    },
    {
      key: COMPANY_PLAN_TYPE_ID.COLLECTIVE,
      value: i18n.ptBR.FILTERS.PLAN_TYPE.OPTIONS[COMPANY_PLAN_TYPE_ID.COLLECTIVE],
    },
  ];

  const validYearsFilterOptions = validYearsOptions().map((value) => {
    return {
      key: value,
      value,
    };
  });

  const formattedSystemTypesOptions = ProgramNamesUrl.map((id, index) => ({
    key: index + 1,
    value: i18n.ptBR.FILTERS.PROGRAMS_NAME[id],
  }));

  const renderDashboardFilters = () => [
    <FilterItem
      key={FIELDS.PROGRAM}
      label={i18n.ptBR.FILTERS.PROGRAMS_NAME.LABEL}
      name={FIELDS.PROGRAM}
    >
      <PickList
        allowClear
        placeholder={i18n.ptBR.FILTERS.PROGRAMS_NAME.PLACEHOLDER}
        options={formattedSystemTypesOptions}
      />
    </FilterItem>,
    <FilterItem
      key={FIELDS.FISCAL_YEAR}
      label={i18n.ptBR.FILTERS.FISCAL_YEAR.LABEL}
      name={FIELDS.FISCAL_YEAR}
    >
      <PickList
        allowClear
        placeholder={i18n.ptBR.FILTERS.FISCAL_YEAR.PLACEHOLDER}
        options={validYearsFilterOptions}
      />
    </FilterItem>,
    <FilterItem
      key={FIELDS.COMPANY_PLAN_TYPE}
      label={i18n.ptBR.FILTERS.PLAN_TYPE.LABEL}
      name={FIELDS.COMPANY_PLAN_TYPE}
    >
      <PickList
        allowClear
        placeholder={i18n.ptBR.FILTERS.PLAN_TYPE.PLACEHOLDER}
        options={planTypeFilterOptions}
      />
    </FilterItem>,
    <FilterItem key={FIELDS.REGION} label={i18n.ptBR.FILTERS.REGION.LABEL} name={FIELDS.REGION}>
      <PickList
        allowClear
        placeholder={i18n.ptBR.FILTERS.REGION.PLACEHOLDER}
        options={regionFilterOptions}
      />
    </FilterItem>,
  ];

  return (
    <PageWithAddons
      filters={renderDashboardFilters()}
      onFilter={handleFilter}
      pageProps={{
        browserTabName: i18n.ptBR.PAGE_TITLE,
        breadcrumbs: [
          <BreadcrumbItem key="dashboard" route={routes.DASHBOARD} title={i18n.ptBR.PAGE_TITLE} />,
        ],
        childrenClassName: styles.childrenClassName,
        title: i18n.ptBR.SECTION_TITLE,
      }}
    >
      <Row gutter={[24, 24]}>
        <Col span={9}>
          <RegisteredCompanies filters={filters} />
        </Col>
        <Col span={12}>
          <CompaniesThatReachedTheGoal filters={filters} />
        </Col>
        <Col span={12}>
          <CollectedVolume filters={filters} />
        </Col>
      </Row>
    </PageWithAddons>
  );
}
