import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { notification, Upload as AntdUpload } from 'antd';

import i18n from './i18n';
import Loader from '../../loader';

function Content({
  error,
  beforeUpload,
  onChange,
  onRemove,
  onDownload,
  fileList,
  maxCount,
  multiple,
  children,
  loading,
  disableUploadButton,
  ...props
}) {
  useEffect(() => {
    if (error) {
      notification.error({
        message: i18n.ptBR.ERRORS[error.type].TITLE,
        description: i18n.ptBR.ERRORS[error.type].DESCRIPTION(error.details),
        duration: 10,
      });
    }
  }, [error]);

  return (
    <AntdUpload.Dragger
      beforeUpload={beforeUpload}
      onChange={onChange}
      onRemove={async (removedFile, ...restParams) => {
        await onRemove(removedFile, ...restParams);
      }}
      onDownload={onDownload}
      fileList={fileList}
      multiple={multiple}
      disabled={loading}
      style={disableUploadButton ? { display: 'none' } : ''}
      maxCount={maxCount}
      {...props}
      // eslint-disable-next-line react/no-unstable-nested-components
    >
      {loading ? <Loader /> : children}
    </AntdUpload.Dragger>
  );
}

export default Content;

Content.propTypes = {
  text: PropTypes.string,
  maxCount: PropTypes.number,
  error: PropTypes.instanceOf(Error),
  beforeUpload: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  fileList: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      lastModified: PropTypes.number.isRequired,
      lastModifiedDate: PropTypes.instanceOf(Date).isRequired,
      originFileObj: PropTypes.shape({
        uid: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        lastModified: PropTypes.number.isRequired,
        size: PropTypes.number.isRequired,
      }).isRequired,
      percent: PropTypes.number.isRequired,
      size: PropTypes.number.isRequired,
    }),
  ),
  multiple: PropTypes.bool,
  children: PropTypes.node,
  disableUploadButton: PropTypes.bool,
};

Content.defaultProps = {
  error: null,
  text: null,
  fileList: undefined,
  maxCount: undefined,
  children: null,
  multiple: false,
  disableUploadButton: false,
  loading: false,
};
