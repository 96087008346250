import { Space } from 'antd';

import i18n from '../../../content/i18n';

import ProgramCard from '../../../../../programs/list-programs/program-card';
import Loader from '../../../../../../common-components/loader';

import { GetCompanyProgramsResponse } from '../../../../../../modules/company/service';
import { ProgramImages } from '../../../../../../modules/programs/enums';

export default function Content({
  programs,
  loading,
}: {
  programs?: GetCompanyProgramsResponse;
  loading: boolean;
}) {
  const renderCompanyPrograms = programs?.map((program) => (
    <ProgramCard
      title={program?.name?.toLocaleUpperCase()}
      image={ProgramImages[program?.id as keyof typeof ProgramImages]}
      key={program?.id}
    />
  ));

  return (
    <div style={{ fontFamily: 'Rawline' }}>
      <div style={{ fontSize: '34px', padding: '30px 10px' }}>
        <span>{i18n.ptBR.TABS.ASSOCIATED_SYSTEMS.TITLE}</span>
      </div>

      {loading || !programs ? (
        <Loader />
      ) : (
        <Space size={24} style={{ margin: '40px 0px 80px 0px' }} wrap>
          {renderCompanyPrograms}
        </Space>
      )}
    </div>
  );
}
