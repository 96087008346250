export const FORM_FIELDS = {
  PLANS_PREFIX: 'plans',

  WASTE_TYPES_PREFIX: 'wasteTypes',
  RECYCLED_AMOUNT: 'recycledAmount',
};

export const EVIDENCE_UPLOAD_VALID_FORMATS = ['xls', 'xlsx'];

export const WEIGHT_BASED_GOALS_PRECISION = 3;

export const API_ERRORS = {};
