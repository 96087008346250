import OpenButton from '../open-button';
import CloseButton from '../close-button';

import type {
  FeatureFlagNames,
  FeatureFlagsObject,
} from '../../cross-cutting/contexts/feature-flags';
import styles from './index.module.css';
import useOnClickOutside from '../../common-components/use-onclick-outside';

type Props = {
  panelVisible: boolean;
  openPanel: () => void;
  closePanel: () => void;
  featureFlags: FeatureFlagsObject;
  toggleFeature: (featureFlagKey: FeatureFlagNames) => void;
  activeFeatureFlagCount: number;
};

export default function Content({
  panelVisible,
  openPanel,
  closePanel,
  featureFlags,
  toggleFeature,
  activeFeatureFlagCount,
}: Props) {
  const { ref } = useOnClickOutside(closePanel);

  const activeFeatureFlagCounterLabel = `${activeFeatureFlagCount} active feature flag(s)`;

  const featureFlagInputs = Object.entries(featureFlags).map((featureFlagMap) => {
    const [key, value] = featureFlagMap as [FeatureFlagNames, boolean];

    return (
      <label key={key} htmlFor={key} className={styles.label}>
        <input
          id={key}
          type="checkbox"
          checked={value}
          onChange={() => toggleFeature(key)}
          className={styles.checkbox}
          data-testid={key}
        />
        {key}
      </label>
    );
  });

  return (
    <div className={styles.container} ref={ref}>
      <div className={styles.openButtonContainer}>
        <OpenButton onClick={openPanel} />

        {activeFeatureFlagCount > 0 ? (
          <button
            aria-label={activeFeatureFlagCounterLabel}
            title={activeFeatureFlagCounterLabel}
            className={styles.activeFeatureFlagCounter}
            type="button"
            onClick={openPanel}
            data-testid="activeFeatureFlagCounter"
          >
            {activeFeatureFlagCount}
          </button>
        ) : null}
      </div>

      <div className={`${styles.panelContainer} ${panelVisible ? styles.panelVisible : ''}`}>
        <div className={styles.panelWrapper}>
          <div className={styles.panel}>
            <section className={styles.section}>
              <h3 className={styles.sectionTitle}>Feature Flags</h3>

              <form>{featureFlagInputs}</form>
            </section>
          </div>

          <footer className={styles.footer}>
            <CloseButton onClick={closePanel} />
          </footer>
        </div>
      </div>
    </div>
  );
}
