import { REGION } from '../../../../../../../../enums';
import { COMPANY_PLAN_TYPE_ID } from '../../../../../../../../modules/plans/enums';
import { ProgramNamesUrl } from '../../../../../../../../modules/programs/enums';

export default {
  INDICATOR: {
    TITLE: 'Volume coletado (ton)',
  },

  PROGRAM: {
    [ProgramNamesUrl[0]]: 'Agrotóxicos, seus Resíduos e Embalagens',
    [ProgramNamesUrl[1]]: 'Baterias de Chumbo Ácido',
    [ProgramNamesUrl[2]]: 'Eletroeletrônicos e Seus Componentes',
    [ProgramNamesUrl[3]]: 'Embalagens de Aço',
    [ProgramNamesUrl[4]]: 'Embalagens Plásticas de Óleos Lubrificantes',
    [ProgramNamesUrl[5]]: 'Embalagens em Geral',
    [ProgramNamesUrl[6]]: 'Lâmpadas Fluorescentes, de Vapor de Sódio e Mercúrio e de Luz Mista',
    [ProgramNamesUrl[7]]: 'Medicamentos, seus Resíduos e Embalagens',
    [ProgramNamesUrl[8]]: 'Óleos Lubrificantes Usados ou Contaminados (OLUC)',
    [ProgramNamesUrl[9]]: 'Pilhas e Baterias',
    [ProgramNamesUrl[10]]: 'Pneus Inservíveis',
    [ProgramNamesUrl[11]]: 'Latas de Alumínio para Bebidas',
  },
  REGION: {
    [REGION.NORTH]: 'Norte',
    [REGION.NORTHEAST]: 'Nordeste',
    [REGION.MIDWEST]: 'Centro-Oeste',
    [REGION.SOUTHEAST]: 'Sudeste',
    [REGION.SOUTH]: 'Sul',
  },
  COMPANY_TYPE: {
    [COMPANY_PLAN_TYPE_ID.INDIVIDUAL]: 'Individual',
    [COMPANY_PLAN_TYPE_ID.COLLECTIVE]: 'Coletivo',
  },
};
