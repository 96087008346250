import { useState } from 'react';
import Table from '../../../../../../common-components/table';
import columns from './columns';

import {
  CompanyPlan,
  CompanyPlanDetails,
  PlanGetOneCompanyPlan,
} from '../../../../../../modules/plans/types';
import i18n from './i18n';
import ViewCompanyPlanDetails from '../view-company-plan-details';

type Props = {
  companyPlans: CompanyPlan[];
  loading: boolean;
  currentPage: number;
  totalCount: number;
  pageSize: number;
  onPageChange: (newPage: number) => void;
  onSelectPlan: (id: string) => void;
  selectedCompanyPlan?: CompanyPlanDetails;
  companyPlanLoading: boolean;
  onCancel: () => void;
};

export default function Content({
  companyPlans,
  loading,
  currentPage,
  totalCount,
  pageSize,
  onPageChange,
  onSelectPlan,
  selectedCompanyPlan,
  companyPlanLoading,
  onCancel,
}: Props) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState<PlanGetOneCompanyPlan>();

  const handleClickProgramCard = (programId: string) => {
    if (selectedCompanyPlan) {
      setSelectedProgram(selectedCompanyPlan.plans.find((plan) => plan.id === programId));

      setIsModalVisible(true);
    }
  };

  const handleCancelModal = () => {
    setSelectedProgram(undefined);
    setIsModalVisible(false);
  };

  if (selectedCompanyPlan || companyPlanLoading) {
    return (
      <ViewCompanyPlanDetails
        onCancel={onCancel}
        companyPlan={selectedCompanyPlan}
        loading={companyPlanLoading}
        onClickProgramCard={handleClickProgramCard}
        isModalVisible={isModalVisible}
        selectedProgram={selectedProgram}
        onCancelModal={handleCancelModal}
      />
    );
  }

  return (
    <div style={{ marginTop: '40px' }}>
      <Table
        listTitle={i18n.ptBR.LIST_TITLE}
        rowKey="id"
        dataSource={companyPlans}
        currentPage={currentPage}
        totalItems={totalCount}
        onPageChange={onPageChange}
        newDesign
        loading={loading}
        columns={columns(onSelectPlan)}
        pageSize={pageSize}
      />
    </div>
  );
}
