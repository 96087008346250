import { CSSProperties } from 'react';

import styles from './index.module.css';

type Props = {
  style?: CSSProperties;
  className?: string;
  progress: number;
  color: string;
};
export default function ProgressBar({ style = {}, className = '', progress, color }: Props) {
  let sanitizedProgress = progress;

  if (progress < 0) {
    sanitizedProgress = 0;
  }
  if (progress > 100) {
    sanitizedProgress = 100;
  }

  return (
    <div
      className={`${styles.container} ${className}`}
      style={{
        ...style,
      }}
    >
      <div
        className={styles.progressBar}
        style={{
          width: `${sanitizedProgress}%`,
          backgroundColor: color,
        }}
      />
    </div>
  );
}
