import { useEffect, useState } from 'react';

import Content from './content';
import ErrorHandler from '../../../../../common-components/error-handler';
import HttpError from '../../../../../cross-cutting/errors/http-error';

import { getAllCompanyPlans, getOneCompanyPlan } from '../../../../../modules/plans/service';
import { CompanyPlan, CompanyPlanDetails } from '../../../../../modules/plans/types';
import { LIST_PAGE_SIZE } from './enums';
import { GetOneCompanyResponse } from '../../../../../modules/company/service';
import { getOneAttachment } from '../../../../../modules/attachment/service';

export default function Declarations({
  company,
  selectedDeclarationParam,
}: {
  company?: GetOneCompanyResponse;
  selectedDeclarationParam: string | null;
}) {
  const [error, setError] = useState<HttpError>();
  const [loading, setLoading] = useState(true);
  const [companyPlans, setCompanyPlans] = useState<CompanyPlan[]>([]);
  const [declarationId, setDeclarationId] = useState<string | null>(selectedDeclarationParam);
  const [selectedDeclaration, setSelectedDeclaration] = useState<CompanyPlanDetails>();
  const [declarationLoading, setDeclarationLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    async function fetchDeclaration() {
      setDeclarationLoading(true);
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const response = await getOneCompanyPlan(declarationId!);

        setSelectedDeclaration(response);
      } catch (err) {
        if (err instanceof HttpError) {
          setError(err);
        }
      }
      setDeclarationLoading(false);
    }

    if (declarationId !== null) fetchDeclaration();
  }, [declarationId]);

  useEffect(() => {
    async function fetchDeclarations() {
      setLoading(true);
      try {
        const response = await getAllCompanyPlans({
          companyId: company?.id,
          page,
          limit: LIST_PAGE_SIZE,
        });

        setCompanyPlans(response.companyPlans.filter((plan) => plan.hasDeclaration));
        setTotalCount(response.totalCount);
      } catch (err) {
        if (err instanceof HttpError) {
          setError(err);
        }
      }
      setLoading(false);
    }

    fetchDeclarations();
  }, [page, company]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };
  const handleSelectDeclaration = (id: string | null) => {
    setDeclarationId(id);
  };

  const handleCancel = () => {
    setSelectedDeclaration(undefined);
    setDeclarationId(null);
  };

  const handleDownloadFile = async (file: { id: string; name: string; type: string }) => {
    await getOneAttachment(file.id, { download: true });
  };
  return (
    <ErrorHandler error={error}>
      <Content
        companyPlans={companyPlans}
        loading={loading}
        onSelectDeclaration={handleSelectDeclaration}
        currentPage={page}
        totalCount={totalCount}
        pageSize={LIST_PAGE_SIZE}
        selectedDeclaration={selectedDeclaration}
        onCancel={handleCancel}
        declarationLoading={declarationLoading}
        onPageChange={handlePageChange}
        onDownloadFile={handleDownloadFile}
      />
    </ErrorHandler>
  );
}
