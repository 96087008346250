import type { FormInstance } from 'antd';
import dayjs from 'dayjs';

import Page from '../../../../../common-components/page';
import Loader from '../../../../../common-components/loader';
import Alert from '../../../../../common-components/alert';
import Form, { FormItem } from '../../../../../common-components/form';
import Checkbox from '../../../../../common-components/checkbox';
import Divider from '../../../../../common-components/divider';
import Button from '../../../../../common-components/button';
import Plan from '../plan';
import ConfirmationModal from '../confirmation-modal';
import dataVeracityTerms from '../../../../../assets/documents/TERMO_DE_VERACIDADE_DE_DADOS.pdf';

import i18n from './i18n';
import styles from './index.module.css';
import type { CompanyPlanDetails } from '../../../../../modules/plans/types';
import type { FormValues } from '../types';
import { FORM_FIELDS } from '../enums';
import routes from '../../../../../routes';

type Props = {
  formInstance: FormInstance;
  companyPlanId: string;
  loading: boolean;
  companyPlan?: CompanyPlanDetails;
  confirmationModalVisible: boolean;
  onFormSubmit: () => void;
  onCancelConfirmation: () => void;
  onConfirm: () => void;
  onFinish: (formValues: FormValues) => void;
  sendDeclarationLoading: boolean;
};

export default function Content({
  formInstance,
  companyPlanId,
  loading,
  companyPlan,
  confirmationModalVisible,
  onFormSubmit,
  onCancelConfirmation,
  onConfirm,
  onFinish,
  sendDeclarationLoading,
}: Props) {
  return (
    <Page
      browserTabName={i18n.ptBR.BROWSER_TAB_NAME}
      className={styles.pageContainer}
      childrenClassName={styles.content}
    >
      <h1 className={styles.pageTitle}>{i18n.ptBR.PAGE_TITLE}</h1>

      {loading || !companyPlan ? (
        <Loader />
      ) : (
        <>
          <div className={styles.companyPlanInfoPanel}>
            <h2 className={styles.companyPlanName}>{companyPlan.name}</h2>

            <div className={styles.companyPlanInfosWrapper}>
              <div className={styles.companyPlanInfo}>
                <span className={styles.companyPlanInfoLabel}>
                  {i18n.ptBR.COMPANY_PLAN_INFO.STATUS}
                </span>
                <span className={styles.companyPlanInfoValue}>
                  {i18n.ptBR.PLAN_STATUS[companyPlan.planStatusId]}
                </span>
              </div>
              {companyPlan.reportedAt ? (
                <div className={styles.companyPlanInfo}>
                  <span className={styles.companyPlanInfoLabel} style={{ textAlign: 'center' }}>
                    {i18n.ptBR.COMPANY_PLAN_INFO.REPORT_DATE}
                  </span>
                  <span className={styles.companyPlanInfoValue}>
                    {dayjs(companyPlan.reportedAt).format('L')}
                  </span>
                </div>
              ) : null}
              <div className={styles.companyPlanInfo}>
                <span className={styles.companyPlanInfoLabel} style={{ textAlign: 'center' }}>
                  {i18n.ptBR.COMPANY_PLAN_INFO.TYPE}
                </span>
                <span className={styles.companyPlanInfoValue}>
                  {i18n.ptBR.COMPANY_PLAN_TYPES[companyPlan.companyPlanTypeId]}
                </span>
              </div>
              <div className={styles.companyPlanInfo}>
                <span className={styles.companyPlanInfoLabel} style={{ textAlign: 'end' }}>
                  {i18n.ptBR.COMPANY_PLAN_INFO.YEAR}
                </span>
                <span className={styles.companyPlanInfoValue}>{companyPlan.fiscalYear}</span>
              </div>
            </div>
          </div>

          <Form form={formInstance} variant="ghost" requiredMark={false} onFinish={onFinish}>
            <section className={styles.evidencesSection}>
              <h3 className={styles.evidencesSectionTitle}>
                {i18n.ptBR.GOAL_EVIDENCES_SECTION.TITLE}
              </h3>

              {companyPlan.plans.map((plan) => (
                <div key={plan.id} className={styles.plan}>
                  <Plan fieldsPrefix={FORM_FIELDS.PLANS_PREFIX} plan={plan} />
                </div>
              ))}
            </section>

            <Alert
              message={i18n.ptBR.FORM.ALERT_MESSAGE}
              type="warning"
              iconName="alertCircle"
              className={styles.alert}
            />

            <a
              href={dataVeracityTerms}
              target="_blank"
              rel="noreferrer"
              className={styles.veracityTerms}
            >
              {i18n.ptBR.DATA_VERACITY_TERMS}
            </a>

            <FormItem
              name={FORM_FIELDS.COMMITTED}
              className={styles.checkboxField}
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) => {
                    if (value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(i18n.ptBR.FORM.FIELDS.COMMITTED.VALIDATION_MESSAGE),
                    );
                  },
                },
              ]}
            >
              <Checkbox>{i18n.ptBR.FORM.FIELDS.COMMITTED.LABEL}</Checkbox>
            </FormItem>

            <FormItem
              name={FORM_FIELDS.ACCEPTED_TERMS}
              className={styles.checkboxField}
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) => {
                    if (value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(i18n.ptBR.FORM.FIELDS.ACCEPTED_TERMS.VALIDATION_MESSAGE),
                    );
                  },
                },
              ]}
            >
              <Checkbox>{i18n.ptBR.FORM.FIELDS.ACCEPTED_TERMS.LABEL}</Checkbox>
            </FormItem>

            <Divider type="line" className={styles.bottomDivider} />

            <div className={styles.formButtonsContainer}>
              <Button
                type="text"
                text={i18n.ptBR.RETURN_BUTTON}
                href={routes.VIEW_COMPANY_PLAN.replace(':id', companyPlanId)}
                className={styles.returnButton}
              />
              <Button
                text={i18n.ptBR.SUBMIT_BUTTON}
                className={styles.submitButton}
                onClick={onFormSubmit}
              />
            </div>
          </Form>
        </>
      )}

      <ConfirmationModal
        visible={confirmationModalVisible}
        sendDeclarationLoading={sendDeclarationLoading}
        onConfirm={onConfirm}
        onCancel={onCancelConfirmation}
      />
    </Page>
  );
}
