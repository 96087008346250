/* eslint-disable import/extensions */
import { Col, FormInstance, Row, Space, Form as AntdForm } from 'antd';
import dayjs from 'dayjs';

import Alert from '../../../common-components/alert';
import Button from '../../../common-components/button';
import Checkbox from '../../../common-components/checkbox';
import DecimalInput from '../../../common-components/decimal-input';
import Divider from '../../../common-components/divider';
import Form from '../../../common-components/form';
import FormItem from '../../../common-components/form/form-item';
import Icon from '../../../common-components/icon';
import Input from '../../../common-components/input';
import Card from '../configure-program-card';
import DraggerUpload, { OriginFileObj } from '../../../common-components/dragger-upload';
import Loader from '../../../common-components/loader';
import PickList from '../../../common-components/pick-list';

import rvTemplate from '../../../assets/documents/TEMPLATE_REPORT_LR.xlsx';
import cnpjsTemplate from '../../../assets/documents/TEMPLATE_CNPJs_EntidadesGestoras.xlsx';

import convertBoldTextToJsx from '../../../cross-cutting/utils/convert-bold-text-to-jsx';
import { ProgramImages } from '../../../modules/programs/enums';
import { CompanyPlanDetails } from '../../../modules/plans/types';
import styles from './index.module.css';
import i18n from './i18n';
import { FORM_FIELDS } from './enums';
import routes from '../../../routes';
import { Program } from '../../../modules/programs/types';
import { FORM_NAMES } from '../new-plan/enums';
import masks from '../../../cross-cutting/utils/mask/masks';
import useUser from '../../../modules/user/use-user';
import { UserCompanyType } from '../../../modules/user/types';
import {
  DOCUMENT_TEMPLATE_NAMES,
  VALID_CNPJ_FILE_FORMATS,
  VALID_COMMUNICATION_DOCUMENT_FILE_FORMATS,
} from '../../../modules/plans/enums';
import { PROGRAMS_IDS } from '../../../enums';

export type UploadedFileData = {
  uid: string;
  id?: string;
  name?: string;
  type: string;
  file?: Blob;
  status?: 'done';
  lastModified?: number;
  lastModifiedDate?: Date;
  originFileObj?: OriginFileObj;
  percent?: number;
  size?: number;
};

type PlanFormProps = {
  finishLoading: boolean;
  form: FormInstance;
  cnpjFileData: UploadedFileData[];
  onCnpjUploadChange: (newFile: UploadedFileData) => void;
  onCnpjUploadDelete: (removedFile: UploadedFileData) => void;
  communicationDocumentFileData: UploadedFileData[];
  onCommunicationDocumentUploadChange: (newFile: UploadedFileData) => void;
  onCommunicationDocumentUploadDelete: (removedFile: UploadedFileData) => void;
  programs?: Program[];
  loading: boolean;
  companyPlan?: CompanyPlanDetails;
  onConfigureProgram?: (program: Program) => void;
  onDownloadFile?: (file: UploadedFileData) => Promise<void>;
  shouldHavePreviousPlanAlert?: boolean;
  cnpjUploadLoading: boolean;
  communicationDocumentUploadLoading: boolean;
};

function PlanForm({
  finishLoading,
  form,
  cnpjFileData,
  onCnpjUploadChange,
  onCnpjUploadDelete,
  communicationDocumentFileData,
  onCommunicationDocumentUploadChange,
  onCommunicationDocumentUploadDelete,
  programs,
  loading,
  companyPlan,
  onConfigureProgram,
  onDownloadFile,
  shouldHavePreviousPlanAlert,
  cnpjUploadLoading,
  communicationDocumentUploadLoading,
}: PlanFormProps) {
  const currentSelectedFiscalYear = AntdForm.useWatch(FORM_FIELDS.FISCAL_YEAR, form);
  const { user } = useUser();
  if (loading) {
    return <Loader style={{ height: '70vh' }} />;
  }
  const disabledYears: number[] = [];

  programs?.forEach((program) => {
    program.plans.forEach((plan) => {
      if (plan.fiscalYear) {
        disabledYears.push(plan.fiscalYear);
      }
    });
  });

  const validYearsOptions = [
    {
      key: 2021,
      value: '2021',
      disabled: disabledYears?.includes(2021),
      title: disabledYears?.includes(2021)
        ? i18n.ptBR.VALIDATE_MESSAGES.FISCAL_YEAR.DISABLED
        : undefined,
    },

    {
      key: dayjs().year(),
      value: dayjs().year().toString(),
      disabled: disabledYears?.includes(dayjs().year()),
      title: disabledYears?.includes(dayjs().year())
        ? i18n.ptBR.VALIDATE_MESSAGES.FISCAL_YEAR.DISABLED
        : undefined,
    },
  ];

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <p className={styles.pageTitle}>
        {companyPlan
          ? `${i18n.ptBR.EDIT_COMPANY_PLAN_TITLE} ${companyPlan.name} | ${companyPlan.fiscalYear}`
          : i18n.ptBR.NEW_COMPANY_PLAN_TITLE}
      </p>
      <Form layout="vertical" name={FORM_NAMES.NEW_PLAN} form={form}>
        <Space style={{ minWidth: '525px' }} direction="vertical" size={24}>
          <FormItem
            style={{ margin: '16px 0 0 0' }}
            name={FORM_FIELDS.FISCAL_YEAR}
            rules={[{ required: true, message: i18n.ptBR.VALIDATE_MESSAGES.FISCAL_YEAR.REQUIRED }]}
            requiredMark
            initialValue={
              companyPlan && {
                key: companyPlan?.fiscalYear,
                value: companyPlan?.fiscalYear.toString(),
              }
            }
          >
            <PickList
              options={validYearsOptions}
              placeholder={i18n.ptBR.FORM_FIELDS.FISCAL_YEAR.PLACEHOLDER}
              className={styles.fiscalYearPickList}
              disabled={!!companyPlan}
            />
          </FormItem>
          {shouldHavePreviousPlanAlert && !companyPlan && (
            <Alert
              iconName="alertCircle"
              style={{ border: '1px solid var(--orange-dark)' }}
              message={i18n.ptBR.FORM_FIELDS.FISCAL_YEAR.ALERT(currentSelectedFiscalYear)}
              type="warning"
            />
          )}
          <FormItem
            name={FORM_FIELDS.PLAN_NAME}
            requiredMark
            rules={[{ required: true }]}
            label={i18n.ptBR.FORM_FIELDS.PLAN_NAME.LABEL}
            initialValue={companyPlan?.name}
          >
            <Input
              style={{ maxWidth: '300px', background: 'var(--green-light)' }}
              maxLength={255}
            />
          </FormItem>

          {user?.profile.companyType === UserCompanyType.MANUFACTURERS_MANAGER && (
            <div className={styles.box}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '10px',
                }}
              >
                <p style={{ alignSelf: 'flex-end' }}>{i18n.ptBR.FORM_FIELDS.CNPJS_UPLOAD.LABEL}</p>
                <a download={DOCUMENT_TEMPLATE_NAMES.CNPJ} href={cnpjsTemplate}>
                  <Button
                    icon={<Icon name="download" />}
                    text={i18n.ptBR.FORM_FIELDS.CNPJS_UPLOAD.BUTTON}
                    title={i18n.ptBR.FORM_FIELDS.CNPJS_UPLOAD.BUTTON}
                    style={{ marginLeft: 'auto' }}
                  />
                </a>
              </div>
              <DraggerUpload
                onFileChange={onCnpjUploadChange}
                title={i18n.ptBR.FORM_FIELDS.CNPJS_UPLOAD.UPLOAD}
                onRemove={onCnpjUploadDelete}
                formats={VALID_CNPJ_FILE_FORMATS}
                multiple={false}
                onDownload={onDownloadFile}
                loading={cnpjUploadLoading}
                style={{ marginBottom: '10px' }}
                maxCount={1}
                fileData={cnpjFileData}
                showUploadList={{
                  showDownloadIcon: true,
                  downloadIcon: 'Download',
                  showRemoveIcon: true,
                }}
              />
            </div>
          )}
          <div className={styles.box}>
            <div>
              <p>{i18n.ptBR.FORM_FIELDS.DOCUMENT_UPLOAD.LABEL}</p>

              <Alert
                message={i18n.ptBR.FORM_FIELDS.DOCUMENT_UPLOAD.ALERT}
                type="success"
                style={{ margin: '8px 0 24px 0' }}
                iconName="alertCircle"
              />

              <Col span={8}>
                <FormItem
                  label={i18n.ptBR.FORM_FIELDS.DOCUMENT_UPLOAD.INVESTMENT}
                  name={FORM_FIELDS.MARKETING_INVESTMENT_PREDICTION}
                  required
                  requiredMark
                  initialValue={companyPlan?.marketingInvestmentPrediction}
                >
                  <DecimalInput unitSymbol="R$" unitSymbolPosition="left" controls={false} />
                </FormItem>
              </Col>
            </div>
            <DraggerUpload
              onFileChange={onCommunicationDocumentUploadChange}
              title={i18n.ptBR.FORM_FIELDS.DOCUMENT_UPLOAD.UPLOAD}
              onRemove={onCommunicationDocumentUploadDelete}
              formats={VALID_COMMUNICATION_DOCUMENT_FILE_FORMATS}
              multiple={false}
              maxCount={1}
              style={{ marginBottom: '10px' }}
              onDownload={onDownloadFile}
              fileData={communicationDocumentFileData}
              loading={communicationDocumentUploadLoading}
              showUploadList={{
                showDownloadIcon: true,
                downloadIcon: 'Download',
                showRemoveIcon: true,
              }}
            />
          </div>

          <div className={styles.box}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignContent: 'flex-end',
                marginBottom: '20px',
              }}
            >
              <p style={{ alignSelf: 'flex-end' }}>
                {i18n.ptBR.FORM_FIELDS.DOWNLOAD_TEMPLATE.LABEL}
              </p>
              <a download={DOCUMENT_TEMPLATE_NAMES.REVERSE_LOGISTICS_REPORT} href={rvTemplate}>
                <Button
                  icon={<Icon name="download" />}
                  text={i18n.ptBR.FORM_FIELDS.DOWNLOAD_TEMPLATE.BUTTON}
                  style={{ marginTop: '8px', marginLeft: 'auto' }}
                />
              </a>
            </div>
            <Alert
              iconName="alertCircle"
              type="warning"
              style={{ border: '1px solid var(--orange-dark)' }}
              message={i18n.ptBR.FORM_FIELDS.DOWNLOAD_TEMPLATE.ALERT}
            />
          </div>
          <div className={styles.box}>
            <p style={{ marginBottom: '16px' }}>{i18n.ptBR.FORM_FIELDS.INFO.TITLE}</p>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <FormItem
                  name={FORM_FIELDS.NAME}
                  label={i18n.ptBR.FORM_FIELDS.INFO.NAME.LABEL}
                  required
                  requiredMark
                  initialValue={companyPlan?.companyPlanContact.name}
                >
                  <Input maxLength={255} />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  name={FORM_FIELDS.SURNAME}
                  required
                  requiredMark
                  label={i18n.ptBR.FORM_FIELDS.INFO.SURNAME.LABEL}
                  initialValue={companyPlan?.companyPlanContact.surname}
                >
                  <Input maxLength={255} />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Divider type="line" />
              <Col span={8}>
                <FormItem
                  required
                  requiredMark
                  name={FORM_FIELDS.CPF}
                  label={i18n.ptBR.FORM_FIELDS.INFO.CPF.LABEL}
                  initialValue={companyPlan?.companyPlanContact.cpf}
                >
                  <Input maxLength={45} mask={masks.ptBR.registrationNumber[0]} />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  rules={[{ type: 'email' }]}
                  required
                  requiredMark
                  name={FORM_FIELDS.EMAIL}
                  label={i18n.ptBR.FORM_FIELDS.INFO.EMAIL.LABEL}
                  initialValue={companyPlan?.companyPlanContact.email}
                >
                  <Input maxLength={255} />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  required
                  requiredMark
                  name={FORM_FIELDS.PHONE}
                  label={i18n.ptBR.FORM_FIELDS.INFO.PHONE.LABEL}
                  initialValue={companyPlan?.companyPlanContact.phone}
                >
                  <Input maxLength={45} mask={masks.ptBR.phone} />
                </FormItem>
              </Col>
            </Row>
          </div>

          <Space size={24} direction="horizontal" wrap>
            {programs?.map((program: Program) => {
              const selectedYear =
                form?.getFieldValue([FORM_FIELDS.FISCAL_YEAR])?.key ||
                form?.getFieldValue([FORM_FIELDS.FISCAL_YEAR]);

              const currentPlan = program.plans.find((plan) => plan.fiscalYear === selectedYear);

              return (
                <Card
                  title={program.name}
                  plan={currentPlan}
                  key={program.id}
                  image={ProgramImages[program.id as keyof typeof ProgramImages]}
                  onConfigure={() => onConfigureProgram?.(program)}
                  shouldRenderGeographicGoal={program.id !== PROGRAMS_IDS.BATERIES_OF_ACID_LEAD}
                />
              );
            })}
          </Space>
          <Alert
            style={{ border: '1px solid var(--orange-dark)' }}
            message={convertBoldTextToJsx(i18n.ptBR.ALERT_MESSAGE, { separator: '<b>' })}
            iconName="alertCircle"
            type="warning"
          />
          <FormItem
            rules={[
              {
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject(i18n.ptBR.VALIDATE_MESSAGES.ACCEPT_TERMS.REQUIRED);
                  }
                  return Promise.resolve();
                },
              },
            ]}
            valuePropName="checked"
            name={FORM_FIELDS.ACCEPT_TERMS}
          >
            <Checkbox>{i18n.ptBR.FORM_FIELDS.ACCEPT_TERMS.LABEL}</Checkbox>
          </FormItem>
          <Divider type="line" />
          <div className={styles.buttonsContainer}>
            <Button
              type="text"
              title={i18n.ptBR.BUTTONS.CANCEL}
              text={i18n.ptBR.BUTTONS.CANCEL}
              loading={finishLoading}
              href={routes.LIST_PLANS}
              disabled={finishLoading}
            />
            <Button
              title={i18n.ptBR.BUTTONS.SAVE}
              text={i18n.ptBR.BUTTONS.SAVE}
              loading={finishLoading}
              htmlType="submit"
              disabled={finishLoading}
            />
          </div>
        </Space>
      </Form>
    </>
  );
}

export default PlanForm;
