const downloadFile = ({
  file,
  type,
  originalName,
}: {
  file: Blob;
  type: string;
  originalName: string;
}) => {
  const url = URL.createObjectURL(new Blob([file], { type }));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', decodeURI(originalName));
  document.body.appendChild(link);
  link.click();
};

export default downloadFile;
