import { useState } from 'react';
import { Form } from 'antd';

import Content from './content';
import ErrorHandler from '../../../../../common-components/error-handler';

import type { UploadedFile } from '../../../../../common-components/dragger-upload';
import type { PlanGetOneCompanyPlan } from '../../../../../modules/plans/types';
import HttpError from '../../../../../cross-cutting/errors/http-error';
import {
  getOneAttachment,
  removeAttachment,
  uploadAttachments,
} from '../../../../../modules/attachment/service';
import { FORM_FIELDS } from '../enums';

export type UploadedFileData = {
  id: string;
  name: string;
  status: 'done';
};

type Props = {
  fieldsPrefix: string;
  plan: PlanGetOneCompanyPlan;
};

export default function Plan({ fieldsPrefix, plan }: Props) {
  const [error, setError] = useState<HttpError>();
  const [uploadFileData, setUploadFileData] = useState<UploadedFileData[]>([]);
  const [uploadFileDataLoading, setUploadFileDataLoading] = useState(false);

  const formInstance = Form.useFormInstance();

  const handleFileChange = async (file: UploadedFile) => {
    setUploadFileDataLoading(true);
    try {
      const { originFileObj: originalFile } = file;

      const [uploadedFile] = await uploadAttachments(originalFile);
      setUploadFileData([
        {
          id: uploadedFile.id,
          name: uploadedFile.originalName,
          status: 'done',
        },
      ]);
      formInstance.setFieldsValue({
        [fieldsPrefix]: {
          [plan.id]: {
            [FORM_FIELDS.EVIDENCE_UPLOAD_ID]: [uploadedFile.id],
          },
        },
      });
    } catch (err) {
      if (err instanceof HttpError) {
        setError(err);
      }
    }
    setUploadFileDataLoading(false);
  };

  const handleRemoveFile = async () => {
    setUploadFileDataLoading(true);
    try {
      const id = formInstance.getFieldValue([
        fieldsPrefix,
        plan.id,
        FORM_FIELDS.EVIDENCE_UPLOAD_ID,
      ]);

      if (id) await removeAttachment(id);

      setUploadFileData([]);
      formInstance.setFieldsValue({
        [fieldsPrefix]: {
          [plan.id]: {
            [FORM_FIELDS.EVIDENCE_UPLOAD_ID]: [],
          },
        },
      });
    } catch (err) {
      if (err instanceof HttpError) {
        setError(err);
      }
    }
    setUploadFileDataLoading(false);
  };

  const handleDownloadFile = async (file: UploadedFileData) => {
    try {
      await getOneAttachment(file.id, { download: true });
    } catch (err) {
      if (err instanceof HttpError) {
        setError(err);
      }
    }
  };

  return (
    <ErrorHandler error={error}>
      <Content
        fieldsPrefix={fieldsPrefix}
        plan={plan}
        uploadFileData={uploadFileData}
        onFileChange={handleFileChange}
        onRemoveFile={handleRemoveFile}
        onDownloadFile={handleDownloadFile}
        uploadFileDataLoading={uploadFileDataLoading}
      />
    </ErrorHandler>
  );
}
