import { Space } from 'antd';

import Form, { FormItem } from '../../../../common-components/form';
import PageWithAddons from '../../../../common-components/page-with-addons';
import PickList from '../../../../common-components/pick-list';

import { ProgramNamesUrl } from '../../../../modules/programs/enums';
import validYearsOptions from '../../../../cross-cutting/utils/get-years-from-project-start';

import styles from './index.module.css';
import i18n from './i18n';
import Button from '../../../../common-components/button';
import BreadcrumbItem from '../../../../common-components/breadcrumbs/breadcrumb-item';
import routes from '../../../../routes';

import { FIELDS } from '../enums';
import Combobox from '../../../../common-components/combobox';
import ErrorHandler from '../../../../common-components/error-handler';

export default function Content({
  onClickGenerateReport,
  loading,
}: {
  onClickGenerateReport: (values: {
    programId: number;
    year: { key: number; value: number };
  }) => void;
  loading: boolean;
}) {
  const formattedSystemTypesOptions = () => {
    const defaultSystems = ProgramNamesUrl.map((id, index) => ({
      key: index + 1,
      value: i18n.ptBR.PROGRAMS_NAME[id],
    }));

    defaultSystems.unshift({
      key: 0,
      value: i18n.ptBR.SELECT_ALL,
    });

    return defaultSystems;
  };

  const years = validYearsOptions().map((year) => ({
    key: year,
    value: year,
  }));

  return (
    <PageWithAddons
      pageProps={{
        browserTabName: i18n.ptBR.BROWSER_TAB_NAME,
        title: i18n.ptBR.PAGE_TITLE,
        childrenClassName: styles.childrenClassName,
        breadcrumbs: [
          <BreadcrumbItem
            key="generateReport"
            route={routes.PLANS_AND_DECLARATIONS_REPORT}
            title={i18n.ptBR.BROWSER_TAB_NAME}
          />,
        ],
      }}
    >
      <ErrorHandler>
        <div className={styles.container}>
          <Form onFinish={onClickGenerateReport}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Space size={48} direction="horizontal">
                <FormItem
                  label={i18n.ptBR.FORM_FIELDS.SYSTEM_TYPE.LABEL}
                  required
                  rules={[{ required: true }]}
                  name={FIELDS.SYSTEMS}
                >
                  <PickList
                    options={formattedSystemTypesOptions()}
                    placeholder={i18n.ptBR.FORM_FIELDS.SYSTEM_TYPE.PLACEHOLDER}
                    allowClear
                  />
                </FormItem>
                <FormItem
                  label={i18n.ptBR.FORM_FIELDS.YEAR.LABEL}
                  required
                  rules={[{ required: true }]}
                  name={FIELDS.YEAR}
                >
                  <Combobox
                    data={years}
                    placeholder={i18n.ptBR.FORM_FIELDS.YEAR.PLACEHOLDER}
                    allowClear
                    optionKey="value"
                    format="value"
                  />
                </FormItem>
              </Space>

              <Button
                text={i18n.ptBR.GENERATE_REPORT_BUTTON}
                htmlType="submit"
                type="default"
                loading={loading}
                disabled={loading}
                newDesign
              />
            </div>
          </Form>
        </div>
      </ErrorHandler>
    </PageWithAddons>
  );
}
