import { Space } from 'antd';

import Dayjs from 'dayjs';
import Button from '../../../../../../common-components/button';
import Collapsible from '../../../../../../common-components/collapsible';
import Divider from '../../../../../../common-components/divider';
import Field from '../../../../../../common-components/field';
import Icon from '../../../../../../common-components/icon';
import Loader from '../../../../../../common-components/loader';
import { PROGRAMS_IDS } from '../../../../../../enums';

import { CompanyPlanDetails, PlanGetOneCompanyPlan } from '../../../../../../modules/plans/types';
import { ProgramImages } from '../../../../../../modules/programs/enums';
import { PROGRAM_STATUS } from '../enums';
import ProgramCard from '../program-card';
import ProgramDetailsModal from '../program-details-modal';
import i18n from './i18n';
import styles from './index.module.css';

type ViewCompanyPlanDetailsProps = {
  companyPlan?: CompanyPlanDetails;
  loading: boolean;
  onCancel: () => void;
  onClickProgramCard: (programId: string) => void;
  isModalVisible: boolean;
  selectedProgram: PlanGetOneCompanyPlan | undefined;
  onCancelModal: () => void;
};

export default function ViewCompanyPlanDetails({
  companyPlan,
  loading,
  onCancel,
  onClickProgramCard,
  isModalVisible,
  selectedProgram,
  onCancelModal,
}: ViewCompanyPlanDetailsProps) {
  if (loading) return <Loader />;

  return (
    <>
      <div style={{ marginTop: '40px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className={styles.planName}>{companyPlan?.name}</span>
          <Button
            style={{ marginLeft: 'auto' }}
            text={i18n.ptBR.BUTTONS.RETURN}
            onClick={() => onCancel()}
            icon={<Icon name="arrowLeft" />}
            newDesign
          />
        </div>

        <Collapsible
          style={{ margin: '30px 0' }}
          openIcon="chevronDown"
          closeIcon="chevronUp"
          title={
            <span className={styles.collapsibleTitle}>{i18n.ptBR.PLAN_DETAILS_FIELDS.TITLE}</span>
          }
          childrenClassname={styles.collapsibleChildrenClassname}
          defaultOpen
        >
          <Space size={80} direction="horizontal">
            <Field
              title={i18n.ptBR.PLAN_DETAILS_FIELDS.SENT_DATE}
              value={Dayjs(companyPlan?.reportedAt).format('L')}
            />
            <Field
              title={i18n.ptBR.PLAN_DETAILS_FIELDS.LAST_UPDATE}
              value={Dayjs(companyPlan?.reportedAt).format('L')}
            />
            <Field
              title={i18n.ptBR.PLAN_DETAILS_FIELDS.PLAN_TYPE.LABEL}
              value={
                i18n.ptBR.PLAN_DETAILS_FIELDS.PLAN_TYPE.VALUES[companyPlan?.companyPlanTypeId || 1]
              }
            />
            <Field title={i18n.ptBR.PLAN_DETAILS_FIELDS.YEAR} value={companyPlan?.fiscalYear} />
          </Space>
        </Collapsible>
        <Divider type="line" />
        <Collapsible
          openIcon="chevronDown"
          closeIcon="chevronUp"
          title={
            <span className={styles.collapsibleTitle}>{i18n.ptBR.PLAN_RESPONSIBLE.TITLE}</span>
          }
          childrenClassname={styles.collapsibleChildrenClassname}
          defaultOpen
          style={{ margin: '30px 0' }}
        >
          <Space size={80} direction="horizontal">
            <Field
              title={i18n.ptBR.PLAN_RESPONSIBLE.FULL_NAME}
              value={`${companyPlan?.companyPlanContact.name} ${companyPlan?.companyPlanContact.surname}`}
            />
            <Field
              title={i18n.ptBR.PLAN_RESPONSIBLE.CPF}
              value={companyPlan?.companyPlanContact.cpf}
            />
            <Field
              title={i18n.ptBR.PLAN_RESPONSIBLE.EMAIL}
              value={companyPlan?.companyPlanContact.email}
            />
            <Field
              title={i18n.ptBR.PLAN_RESPONSIBLE.PHONE}
              value={companyPlan?.companyPlanContact.phone}
            />
          </Space>
        </Collapsible>
        <Divider type="line" style={{ margin: '30px 0' }} />

        <Space direction="horizontal" wrap size={24}>
          {companyPlan?.plans.map((plan) => {
            const programImage = ProgramImages[plan.program.id as keyof typeof ProgramImages];

            const hasWeightGoal = plan.programWasteTypes.some(
              (pWasteType) => pWasteType.totalWeightSold || pWasteType.weightGoal,
            );
            const programStatus =
              plan.geographicGoalAttachment && hasWeightGoal
                ? PROGRAM_STATUS.CONFIGURED
                : PROGRAM_STATUS.NOT_CONFIGURED;

            return (
              <ProgramCard
                key={plan.id}
                onClick={onClickProgramCard}
                programName={plan.program.name}
                programId={plan.id}
                programImage={programImage}
                programStatus={programStatus}
                isGeographicGoalAchieved={!!plan.geographicGoalAttachment}
                isQuantityGoalAchieved={hasWeightGoal}
                shouldRenderGeographicGoal={plan.program.id !== PROGRAMS_IDS.BATERIES_OF_ACID_LEAD}
              />
            );
          })}
        </Space>

        <Button
          style={{ marginLeft: 'auto', marginTop: '30px' }}
          text={i18n.ptBR.BUTTONS.RETURN}
          onClick={() => onCancel()}
          icon={<Icon name="arrowLeft" />}
          newDesign
        />
      </div>
      <ProgramDetailsModal
        selectedProgram={selectedProgram}
        visible={isModalVisible}
        onCancel={onCancelModal}
        shouldRenderGeographicGoal={
          selectedProgram?.program.id !== PROGRAMS_IDS.BATERIES_OF_ACID_LEAD
        }
      />
    </>
  );
}
