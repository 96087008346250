import styles from './index.module.css';
import Icon from '../../icon';
import icons from '../../icon/icons';

function TabTitle({ text, icon }: { text: string; icon?: keyof typeof icons }) {
  return (
    <div className={styles.container}>
      {icon ? (
        <div className={styles.iconContainer}>
          <Icon name={icon} className={styles.icon} />
        </div>
      ) : null}
      <strong className={styles.text}>{text}</strong>
    </div>
  );
}

export default TabTitle;
