/* eslint-disable @typescript-eslint/no-explicit-any */
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { Key } from 'antd/lib/table/interface';
import { Select, SelectProps } from 'antd';

import i18n from './i18n';
import matchChildrenWithInputValue from '../combobox/match-children-with-input-value';

import styles from './index.module.css';

type PickListProps = Omit<SelectProps, 'options'> & {
  options: { key: Key; value: string | boolean | number; disabled?: boolean; title?: string }[];
  className?: string;
  placeholder?: string;
  size?: SizeType;
  loading?: boolean;
  disabled?: boolean;
};
function PickList({
  options = [],
  className = '',
  placeholder = '',
  size = 'large',
  loading = false,
  disabled = false,
  ...props
}: PickListProps) {
  const { Option } = Select;

  return (
    <Select
      showSearch
      showArrow
      size={size}
      className={`${styles.pickList} ${className}`}
      placeholder={placeholder || i18n.ptBR.PLACEHOLDER}
      filterOption={matchChildrenWithInputValue as any}
      disabled={disabled}
      loading={loading || (!disabled && !options)}
      dropdownClassName={styles.dropdown}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {options &&
        options.map((option) => {
          return (
            <Option
              key={option.key}
              value={option.key}
              disabled={option.disabled}
              title={option.title}
            >
              {option.value}
            </Option>
          );
        })}
    </Select>
  );
}

export default PickList;
