/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode } from 'react';
import { Form, Space } from 'antd';

import Button from '../../button';
import Collapsible from '../../collapsible';
import { FormItem } from '../../form';

import styles from './index.module.css';
import i18n from './i18n';

type FilterBoxProps = {
  filters: ReactNode[];
  onFilter: (values: any) => void;
  defaultOpen?: boolean;
};
function FilterBox({ filters, onFilter, defaultOpen }: FilterBoxProps) {
  const handleFilter = (values: ReactNode[]) => {
    const isSomeFilterFilled = Object.values(values).some((value) => !!value);

    if (isSomeFilterFilled) {
      onFilter(values);
      /**
       * if nothing was sent in the input, the onClick function did not detect
       * that there was anything and did not pass the value to the filter box.
       */
    } else onFilter('');
  };

  const renderFilters = () => {
    return filters.map((filter: ReactNode, index: number) => {
      const key = index;
      return (
        <div key={key} className={styles.filterContainer}>
          {filter}
        </div>
      );
    });
  };

  return (
    <Collapsible
      openIcon="chevronDown"
      closeIcon="chevronUp"
      defaultOpen={defaultOpen}
      title={i18n.ptBR.TITLE}
      className={styles.container}
    >
      {/* filters */}
      <Form layout="vertical" onFinish={handleFilter}>
        <Space
          wrap
          align="end"
          direction="horizontal"
          style={{ justifyContent: 'space-between', display: 'flex' }}
        >
          <Space wrap>{renderFilters()}</Space>
          <div style={{ display: 'flex', gap: '24px' }}>
            <FormItem>
              <Button type="stroke" text={i18n.ptBR.BUTTONS.CLEAR} htmlType="reset" newDesign />
            </FormItem>
            <FormItem>
              <Button type="default" text={i18n.ptBR.BUTTONS.FILTER} htmlType="submit" newDesign />
            </FormItem>
          </div>
        </Space>
      </Form>
    </Collapsible>
  );
}

export default FilterBox;
