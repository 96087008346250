import { COMPANY_PLAN_TYPE_ID } from '../../../../../../../modules/plans/enums';

export default {
  PLAN_DETAILS_FIELDS: {
    TITLE: 'Detalhes',
    SENT_DATE: 'Entregue em',
    LAST_UPDATE: 'Última atualização',
    PLAN_TYPE: {
      LABEL: 'Tipo',
      VALUES: {
        [COMPANY_PLAN_TYPE_ID.COLLECTIVE]: 'Coletivo',
        [COMPANY_PLAN_TYPE_ID.INDIVIDUAL]: 'Individual',
      },
    },
    YEAR: 'Ano',
  },
  PLAN_RESPONSIBLE: {
    TITLE: 'Responsável pelo cadastramento do plano de Logística Reversa',
    FULL_NAME: 'Nome e Sobrenome',
    CPF: 'CPF',
    EMAIL: 'E-mail',
    PHONE: 'Telefone',
  },
  BUTTONS: {
    RETURN: 'Voltar',
  },
};
