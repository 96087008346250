import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import Content from './content';
import ErrorHandler from '../../../common-components/error-handler';
import HttpError from '../../../cross-cutting/errors/http-error';

import { ProgramNamesUrl } from '../../../modules/programs/enums';
import { LIST_PAGE_SIZE } from './enums';
import { getAllCompanies } from '../../../modules/company/service';
import { Company } from '../../../modules/company/types';
import { FilterType, Sorter } from './types';
import { OrderBy } from '../../../cross-cutting/data-access';

export default function ListCompanies() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<HttpError | null>(null);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [listOrdination, setListOrdination] = useState<OrderBy<'legalName'>>({
    legalName: 'asc',
  });
  const [filters, setFilters] = useState({
    page: 1,
    limit: LIST_PAGE_SIZE,
  });

  const handleOrdinationChange = (sorter: Sorter | Sorter[]) => {
    const sorters = Array.isArray(sorter) ? sorter : [sorter];

    const newListOrdination: OrderBy<'legalName'> = {};

    sorters.forEach(({ columnKey, order }) => {
      const orderReq = order === 'ascend' ? 'asc' : 'desc';
      newListOrdination[columnKey] = order ? orderReq : undefined;
    });
    setListOrdination(newListOrdination);
  };

  const handleFilter = (formValues: FilterType) => {
    setFilters((prevValues) => ({
      ...prevValues,
      companyTypeId: formValues.companyTypeId,
      region: formValues.region,
      legalName: formValues.legalNameOrCnpj || undefined,
      cnpj: formValues.legalNameOrCnpj || undefined,
      page: 1,
    }));
  };
  const { id } = useParams();
  useEffect(() => {
    async function fetchCompanies() {
      setLoading(true);
      try {
        const response = await getAllCompanies({
          programId: ProgramNamesUrl.findIndex((value) => value === id) + 1,
          orderBy: listOrdination,
          ...filters,
        });

        setCompanies(response.companies);
        setTotalCount(response.totalCount);
      } catch (err) {
        if (err instanceof HttpError) {
          setError(err);
        }
      }
      setLoading(false);
    }

    fetchCompanies();
  }, [id, filters, listOrdination]);

  const handlePageChange = (newPage: number) => {
    setFilters((prevFilters) => ({ ...prevFilters, page: newPage }));
  };

  return (
    <ErrorHandler error={error}>
      <Content
        totalCount={totalCount}
        currentPage={filters.page}
        onPageChange={handlePageChange}
        companies={companies}
        loading={loading}
        onFilter={handleFilter}
        pageSize={LIST_PAGE_SIZE}
        onOrdinationChange={handleOrdinationChange}
        programId={id}
      />
    </ErrorHandler>
  );
}
