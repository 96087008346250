export default {
  NEW_COMPANY_PLAN_TITLE: 'Novo plano',
  EDIT_COMPANY_PLAN_TITLE: 'Editar plano',
  FORM_FIELDS: {
    PLAN_NAME: {
      LABEL: 'Nome do sistema de Logística Reversa',
    },
    CNPJS_UPLOAD: {
      LABEL: 'CNPJ das empresas associadas (Matriz e filiais)',
      BUTTON: 'Baixar template de CNPJs',
      UPLOAD: 'Upload de CNPJs',
    },
    DOCUMENT_UPLOAD: {
      LABEL: 'Plano de comunicação',
      INVESTMENT: 'Previsão de investimento no ano',
      UPLOAD: 'Upload do documento',
      ALERT: 'Aqui você poderá adicionar documento .PDF usado em ações de campanhas e divulgações',
    },
    FISCAL_YEAR: {
      PLACEHOLDER: 'Selecione o ano',
      ALERT: (currentFiscalYear: number) =>
        `O plano de ${
          currentFiscalYear - 1
        } precisa ser enviado antes que você possa criar o de ${currentFiscalYear}`,
    },
    DOWNLOAD_TEMPLATE: {
      LABEL: 'Documento para comprovação de meta',
      BUTTON: 'Baixar template',
      ALERT:
        'Faça o download do template e preencha o documento ao longo do ano. Você deverá anexar a versão final do documento no momento de enviar a declaração anual do(s) seu(s) sistema(s) de Logística Reversa.',
    },
    INFO: {
      TITLE: 'Responsável pelo cadastramento do plano de Logística Reversa',
      NAME: {
        LABEL: 'Nome',
      },
      SURNAME: {
        LABEL: 'Sobrenome',
      },
      CPF: {
        LABEL: 'CPF',
      },
      EMAIL: {
        LABEL: 'E-mail',
      },
      PHONE: {
        LABEL: 'Telefone',
      },
    },
    ACCEPT_TERMS: {
      LABEL:
        'Declaro que estou ciente e declarei as informações corretas sobre o plano de Logística Reversa.',
    },
  },
  ALERT_MESSAGE:
    '<b>Campos com * são obrigatórios<b>. Você pode salvar e continuar depois sem preencher tudo mas lembre-se que para enviar o plano é obrigatório preencher todos os campos.',

  BUTTONS: {
    CANCEL: 'Cancelar',
    SAVE: 'Salvar',
  },

  VALIDATE_MESSAGES: {
    ACCEPT_TERMS: {
      REQUIRED: 'Você deve aceitar os termos para continuar',
    },
    FISCAL_YEAR: {
      DISABLED: 'Não é possível escolher esta opção pois já existe um plano para este ano fiscal',
      REQUIRED: 'Por favor, selecione o ano fiscal',
    },
  },
};
