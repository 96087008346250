import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Content from './content';
import ErrorHandler from '../../common-components/error-handler';

import { STEPS } from './enums';
import routes from '../../routes';

export default function UserPasswordRecovery() {
  const [currentStep, setCurrentStep] = useState(STEPS.REQUEST_CODE);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');

  const navigate = useNavigate();
  const handleFinishFirstStep = (receivedEmail: string) => {
    setEmail(receivedEmail);
    setCurrentStep(STEPS.VALIDATE_CODE);
  };

  const handleCancelClick = () => {
    navigate(routes.LOGIN);
  };

  const handleFinishSecondStep = (receivedCode: number) => {
    setCode(receivedCode.toString());
    setCurrentStep(STEPS.RESET_PASSWORD);
  };

  const handleFinishThirdStep = () => {
    setCurrentStep(STEPS.SUCCESS);
  };

  return (
    <ErrorHandler>
      <Content
        onFinishFirstStep={handleFinishFirstStep}
        onFinishSecondStep={handleFinishSecondStep}
        onFinishThirdStep={handleFinishThirdStep}
        onCancelClick={handleCancelClick}
        email={email}
        code={code}
        currentStep={currentStep}
      />
    </ErrorHandler>
  );
}
