import { CSSProperties } from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import Checkbox from '../../../../common-components/checkbox';
import Icon from '../../../../common-components/icon';

import i18n from './i18n';
import styles from './index.module.css';

type CardProps = {
  title: string;
  checked: boolean;
  onChange?: (checked: CheckboxChangeEvent) => void;
  image: string;
  programUrl: string;
  style?: CSSProperties;
  programId: number;
};
function Card({ title, image, programUrl, checked, onChange, style, programId }: CardProps) {
  return (
    <div
      className={styles.container}
      style={{
        background: !checked ? 'var(--green-light)' : 'white',
        ...style,
      }}
    >
      <Checkbox checked={checked} onChange={onChange} className={styles.checkBox} value={programId}>
        <img
          style={{
            filter: !checked ? 'grayscale(100%)' : undefined,
            mixBlendMode: !checked ? 'multiply' : undefined,
            transition: '250ms',
          }}
          src={image}
          alt={title}
        />
        <div className={styles.textContainer}>
          <span className={styles.title}>{title}</span>
        </div>
      </Checkbox>
      <a
        href={programUrl}
        target="_blank"
        style={{ color: 'black', padding: '0px 0 16px 16px' }}
        rel="noreferrer"
      >
        <Icon
          style={{ display: 'inline-block', marginRight: '4px', marginBottom: '-2px' }}
          name="infoFilled"
        />
        <span>{i18n.ptBR.DESCRIPTION}</span>
      </a>
    </div>
  );
}

export default Card;
