import { Form, notification } from 'antd';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import Page from '../../../common-components/page';
import Content from './content';
import ErrorHandler from '../../../common-components/error-handler';

import i18n from './i18n';
import styles from './content/index.module.css';
import { getOneCompanyPlan, sendCompanyPlan } from '../../../modules/plans/service';
import downloadFile from '../../../cross-cutting/utils/download-file';
import HttpError from '../../../cross-cutting/errors/http-error';
import { CompanyPlanDetails } from '../../../modules/plans/types';
import { getUserPrograms } from '../../../modules/programs/service';

import { Program } from '../../../modules/programs/types';
import ConfigureProgramFormModal from '../../programs/program-form-modal';
import { UploadedFileData } from './view-declaration/plan';
import { getOneAttachment } from '../../../modules/attachment/service';
import { FORM_FIELDS } from './enums';

function ViewCompanyPlan() {
  const { id } = useParams();

  const [error, setError] = useState<HttpError | undefined>(undefined);
  const [companyPlan, setCompanyPlan] = useState<CompanyPlanDetails>();
  const [programs, setPrograms] = useState<Program[] | undefined>();
  const [form] = Form.useForm();

  const [communicationDocumentFileData, setCommunicationDocumentFileData] =
    useState<UploadedFileData[]>();
  const [cnpjFileData, setCnpjFileData] = useState<UploadedFileData[]>();
  const [fileDataLoading, setFileDataLoading] = useState(false);

  const [companyPlanLoading, setCompanyPlanLoading] = useState(false);
  const [companyPlanSending, setCompanyPlanSending] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState<Program>({} as Program);
  const [isProgramModalVisible, setProgramModalVisible] = useState(false);
  const [programLoading, setProgramsLoading] = useState(false);
  const currentSelectedFiscalYear = companyPlan?.fiscalYear as number;

  const fetchPlanDetails = useCallback(async () => {
    setCompanyPlanLoading(true);
    if (!id) throw new Error('Plan id is required');
    try {
      const response = await getOneCompanyPlan(id);

      setCompanyPlan(response);
      setCompanyPlanLoading(false);
    } catch (err) {
      if (err instanceof HttpError) setError(err);
    }
    setCompanyPlanLoading(false);
  }, [id]);

  const handleSendCompanyPlan = async () => {
    if (!form.getFieldValue([FORM_FIELDS.ACCEPT_TERMS])) {
      notification.error({ message: i18n.ptBR.VALIDATE_MESSAGES.ACCEPT_TERMS.REQUIRED });
      return;
    }

    if (!id) return;

    setCompanyPlanSending(true);
    try {
      await sendCompanyPlan(id);
      notification.success({ message: i18n.ptBR.SUCCESS_NOTIFICATION });
      await fetchPlanDetails();
    } catch (err) {
      if (err instanceof HttpError) {
        setError(err);
      }
    }
    setCompanyPlanSending(false);
  };

  useEffect(() => {
    const fetchPrograms = async () => {
      setProgramsLoading(true);
      try {
        const response = await getUserPrograms();
        setPrograms(response);
      } catch (err) {
        if (err instanceof HttpError) {
          setError(err);
        }
      }
      setProgramsLoading(false);
    };

    const fetchAttachmentDetails = async (
      uploadedFileId: string,
      setFileDataState: React.Dispatch<React.SetStateAction<UploadedFileData[] | undefined>>,
    ) => {
      setFileDataLoading(true);
      try {
        const response = await getOneAttachment(uploadedFileId);
        setFileDataState([
          {
            id: response.id,
            name: response.originalName,
            type: response.type,
            file: response.file,
            status: 'done',
          },
        ]);
      } catch (err) {
        if (err instanceof HttpError) {
          setError(err);
        }
      }
      setFileDataLoading(false);
    };
    fetchPrograms();

    fetchPlanDetails();
    if (companyPlan?.associatedCompaniesAttachment?.uploadedFileId) {
      fetchAttachmentDetails(
        companyPlan?.associatedCompaniesAttachment?.uploadedFileId,
        setCnpjFileData,
      );
    }
    if (companyPlan?.marketingCampaignAttachment?.uploadedFileId) {
      fetchAttachmentDetails(
        companyPlan?.marketingCampaignAttachment?.uploadedFileId,
        setCommunicationDocumentFileData,
      );
    }
  }, [
    companyPlan?.associatedCompaniesAttachment?.uploadedFileId,
    companyPlan?.marketingCampaignAttachment?.uploadedFileId,
    fetchPlanDetails,
    id,
  ]);

  const handleDownloadFile = async (file: UploadedFileData) => {
    downloadFile({
      file: file.file,
      type: file.type,
      originalName: file.name,
    });
  };

  const handleViewProgram = (viewProgram: Program) => {
    setSelectedProgram(viewProgram);

    setProgramModalVisible(true);
  };

  return (
    <Page
      childrenClassName={styles.containerChildren}
      className={styles.container}
      browserTabName={i18n.ptBR.PAGE_TITLE}
    >
      <ErrorHandler error={error}>
        <Content
          companyPlan={companyPlan}
          onDownloadFile={handleDownloadFile}
          loading={programLoading || companyPlanLoading || fileDataLoading}
          programs={programs}
          onViewProgram={handleViewProgram}
          companyPlanSending={companyPlanSending}
          onSendCompanyPlan={handleSendCompanyPlan}
          id={id}
          form={form}
          cnpjFileData={cnpjFileData}
          communicationDocumentFileData={communicationDocumentFileData}
        />
        <ConfigureProgramFormModal
          onCancel={() => setProgramModalVisible(false)}
          isProgramModalVisible={isProgramModalVisible}
          selectedFiscalYear={currentSelectedFiscalYear}
          program={selectedProgram}
          isViewPage
          programWasteTypes={
            companyPlan?.plans.find((plan) => selectedProgram?.id === plan?.program.id)
              ?.programWasteTypes
          }
        />
      </ErrorHandler>
    </Page>
  );
}

export default ViewCompanyPlan;
