import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import Content from './content';
import ErrorHandler from '../../../../common-components/error-handler';

import HttpError from '../../../../cross-cutting/errors/http-error';
import { getAllCompanyPlans } from '../../../../modules/plans/service';
import { CompanyPlan } from '../../../../modules/plans/types';
import { LIST_PAGE_SIZE } from './enums';

import { OrderBy } from '../../../../cross-cutting/data-access';
import { FilterType, Sorter } from '../types';
import { ProgramNamesUrl } from '../../../../modules/programs/enums';

export default function ListDeclarations() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<HttpError | null>(null);
  const [companyPlans, setCompanyPlans] = useState<CompanyPlan[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [listOrdination, setListOrdination] = useState<OrderBy<'legalName' | 'year'>>({
    year: 'asc',
  });
  const [filters, setFilters] = useState({
    page: 1,
    limit: LIST_PAGE_SIZE,
  });
  const { id } = useParams();

  const handleOrdinationChange = (sorter: Sorter | Sorter[]) => {
    const sorters = Array.isArray(sorter) ? sorter : [sorter];

    const newListOrdination: OrderBy<'legalName' | 'year'> = {};

    sorters.forEach(({ columnKey, order }) => {
      const orderReq = order === 'ascend' ? 'asc' : 'desc';
      newListOrdination[columnKey] = order ? orderReq : undefined;
    });
    setListOrdination(newListOrdination);
  };

  const handleFilter = (formValues: FilterType) => {
    setFilters((prevValues) => ({
      ...prevValues,
      page: 1,
      legalName: formValues.legalNameOrCnpj || undefined,
      cnpj: formValues.legalNameOrCnpj || undefined,
      companyPlanTypeId: formValues.planType,
      region: formValues.region,
      fiscalYear: formValues.fiscalYear,
    }));
  };
  useEffect(() => {
    async function fetchCompanyPlans() {
      setLoading(true);
      try {
        const response = await getAllCompanyPlans({
          programId: ProgramNamesUrl.findIndex((value) => value === id) + 1,
          orderBy: listOrdination,
          ...filters,
        });

        setCompanyPlans(response.companyPlans.filter((plan) => plan.hasDeclaration));
        setTotalCount(response.totalCount);
      } catch (err) {
        if (err instanceof HttpError) {
          setError(err);
        }
      }
      setLoading(false);
    }

    fetchCompanyPlans();
  }, [id, filters, listOrdination]);

  const handlePageChange = (page: number) => {
    setFilters((prevFilters) => ({ ...prevFilters, page }));
  };

  return (
    <ErrorHandler error={error}>
      <Content
        onFilter={handleFilter}
        loading={loading}
        totalCount={totalCount}
        pageSize={filters.limit}
        onPageChange={handlePageChange}
        currentPage={filters.page}
        companyPlans={companyPlans}
        programId={id}
        onOrdinationChange={handleOrdinationChange}
      />
    </ErrorHandler>
  );
}
