import { Helmet } from 'react-helmet';
import { CSSProperties } from 'react';

import Header from './header';
import Footer from './footer';
import NewHeader from './new-header';
import Breadcrumbs from '../breadcrumbs';
import Icon from '../icon';

import styles from './index.module.css';
import i18n from './i18n';

export type PageProps = {
  className?: string;
  browserTabName: string;
  children?: React.ReactNode;
  childrenClassName?: string;
  style?: CSSProperties;
  title?: string | React.ReactNode;
  newDesign?: boolean;
  breadcrumbs?: JSX.Element[];
};
function Page({
  browserTabName,
  children,
  childrenClassName,
  className,
  style,
  title = i18n.ptBR.TITLE,
  newDesign,
  breadcrumbs,
}: PageProps) {
  return (
    <>
      <Helmet>
        <title>{browserTabName}</title>
      </Helmet>
      {newDesign ? <NewHeader /> : <Header />}

      {newDesign && (
        <div style={{ margin: '0px 0px 8px 50px' }}>
          <Breadcrumbs>{breadcrumbs}</Breadcrumbs>
        </div>
      )}

      {newDesign ? (
        <div style={{ paddingBottom: '42px' }} className={childrenClassName}>
          <p className={styles.newDesignTitle}>{title}</p>
          {children}
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
            ...style,
          }}
          className={` ${className} `}
        >
          <div className={styles.titleIndicatorContainer}>
            <div className={styles.titleIndicator} />
            <div className={styles.titleIndicatorCircle}>
              <Icon color="white" name="recyclingSymbol" size={18} />
            </div>
            <p className={styles.titleText}>{title}</p>
          </div>
          <div className={`${styles.default} ${childrenClassName ?? ''}`}>{children}</div>
        </div>
      )}
      <Footer />
    </>
  );
}

export default Page;
