/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useParams, useNavigate } from 'react-router-dom';
import { Form, notification } from 'antd';
import React, { useEffect, useState } from 'react';

import Page from '../../../common-components/page';
import Content, { UploadedFileData } from '../plan-form';
import ErrorHandler from '../../../common-components/error-handler';
import Button from '../../../common-components/button';
import ConfigureProgramFormModal from '../../programs/program-form-modal';
import AssociatedCompaniesAttachmentErrorModal from '../associated-companies-attachment-error-modal';

import i18n from './i18n';
import styles from './index.module.css';
import { editCompanyPlan, getOneCompanyPlan } from '../../../modules/plans/service';
import downloadFile from '../../../cross-cutting/utils/download-file';
import HttpError from '../../../cross-cutting/errors/http-error';
import { getUserPrograms } from '../../../modules/programs/service';
import { Plan, Program } from '../../../modules/programs/types';
import { CompanyPlanDetails } from '../../../modules/plans/types';
import { FORM_FIELDS as PLAN_FORM_FIELDS } from '../plan-form/enums';
import { FORM_FIELDS as PROGRAM_FORM_FIELDS } from '../../programs/program-form-modal/enums';

import routes from '../../../routes';
import {
  getOneAttachment,
  removeAttachment,
  uploadAttachments,
} from '../../../modules/attachment/service';
import {
  MAX_CNPJ_FILE_SIZE,
  MAX_CNPJ_FILE_SIZE_KBS,
  MAX_COMMUNICATION_DOCUMENT_FILE_SIZE,
  MAX_COMMUNICATION_DOCUMENT_FILE_SIZE_KBS,
} from './enums';
import useUser from '../../../modules/user/use-user';
import { UserCompanyType } from '../../../modules/user/types';
import { ResidueType } from '../../programs/program-form-modal/residue-list';
import { FORM_NAMES } from '../new-plan/enums';
import sanitizeInputValue from '../../../cross-cutting/utils/sanitize-input-value';
import { UNPROCESSABLE_ENTITY_ERROR_TYPES } from '../../../enums';

const getIsAssociatedCompaniesAttachmentError = (error: HttpError | undefined) => {
  const associatedCompaniesAttachmentErrorTypes = [
    UNPROCESSABLE_ENTITY_ERROR_TYPES.COMPANY_PLAN_ASSOCIATED_COMPANIES_ATTACHMENT_CNPJ_INVALID,
    UNPROCESSABLE_ENTITY_ERROR_TYPES.COMPANY_PLAN_ASSOCIATED_COMPANIES_ATTACHMENT_CNPJ_MISSING,
    UNPROCESSABLE_ENTITY_ERROR_TYPES.COMPANY_PLAN_ASSOCIATED_COMPANIES_ATTACHMENT_LEGAL_NAME_GREATER_THAN_ALLOWED,
    UNPROCESSABLE_ENTITY_ERROR_TYPES.COMPANY_PLAN_ASSOCIATED_COMPANIES_ATTACHMENT_LEGAL_NAME_MISSING,
  ];

  const isAssociatedCompaniesAttachmentError = error?.details?.some((detail: { type: string }) =>
    associatedCompaniesAttachmentErrorTypes.includes(detail.type),
  );
  return !!isAssociatedCompaniesAttachmentError;
};

function EditCompanyPlan() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [companyPlan, setCompanyPlan] = useState<CompanyPlanDetails>();

  const [error, setError] = useState<HttpError | undefined>(undefined);
  const [associatedCompaniesAttachmentError, setAssociatedCompaniesAttachmentError] =
    useState<HttpError>();
  const [
    associatedCompaniesAttachmentErrorModalVisible,
    setAssociatedCompaniesAttachmentErrorModalVisible,
  ] = useState(false);

  const [selectedProgram, setSelectedProgram] = useState<Program>({} as Program);
  const [isProgramModalVisible, setProgramModalVisible] = useState(false);

  const [cnpjFileData, setCnpjFileData] = useState<UploadedFileData[]>([]);
  const [communicationDocumentFileData, setCommunicationDocumentFileData] = useState<
    UploadedFileData[]
  >([]);
  const [cnpjUploadedFileId, setCnpjUploadedFileId] = useState<string>();
  const [communicationDocumentUploadedFileId, setCommunicationDocumentUploadedFileId] =
    useState<string>();

  const [cnpjUploadLoading, setCnpjUploadLoading] = useState(false);
  const [communicationDocumentUploadLoading, setCommunicationDocumentUploadLoading] =
    useState(false);

  const [programs, setPrograms] = useState<Program[]>([]);

  const [fileDataLoading, setFileDataLoading] = useState(false);
  const [programsLoading, setProgramsLoading] = useState(false);
  const [companyPlanLoading, setCompanyPlanLoading] = useState(false);
  const [finishLoading, setFinishLoading] = useState(false);
  const [form] = Form.useForm();
  const { user } = useUser();
  const currentSelectedFiscalYear = Form.useWatch(PLAN_FORM_FIELDS.FISCAL_YEAR, form)?.key;

  const onCloseProgramModal = () => {
    setProgramModalVisible(false);
  };

  // CNPJ UPLOAD
  const handleCnpjUploadChange = async (newFile: UploadedFileData) => {
    setCnpjUploadLoading(true);
    if (newFile.size! > MAX_CNPJ_FILE_SIZE) {
      notification.error({ message: i18n.ptBR.FILE_SIZE_NOTIFICATION(MAX_CNPJ_FILE_SIZE_KBS) });
      setCnpjUploadLoading(false);

      return;
    }

    try {
      const response = await uploadAttachments(newFile.originFileObj!);
      setCnpjUploadedFileId(response?.[0]?.id);
      notification.success({
        message: i18n.ptBR.FILE_SENDED,
      });
      setCnpjFileData([newFile]);
    } catch (err) {
      if (err instanceof HttpError) {
        setError(err);
      }
    }
    setCnpjUploadLoading(false);
  };
  const handleCnpjUploadDelete = async (removedFile: UploadedFileData) => {
    setCnpjUploadLoading(true);
    try {
      // if a file was upload before, delete it from server
      if (
        cnpjUploadedFileId &&
        cnpjUploadedFileId !== companyPlan?.associatedCompaniesAttachment?.uploadedFileId
      )
        await removeAttachment(cnpjUploadedFileId);

      setCnpjUploadedFileId(undefined);
      setCnpjFileData((prevFileList) =>
        prevFileList.filter((file) => file.uid !== removedFile.uid),
      );
    } catch (err) {
      if (err instanceof HttpError) {
        setError(err);
      }
    }
    setCnpjUploadLoading(false);
  };
  // CNPJ UPLOAD

  // PLAN DOCUMENT UPLOAD
  const handleCommunicationDocumentUploadChange = async (newFile: UploadedFileData) => {
    setCommunicationDocumentUploadLoading(true);
    if (newFile.size! > MAX_COMMUNICATION_DOCUMENT_FILE_SIZE) {
      notification.error({
        message: i18n.ptBR.FILE_SIZE_NOTIFICATION(MAX_COMMUNICATION_DOCUMENT_FILE_SIZE_KBS),
      });
      setCommunicationDocumentUploadLoading(false);

      return;
    }

    try {
      const response = await uploadAttachments(newFile.originFileObj!);
      setCommunicationDocumentUploadedFileId(response?.[0]?.id);
      notification.success({
        message: i18n.ptBR.FILE_SENDED,
      });
      setCommunicationDocumentFileData([newFile]);
    } catch (err) {
      if (err instanceof HttpError) {
        setError(err);
      }
    }
    setCommunicationDocumentUploadLoading(false);
  };

  const handleCommunicationDocumentUploadDelete = async (removedFile: UploadedFileData) => {
    setCommunicationDocumentUploadLoading(true);
    try {
      // if a file was upload before, delete it from server
      if (
        communicationDocumentUploadedFileId &&
        communicationDocumentUploadedFileId !==
          companyPlan?.marketingCampaignAttachment?.uploadedFileId
      )
        await removeAttachment(communicationDocumentUploadedFileId);

      setCommunicationDocumentUploadedFileId(undefined);
      setCommunicationDocumentFileData((prevFileList) =>
        prevFileList.filter((file) => file.uid !== removedFile.uid),
      );
    } catch (err) {
      if (err instanceof HttpError) {
        setError(err);
      }
    }
    setCommunicationDocumentUploadLoading(false);
  };

  // PLAN DOCUMENT UPLOAD

  type FormValues = {
    acceptTerms: boolean;
    cpf: string;
    email: string;
    fiscalYear: number;
    name: string;
    phone: string;
    planName: string;
    surname: string;
    marketingInvestmentPrediction: number;
  };

  const handleFinish = async (values: FormValues, updatedPrograms: Program[]) => {
    const plans: Plan[] = [];

    updatedPrograms.forEach((program) => {
      program.plans.forEach((plan) => {
        // backend sets value to '' in some cases, so this assure to be null
        let newReverseLogisticsWebsite: string | null = plan.reverseLogisticsWebsite ?? '';
        if (newReverseLogisticsWebsite.length === 0) newReverseLogisticsWebsite = null;

        if (plan.fiscalYear === currentSelectedFiscalYear) {
          return plans.push({
            id: plan.id,
            reverseLogisticsWebsite: newReverseLogisticsWebsite,
            programWasteTypes: plan.programWasteTypes,
            geographicGoalAttachmentId:
              (plan.geographicGoalAttachmentId || plan.geographicGoalAttachment?.uploadedFileId) ??
              null,
          });
        }
        return null;
      });
    });
    setFinishLoading(true);
    const payload = {
      marketingInvestmentPrediction: values.marketingInvestmentPrediction || null,
      name: values.planName,
      contact: {
        name: values.name || null,
        surname: values.surname || null,
        cpf: (values.cpf && sanitizeInputValue(values.cpf)) || null,
        email: values.email || null,
        phone: values.phone || null,
      },
      associatedCompaniesAttachmentId:
        user!.company!.companyTypeId === UserCompanyType.MANUFACTURERS_MANAGER
          ? cnpjUploadedFileId || null
          : undefined,
      marketingCampaignAttachmentId: communicationDocumentUploadedFileId || null,
      companyTypeId: user!.company!.companyTypeId,
      plans,
    };
    try {
      await editCompanyPlan(payload, id as string);

      navigate(routes.LIST_PLANS);
      notification.success({
        message: i18n.ptBR.SUCCESS_NOTIFICATION.MESSAGE,
        btn: (
          <Button
            type="stroke"
            onClick={() => navigate(routes.VIEW_COMPANY_PLAN.replace(':id', id as string))}
            text={i18n.ptBR.BUTTONS.VIEW_COMPANY_PLAN}
          />
        ),
      });
    } catch (err) {
      if (err instanceof HttpError) {
        const isAssociatedCompaniesAttachmentError = getIsAssociatedCompaniesAttachmentError(err);

        if (isAssociatedCompaniesAttachmentError) {
          setAssociatedCompaniesAttachmentError(err);
          setAssociatedCompaniesAttachmentErrorModalVisible(true);
        } else {
          setError(err);
        }
      }
    }
    setFinishLoading(false);
  };

  useEffect(() => {
    const fetchPrograms = async () => {
      setProgramsLoading(true);
      try {
        const response = await getUserPrograms();
        setPrograms(response);
      } catch (err) {
        if (err instanceof HttpError) {
          setError(err);
        }
      }
      setProgramsLoading(false);
    };
    const fetchPlanDetails = async () => {
      setCompanyPlanLoading(true);
      if (!id) throw new Error('Plan id is required');
      try {
        const response = await getOneCompanyPlan(id);

        setCnpjUploadedFileId(response.associatedCompaniesAttachment?.uploadedFileId);
        setCommunicationDocumentUploadedFileId(
          response.marketingCampaignAttachment?.uploadedFileId,
        );
        setCompanyPlan(response);
        setCompanyPlanLoading(false);
      } catch (err) {
        if (err instanceof HttpError) setError(err);
      }
      setCompanyPlanLoading(false);
    };
    const fetchAttachmentDetails = async (
      uploadedFileId: string,
      setFileState: React.Dispatch<React.SetStateAction<UploadedFileData[]>>,
    ) => {
      setFileDataLoading(true);
      try {
        const response = await getOneAttachment(uploadedFileId);

        setFileState([
          {
            id: response.id,
            name: response.originalName,
            type: response.type,
            file: response.file,
            status: 'done',
          },
        ] as UploadedFileData[]);
      } catch (err) {
        if (err instanceof HttpError) {
          setError(err);
        }
      }
      setFileDataLoading(false);
    };
    fetchPrograms();
    fetchPlanDetails();
    if (companyPlan?.associatedCompaniesAttachment?.uploadedFileId) {
      fetchAttachmentDetails(
        companyPlan?.associatedCompaniesAttachment?.uploadedFileId,
        setCnpjFileData,
      );
    }
    if (companyPlan?.marketingCampaignAttachment?.uploadedFileId) {
      fetchAttachmentDetails(
        companyPlan?.marketingCampaignAttachment?.uploadedFileId,
        setCommunicationDocumentFileData,
      );
    }
  }, [
    companyPlan?.associatedCompaniesAttachment?.uploadedFileId,
    companyPlan?.marketingCampaignAttachment?.uploadedFileId,
    id,
  ]);

  const handleDownloadFile = async (file: UploadedFileData) => {
    downloadFile({
      file: file.file as Blob,
      type: file.type,
      originalName: file.name as string,
    });
  };

  const handleConfigureProgram = (program: Program) => {
    if (!currentSelectedFiscalYear) {
      notification.error({
        message: i18n.ptBR.SELECT_FISCAL_YEAR.MESSAGE,
        description: i18n.ptBR.SELECT_FISCAL_YEAR.DESCRIPTION,
      });
      return;
    }

    setSelectedProgram(program);
    setProgramModalVisible(true);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeProgramData = (values: any) => {
    const residueTypeList = values[PROGRAM_FORM_FIELDS.RESIDUE_LIST] ?? [];

    const newProgramWasteTypes = residueTypeList.map((item: ResidueType) => ({
      programWasteTypeId: item.residue.id,
      totalWeightSold: item.quantity || null,
      weightGoal: item.declaredGoal || null,
    }));

    const missingWasteTypes = selectedProgram.wasteTypes.filter(
      (item) => !residueTypeList.find((residue: ResidueType) => residue.residue.id === item.id),
    );

    // add missing residue types, backend expects all of them, even if they are not used (totalWeightSold = 0)
    missingWasteTypes.forEach((item) => {
      newProgramWasteTypes.push({
        programWasteTypeId: item.id,
        totalWeightSold: null,
        weightGoal: null,
      });
    });

    const oldProgramPlan = selectedProgram.plans.find(
      (plan) => plan.fiscalYear === currentSelectedFiscalYear,
    );

    const oldProgramPlanIndex = selectedProgram.plans.findIndex(
      (plan) => plan.fiscalYear === currentSelectedFiscalYear,
    );

    const geographicGoalAttachmentId =
      values[PROGRAM_FORM_FIELDS.GEOGRAPHIC_GOAL_ATTACHMENT_ID]?.length > 0
        ? values[PROGRAM_FORM_FIELDS.GEOGRAPHIC_GOAL_ATTACHMENT_ID]
        : null;

    const newPlans = [...selectedProgram.plans];
    newPlans[oldProgramPlanIndex!] = {
      ...oldProgramPlan,
      geographicGoalAttachment: undefined,
      programWasteTypes: newProgramWasteTypes,
      geographicGoalAttachmentId,
      reverseLogisticsWebsite: values[PROGRAM_FORM_FIELDS.REVERSE_LOGISTICS_LINK] ?? null,
    };

    const updatedProgram: Program = {
      ...selectedProgram,
      plans: newPlans,
    };

    setPrograms((prev) => {
      const newPrograms = [...prev!];
      const index = newPrograms.findIndex((p) => p.id === updatedProgram.id);
      newPrograms[index] = updatedProgram;
      return newPrograms;
    });
  };

  return (
    <ErrorHandler error={error}>
      <Page className={styles.container} browserTabName={i18n.ptBR.PAGE_TITLE}>
        <Form.Provider
          onFormFinish={(name, { values }) => {
            if (name === FORM_NAMES.NEW_PLAN) {
              handleFinish(values as FormValues, programs);
            }

            if (name === FORM_NAMES.CONFIGURE_PROGRAM) {
              onChangeProgramData(values);
              setProgramModalVisible(false);
            }
          }}
        >
          <Content
            onDownloadFile={handleDownloadFile}
            cnpjFileData={cnpjFileData}
            onCnpjUploadChange={handleCnpjUploadChange}
            onCnpjUploadDelete={handleCnpjUploadDelete}
            communicationDocumentFileData={communicationDocumentFileData}
            onCommunicationDocumentUploadChange={handleCommunicationDocumentUploadChange}
            onCommunicationDocumentUploadDelete={handleCommunicationDocumentUploadDelete}
            cnpjUploadLoading={cnpjUploadLoading}
            communicationDocumentUploadLoading={communicationDocumentUploadLoading}
            form={form}
            finishLoading={finishLoading}
            programs={programs}
            loading={programsLoading || companyPlanLoading || fileDataLoading}
            companyPlan={companyPlan}
            onConfigureProgram={handleConfigureProgram}
          />
          <ConfigureProgramFormModal
            program={selectedProgram}
            onCancel={onCloseProgramModal}
            programWasteTypes={
              companyPlan?.plans.find((plan) => selectedProgram?.id === plan?.program.id)
                ?.programWasteTypes
            }
            isProgramModalVisible={isProgramModalVisible}
            selectedFiscalYear={currentSelectedFiscalYear}
          />
        </Form.Provider>
      </Page>

      <AssociatedCompaniesAttachmentErrorModal
        visible={associatedCompaniesAttachmentErrorModalVisible}
        error={associatedCompaniesAttachmentError}
        onCancel={() => setAssociatedCompaniesAttachmentErrorModalVisible(false)}
      />
    </ErrorHandler>
  );
}

export default EditCompanyPlan;
