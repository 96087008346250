import { ReactNode } from 'react';
import { Helmet } from 'react-helmet';

import Icon from '../icon';

import i18n from './i18n';
import styles from './index.module.css';
import sinirPlusLogo from '../../assets/logos/logo-sinir-plus.svg';
import userManualPdf from '../../assets/documents/Manual_do_Usuario_-_SINIR_LR_-_PERIODO_ELEITORAL.pdf';
import { APP_SUPPORT_CONTACT_EMAIL } from '../../enums';

type Props = {
  browserTabName: string;
  children: ReactNode;
};

export default function AccessPage({ browserTabName, children }: Props) {
  return (
    <>
      <Helmet>
        <title>{browserTabName}</title>
      </Helmet>

      <div className={styles.pageContainer}>
        <aside>
          <div className={styles.sidebar}>
            <img src={sinirPlusLogo} alt={i18n.ptBR.SINIR_PLUS_LOGO_ALT} className={styles.logo} />

            <div className={styles.sidebarContent}>
              <p className={styles.supportSectionTitle}>{i18n.ptBR.SUPPORT_SECTION.TITLE}</p>

              <div className={styles.supportBox} style={{ marginBottom: '16px' }}>
                <p className={styles.supportBoxTitle}>{i18n.ptBR.SUPPORT_SECTION.ABOUT_APP}</p>
                <p className={styles.supportBoxContent}>{APP_SUPPORT_CONTACT_EMAIL}</p>
              </div>

              <a
                href={userManualPdf}
                target="_blank"
                rel="noreferrer"
                className={styles.downloadUserManual}
              >
                <Icon name="pdf" style={{ marginRight: '8px' }} />
                {i18n.ptBR.DOWNLOAD_USER_MANUAL}
              </a>
            </div>
          </div>
        </aside>

        <main className={styles.main}>
          <section className={styles.mainContent}>{children}</section>
        </main>
      </div>
    </>
  );
}
