import AccessPage from '../../../common-components/access-page';
import Form, { FormItem } from '../../../common-components/form';
import Input, { InputPassword } from '../../../common-components/input';
import Button from '../../../common-components/button';
import Divider from '../../../common-components/divider';

import i18n from './i18n';
import styles from './index.module.css';
import { FormData, FormFields } from '../enums';
import routes from '../../../routes';

type Props = {
  loading: boolean;
  onFinish: (formValues: FormData) => void;
};

export default function Content({ loading, onFinish }: Props) {
  return (
    <AccessPage browserTabName={i18n.ptBR.BROWSER_TAB_NAME}>
      <header className={styles.header}>
        <h1 className={styles.title}>{i18n.ptBR.TITLE}</h1>
        <small className={styles.helpText}>{i18n.ptBR.HELP_TEXT}</small>
      </header>

      <Form variant="ghost" onFinish={onFinish}>
        <FormItem
          name={FormFields.EMAIL}
          label={i18n.ptBR.FORM_FIELDS.EMAIL.LABEL}
          rules={[
            {
              required: true,
              type: 'email',
              message: i18n.ptBR.FORM_FIELDS.EMAIL.VALIDATION_MESSAGE,
            },
          ]}
        >
          <Input />
        </FormItem>

        <FormItem
          name={FormFields.PASSWORD}
          label={i18n.ptBR.FORM_FIELDS.PASSWORD.LABEL}
          rules={[{ required: true, message: i18n.ptBR.FORM_FIELDS.PASSWORD.VALIDATION_MESSAGE }]}
        >
          <InputPassword />
        </FormItem>

        <Button
          type="text"
          text={i18n.ptBR.RECOVERY_PASSWORD_BUTTON}
          href={routes.PASSWORD_RECOVERY}
          className={styles.recoveryButton}
        />

        <Button
          text={i18n.ptBR.SUBMIT_BUTTON}
          htmlType="submit"
          className={styles.submitButton}
          block
          loading={loading}
        />
      </Form>

      <Divider type="line" className={styles.divider} />

      <div>
        <small className={`${styles.helpText} ${styles.signUpHelpText}`}>
          {i18n.ptBR.SIGN_UP.HELP_TEXT}
        </small>
        <Button
          type="stroke"
          text={i18n.ptBR.SIGN_UP.LINK}
          href={routes.SIGN_UP}
          className={styles.signUpLink}
          block
        />
      </div>
    </AccessPage>
  );
}
