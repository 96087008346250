import { ComponentPropsWithoutRef } from 'react';
import i18n from './i18n';

export default function Loader({ style, ...restProps }: ComponentPropsWithoutRef<'div'>) {
  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ...style }}
      {...restProps}
    >
      <span>{i18n.ptBR.LOADING}</span>
    </div>
  );
}
