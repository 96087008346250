import type { CSSProperties, ReactNode } from 'react';
import Icon from '../icon';
import icons from '../icon/icons';
import styles from './index.module.css';

type AlertProps = {
  type: 'warning' | 'error' | 'success' | 'admin';
  message: ReactNode;
  style?: CSSProperties;
  iconName?: keyof typeof icons;
  className?: string;
  iconColor?: string;
};
function Alert({ type, message, iconName, style, iconColor, className = '' }: AlertProps) {
  let typeClassName;
  switch (type) {
    case 'error':
      typeClassName = styles.error;
      break;

    case 'warning':
      typeClassName = styles.warning;
      break;

    case 'success':
      typeClassName = styles.success;
      break;
    case 'admin':
      typeClassName = styles.admin;
      break;
    default:
      break;
  }
  return (
    <div style={{ ...style }} className={`${styles.container} ${typeClassName} ${className}`}>
      {iconName && <Icon color={iconColor} size={16} name={iconName} />}
      <span className={styles.message}>{message}</span>
    </div>
  );
}

export default Alert;
