import BigNumber from 'bignumber.js';
import { Space, Form as AntdForm } from 'antd';
import { useEffect, useState } from 'react';

import ResidueList from './residue-list';

import Alert from '../../../common-components/alert';
import Button from '../../../common-components/button';
import Divider from '../../../common-components/divider';
import DraggerUpload, { UploadedFile } from '../../../common-components/dragger-upload';
import Form, { FormItem } from '../../../common-components/form';
import Input from '../../../common-components/input';
import Icon from '../../../common-components/icon';
import ErrorHandler from '../../../common-components/error-handler';
import pevsTemplate from '../../../assets/documents/TEMPLATE_PEVS.xlsx';

import styles from './index.module.css';
import { FORM_FIELDS } from './enums';
import i18n from './i18n';
import { Program } from '../../../modules/programs/types';
import Modal from '../../../common-components/modal';
import { FORM_NAMES } from '../../plans/new-plan/enums';
import { ProgramImages } from '../../../modules/programs/enums';
import {
  getOneAttachment,
  removeAttachment,
  uploadAttachments,
} from '../../../modules/attachment/service';
import HttpError from '../../../cross-cutting/errors/http-error';
import convertBoldTextToJsx from '../../../cross-cutting/utils/convert-bold-text-to-jsx';
import { DOCUMENT_TEMPLATE_NAMES, VALID_PEVS_FILE_FORMATS } from '../../../modules/plans/enums';
import formatWeight from '../../../cross-cutting/utils/format-weight';
import { PROGRAMS_IDS } from '../../../enums';

type ProgramFormProps = {
  program: Program;
  onCancel?: () => void;
  isProgramModalVisible: boolean;
  selectedFiscalYear: number;
  isViewPage?: boolean;
  programWasteTypes?:
    | {
        id: number;
        name: string;
        totalWeightSold: string;
        weightGoal: string;
        overdueAmount: string | null;
      }[]
    | undefined;
};

export default function ProgramForm({
  program,
  onCancel,
  isProgramModalVisible,
  selectedFiscalYear,
  isViewPage,
  programWasteTypes,
}: ProgramFormProps) {
  const [pevsAttachments, setPevsAttachments] = useState<
    UploadedFile[] | { name: string; status: string }[] | undefined
  >([]);
  const [pevsAttachmentsLoading, setPevsAttachmentsLoading] = useState(false);
  const [form] = AntdForm.useForm();
  const [error, setError] = useState<HttpError | undefined>(undefined);
  const programPlan = program?.plans?.find((plan) => plan.fiscalYear === selectedFiscalYear);
  const programGoals = program?.fiscalYears?.find(
    (programFiscalYear) => programFiscalYear.fiscalYear === selectedFiscalYear,
  );

  const handlePevsUploadChange = async (file: UploadedFile) => {
    setPevsAttachmentsLoading(true);
    try {
      const { originFileObj: originalFile } = file;

      const [uploadedFile] = await uploadAttachments(originalFile);

      setPevsAttachments([file]);
      form.setFieldsValue({
        [FORM_FIELDS.GEOGRAPHIC_GOAL_ATTACHMENT_ID]: uploadedFile.id,
      });
    } catch (err) {
      if (err instanceof HttpError) {
        setError(err);
      }
    }
    setPevsAttachmentsLoading(false);
  };

  const handlePevsUploadDelete = async () => {
    setPevsAttachmentsLoading(true);
    try {
      const id = form.getFieldValue(FORM_FIELDS.GEOGRAPHIC_GOAL_ATTACHMENT_ID);

      // if a file was upload before, delete it from server
      if (id !== programPlan?.geographicGoalAttachment?.uploadedFileId) await removeAttachment(id);
      setPevsAttachments(undefined);
      form.setFieldsValue({
        [FORM_FIELDS.GEOGRAPHIC_GOAL_ATTACHMENT_ID]: undefined,
      });
    } catch (err) {
      if (err instanceof HttpError) {
        setError(err);
      }
    }
    setPevsAttachmentsLoading(false);
  };

  const isQuantityGoalAchieved =
    programPlan?.programWasteType?.totalWeightSold === programPlan?.programWasteType?.weightGoal;

  const isGeographicGoalAchieved =
    programPlan?.geographicGoalAttachment?.uploadedFileId ||
    programPlan?.geographicGoalAttachmentId;

  const isConfigured =
    program?.id !== PROGRAMS_IDS.BATERIES_OF_ACID_LEAD
      ? isQuantityGoalAchieved && isGeographicGoalAchieved
      : isQuantityGoalAchieved;

  useEffect(() => {
    setPevsAttachments(
      isGeographicGoalAchieved
        ? [{ name: i18n.ptBR.TEMPLATE_NAMES.PEVS, status: 'done' }]
        : undefined,
    );
    form.resetFields();
    if (programPlan) {
      form.setFieldsValue({
        [FORM_FIELDS.RESIDUE_LIST]: programPlan?.programWasteTypes?.map((initialWaste) => ({
          quantity: initialWaste.totalWeightSold,
          declaredGoal: initialWaste.weightGoal,
          residue: {
            id: initialWaste.programWasteTypeId,
            name: program.wasteTypes.find((wt) => wt.id === initialWaste.programWasteTypeId)?.name,
          },
        })),
        [FORM_FIELDS.REVERSE_LOGISTICS_LINK]: programPlan?.reverseLogisticsWebsite,
        [FORM_FIELDS.GEOGRAPHIC_GOAL_ATTACHMENT_ID]:
          programPlan.geographicGoalAttachmentId ||
          programPlan.geographicGoalAttachment?.uploadedFileId,
      });
    }
  }, [
    form,
    programPlan?.programWasteTypes,
    programPlan?.reverseLogisticsWebsite,
    programPlan,
    program?.wasteTypes,
    isGeographicGoalAchieved,
  ]);
  const onDownloadFile = async () => {
    try {
      await getOneAttachment(isGeographicGoalAchieved as string, { download: true });
    } catch (err) {
      if (err instanceof HttpError) {
        setError(err);
      }
    }
  };

  return (
    <Modal
      visible={isProgramModalVisible}
      title={i18n.ptBR.PAGE_HEADING}
      onCancel={() => onCancel}
      width="800px"
      footer={null}
      isLoading={!program}
      destroyOnClose
    >
      <ErrorHandler error={error}>
        <Form name={FORM_NAMES.CONFIGURE_PROGRAM} layout="vertical" form={form}>
          <Space
            style={{ display: 'flex', flexDirection: 'column' }}
            direction="vertical"
            size={24}
          >
            <section
              className={styles.box}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                color: 'var(--blue-dark)',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={ProgramImages[program.id as keyof typeof ProgramImages]}
                  alt={program?.name}
                  style={{ width: '64px', height: '64px' }}
                />
                <span style={{ fontWeight: '700', fontSize: '24px' }}>{program?.name}</span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ alignSelf: 'flex-end' }}>{i18n.ptBR.STATUS}</span>
                <span style={{ fontWeight: '700' }}>
                  {isConfigured
                    ? i18n.ptBR.STATUS_OPTIONS.CONFIGURED
                    : i18n.ptBR.STATUS_OPTIONS.NOT_CONFIGURED}
                </span>
              </div>
            </section>
            <section className={styles.box}>
              <Space direction="vertical" size={8} style={{ width: '100%' }}>
                <span style={{ fontWeight: '500' }}>{i18n.ptBR.SECTIONS.GOALS}</span>
                <Alert
                  message={i18n.ptBR.PROGRAM_YEAR(
                    programGoals?.goalBaseYear ?? i18n.ptBR.PROGRAM_YEAR_NOT_AVAILABLE,
                  )}
                  type="success"
                  iconName="alertCircle"
                  style={{ padding: '8px 16px' }}
                />
                <ResidueList
                  residueOptions={program?.wasteTypes ?? []}
                  form={form}
                  goal={programGoals}
                  isViewPage={isViewPage}
                />
                {programWasteTypes?.map((wasteType) => {
                  if (wasteType.overdueAmount !== null) {
                    return (
                      <Alert
                        key="alert"
                        type="warning"
                        message={convertBoldTextToJsx(
                          i18n.ptBR.OVERDUE_AMOUNT_MESSAGE(
                            wasteType.name,
                            formatWeight(
                              new BigNumber(wasteType.overdueAmount).div(1000).toNumber() || 0,
                              'ton',
                              '.',
                            ),
                          ),
                        )}
                      />
                    );
                  }
                  return null;
                })}
              </Space>
            </section>
            {/* hide if programId === baterias de chumbo ácido */}
            {program?.id !== PROGRAMS_IDS.BATERIES_OF_ACID_LEAD && (
              <section className={styles.box}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '20px',
                    alignItems: 'center',
                  }}
                >
                  <span style={{ fontWeight: '500' }}>
                    <span>{i18n.ptBR.FORM_FIELDS.PEVS_TEMPLATES.LABEL.GEOGRAPHIC_GOAL}</span>
                    <Divider style={{ color: 'black' }} type="dot" />
                    <span>{i18n.ptBR.FORM_FIELDS.PEVS_TEMPLATES.LABEL.PEVS}</span>
                  </span>
                  <a download={DOCUMENT_TEMPLATE_NAMES.PEVS} href={pevsTemplate}>
                    <Button
                      icon={<Icon name="download" />}
                      text={i18n.ptBR.FORM_FIELDS.PEVS_TEMPLATES.BUTTON}
                    />
                  </a>
                </div>
                <AntdForm.Item shouldUpdate>
                  {() => {
                    return (
                      <FormItem name="geographicGoalAttachmentId">
                        <DraggerUpload
                          title={i18n.ptBR.FORM_FIELDS.PEVS_TEMPLATES.UPLOAD_TEXT}
                          formats={VALID_PEVS_FILE_FORMATS}
                          onRemove={handlePevsUploadDelete}
                          fileData={
                            isViewPage && isGeographicGoalAchieved
                              ? ([
                                  { name: i18n.ptBR.TEMPLATE_NAMES.PEVS, status: 'done' },
                                ] as unknown[])
                              : pevsAttachments
                          }
                          onFileChange={handlePevsUploadChange}
                          loading={pevsAttachmentsLoading}
                          maxCount={1}
                          onDownload={onDownloadFile}
                          onlyDisplayList={isViewPage}
                          showUploadList={{
                            showDownloadIcon: true,
                            downloadIcon: 'Download',

                            showRemoveIcon: !isViewPage,
                          }}
                        />
                      </FormItem>
                    );
                  }}
                </AntdForm.Item>
              </section>
            )}
            <section className={styles.box}>
              <span style={{ fontWeight: '500' }}>{i18n.ptBR.SECTIONS.WEBSITE_LINK}</span>

              <FormItem
                style={{ marginTop: '24px' }}
                name={FORM_FIELDS.REVERSE_LOGISTICS_LINK}
                label={i18n.ptBR.FORM_FIELDS.REVERSE_LOGISTIC_LINK.LABEL}
              >
                <Input disabled={isViewPage} style={{ backgroundColor: 'transparent' }} />
              </FormItem>
            </section>
            {!isViewPage && (
              <Alert
                type="warning"
                iconName="alertCircle"
                message={convertBoldTextToJsx(i18n.ptBR.ALERT_MESSAGES.WARNING, {
                  separator: '<b>',
                })}
                style={{ padding: '8px 16px', fontSize: '12px' }}
              />
            )}
            <Divider type="line" />

            <Space
              size={16}
              direction="horizontal"
              align="center"
              style={{ justifyContent: 'flex-end', width: '100%' }}
            >
              <Button
                type="text"
                onClick={() => {
                  onCancel?.();
                }}
                text={i18n.ptBR.RETURN_BUTTON}
                disabled={pevsAttachmentsLoading}
                loading={pevsAttachmentsLoading}
              />
              <Button
                onClick={() => {
                  if (isViewPage) {
                    onCancel?.();
                  }
                }}
                type="default"
                text={i18n.ptBR.SAVE_PROGRAM}
                htmlType="submit"
                disabled={pevsAttachmentsLoading}
                loading={pevsAttachmentsLoading}
              />
            </Space>
          </Space>
        </Form>
      </ErrorHandler>
    </Modal>
  );
}
