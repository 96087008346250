import { INTERNAL_ERROR_TYPES } from '../enums';

export default {
  TITLE: 'Oops! Existem dados inválidos no arquivo',

  ERROR_DESCRIPTIONS: {
    [INTERNAL_ERROR_TYPES.MISSING_OR_INVALID_DATA]: () =>
      'Identificamos que existem dados *ausentes* ou *inválidos* no arquivo anexado. Revise as linhas:',
    [INTERNAL_ERROR_TYPES.LEGAL_NAME_GREATER_THAN_ALLOWED]: (maxLength?: number) =>
      `Identificamos que há Razões Sociais *ultrapassando o limite de ${maxLength} caracteres* no arquivo anexado. Revise as linhas:`,
    AFTER_SOLVING: 'Após realizar as alterações, anexe o arquivo novamente.',
  },

  CLOSE_BUTTON: 'Voltar',
} as const;
