import { HTTP_STATUS_CODES, UNPROCESSABLE_ENTITY_ERROR_TYPES } from '../../../enums';

export default {
  TITLE: 'Opa! Parece que algo deu errado!',

  ERROR_BOUNDARY: {
    TITLE: 'Opa! Parece que um erro inesperado aconteceu!',
    DESCRIPTION:
      'Você pode tentar realizar a ação novamente, ou entrar em contato com o nosso suporte técnico.',
  },

  HTTP_ERRORS: {
    [HTTP_STATUS_CODES.NETWORK_ERROR]: {
      TITLE: 'Opa! Parece que algo deu errado!',
      DESCRIPTION:
        'Não foi possível completar sua requisição. Por favor verifique a sua conexão com a internet ou tente novamente mais tarde',
    },
    [HTTP_STATUS_CODES.BAD_REQUEST]: {
      TITLE: 'Opa! Parece que algo deu errado!',
      DESCRIPTION:
        'Tivemos um erro inesperado por aqui! Você pode tentar realizar a ação novamente, ou entrar em contato com o nosso suporte técnico',
    },
    [HTTP_STATUS_CODES.FORBIDDEN]: {
      TITLE: 'Opa! Parece que algo deu errado!',
      DESCRIPTION:
        'Parece que você não tem permissão suficiente para acessar esse recurso, por favor contate um administrador',
    },
    [HTTP_STATUS_CODES.NOT_FOUND]: {
      TITLE: 'Opa! Parece que algo deu errado!',
      DESCRIPTION: 'Infelizmente não conseguimos encontrar o recurso que você estava procurando',
    },
    [HTTP_STATUS_CODES.CONFLICT]: {
      TITLE: 'Opa! Parece que algo deu errado!',
      ERROR_TYPES: {},
    },
    [HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY]: {
      TITLE: 'Opa! Parece que algo deu errado!',
      DESCRIPTION:
        'Tivemos um erro inesperado por aqui! Você pode tentar realizar a ação novamente, ou entrar em contato com o nosso suporte técnico',
      ERROR_TYPES: {
        [UNPROCESSABLE_ENTITY_ERROR_TYPES.CREDENTIALS_ALREADY_REGISTERED]: {
          TITLE: 'Opa! Parece que algo deu errado!',
          DESCRIPTION: 'Já existe uma conta registrada com esse Email ou CNPJ.',
        },
        [UNPROCESSABLE_ENTITY_ERROR_TYPES.PROGRAM_NOT_FOUND]: {
          TITLE: 'Opa! Parece que algo deu errado!',
          DESCRIPTION: 'Não foi possível encontrar o sistema que você estava procurando',
        },
        [UNPROCESSABLE_ENTITY_ERROR_TYPES.FISCAL_YEAR_INVALID]: {
          TITLE: 'Opa! Parece que algo deu errado!',
          DESCRIPTION: 'O ano fiscal informado é inválido',
        },
        [UNPROCESSABLE_ENTITY_ERROR_TYPES.COMPANY_PLAN_FISCAL_YEAR_ALREADY_REGISTERED]: {
          TITLE: 'Opa! Parece que algo deu errado!',
          DESCRIPTION: 'Já existe um plano registrado para este ano fiscal',
        },
        [UNPROCESSABLE_ENTITY_ERROR_TYPES.COMPANY_PLAN_MISSING_INFO]: {
          TITLE: 'Opa! Parece que algo deu errado!',
          DESCRIPTION: 'Algumas informações do plano faltam ser preenchidas',
        },
        [UNPROCESSABLE_ENTITY_ERROR_TYPES.COMPANY_PLAN_PREVIOUS_COMPANY_PLAN_WAS_NOT_SENT]: {
          TITLE: 'Opa! Parece que algo deu errado!',
          DESCRIPTION: 'O plano do ano anterior a esse ainda não foi enviado',
        },
        [UNPROCESSABLE_ENTITY_ERROR_TYPES.COMPANY_PLAN_ALREADY_SENT]: {
          TITLE: 'Opa! Parece que algo deu errado!',
          DESCRIPTION: 'Este plano já foi enviado',
        },
        [UNPROCESSABLE_ENTITY_ERROR_TYPES.ATTACHMENT_ASSOCIATED_WITH_RESOURCE]: {
          TITLE: 'Opa! Parece que algo deu errado!',
          DESCRIPTION: 'Este anexo já está associado a um recurso',
        },
        [UNPROCESSABLE_ENTITY_ERROR_TYPES.COMPANY_PLAN_PREVIOUS_COMPANY_PLAN_DECLARATIONS_WERE_NOT_SENT]:
          {
            TITLE: 'Opa! Parece que algo deu errado!',
            DESCRIPTION: 'As declarações do plano do ano anterior a esse ainda não foram enviadas',
          },
        [UNPROCESSABLE_ENTITY_ERROR_TYPES.COMPLIANCE_CERTIFICATE_FISCAL_YEAR_NOT_FOUND]: {
          TITLE: 'Opa! Parece que algo deu errado!',
          DESCRIPTION:
            'Não foi possivel gerar a certidão de conformidade por que as datas de entrega do ano fiscal ainda não foram definidas no nosso sistema',
        },
        [UNPROCESSABLE_ENTITY_ERROR_TYPES.FISCAL_YEAR_REPORT_PERIOD_HAS_NOT_STARTED]: {
          TITLE: 'Opa! Parece que algo deu errado!',
          DESCRIPTION:
            'Não foi possivel realizar a declaração pois o período ainda não foi iniciado',
        },
        [UNPROCESSABLE_ENTITY_ERROR_TYPES.FISCAR_YEAR_DECLARATION_DEADLINE_HAS_PASSED]: {
          TITLE: 'Opa! Parece que algo deu errado!',
          DESCRIPTION: 'Não foi possivel realizar a declaração pois o período já foi finalizado',
        },
      },
    },
    [HTTP_STATUS_CODES.INTERNAL_SERVER_ERROR]: {
      TITLE: 'Opa! Parece que algo deu errado!',
      DESCRIPTION:
        'Tivemos um erro inesperado por aqui! Você pode tentar realizar a ação novamente, ou entrar em contato com o nosso suporte técnico',
    },
    [HTTP_STATUS_CODES.SERVICE_UNAVAILABLE]: {
      TITLE: 'Opa! Parece que algo deu errado!',
      DESCRIPTION:
        'Que pena, parece que nosso servidor está temporariamente indisponível, por favor tente novamente mais tarde',
    },
  },
};
