import { TablePaginationConfig } from 'antd';
import { FilterValue } from 'antd/lib/table/interface';
import { REGION } from '../../../../enums';

import { FilterItem } from '../../../../common-components/filter';
import PickList from '../../../../common-components/pick-list';
import columns from './columns';
import Input from '../../../../common-components/input';
import BreadcrumbItem from '../../../../common-components/breadcrumbs/breadcrumb-item';
import PageWithAddons from '../../../../common-components/page-with-addons';

import { Company } from '../../../../modules/company/types';
import i18n from './i18n';
import { COMPANY_TYPE_ID } from '../../../user-registration/form/enums';
import { FIELDS } from '../enums';
import { FilterType, Sorter } from '../types';
import styles from '../index.module.css';
import routes from '../../../../routes';
import { ProgramNamesUrl } from '../../../../modules/programs/enums';

type ContentProps = {
  loading: boolean;
  companies: Company[];
  totalCount: number;
  currentPage: number;
  onPageChange: (newPage: number) => void;
  pageSize: number;
  onFilter: (filters: FilterType) => void;
  onOrdinationChange: (sorter: Sorter) => void;
  programId?: string;
};

export default function Content({
  loading,
  companies,
  currentPage,
  onPageChange,
  totalCount,
  pageSize,
  onFilter,
  onOrdinationChange,
  programId,
}: ContentProps) {
  const companyTypeIdFilterOptions = [
    {
      key: COMPANY_TYPE_ID.MANUFACTURER,
      value: i18n.ptBR.COLUMNS.COMPANY_TYPE[COMPANY_TYPE_ID.MANUFACTURER],
    },
    {
      key: COMPANY_TYPE_ID.MANUFACTURER_MANAGER,
      value: i18n.ptBR.COLUMNS.COMPANY_TYPE[COMPANY_TYPE_ID.MANUFACTURER_MANAGER],
    },
  ];

  const regionFilterOptions = [
    {
      key: REGION.NORTHEAST,
      value: i18n.ptBR.FILTERS.REGION.OPTIONS[REGION.NORTHEAST],
    },
    {
      key: REGION.NORTH,
      value: i18n.ptBR.FILTERS.REGION.OPTIONS[REGION.NORTH],
    },
    {
      key: REGION.MIDWEST,
      value: i18n.ptBR.FILTERS.REGION.OPTIONS[REGION.MIDWEST],
    },
    {
      key: REGION.SOUTHEAST,
      value: i18n.ptBR.FILTERS.REGION.OPTIONS[REGION.SOUTHEAST],
    },
    {
      key: REGION.SOUTH,
      value: i18n.ptBR.FILTERS.REGION.OPTIONS[REGION.SOUTH],
    },
  ];

  const filters = [
    <FilterItem
      key={FIELDS.COMPANY_TYPE_ID}
      label={i18n.ptBR.FILTERS.COMPANY_TYPE_ID.LABEL}
      name={FIELDS.COMPANY_TYPE_ID}
    >
      <PickList
        placeholder={i18n.ptBR.FILTERS.COMPANY_TYPE_ID.PLACEHOLDER}
        options={companyTypeIdFilterOptions}
        allowClear
      />
    </FilterItem>,
    <FilterItem key={FIELDS.REGION} label={i18n.ptBR.FILTERS.REGION.LABEL} name={FIELDS.REGION}>
      <PickList
        allowClear
        placeholder={i18n.ptBR.FILTERS.REGION.PLACEHOLDER}
        options={regionFilterOptions}
      />
    </FilterItem>,
  ];

  const tableFilters = (
    <FilterItem noStyle name={FIELDS.LEGAL_NAME_OR_CNPJ}>
      <Input
        placeholder={i18n.ptBR.FILTERS.LEGAL_NAME_OR_CNPJ.PLACEHOLDER}
        style={{ border: '0px', background: '#F8F8F8', width: '300px' }}
      />
    </FilterItem>
  );

  const programName =
    i18n.ptBR.PROGRAMS_NAME[programId as typeof ProgramNamesUrl[keyof typeof programId]];

  return (
    <PageWithAddons<Company>
      pageProps={{
        browserTabName: i18n.ptBR.BROWSER_TAB_NAME(programName),
        childrenClassName: styles.childrenClassName,
        breadcrumbs: [
          <BreadcrumbItem
            key={routes.LIST_PROGRAMS}
            title={programName}
            route={routes.VIEW_PROGRAM_COMPANIES.replace(
              ':id',
              programId as typeof ProgramNamesUrl[keyof typeof programId],
            )}
          />,
        ],
        title: programName,
      }}
      tableProps={{
        dataSource: companies,
        columns: columns(),
        rowKey: 'id',
        newDesign: true,
        onChange: (
          _pagination: TablePaginationConfig,
          _filter: Record<string, FilterValue | null>,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          sorter: any,
        ) => onOrdinationChange(sorter),
        totalItems: totalCount,
        currentPage,
        pagination: {
          disabled: false,
        },
        pageSize,
        loading,
        onPageChange,
        listTitle: i18n.ptBR.LIST_TITLE,
      }}
      tableFilters={tableFilters}
      filters={filters}
      onFilter={onFilter}
      enableNavigationModal
    />
  );
}
