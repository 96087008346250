import { Breadcrumb } from 'antd';

import routes from '../../routes';
import Home from '../../assets/icons/home.svg';
import nextCrumb from '../../assets/icons/next-crumb.svg';

import i18n from './i18n';
import styles from './breadcrumb-item/index.module.css';

export default function Breadcrumbs({ children = [] }: { children?: JSX.Element[] }) {
  const defaultBreadcrumbs = [
    <Breadcrumb.Item href={routes.HOME} key="home">
      <img src={Home} alt={i18n.ptBR.INITIAL_PAGE} />
      {children.length === 0 && (
        <span className={styles.title} style={{ marginLeft: '8px' }}>
          {i18n.ptBR.INITIAL_PAGE}
        </span>
      )}
    </Breadcrumb.Item>,
  ];

  const allBreadcrumbs = defaultBreadcrumbs.concat(children);

  return (
    <Breadcrumb separator={<img src={nextCrumb} alt={i18n.ptBR.NEXT_CRUMB} />}>
      {allBreadcrumbs}
    </Breadcrumb>
  );
}
