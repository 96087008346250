import { ColumnsType } from 'antd/lib/table';
import Button from '../../../../../common-components/button';
import Icon from '../../../../../common-components/icon';
import cnpjFormatter from '../../../../../cross-cutting/utils/cnpjFormatter';
import useFeatureFlags from '../../../../../cross-cutting/utils/use-feature-flags';
import { VIEW_COMPANY_DETAILS_FF } from '../../../../../feature-flags';

import { CompanyPlan } from '../../../../../modules/plans/types';
import routes from '../../../../../routes';
import { TABS } from '../../../../companies/view-company/enums';
import i18n from './i18n';

export default (): ColumnsType<CompanyPlan> => {
  const { featureEnabled } = useFeatureFlags();
  return [
    {
      title: i18n.ptBR.COLUMNS.LABELS.LEGAL_NAME,
      key: 'legalName',
      render: (_, { company }) => {
        return <span data-testid="legalName">{company.legalName}</span>;
      },
      sorter: {
        multiple: 1,
        compare: (a, b) => a.company.legalName.localeCompare(b.company.legalName),
      },

      width: '30%',
    },
    {
      title: i18n.ptBR.COLUMNS.LABELS.CNPJ,
      key: 'cnpj',
      render: (_, { company }) => {
        return <span data-testid="cnpj">{cnpjFormatter(company.cnpj)}</span>;
      },
    },
    {
      title: i18n.ptBR.COLUMNS.LABELS.STATE,
      key: 'state',
      render: (_, { company }) => {
        return (
          <span data-testid="state">
            {i18n.ptBR.COLUMNS.STATES[company.address.stateAbbreviation as never]}
          </span>
        );
      },
    },
    {
      title: i18n.ptBR.COLUMNS.LABELS.PLAN_TYPE,
      key: 'companyPlanTypeId',
      render: (_, { companyPlanTypeId }) => {
        return (
          <span data-testid="companyPlanTypeId">
            {i18n.ptBR.FILTERS.PLAN_TYPE.OPTIONS[companyPlanTypeId]}
          </span>
        );
      },
    },
    {
      title: i18n.ptBR.COLUMNS.LABELS.YEAR,
      key: 'year',
      sorter: {
        multiple: 1,
        compare: (a, b) => a.fiscalYear - b.fiscalYear,
      },
      render: (_, { fiscalYear }) => {
        return <span data-testid="fiscalYear">{fiscalYear}</span>;
      },
    },
    {
      title: i18n.ptBR.COLUMNS.LABELS.ACTIONS,
      key: 'actions',
      render: (_, { id, company }) => {
        return featureEnabled(VIEW_COMPANY_DETAILS_FF) ? (
          <Button
            href={routes.VIEW_COMPANY_DETAILS.replace(/:id/, company.id).concat(
              `?companyPlanId=${id}&redirectToTab=${TABS.REVERSE_LOGISTICS_PLANS}`,
            )}
            icon={<Icon name="eyeView" />}
            type="text"
          />
        ) : null;
      },
    },
  ];
};
