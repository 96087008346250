/* eslint-disable react/jsx-props-no-spreading */

import { Checkbox as AntdCheckbox, CheckboxProps } from 'antd';

import styles from './index.module.css';

type CheckboxComponentProps = {
  children?: React.ReactNode;
  className?: string;
};
function Checkbox({ children, className, ...props }: CheckboxProps & CheckboxComponentProps) {
  return (
    <AntdCheckbox className={`${styles.checkbox} ${className}`} {...props}>
      <span className={styles.checkboxText}>{children}</span>
    </AntdCheckbox>
  );
}

const { Group } = AntdCheckbox;
export { Group as CheckboxGroup };

export default Checkbox;

Checkbox.defaultProps = {
  className: '',
};
