import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Content from './content';

import ErrorHandler from '../../common-components/error-handler';
import sanitizeInputValue from '../../cross-cutting/utils/sanitize-input-value';

import HttpError from '../../cross-cutting/errors/http-error';
import { getAllPrograms } from '../../modules/programs/service';
import { PublicProgram } from '../../modules/programs/types';
import { createAccount, NewAccountProps } from '../../modules/user/service';
import { login } from '../../modules/authentication/service';
import routes from '../../routes';

export default function UserRegistration() {
  const navigate = useNavigate();

  const [error, setError] = useState<HttpError>();
  const [programs, setPrograms] = useState<PublicProgram[]>({} as PublicProgram[]);
  const [loading, setLoading] = useState(true);
  const [selectedPrograms, setSelectedPrograms] = useState<number[]>([]);
  const [finishing, setFinishing] = useState(false);

  const onProgramClick = (checked: boolean, programId: number) => {
    if (checked) {
      setSelectedPrograms([...selectedPrograms, programId]);
    } else {
      setSelectedPrograms(selectedPrograms.filter((id) => id !== programId));
    }
  };

  useEffect(() => {
    async function fetchPrograms() {
      setLoading(true);
      try {
        const response = await getAllPrograms();

        setPrograms(response.programs);
      } catch (err) {
        if (err instanceof HttpError) {
          setError(err);
        }
      }
      setLoading(false);
    }

    fetchPrograms();
  }, []);

  const handleFinish = async (values: NewAccountProps) => {
    setFinishing(true);
    try {
      const payload = {
        companyTypeId: values.companyTypeId,
        cnpj: sanitizeInputValue(values.cnpj),
        legalName: values.legalName,
        mainCnae: values.mainCnae,
        phone: values.phone,
        address: {
          postalCode: values.address.postalCode,
          streetName: values.address.streetName,
          city: values.address.city,
          neighborhood: values.address.neighborhood,
          stateAbbreviation: values.address.stateAbbreviation,
          number: values.address.number,
          complement: values.address.complement ?? '',
        },
        email: values.email,
        password: values.password,
        repeatPassword: values.repeatPassword,
        acceptedTermsOfService: values.acceptedTermsOfService,
        acceptedPrivacyPolicy: values.acceptedPrivacyPolicy,
        programIds: selectedPrograms,
      };

      await createAccount(payload);
      await login({
        username: values.email,
        password: values.password,
      });
      navigate(routes.HOME);
    } catch (err) {
      if (err instanceof HttpError) {
        setFinishing(false);
        setError(err);
      }
    }
    setFinishing(false);
  };

  return (
    <ErrorHandler error={error}>
      <Content
        loading={loading}
        handleFinish={handleFinish}
        programs={programs}
        onSelectProgram={onProgramClick}
        currentSelectedPrograms={selectedPrograms}
        finishing={finishing}
      />
    </ErrorHandler>
  );
}
