import type { FeatureFlagNames } from '.';
import * as featureFlagsEnum from '../../../feature-flags';

const getOperatingFeatureFlags = () => {
  // when there are no feature flag exports, `Object.values` types as `unknown[]`, but it should
  // be `never[]`
  const operatingFeatureFlags: FeatureFlagNames[] = Object.values(featureFlagsEnum);
  return operatingFeatureFlags;
};

export default getOperatingFeatureFlags;
