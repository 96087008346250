import { Space } from 'antd';
import BigNumber from 'bignumber.js';
import Dayjs from 'dayjs';

import Alert from '../../../../../../common-components/alert';
import Button from '../../../../../../common-components/button';
import Collapsible from '../../../../../../common-components/collapsible';
import Divider from '../../../../../../common-components/divider';
import DraggerUpload from '../../../../../../common-components/dragger-upload';
import Field from '../../../../../../common-components/field';
import Icon from '../../../../../../common-components/icon';
import Loader from '../../../../../../common-components/loader';
import Table from '../../../../../../common-components/table';
import columns from './columns';

import convertBoldTextToJsx from '../../../../../../cross-cutting/utils/convert-bold-text-to-jsx';
import { CompanyPlanDetails } from '../../../../../../modules/plans/types';
import i18n from './i18n';
import styles from './index.module.css';

type ViewDeclarationDetailsProps = {
  declaration?: CompanyPlanDetails;
  loading: boolean;
  onCancel: () => void;
  onDownloadFile: (file: { name: string; id: string; type: string }) => Promise<void>;
};

export default function ViewDeclarationDetails({
  declaration,
  loading,
  onCancel,
  onDownloadFile,
}: ViewDeclarationDetailsProps) {
  if (loading) return <Loader />;

  return (
    <div style={{ marginTop: '40px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span className={styles.planName}>{declaration?.name}</span>
        <Button
          style={{ marginLeft: 'auto' }}
          text={i18n.ptBR.BUTTONS.RETURN}
          onClick={() => onCancel()}
          icon={<Icon name="arrowLeft" />}
          newDesign
        />
      </div>

      <Collapsible
        style={{ margin: '30px 0' }}
        openIcon="chevronDown"
        closeIcon="chevronUp"
        title={
          <span className={styles.collapsibleTitle}>{i18n.ptBR.PLAN_DETAILS_FIELDS.TITLE}</span>
        }
        childrenClassname={styles.collapsibleChildrenClassname}
        defaultOpen
        className={styles.collapsible}
      >
        <Space size={80} direction="horizontal">
          <Field
            title={i18n.ptBR.PLAN_DETAILS_FIELDS.SENT_DATE}
            value={Dayjs(declaration?.reportedAt).format('L')}
          />
          <Field
            title={i18n.ptBR.PLAN_DETAILS_FIELDS.DECLARED_AT}
            value={Dayjs(declaration?.declaredAt).format('L')}
          />
          <Field
            title={i18n.ptBR.PLAN_DETAILS_FIELDS.PLAN_TYPE.LABEL}
            value={
              i18n.ptBR.PLAN_DETAILS_FIELDS.PLAN_TYPE.VALUES[declaration?.companyPlanTypeId || 1]
            }
          />
          <Field title={i18n.ptBR.PLAN_DETAILS_FIELDS.YEAR} value={declaration?.fiscalYear} />
        </Space>
      </Collapsible>

      {declaration?.plans.map((plan) => {
        const unitOfMeasure = plan?.program.fiscalYear?.goalUnitOfMeasureId;
        const goal = plan?.program?.fiscalYear?.goal;

        return plan.declaration?.declarationHasProgramWasteTypes?.map((planDeclaration) => {
          const wasteType = plan?.programWasteTypes?.find(
            ({ id }) => id === planDeclaration.programWasteTypeId,
          );

          if (!wasteType) {
            return null;
          }

          const amountCollected = new BigNumber(planDeclaration?.amountCollected)
            .div(1000)
            .toNumber();

          return (
            <Collapsible
              key={plan.id}
              style={{ margin: '30px 0' }}
              openIcon="chevronDown"
              closeIcon="chevronUp"
              title={<span className={styles.collapsibleTitle}>{plan.program.name}</span>}
              childrenClassname={styles.collapsibleChildrenClassname}
              className={styles.collapsible}
            >
              {plan?.program?.fiscalYear?.goalBaseYear && (
                <Alert
                  message={
                    <span>
                      {convertBoldTextToJsx(
                        i18n.ptBR.FIELDS.INFORMATION.TITLE(declaration?.fiscalYear),
                      )}
                    </span>
                  }
                  type="admin"
                  iconName="alertCircle"
                  style={{ marginBottom: '10px', padding: '16px 12px' }}
                />
              )}
              <Table<{
                id: number;
                name: string;
                overdueAmount: null | string;
                totalWeightSold: string;
                weightGoal: string;
              }>
                rowKey="id"
                newDesign
                dataSource={plan.programWasteTypes || []}
                columns={columns({
                  unitOfMeasure,
                  goal,
                  amountCollected,
                }).filter((a) => a.render)}
                totalItems={undefined}
                pagination={false}
              />

              <DraggerUpload
                newDesign
                loading={false}
                formats={['xls']}
                multiple={false}
                style={{ marginBottom: '10px' }}
                maxCount={1}
                fileData={[
                  {
                    id: plan.declaration?.declarationEvidenceAttachment?.uploadedFileId || '',
                    name:
                      plan.declaration?.declarationEvidenceAttachment?.uploadedFile?.originalName ||
                      '',
                    type:
                      plan.declaration?.declarationEvidenceAttachment?.uploadedFile?.extension ||
                      '',
                    status: 'done',
                  },
                ]}
                onlyDisplayList
                onDownload={onDownloadFile}
                showUploadList={{
                  showDownloadIcon: true,
                  downloadIcon: <Icon name="download" color="#1351B4" />,
                  showRemoveIcon: false,
                }}
              />
            </Collapsible>
          );
        });
      })}
      <Divider type="line" style={{ margin: '30px 0' }} />
      <Button
        style={{ marginLeft: 'auto' }}
        text={i18n.ptBR.BUTTONS.RETURN}
        onClick={() => onCancel()}
        icon={<Icon name="arrowLeft" />}
        newDesign
      />
    </div>
  );
}
