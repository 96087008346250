import { Input } from 'antd';
import type { InputProps } from 'antd';

import styles from '../index.module.css';

const AntdInputPassword = Input.Password;

function InputPassword({ className = '', ...props }: InputProps) {
  return (
    <AntdInputPassword
      {...props}
      className={`${styles.input} ${styles.inputPassword} ${className}`}
    />
  );
}

export default InputPassword;
