import BigNumber from 'bignumber.js';
/* eslint-disable no-unsafe-optional-chaining */
import { Drawer } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoSinir } from '../../../../assets/logos/logo-sinir-plus.svg';

import Icon from '../../../icon';
import CollapsibleMenu from '../../../collapsible-menu';
import Alert from '../../../alert';

import useUser from '../../../../modules/user/use-user';
import styles from './index.module.css';
import i18n from './i18n';
import routes from '../../../../routes';
import convertBoldTextToJsx from '../../../../cross-cutting/utils/convert-bold-text-to-jsx';
import useOnClickOutside from '../../../use-onclick-outside';
import enums, { LEGISLATION_LINK } from '../../../../enums';

import userGuide from '../../../../assets/documents/Manual_do_Usuario_-_SINIR_LR_-_PERIODO_ELEITORAL.pdf';
import privacyPolicy from '../../../../assets/documents/Politica_de_privacidade_Sistema-Logistica-Reversa_final.pdf';
import termsOfService from '../../../../assets/documents/Termo_de_Uso_Sistema_Logistica_Reversa_final.pdf';
import { ProgramNamesUrl } from '../../../../modules/programs/enums';
import { GetTotalAndFreeStorageIndicatorResponse } from '../../../../modules/indicators/types';

type ContentProps = {
  onClickLogout?: () => void;
  totalAndFreeStorageIndicator?: GetTotalAndFreeStorageIndicatorResponse;
};
export default function Content({ onClickLogout, totalAndFreeStorageIndicator }: ContentProps) {
  const { user } = useUser();

  const isLoggedIn = !!user;
  const [isDropDownVisible, setIsDropDownVisible] = useState(false);
  const [isMenuDrawerOpen, setIsMenuDrawerOpen] = useState(false);

  const onClose = () => {
    setIsMenuDrawerOpen(false);
  };

  const { ref: refDropDown } = useOnClickOutside(setIsDropDownVisible);

  return (
    <>
      <header className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.logoContainer}>
            <Link to={routes.HOME}>
              <LogoSinir style={{ width: 'auto', height: '40px' }} />
            </Link>
          </div>
          <div className={styles.menuContainer}>
            <a className={styles.menuItem} target="_blank" href={termsOfService} rel="noreferrer">
              {i18n.ptBR.BUTTON.TERMS_OF_SERVICE}
            </a>
            <a className={styles.menuItem} target="_blank" href={privacyPolicy} rel="noreferrer">
              {i18n.ptBR.BUTTON.PRIVACY_POLICY}
            </a>
            <a className={styles.menuItem} target="_blank" href={userGuide} rel="noreferrer">
              {i18n.ptBR.BUTTON.USER_GUIDE}
            </a>
            <a className={styles.menuItem} target="_blank" href={LEGISLATION_LINK} rel="noreferrer">
              {i18n.ptBR.BUTTON.LEGISLATION}
            </a>
            <div ref={refDropDown}>
              <div
                className={`${styles.divMenuDropDown} ${isDropDownVisible ? styles.active : ''}`}
                onClick={() => {
                  setIsDropDownVisible((prevIsDropdownVisible) => !prevIsDropdownVisible);
                }}
                tabIndex={0}
                role="button"
                onKeyDown={(e) => {
                  if (
                    e.keyCode === enums.KEYBOARD_CHAR_CODES.ENTER ||
                    e.keyCode === enums.KEYBOARD_CHAR_CODES.SPACE_BAR
                  ) {
                    setIsDropDownVisible((prevIsDropdownVisible) => !prevIsDropdownVisible);
                  }
                }}
              >
                <Icon color="#C5D4EB" name="avatar" />
                <span style={{ color: '#565C65' }}>
                  {convertBoldTextToJsx(
                    i18n.ptBR.HELLO_USER(user?.company?.legalName || user?.firstName),
                  )}
                </span>
                <Icon name="chevronDown" size={16} />
              </div>
              {onClickLogout && isLoggedIn && isDropDownVisible && (
                <div className={styles.dropdown}>
                  <div className={styles.dropdownItem}>
                    <button type="button" onClick={onClickLogout}>
                      <span>{i18n.ptBR.BUTTON.LOGOUT}</span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            margin: ' 0px 0px 40px 10px',
            gap: '18px',
            alignItems: 'center',
          }}
        >
          <div
            onKeyDown={(e) => {
              if (
                e.keyCode === enums.KEYBOARD_CHAR_CODES.ENTER ||
                e.keyCode === enums.KEYBOARD_CHAR_CODES.SPACE_BAR
              ) {
                setIsMenuDrawerOpen((prevValues) => !prevValues);
              }
            }}
            role="button"
            tabIndex={0}
            onClick={() => setIsMenuDrawerOpen((prevValues) => !prevValues)}
            style={{ cursor: 'pointer' }}
          >
            <Icon color="#1351B4" name="bars" />
          </div>
          <p style={{ color: '#333333', fontSize: '24px' }}>{i18n.ptBR.MENU_TITLE}</p>
        </div>
        <Drawer
          placement="left"
          title={i18n.ptBR.MENU_TITLE}
          onClose={onClose}
          visible={isMenuDrawerOpen}
          closeIcon={<Icon name="closeIcon" color="#1351B4" />}
          className={styles.drawer}
          headerStyle={{ color: '#1351B4' }}
        >
          <Link className={styles.collapsibleMenuItem} to={routes.DASHBOARD}>
            {i18n.ptBR.MENU_ITEMS.DASHBOARD}
          </Link>

          <CollapsibleMenu title={i18n.ptBR.MENU_ITEMS.REVERSE_LOGISTICS_PROGRAMS}>
            {ProgramNamesUrl.map((programUrl, index) => {
              return (
                <Link
                  style={{ color: '#333333' }}
                  key={programUrl}
                  to={routes.VIEW_PROGRAM_COMPANIES.replace(':id', programUrl)}
                >
                  {i18n.ptBR.PROGRAMS_NAME[index]}
                </Link>
              );
            })}
          </CollapsibleMenu>
          <CollapsibleMenu title={i18n.ptBR.MENU_ITEMS.PLANS}>
            {ProgramNamesUrl.map((programUrl, index) => {
              return (
                <Link
                  style={{ color: '#333333' }}
                  key={programUrl}
                  to={routes.ADMIN_LIST_PLANS.replace(':id', programUrl)}
                >
                  {i18n.ptBR.PROGRAMS_NAME[index]}
                </Link>
              );
            })}
          </CollapsibleMenu>
          <CollapsibleMenu title={i18n.ptBR.MENU_ITEMS.DECLARATIONS}>
            {ProgramNamesUrl.map((programUrl, index) => {
              return (
                <Link
                  style={{ color: '#333333' }}
                  key={programUrl}
                  to={routes.LIST_DECLARATIONS.replace(':id', programUrl)}
                >
                  {i18n.ptBR.PROGRAMS_NAME[index]}
                </Link>
              );
            })}
          </CollapsibleMenu>
          <Link className={styles.collapsibleMenuItem} to={routes.PLANS_AND_DECLARATIONS_REPORT}>
            {i18n.ptBR.PLANS_AND_DECLARATIONS_REPORT}
          </Link>
        </Drawer>
      </header>
      {totalAndFreeStorageIndicator && totalAndFreeStorageIndicator.free <= 20 && (
        <Alert
          iconColor="black"
          iconName="alertTriangle"
          type="warning"
          className={styles.storageAlert}
          message={
            <div style={{ color: 'black', gap: '16px', display: 'flex', flexDirection: 'column' }}>
              <p style={{ fontWeight: '600' }}>
                {i18n.ptBR.STORAGE_WARNING.TITLE(
                  new BigNumber(totalAndFreeStorageIndicator.total)
                    .minus(totalAndFreeStorageIndicator.free)
                    .div(totalAndFreeStorageIndicator.total)
                    .times(100)
                    .toFixed(2)
                    .toString(),
                )}
              </p>
              <p>
                {convertBoldTextToJsx(
                  i18n.ptBR.STORAGE_WARNING.MESSAGE(totalAndFreeStorageIndicator.free),
                )}
              </p>
            </div>
          }
        />
      )}
    </>
  );
}
