import { useMemo, useState } from 'react';
import type { ReactNode } from 'react';

import FeatureFlagsContext from '.';
import type { FeatureFlagsObject } from '.';
import getStorageValue from './get-storage-value';
import getDefaultValue from './get-default-value';
import getOperatingFeatureFlags from './get-operating-feature-flags';

const operatingFeatureFlags = getOperatingFeatureFlags();
const storageValue = getStorageValue();
const defaultValue = getDefaultValue(operatingFeatureFlags, storageValue);

type Props = {
  children: ReactNode;
};

export default function FeatureFlagsProvider({ children }: Props) {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlagsObject>(defaultValue);

  const contextValue = useMemo(
    () => ({
      featureFlags,
      setFeatureFlags,
    }),
    [featureFlags],
  );

  return (
    <FeatureFlagsContext.Provider value={contextValue}>{children}</FeatureFlagsContext.Provider>
  );
}
