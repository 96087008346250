import { useState } from 'react';

import ErrorHandler from '../../../../common-components/error-handler';
import Content, { FormValues } from './content';

import HttpError from '../../../../cross-cutting/errors/http-error';
import { requestResetPassword } from '../../../../modules/authentication/service';
import { HTTP_STATUS_CODES } from '../../../../enums';
import i18n from './content/i18n';

type Props = {
  code: string;
  email: string;
  onFinish: () => void;
};

function ChangePassword({ code, email, onFinish }: Props) {
  const [error, setError] = useState<HttpError>();

  const handleFinish = async (formValues: FormValues) => {
    const payload = {
      code,
      email,
      newPassword: formValues.password,
    };
    try {
      await requestResetPassword(payload);
      onFinish();
    } catch (err) {
      if (err instanceof HttpError) {
        setError(err);
      }
    }
  };

  const options = [
    {
      code: HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY,
      types: {
        [error?.details?.[0].type]: {
          title: i18n.ptBR.UNPROCESSABLE_ENTITY_ERROR_MESSAGES[error?.details?.[0].type]?.TITLE,
          description:
            i18n.ptBR.UNPROCESSABLE_ENTITY_ERROR_MESSAGES[error?.details?.[0].type]?.DESCRIPTION,
        },
      },
    },
  ];

  return (
    <ErrorHandler error={error} options={options}>
      <Content email={email} onFinish={handleFinish} />
    </ErrorHandler>
  );
}

export default ChangePassword;
