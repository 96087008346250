import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import ButtonMenu, { Item } from '../../../../common-components/table/button-menu';
import Divider from '../../../../common-components/divider';
import { TableCell } from '../../../../common-components/table';
import Tag from '../../../../common-components/tag';

import { CompanyPlan } from '../../../../modules/plans/types';
import routes from '../../../../routes';
import { PLAN_STATUS_COLORS } from '../enums';
import i18n from './i18n';
import styles from './index.module.css';
import { PLAN_STATUS_ID } from '../../../../modules/plans/enums';

export default (): ColumnsType<CompanyPlan> => {
  return [
    {
      title: i18n.ptBR.COLUMNS.LABELS.PLAN,
      key: 'name',
      width: '60%',
      render: (_, { name, fiscalYear }) => {
        const isFiscalYearCurrent = dayjs().isSame(fiscalYear.toString(), 'year');
        return isFiscalYearCurrent ? (
          <TableCell>
            {name}
            <Divider type="dot" className={styles.dot} />
            {i18n.ptBR.COLUMNS.PLAN.CURRENT_YEAR}
          </TableCell>
        ) : (
          <TableCell>{name}</TableCell>
        );
      },
    },
    {
      title: i18n.ptBR.COLUMNS.LABELS.YEAR,
      dataIndex: 'fiscalYear',
      key: 'fiscalYear',
      width: '15%',
    },
    {
      title: i18n.ptBR.COLUMNS.LABELS.STATUS,
      key: 'status',
      width: '15%',
      render: (_, { planStatusId }) => {
        const { color, backgroundColor } = PLAN_STATUS_COLORS[planStatusId];

        const tag = (
          <Tag
            text={i18n.ptBR.COLUMNS.STATUS[planStatusId]}
            color={color}
            backgroundColor={backgroundColor}
          />
        );

        return tag;
      },
    },
    {
      title: i18n.ptBR.COLUMNS.LABELS.ACTIONS,
      key: 'actions',
      width: '10%',
      align: 'center',
      render: (_, { id, hasDeclaration, planStatusId }) => {
        const buttons: Item[] = [];
        if (hasDeclaration) {
          buttons.push({
            key: 'viewDeclarationButton',
            text: i18n.ptBR.BUTTONS.VIEW_DECLARATION,
            icon: 'award',
            href: routes.VIEW_DECLARATION.replace(':id', id),
          });
        }

        buttons.push(
          {
            key: 'viewButton',
            text: i18n.ptBR.BUTTONS.VIEW_COMPANY_PLAN,
            icon: 'fileText',
            href: routes.VIEW_COMPANY_PLAN.replace(':id', id),
          },
          {
            key: 'editButton',
            text: i18n.ptBR.BUTTONS.EDIT_COMPANY_PLAN,
            disabled: planStatusId === PLAN_STATUS_ID.SENT,
            icon: 'edit3',
            href: routes.EDIT_COMPANY_PLAN.replace(':id', id),
          },
        );
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ButtonMenu items={buttons} />
          </div>
        );
      },
    },
  ];
};
