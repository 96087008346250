const numberFormatter = (
  locale: 'pt-BR' | 'en-US',
  options?: {
    style?: 'currency';
    currency?: 'BRL';
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
    minimumIntegerDigits?: number;
  },
) => {
  const { style, currency, minimumFractionDigits, maximumFractionDigits, minimumIntegerDigits } =
    options ?? {};
  return Intl.NumberFormat(locale, {
    style,
    currency,
    minimumFractionDigits,
    maximumFractionDigits,
    minimumIntegerDigits,
  }).format;
};

export default numberFormatter;
