import Button from '../../../../common-components/button';
import Form from '../../../../common-components/form';

import routes from '../../../../routes';
import i18n from './i18n';
import styles from './index.module.css';

function Success() {
  return (
    <Form className={styles.container}>
      <h1 className={styles.title}>{i18n.ptBR.TITLE}</h1>
      <h2 className={styles.text}>{i18n.ptBR.TEXT}</h2>

      <Button
        className={styles.redirectButton}
        type="default"
        text={i18n.ptBR.BUTTON}
        block
        href={routes.LOGIN}
      />
    </Form>
  );
}
export default Success;
