export default {
  PAGE_TITLE: 'Cadastro de usuário',

  FIELDS: {
    COMPANY_TYPE_ID: {
      VALUES: {
        MANUFACTURER: 'Empresa / Individual',
        MANUFACTURER_MANAGER: 'Entidade Gestora / Coletivo',
      },
      LABEL: 'Quero me cadastrar como',
      VALIDATION_MESSAGE: 'Selecione um tipo de conta',
    },
    CNPJ: {
      LABEL: 'CNPJ',
      VALIDATION_MESSAGES: {
        INVALID_CNPJ: 'CNPJ inválido',
      },
    },
    LEGAL_NAME: {
      LABEL: 'Razão social',
    },
    CNAE: { LABEL: 'CNAE principal' },
    PHONE: { LABEL: 'Telefone' },
    POSTAL_CODE: { LABEL: 'CEP' },
    STREET: { LABEL: 'Logradouro' },
    NUMBER: { LABEL: 'Número' },
    NEIGHBORHOOD: { LABEL: 'Bairro' },
    COMPLEMENT: { LABEL: 'Complemento' },
    STATE: { LABEL: 'Estado', PLACEHOLDER: 'Selecione um Estado' },
    CITY: { LABEL: 'Cidade' },
    EMAIL: { LABEL: 'E-mail' },
    PASSWORD: {
      LABEL: 'Senha',
      VALIDATION_MESSAGES: {
        INVALID_PASSWORD: 'Senha inválida',
        INVALID_PASSWORD_LENGTH: 'Mínimo de 8 caracteres',
        MISSING_LETTER: 'Deve conter pelo menos uma letra',
        MISSING_NUMBER: 'Deve conter pelo menos um número',
        MISSING_SPECIAL_CHARACTER: 'Deve conter pelo menos um caractere especial',
      },
    },
    CONFIRM_PASSWORD: {
      LABEL: 'Confirmar senha',
      VALIDATION_MESSAGES: {
        INVALID_CONFIRM_PASSWORD: 'Senhas não conferem',
        REQUIRED: 'Confirme sua senha',
      },
    },
    USER_TERMS: {
      SERVICE: {
        LABEL: 'Termos de Uso',
        VALUE: 'Li e aceito os Termos de Uso',
      },
      PRIVACY: {
        LABEL: 'Política de Privacidade',
        VALUE: 'Li e aceito a Política de Privacidade',
      },
      VALIDATION_MESSAGES: {
        MUST_SELECT_SERVICE: 'É necessário aceitar os Termos de Uso',
        MUST_SELECT_PRIVACY: 'É necessário aceitar os Termos de Política de Privacidade',
      },
    },
  },
  SECTIONS: {
    ADDRESS: 'Endereço',
  },

  SUBMIT_BUTTON: 'Cadastrar',
  CONFIRM_BUTTON: 'Sim, finalizar cadastro',

  CONFIRM_MODAL: {
    TITLE: 'Deseja finalizar o cadastro?',
    MESSAGE:
      'Ao completar esta ação, as informações fornecidas serão registradas e não poderão ser modificadas.',
    ALERT: 'Essa ação é irreversível!',
    RETURN_BUTTON: 'Cancelar',
  },
};
