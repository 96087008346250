import { ColumnsType } from 'antd/lib/table';
import BigNumber from 'bignumber.js';

import formatWeight from '../../../../../../cross-cutting/utils/format-weight';
import { GOAL_UNITS_OF_MEASUREMENT } from '../../../../../../modules/plans/enums';

import i18n from './i18n';
import styles from './index.module.css';

type ProgramWasteType = {
  id: number;
  name: string;
  overdueAmount: null | string;
  totalWeightSold: string;
  weightGoal: string;
};

const formatPercentage = (decimal: number) => {
  const percentage = new BigNumber(decimal)
    .times(100)
    .decimalPlaces(2, BigNumber.ROUND_HALF_EVEN)
    .toNumber();
  return percentage;
};

export default ({
  unitOfMeasure,
  goal,
}: {
  unitOfMeasure?: typeof GOAL_UNITS_OF_MEASUREMENT[keyof typeof GOAL_UNITS_OF_MEASUREMENT];
  goal?: string;
}): ColumnsType<ProgramWasteType> => {
  const isPercentage = unitOfMeasure === GOAL_UNITS_OF_MEASUREMENT.PERCENTAGE;
  const isTon = unitOfMeasure === GOAL_UNITS_OF_MEASUREMENT.TON_PER_YEAR;

  return [
    {
      title: i18n.ptBR.LIST_FIELDS.RESIDUE_OBJECT.LABEL,
      render: (_, data) => data.name,
      width: '40%',
    },
    isPercentage
      ? {
          title: i18n.ptBR.LIST_FIELDS.KG_QUANTITY.LABEL,
          render: (_, data) => {
            const formattedTotalQuantity = new BigNumber(data.totalWeightSold ?? 0)
              .decimalPlaces(3)
              .div(1000)
              .toNumber();

            return formatWeight(formattedTotalQuantity, 'ton', '.');
          },
        }
      : {
          className: styles.empty,
        },
    {
      title: i18n.ptBR.FORM_FIELDS.REQUIRED_GOAL.LABEL,
      render: () => {
        if (!goal) return 'N/A';

        const value = isPercentage
          ? `${formatPercentage(+goal || 0)}%`
          : formatWeight(
              +goal,
              isTon
                ? i18n.ptBR.GOAL_UNIT_OF_MEASURE[GOAL_UNITS_OF_MEASUREMENT.TON_PER_YEAR]
                : 'ton',
              '.',
            );

        return value;
      },
    },
    {
      title: i18n.ptBR.LIST_FIELDS.KG_QUANTITY_COLLECTED.LABEL,
      render: (_, data) => {
        const finalGoal: number | string = new BigNumber(data.weightGoal ?? 0).div(1000).toNumber();

        if (!goal) return formatWeight(finalGoal, 'ton', '.');

        const totalPercent = new BigNumber(data.totalWeightSold ?? 0).div(100).toNumber();
        const final = new BigNumber(data.weightGoal ?? 0)
          .div(totalPercent)
          .div(100)
          .toNumber()
          .toFixed(2);

        return isPercentage
          ? `${formatPercentage(+final || 0)}%`
          : formatWeight(
              +finalGoal,
              isTon
                ? i18n.ptBR.GOAL_UNIT_OF_MEASURE[GOAL_UNITS_OF_MEASUREMENT.TON_PER_YEAR]
                : 'ton',
              '.',
            );
      },
    },
    {
      title: i18n.ptBR.LIST_FIELDS.FINAL_GOAL.LABEL,
      render: (_, data) => {
        const finalGoal: number | string = new BigNumber(data.weightGoal ?? 0)
          .decimalPlaces(3)
          .div(1000)
          .toNumber();

        if (!goal) return formatWeight(finalGoal, 'ton', '.');

        const totalPercent = new BigNumber(data.totalWeightSold ?? 0).div(100).toNumber();
        const final = new BigNumber(data.weightGoal ?? 0)
          .div(totalPercent)
          .div(100)
          .toNumber()
          .toFixed(2);

        return isPercentage
          ? `${formatPercentage(+final || 0)}%`
          : formatWeight(
              finalGoal,
              isTon
                ? i18n.ptBR.GOAL_UNIT_OF_MEASURE[GOAL_UNITS_OF_MEASUREMENT.TON_PER_YEAR]
                : 'ton',
              '.',
            );
      },
    },
  ];
};
