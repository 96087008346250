import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import Loader from '../../common-components/loader';

import { getUser } from '../user/service';
import { refreshAuthentication } from './service';
import routes from '../../routes';
import useUser from '../user/use-user';

function WithAuthentication() {
  const { user, setUser } = useUser();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const verifyAuthentication = async () => {
      try {
        await refreshAuthentication();

        const newUser = await getUser();

        setUser?.(newUser);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    verifyAuthentication();
  }, [setUser]);

  if (loading) {
    return <Loader style={{ height: '100vh' }} />;
  }

  if (user) {
    return <Outlet />;
  }

  return <Navigate to={routes.LOGIN} />;
}

export default WithAuthentication;
