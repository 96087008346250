export const FORM_FIELDS = {
  PLANS_PREFIX: 'plans',
  COMMITTED: 'committed',
  ACCEPTED_TERMS: 'acceptedTerms',

  WASTE_TYPES_PREFIX: 'wasteTypes',
  RECYCLED_AMOUNT: 'recycledAmount',

  EVIDENCE_UPLOAD_ID: 'evidenceUpload',
};

export const EVIDENCE_UPLOAD_VALID_FORMATS = ['xls', 'xlsx'];

export const WEIGHT_BASED_GOALS_PRECISION = 3;

export const API_ERRORS = {
  COMPANY_PLAN_NOT_APPROVED: 'company_plan.plan_status_id.not_sent',
  UPLOADED_FILE_NOT_FOUND: 'uploaded_file.not_found',
  PLAN_ALREADY_HAS_DECLARATION: 'plan.already_has_declaration',
  DEADLINE_HAS_PASSED: 'fiscal_year.declaration_deadline.has_passed',
};
