/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo, CSSProperties } from 'react';
import { Select, SelectProps } from 'antd';

import Icon from '../icon';

import styles from './index.module.css';
import i18n from './i18n';
import matchChildrenWithInputValue from './match-children-with-input-value';
import objectGet from '../../cross-cutting/utils/object-get';

const { Option } = Select;

type ComboboxProps<T> = {
  data?: T[] | null;
  optionKey: ((item: T) => string) | keyof T;
  format: ((item: T) => string) | keyof T;
  onSelect?: (optionValue: T) => void | Record<string, never>;
  placeholder?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  multiple?: 'multiple' | 'tags' | boolean;
  className?: string;
  style?: CSSProperties;
  value?: Record<string, never> | [];
  onChange?: (optionValue: string) => void | Record<string, never>;
} & SelectProps<any>;

function Combobox<T = Record<any, any>>({
  data = null,
  optionKey,
  format,
  placeholder,
  disabled = false,
  autoFocus = false,
  onSelect = () => {},
  className = '',
  style,
  // form props
  multiple = false,
  value,
  onChange = () => {},
  ...restProps
}: ComboboxProps<T>) {
  const getOptionKey = useCallback(
    (valueObject: any) => {
      return objectGet(valueObject, optionKey as string);
    },
    [optionKey],
  );

  const getOptionDisplayText = useCallback(
    (valueObject: any) => {
      return objectGet(valueObject, format as string);
    },
    [format],
  );

  const handleSelect = (optionValue: any) => {
    const parsedValue = multiple ? optionValue.map(JSON.parse) : JSON.parse(optionValue);

    if (onChange) {
      onChange(parsedValue);
    }

    if (onSelect) {
      onSelect(parsedValue);
    }
  };

  const notFoundContent = () => {
    if (data?.length === 0) {
      return <span>{i18n.ptBR.NO_RESULTS}</span>;
    }

    return null;
  };
  const menuItemSelectedIcon = <Icon name="plus" color="var(--blue)" />;

  const optionList = useMemo(() => {
    return data?.map((result) => (
      <Option key={getOptionKey(result) as string} value={JSON.stringify(result)}>
        {getOptionDisplayText(result) as string}
      </Option>
    ));
  }, [data, getOptionKey, getOptionDisplayText]);

  return (
    <Select
      {...restProps}
      mode={multiple ? 'multiple' : undefined}
      onChange={handleSelect}
      placeholder={placeholder}
      disabled={disabled}
      autoFocus={autoFocus}
      filterOption={matchChildrenWithInputValue}
      menuItemSelectedIcon={menuItemSelectedIcon}
      notFoundContent={notFoundContent()}
      className={`${styles.select} ${className}`}
      style={style}
      dropdownClassName={styles.dropdown}
      value={value ? getOptionDisplayText(value) : undefined}
      data-testid="combobox"
      showSearch
      showArrow
    >
      {optionList}
    </Select>
  );
}

export default Combobox;
