import { Space } from 'antd';
import { useState } from 'react';

import Button from '../../../../../common-components/button';
import Form, { FormItem } from '../../../../../common-components/form';
import Icon from '../../../../../common-components/icon';
import { InputPassword } from '../../../../../common-components/input';

import convertBoldTextToJsx from '../../../../../cross-cutting/utils/convert-bold-text-to-jsx';
import { FORM_FIELDS } from '../enums';
import i18n from './i18n';
import styles from './index.module.css';

type Props = {
  email: string;
  onFinish: (formValues: FormValues) => void;
};

export type FormValues = {
  password: string;
  repeatPassword: string;
};

const validatedPasswordRules = (
  min: boolean,
  number: boolean,
  letter: boolean,
  special: boolean,
) => {
  const renderPasswordValidateMessage = (
    className: string,
    message: string,
    icon: React.ReactNode,
  ) => {
    return (
      <div className={className}>
        {icon}
        <span>{message}</span>
      </div>
    );
  };

  const passLengthText = i18n.ptBR.FORM_FIELDS.PASSWORD.VALIDATION_MESSAGES.INVALID_PASSWORD_LENGTH;
  const noNumberText = i18n.ptBR.FORM_FIELDS.PASSWORD.VALIDATION_MESSAGES.MISSING_NUMBER;
  const noLetterText = i18n.ptBR.FORM_FIELDS.PASSWORD.VALIDATION_MESSAGES.MISSING_LETTER;
  const noSpecialText =
    i18n.ptBR.FORM_FIELDS.PASSWORD.VALIDATION_MESSAGES.MISSING_SPECIAL_CHARACTER;

  const errorIcon = <Icon name="xCircle" className={styles.icon} />;
  const sucessIcon = <Icon name="alertCircle" className={styles.icon} />;

  return (
    <Space size={2} direction="vertical" align="start" style={{ marginTop: '8px' }}>
      {!min
        ? renderPasswordValidateMessage(styles.passwordError, passLengthText, errorIcon)
        : renderPasswordValidateMessage(styles.passwordSuccess, passLengthText, sucessIcon)}
      {!number
        ? renderPasswordValidateMessage(styles.passwordError, noNumberText, errorIcon)
        : renderPasswordValidateMessage(styles.passwordSuccess, noNumberText, sucessIcon)}
      {!letter
        ? renderPasswordValidateMessage(styles.passwordError, noLetterText, errorIcon)
        : renderPasswordValidateMessage(styles.passwordSuccess, noLetterText, sucessIcon)}
      {!special
        ? renderPasswordValidateMessage(styles.passwordError, noSpecialText, errorIcon)
        : renderPasswordValidateMessage(styles.passwordSuccess, noSpecialText, sucessIcon)}
    </Space>
  );
};

const renderPasswordsMatchValidateMessage = (match: boolean) => {
  return !match ? (
    <div className={styles.passwordError} style={{ color: 'var(--feedback-danger)' }}>
      <Icon name="xCircle" className={styles.icon} />
      <span>
        {i18n.ptBR.FORM_FIELDS.CONFIRM_PASSWORD.VALIDATION_MESSAGES.INVALID_CONFIRM_PASSWORD}
      </span>
    </div>
  ) : null;
};

function Content({ email, onFinish }: Props) {
  const [passwordSuccess, setPasswordSuccess] = useState(false);
  const content = (
    <Form onFinish={onFinish} layout="vertical">
      <h1 className={styles.title}>{i18n.ptBR.TITLE}</h1>
      <small className={styles.text}>{convertBoldTextToJsx(i18n.ptBR.TEXT(email))}</small>
      <FormItem
        noStyle
        shouldUpdate={(prevValues, newValues) => {
          return prevValues[FORM_FIELDS.PASSWORD] !== newValues[FORM_FIELDS.PASSWORD];
        }}
      >
        {({ getFieldValue }) => {
          const password = getFieldValue(FORM_FIELDS.PASSWORD) ?? false;

          const hasMinLength = password.length >= 8;
          const hasOneLetter = password && /(?=.*[A-Za-z])/.test(password);
          const hasOneNumber = password && /(?=.*\d)/.test(password);
          const hasOneSpecialChar = password && /(?=.*[@$!%*#?&])/.test(password);

          return (
            <FormItem
              label={i18n.ptBR.FORM_FIELDS.PASSWORD.LABEL}
              name={FORM_FIELDS.PASSWORD}
              help={validatedPasswordRules(
                hasMinLength,
                hasOneNumber,
                hasOneLetter,
                hasOneSpecialChar,
              )}
              className={`${styles.passwordField} ${styles.formItem}`}
              rules={[
                { required: true },
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.resolve();
                    }

                    if (value.length < 8) {
                      setPasswordSuccess(false);
                      return Promise.reject(
                        new Error(
                          i18n.ptBR.FORM_FIELDS.PASSWORD.VALIDATION_MESSAGES.INVALID_PASSWORD_LENGTH,
                        ),
                      );
                    }

                    if (!hasOneLetter) {
                      setPasswordSuccess(false);
                      return Promise.reject(
                        new Error(
                          i18n.ptBR.FORM_FIELDS.PASSWORD.VALIDATION_MESSAGES.MISSING_LETTER,
                        ),
                      );
                    }

                    if (!hasOneNumber) {
                      setPasswordSuccess(false);
                      return Promise.reject(
                        new Error(
                          i18n.ptBR.FORM_FIELDS.PASSWORD.VALIDATION_MESSAGES.MISSING_NUMBER,
                        ),
                      );
                    }

                    if (!hasOneSpecialChar) {
                      setPasswordSuccess(false);
                      return Promise.reject(
                        new Error(
                          i18n.ptBR.FORM_FIELDS.PASSWORD.VALIDATION_MESSAGES.MISSING_SPECIAL_CHARACTER,
                        ),
                      );
                    }

                    setPasswordSuccess(true);
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputPassword
                minLength={8}
                maxLength={64}
                className={passwordSuccess ? styles.successInput : ''}
              />
            </FormItem>
          );
        }}
      </FormItem>

      <FormItem
        noStyle
        shouldUpdate={(prevValues, newValues) => {
          return (
            prevValues[FORM_FIELDS.REPEAT_PASSWORD] !== newValues[FORM_FIELDS.REPEAT_PASSWORD] ||
            prevValues[FORM_FIELDS.PASSWORD] !== newValues[FORM_FIELDS.PASSWORD]
          );
        }}
      >
        {({ getFieldValue }) => {
          const repeatPassword = getFieldValue(FORM_FIELDS.REPEAT_PASSWORD);
          const passwordsMatch = repeatPassword === getFieldValue(FORM_FIELDS.PASSWORD);
          return (
            <FormItem
              className={styles.formItem}
              label={i18n.ptBR.FORM_FIELDS.CONFIRM_PASSWORD.LABEL}
              name={FORM_FIELDS.REPEAT_PASSWORD}
              help={!passwordsMatch ? renderPasswordsMatchValidateMessage(passwordsMatch) : <> </>}
              rules={[
                {
                  required: true,
                  message: i18n.ptBR.FORM_FIELDS.CONFIRM_PASSWORD.VALIDATION_MESSAGES.REQUIRED,
                },
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.resolve();
                    }

                    if (!passwordsMatch) {
                      return Promise.reject(
                        new Error(
                          i18n.ptBR.FORM_FIELDS.CONFIRM_PASSWORD.VALIDATION_MESSAGES.INVALID_CONFIRM_PASSWORD,
                        ),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputPassword
                className={repeatPassword && passwordsMatch ? styles.successInput : ''}
              />
            </FormItem>
          );
        }}
      </FormItem>

      <Button
        className={styles.submitButton}
        type="default"
        htmlType="submit"
        text={i18n.ptBR.BUTTONS.SUBMIT}
        block
      />
    </Form>
  );

  return content;
}

export default Content;
