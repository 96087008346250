import { FormInstance, Space } from 'antd';

import Button from '../../../../common-components/button';
import Checkbox from '../../../../common-components/checkbox';
import Divider from '../../../../common-components/divider';
import Form from '../../../../common-components/form';
import FormItem from '../../../../common-components/form/form-item';
import Card from '../../configure-program-card';
import DraggerUpload from '../../../../common-components/dragger-upload';
import Loader from '../../../../common-components/loader';
import Alert from '../../../../common-components/alert';
import Icon from '../../../../common-components/icon';

import formatPhoneNumber from '../../../../cross-cutting/utils/phoneFormatter';
import mask from '../../../../cross-cutting/utils/mask';
import useUser from '../../../../modules/user/use-user';

import { FORM_FIELDS } from '../enums';
import i18n from './i18n';
import styles from './index.module.css';
import { ProgramImages } from '../../../../modules/programs/enums';
import { CompanyPlanDetails } from '../../../../modules/plans/types';
import routes from '../../../../routes';
import {
  DOCUMENT_TEMPLATE_NAMES,
  PLAN_STATUS_ID,
  VALID_CNPJ_FILE_FORMATS,
  VALID_COMMUNICATION_DOCUMENT_FILE_FORMATS,
} from '../../../../modules/plans/enums';
import { UserCompanyType } from '../../../../modules/user/types';
import { Program } from '../../../../modules/programs/types';
import { UploadedFileData } from '../view-declaration/plan';
import rvTemplate from '../../../../assets/documents/TEMPLATE_REPORT_LR.xlsx';
import { PROGRAMS_IDS } from '../../../../enums';

type ContentProps = {
  companyPlan?: CompanyPlanDetails;
  loading: boolean;
  companyPlanSending: boolean;
  onSendCompanyPlan: () => void;
  id: string | undefined;
  onViewProgram: (program: Program) => void;
  programs?: Program[];
  form: FormInstance;
  onDownloadFile: (file: UploadedFileData) => Promise<void>;
  cnpjFileData?: UploadedFileData[];
  communicationDocumentFileData?: UploadedFileData[];
};
function Content({
  companyPlan,
  loading,
  companyPlanSending,
  onSendCompanyPlan,
  cnpjFileData,

  communicationDocumentFileData,

  id,
  onDownloadFile,
  onViewProgram,
  form,
  programs,
}: ContentProps) {
  const { user } = useUser();
  if (loading) {
    return <Loader style={{ height: '70vh' }} />;
  }
  const hasBeenSent = companyPlan?.planStatusId === PLAN_STATUS_ID.SENT;

  const declarationHasBeenSent = companyPlan?.plans[0].declaration;
  return (
    <Form form={form} layout="vertical">
      <Space style={{ width: '100%' }} direction="vertical" size={24}>
        <div className={styles.box} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span style={{ fontWeight: '700', fontSize: '24px' }}>{companyPlan?.name}</span>

          <div style={{ display: 'flex', gap: '20px' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ alignSelf: 'flex-start' }}>
                {i18n.ptBR.PLAN_COMPANY_DETAILS.TYPE.TITLE}
              </span>
              <span style={{ fontWeight: '700' }}>
                {companyPlan &&
                  i18n.ptBR.PLAN_COMPANY_DETAILS.TYPE.VALUES[companyPlan?.companyPlanTypeId]}
              </span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ alignSelf: 'flex-end' }}>{i18n.ptBR.PLAN_COMPANY_DETAILS.YEAR}</span>
              <span style={{ fontWeight: '700' }}>{companyPlan?.fiscalYear}</span>
            </div>
          </div>
        </div>

        <div style={{ fontSize: '16px' }} className={styles.box}>
          <p style={{ marginBottom: '8px', fontSize: '12px' }}>
            {i18n.ptBR.RESPONSIBLE_DETAILS.TITLE}
          </p>

          <p style={{ fontWeight: '700' }}>
            {companyPlan?.companyPlanContact.name} {companyPlan?.companyPlanContact.surname}
          </p>
          <Divider type="line" />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p>{i18n.ptBR.RESPONSIBLE_DETAILS.CPF}</p>
            <p>{i18n.ptBR.RESPONSIBLE_DETAILS.EMAIL}</p>
            <p>{i18n.ptBR.RESPONSIBLE_DETAILS.PHONE}</p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: '700' }}>
            <p>
              {mask(companyPlan?.companyPlanContact.cpf || '', 'registrationNumber') ||
                i18n.ptBR.RESPONSIBLE_DETAILS.NOT_INFORMED}
            </p>
            <p>
              {companyPlan?.companyPlanContact.email || i18n.ptBR.RESPONSIBLE_DETAILS.NOT_INFORMED}
            </p>
            <p>
              {formatPhoneNumber(companyPlan?.companyPlanContact.phone || '') ||
                i18n.ptBR.RESPONSIBLE_DETAILS.NOT_INFORMED}
            </p>
          </div>
        </div>

        {user?.profile.companyType === UserCompanyType.MANUFACTURERS_MANAGER && (
          <div className={styles.box}>
            <p style={{ marginBottom: '8px' }}>{i18n.ptBR.FORM_FIELDS.CNPJS_UPLOAD.LABEL}</p>
            <DraggerUpload
              formats={VALID_CNPJ_FILE_FORMATS}
              multiple={false}
              maxCount={1}
              fileData={cnpjFileData}
              style={{ marginBottom: '10px' }}
              onlyDisplayList
              onDownload={onDownloadFile}
              showUploadList={{
                showDownloadIcon: true,
                downloadIcon: 'Download',
                showRemoveIcon: false,
              }}
              onRemove={() => {}}
              onChange={() => {}}
            />
          </div>
        )}

        <div className={styles.box}>
          <p style={{ marginBottom: '8px' }}>
            {i18n.ptBR.FORM_FIELDS.COMMUNICATION_PLAN_UPLOAD.LABEL}
          </p>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p>{i18n.ptBR.FORM_FIELDS.COMMUNICATION_PLAN_UPLOAD.INVESTED_VALUE}</p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: '700' }}>
            <p>
              {(companyPlan?.marketingInvestmentPrediction &&
                `R$${companyPlan?.marketingInvestmentPrediction}`) ||
                i18n.ptBR.FORM_FIELDS.COMMUNICATION_PLAN_UPLOAD.NOT_INFORMED}
            </p>
          </div>

          <DraggerUpload
            formats={VALID_COMMUNICATION_DOCUMENT_FILE_FORMATS}
            multiple={false}
            style={{ marginBottom: '10px' }}
            maxCount={1}
            fileData={communicationDocumentFileData}
            onlyDisplayList
            onDownload={onDownloadFile}
            showUploadList={{
              showDownloadIcon: true,
              downloadIcon: 'Download',
              showRemoveIcon: false,
            }}
            onRemove={() => {}}
            onChange={() => {}}
          />
        </div>

        <div className={styles.box}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignContent: 'flex-end',
              marginBottom: '20px',
            }}
          >
            <p style={{ alignSelf: 'flex-end' }}>{i18n.ptBR.FORM_FIELDS.DOWNLOAD_TEMPLATE.LABEL}</p>
            <a download={DOCUMENT_TEMPLATE_NAMES.REVERSE_LOGISTICS_REPORT} href={rvTemplate}>
              <Button
                icon={<Icon name="download" />}
                text={i18n.ptBR.FORM_FIELDS.DOWNLOAD_TEMPLATE.BUTTON}
                style={{ marginTop: '8px', marginLeft: 'auto' }}
              />
            </a>
          </div>
          <Alert
            iconName="alertCircle"
            type="warning"
            style={{ border: '1px solid var(--orange-dark)' }}
            message={i18n.ptBR.FORM_FIELDS.DOWNLOAD_TEMPLATE.ALERT}
          />
        </div>

        <Space size={24} direction="horizontal" wrap>
          {programs?.map((program) => {
            const selectedYear = companyPlan?.fiscalYear;

            const currentPlan =
              program.plans.find((plan) => plan.fiscalYear === selectedYear) || program.plans[0];

            return (
              <Card
                title={program.name}
                plan={currentPlan}
                isViewPage
                key={program.id}
                image={ProgramImages[program.id as keyof typeof ProgramImages]}
                onConfigure={() => onViewProgram?.(program)}
                shouldRenderGeographicGoal={program.id !== PROGRAMS_IDS.BATERIES_OF_ACID_LEAD}
              />
            );
          })}
        </Space>
        {!hasBeenSent && (
          <FormItem
            rules={[
              {
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject(i18n.ptBR.VALIDATE_MESSAGES.ACCEPT_TERMS.REQUIRED);
                  }
                  return Promise.resolve();
                },
              },
            ]}
            valuePropName="checked"
            name={FORM_FIELDS.ACCEPT_TERMS}
          >
            <Checkbox>{i18n.ptBR.FORM_FIELDS.ACCEPT_TERMS.LABEL}</Checkbox>
          </FormItem>
        )}
        <Divider type="line" />
        <div className={styles.buttonsContainer}>
          <Button
            href={routes.LIST_PLANS}
            type="text"
            title={i18n.ptBR.BUTTONS.RETURN}
            text={i18n.ptBR.BUTTONS.RETURN}
          />

          {!declarationHasBeenSent && hasBeenSent && (
            <Button
              icon={<Icon name="arrowRight" />}
              text={i18n.ptBR.BUTTONS.SUBMIT}
              className={styles.submitButton}
              href={routes.CREATE_DECLARATION.replace(':id', id || '')}
            />
          )}

          {!hasBeenSent && (
            <>
              <Button
                title={i18n.ptBR.BUTTONS.EDIT}
                type="stroke"
                text={i18n.ptBR.BUTTONS.EDIT}
                href={routes.EDIT_COMPANY_PLAN.replace(':id', companyPlan?.id || '')}
              />
              <Button
                title={i18n.ptBR.BUTTONS.SEND}
                text={i18n.ptBR.BUTTONS.SEND}
                onClick={onSendCompanyPlan}
                loading={companyPlanSending}
              />
            </>
          )}
        </div>
      </Space>
    </Form>
  );
}

export default Content;
