import { useNavigate } from 'react-router-dom';
import Page from '../../../../common-components/page';
import Table from '../../../../common-components/table';
import columns from './columns';

import Icon from '../../../../common-components/icon';
import Button from '../../../../common-components/button';

import i18n from './i18n';
import styles from './index.module.css';
import { CompanyPlan } from '../../../../modules/plans/types';
import routes from '../../../../routes';

type Props = {
  companyPlans: CompanyPlan[];
  loading: boolean;
  currantPage: number;
  totalCount: number;
  pageSize: number;
  onPageChange: (newPage: number) => void;
};

export default function Content({
  companyPlans,
  loading,
  currantPage,
  totalCount,
  pageSize,
  onPageChange,
}: Props) {
  const navigate = useNavigate();
  return (
    <Page
      browserTabName={i18n.ptBR.PAGE_TITLE}
      childrenClassName={styles.pageContent}
      className={styles.pageContainer}
    >
      <div className={styles.section}>
        <p className={styles.sectionTitle}>{i18n.ptBR.SECTION_TITLE}</p>
        <Button
          onClick={() => navigate(routes.NEW_PLAN)}
          icon={<Icon name="plus" />}
          text={i18n.ptBR.BUTTONS.NEW_COMPANY_PLAN}
        />
      </div>
      <div className={styles.tableContainer}>
        <Table
          rowKey="id"
          dataSource={companyPlans}
          currentPage={currantPage}
          totalItems={totalCount}
          onPageChange={onPageChange}
          loading={loading}
          columns={columns()}
          pageSize={pageSize}
        />
      </div>
    </Page>
  );
}
