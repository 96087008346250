import {
  CompanyPlan,
  CreateCompanyPlanPayload,
  GetAllCompanyPlansPayload,
  CompanyPlanDetails,
  EditCompanyPlanPayload,
} from './types';
import dataAccess from '../../cross-cutting/data-access';

export const createCompanyPlan = async (payload: CreateCompanyPlanPayload) => {
  try {
    const response = await dataAccess.post(`/company-plans`, payload);
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
};
type GetAllCompanyPlansResponse = {
  companyPlans: CompanyPlan[];
  totalCount: number;
};

export const getAllCompanyPlans = async ({
  code,
  limit,
  page,
  programId,
  orderBy,
  cnpj,
  legalName,
  region,
  companyPlanTypeId,
  fiscalYear,
  companyId,
}: GetAllCompanyPlansPayload) => {
  try {
    const response = await dataAccess.get(`/company-plans`, {
      params: {
        code,
        limit,
        page,
        programId,
        orderBy,
        cnpj,
        legalName,
        region,
        companyPlanTypeId,
        fiscalYear,
        companyId,
      },
    });
    return response.data as GetAllCompanyPlansResponse;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getOneCompanyPlan = async (id: string) => {
  try {
    const response = await dataAccess.get(`/company-plans/${id}`);
    return response.data as CompanyPlanDetails;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const editCompanyPlan = async (payload: EditCompanyPlanPayload, id: string) => {
  try {
    const response = await dataAccess.put(`/company-plans/${id}`, payload);
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const sendCompanyPlan = async (id: string) => {
  try {
    const response = await dataAccess.post(`/company-plans/${id}/send`);
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

type CreateCompanyPlanDeclarationPayload = {
  declarations: {
    planId: string;
    wasteTypes: {
      programWasteTypeId: string;
      amountCollected: number;
    }[];
    uploadId: string;
  }[];
};
type CreateCompanyPlanDeclarationResponse = {
  id: string;
  planId: string;
  amountCollected: string;
  declarationEvidenceAttachment: {
    declarationId: string;
    uploadedFileId: string;
    createdAt: string;
  };
  declarationHasProgramWasteTypes: {
    declarationId: string;
    programWasteTypeId: string;
    amountCollected: string;
    createdAt: string;
  }[];
  createdAt: string;
}[];
export const createCompanyPlanDeclaration = async (
  id: string,
  payload: CreateCompanyPlanDeclarationPayload,
) => {
  try {
    const response = await dataAccess.post(`/company-plans/${id}/declare`, payload);
    return response.data as CreateCompanyPlanDeclarationResponse;
  } catch (err) {
    return Promise.reject(err);
  }
};
