import { Col, Row } from 'antd';

import Collapsible from '../../../../../../common-components/collapsible';
import Loader from '../../../../../../common-components/loader';
import Field from '../../../../../../common-components/field';
import Divider from '../../../../../../common-components/divider';

import cnpjFormatter from '../../../../../../cross-cutting/utils/cnpjFormatter';
import formatPhoneNumber from '../../../../../../cross-cutting/utils/phoneFormatter';

import { GetOneCompanyResponse } from '../../../../../../modules/company/service';
import i18n from '../../../content/i18n';

import styles from '../../../content/index.module.css';

type ContentProps = {
  company?: GetOneCompanyResponse;
};

function Content({ company }: ContentProps) {
  return !company ? (
    <Loader />
  ) : (
    <div style={{ fontFamily: 'Rawline' }}>
      <div style={{ fontSize: '34px', padding: '30px 10px' }}>
        <span>{i18n.ptBR.TABS.REGISTRATION_DATA.TITLE}</span>
      </div>

      {/* Company data */}
      <Collapsible
        openIcon="chevronDown"
        closeIcon="chevronUp"
        title={
          <span className={styles.collapsibleTitle}>
            {i18n.ptBR.TABS.REGISTRATION_DATA.COMPANY.TITLE}
          </span>
        }
        childrenClassname={styles.collapsibleChildrenClassname}
        defaultOpen
      >
        <Row gutter={[8, 8]}>
          <Col sm={8} xs={12}>
            <Field
              title={i18n.ptBR.TABS.REGISTRATION_DATA.COMPANY.FIELDS.TYPE}
              value={i18n.ptBR.COMPANY_TYPES[company.companyTypeId]}
            />
          </Col>
          <Col sm={8} xs={24}>
            <Field
              title={i18n.ptBR.TABS.REGISTRATION_DATA.COMPANY.FIELDS.CNPJ}
              value={company.cnpj}
              mask={cnpjFormatter}
            />
          </Col>
          <Col sm={8} xs={12}>
            <Field
              title={i18n.ptBR.TABS.REGISTRATION_DATA.COMPANY.FIELDS.MAIN_CNAE}
              value={company.mainCnae}
            />
          </Col>
        </Row>
      </Collapsible>

      <Divider type="line" style={{ margin: '16px 0px' }} />

      {/* Address */}
      <Collapsible
        openIcon="chevronDown"
        closeIcon="chevronUp"
        title={
          <span className={styles.collapsibleTitle}>
            {i18n.ptBR.TABS.REGISTRATION_DATA.ADDRESS.TITLE}
          </span>
        }
        childrenClassname={styles.collapsibleChildrenClassname}
        defaultOpen
      >
        <Row gutter={[8, 8]}>
          <Col sm={4} xs={12}>
            <Field
              title={i18n.ptBR.TABS.REGISTRATION_DATA.ADDRESS.FIELDS.POSTAL_CODE}
              value={company.address.postalCode}
            />
          </Col>
          <Col sm={4} xs={12}>
            <Field
              title={i18n.ptBR.TABS.REGISTRATION_DATA.ADDRESS.FIELDS.STREET_NAME}
              value={company.address.streetName}
            />
          </Col>
          <Col sm={4} xs={12}>
            <Field
              title={i18n.ptBR.TABS.REGISTRATION_DATA.ADDRESS.FIELDS.NUMBER}
              value={company.address.number}
            />
          </Col>
          <Col sm={4} xs={12}>
            <Field
              title={i18n.ptBR.TABS.REGISTRATION_DATA.ADDRESS.FIELDS.NEIGHBORHOOD}
              value={company.address.neighborhood}
            />
          </Col>
          <Col sm={4} xs={12}>
            <Field
              title={i18n.ptBR.TABS.REGISTRATION_DATA.ADDRESS.FIELDS.CITY}
              value={company.address.city}
            />
          </Col>
          <Col sm={4} xs={12}>
            <Field
              title={i18n.ptBR.TABS.REGISTRATION_DATA.ADDRESS.FIELDS.STATE}
              value={company.address.stateAbbreviation}
            />
          </Col>
        </Row>
      </Collapsible>

      <Divider type="line" style={{ margin: '16px 0px' }} />

      {/* Contact  */}
      <Collapsible
        openIcon="chevronDown"
        closeIcon="chevronUp"
        title={
          <span className={styles.collapsibleTitle}>
            {i18n.ptBR.TABS.REGISTRATION_DATA.CONTACT.TITLE}
          </span>
        }
        childrenClassname={styles.collapsibleChildrenClassname}
        defaultOpen
      >
        <Row gutter={[8, 0]}>
          <Col span={24}>
            <Field
              title={i18n.ptBR.TABS.REGISTRATION_DATA.CONTACT.FIELDS.PHONE}
              value={company.phone}
              mask={formatPhoneNumber}
            />
          </Col>
        </Row>
      </Collapsible>
    </div>
  );
}

export default Content;
