export default {
  primary: 'var(--primary)',
  dark: 'var(--dark)',
  dark5p: 'var(--dark-5p)',

  feedbackSuccessLight: 'var(--feedback-success-light)',
  feedbackSuccessDark: 'var(--feedback-success-dark)',
  feedbackWarningLight: 'var(--feedback-warning-light)',
  feedbackWarningDark: 'var(--feedback-warning-dark)',
  feedbackDangerLight: 'var(--feedback-danger-light)',
  feedbackDanger: 'var(--feedback-danger)',
  feedbackDangerDark: 'var(--feedback-danger-dark)',

  greenLight: 'var(--green-light)',
  green: 'var(--green)',
  greenDark: 'var(--green-dark)',

  blueLight: 'var(--blue-light)',
  blue: 'var(--blue)',
  blueDark: 'var(--blue-dark)',
  blueGradient: 'var(--blue-gradient)',

  title: 'var(--title)',

  orangeLight: 'var(--orange-light)',
  orangeDark: 'var(--orange-dark)',

  redLight: 'var(--red-light)',
  red: 'var(--red)',
  redDark: 'var(--red-dark)',

  white: 'var(--white)',
  gray0: 'var(--gray-0)',
  gray1: 'var(--gray-1)',
  gray2: 'var(--gray-2)',
  gray3: 'var(--gray-3)',

  cobalt: 'var(--cobalt)',
  sky: 'var(--sky)',
} as const;
