import { GetAllCompaniesPayload } from './types';
import dataAccess from '../../cross-cutting/data-access';
import { COMPANY_TYPE_ID } from '../../pages/user-registration/form/enums';

export const getOneComplianceCertificate = async () => {
  try {
    const response = await dataAccess.get('/company/compliance-certificate', {
      responseType: 'blob',
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getAllCompanies = async ({
  programId,
  cnpj,
  companyTypeId,
  legalName,
  limit,
  orderBy,
  page,
  region,
}: GetAllCompaniesPayload) => {
  try {
    const response = await dataAccess.get('/companies', {
      params: {
        programId,
        cnpj,
        companyTypeId,
        legalName,
        limit,
        orderBy,
        page,
        region,
      },
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export type GetOneCompanyResponse = {
  id: string;
  legalName: string;
  cnpj: string;
  mainCnae: string;
  companyTypeId: typeof COMPANY_TYPE_ID[keyof typeof COMPANY_TYPE_ID];
  phone: string;
  address: {
    postalCode: string;
    streetName: string;
    number: string;
    neighborhood: string;
    city: string;
    stateAbbreviation: string;
  };
};

export const getOneCompany = async (companyId: string) => {
  try {
    const response = await dataAccess.get(`companies/${companyId}`);
    return response.data as GetOneCompanyResponse;
  } catch (err) {
    return Promise.reject(err);
  }
};

export type GetCompanyProgramsResponse = {
  id: number;
  name: string;
}[];

export const getCompanyPrograms = async (companyId: string) => {
  try {
    const response = await dataAccess.get(`companies/${companyId}/programs`);
    return response.data as GetCompanyProgramsResponse;
  } catch (err) {
    return Promise.reject(err);
  }
};
