export const FORM_FIELDS = {
  NEW_RESIDUE: 'newResidue',
  RESIDUE_NAME: 'residue',
  QUANTITY: 'quantity',
  DECLARED_GOAL: 'declaredGoal',
  RESIDUE_LIST: 'residues',

  REVERSE_LOGISTICS_LINK: 'reverseLogisticsWebsite',
  GEOGRAPHIC_GOAL_ATTACHMENT_ID: 'geographicGoalAttachmentId',
};
