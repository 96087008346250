import { ResponsiveLine, LineProps } from '@nivo/line';
import BigNumber from 'bignumber.js';

import formatWeight from '../../../cross-cutting/utils/format-weight';

export type LineGraphsProps = LineProps & {
  enablePointLabel?: boolean;
};

export default function LineGraph({
  curve = 'linear',
  lineWidth = 1,
  pointSize = 8,
  pointBorderWidth = 8,
  enablePointLabel = true,
  margin = { top: 30, right: 30, bottom: 55, left: 60 },
  legends = [
    {
      anchor: 'bottom',
      direction: 'row',
      itemHeight: 20,
      itemWidth: 90,
      translateX: 20,
      translateY: 45,
    },
  ],
  ...props
}: LineGraphsProps) {
  return (
    <ResponsiveLine
      curve={curve}
      lineWidth={lineWidth}
      pointSize={pointSize}
      pointBorderWidth={pointBorderWidth}
      enablePointLabel={enablePointLabel}
      legends={legends}
      margin={margin}
      colors={{ datum: 'color' }}
      yFormat={(value) =>
        formatWeight(new BigNumber(value as number).div(1000).toNumber(), 'ton', '.', 0)
      }
      {...props}
    />
  );
}
