import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ErrorHandler from '../../../../common-components/error-handler';
import HttpError from '../../../../cross-cutting/errors/http-error';
import { getOneCompanyPlan } from '../../../../modules/plans/service';
import type { CompanyPlanDetails } from '../../../../modules/plans/types';
import Content from './content';

export default function CreateDeclaration() {
  const { id: companyPlanId } = useParams<'id'>();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<HttpError>();
  const [companyPlan, setCompanyPlan] = useState<CompanyPlanDetails>();

  useEffect(() => {
    const fetchCompanyPlan = async (id: string) => {
      try {
        const response = await getOneCompanyPlan(id);
        setCompanyPlan(response);
      } catch (err) {
        if (err instanceof HttpError) {
          setError(err);
        }
      }
      setLoading(false);
    };
    if (companyPlanId) {
      fetchCompanyPlan(companyPlanId);
    }
  }, [companyPlanId]);

  return (
    <ErrorHandler error={error}>
      {companyPlanId ? (
        <Content companyPlanId={companyPlanId} loading={loading} companyPlan={companyPlan} />
      ) : null}
    </ErrorHandler>
  );
}
