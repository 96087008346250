import Button from '../../../../../common-components/button';
import Form, { FormItem } from '../../../../../common-components/form';
import Input from '../../../../../common-components/input';

import { EMAIL_MAX_LENGTH, FORM_FIELDS } from '../enums';
import i18n from './i18n';
import styles from './index.module.css';

type Props = {
  onCancelClick: () => void;
  onFinish: (formValues: { email: string }) => void;
  loading: boolean;
};

function Content({ onCancelClick, onFinish, loading }: Props) {
  const content = (
    <Form className={styles.container} onFinish={onFinish} variant="ghost">
      <h1 className={styles.title}>{i18n.ptBR.TITLE}</h1>
      <p className={styles.text}>{i18n.ptBR.TEXT}</p>
      <FormItem
        name={FORM_FIELDS.EMAIL}
        label={i18n.ptBR.FORM_FIELDS.EMAIL.LABEL}
        rules={[
          { required: true, message: i18n.ptBR.FORM_FIELDS.EMAIL.ERROR_MESSAGE },
          { type: 'email', message: i18n.ptBR.FORM_FIELDS.EMAIL.ERROR_MESSAGE },
        ]}
      >
        <Input placeholder={i18n.ptBR.FORM_FIELDS.EMAIL.PLACEHOLDER} maxLength={EMAIL_MAX_LENGTH} />
      </FormItem>
      <Button
        className={styles.submitButton}
        type="default"
        htmlType="submit"
        text={i18n.ptBR.BUTTONS.SUBMIT}
        block
        loading={loading}
      />
      <Button
        type="text"
        text={i18n.ptBR.BUTTONS.CANCEL}
        style={{ color: 'var(--gray-1)' }}
        disabled={loading}
        onClick={onCancelClick}
      />
    </Form>
  );

  return content;
}

export default Content;
