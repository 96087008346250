export default {
  BUTTON: {
    LOGOUT: 'Sair',
    COMPLIANCE_CERTIFICATE: 'Certidão de conformidade',
    TERMS_OF_SERVICE: 'Termos de uso',
    PRIVACY_POLICY: 'Política de Privacidade',
    USER_GUIDE: 'Manual do Usuário',
    LEGISLATION: 'Legislação',
  },
  MENU_TITLE: 'Serviços de Logística Reversa',
  MENU_ITEMS: {
    REVERSE_LOGISTICS_PROGRAMS: 'Sistemas de Logística Reversa',
    PLANS: 'Planos de Logística Reversa',
    DECLARATIONS: 'Declarações Anuais de Coleta',
    DASHBOARD: 'Dashboard',
  },
  STORAGE_WARNING: {
    TITLE: (storagePercentage: string) =>
      `O armazenamento está quase cheio (${storagePercentage}%)`,
    MESSAGE: (
      storageLeft: number,
    ) => `Faltam apenas *${storageLeft}gb* para esgotar o armazenamento. Acione o setor de tecnologia para liberar mais espaço.

`,
  },
  HELLO_USER: (userName?: string) => `Olá, *${userName}*`,
  PROGRAMS_NAME: [
    'Agrotóxicos, seus Resíduos e Embalagens',
    'Baterias de Chumbo Ácido',
    'Eletroeletrônicos e Seus Componentes',
    'Embalagens de Aço',
    'Embalagens Plásticas de Óleos Lubrificantes',
    'Embalagens em Geral',
    'Lâmpadas Fluorescentes, de Vapor de Sódio e Mercúrio e de Luz Mista',
    'Medicamentos, seus Resíduos e Embalagens',
    'Óleos Lubrificantes Usados ou Contaminados (OLUC)',
    'Pilhas e Baterias',
    'Pneus Inservíveis',
    'Latas de Alumínio para Bebidas',
  ],
  PLANS_AND_DECLARATIONS_REPORT: 'Relatório de Planos e Declarações',
};
