import Button from '../../../../../common-components/button';
import Divider from '../../../../../common-components/divider';
import Form, { FormItem } from '../../../../../common-components/form';
import Input from '../../../../../common-components/input';
import convertBoldTextToJsx from '../../../../../cross-cutting/utils/convert-bold-text-to-jsx';
import { FORM_FIELDS, MAX_CODE_LENGTH } from '../enums';
import styles from './index.module.css';
import i18n from './i18n';

type Props = {
  onValidateCode: (formValues: { recoveryCode: number }) => void;
  onResendCode: () => void;
  email: string;
  loading: boolean;
};

function Content({ email, onValidateCode, onResendCode, loading }: Props) {
  const content = (
    <Form className={styles.container} variant="ghost" onFinish={onValidateCode}>
      <h1 className={styles.title}>{i18n.ptBR.TITLE}</h1>
      <h2 className={styles.text}>{convertBoldTextToJsx(i18n.ptBR.TEXT(email))}</h2>
      <FormItem
        name={FORM_FIELDS.RECOVERY_CODE_NUMBER}
        label={i18n.ptBR.FORM_FIELDS.RECOVERY_CODE.LABEL}
        className={styles.formItem}
        rules={[{ required: true, message: i18n.ptBR.FORM_FIELDS.RECOVERY_CODE.ERROR_MESSAGE }]}
      >
        <Input
          placeholder={i18n.ptBR.FORM_FIELDS.RECOVERY_CODE.PLACEHOLDER}
          maxLength={MAX_CODE_LENGTH}
        />
      </FormItem>
      <Button
        className={styles.submitButton}
        type="default"
        htmlType="submit"
        text={i18n.ptBR.BUTTONS.SUBMIT}
        loading={loading}
        block
      />
      <Divider className={styles.divider} type="line" />

      <span style={{ color: 'var(--gray-1)' }}>{i18n.ptBR.BUTTONS.TEXT}</span>

      <Button
        className={styles.strokeButton}
        type="stroke"
        text={i18n.ptBR.BUTTONS.RESEND}
        block
        onClick={onResendCode}
        loading={loading}
      />
    </Form>
  );

  return content;
}
export default Content;
