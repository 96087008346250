import { useState } from 'react';
import dayjs from 'dayjs';

import Content from './content';
import ErrorHandler from '../../../../common-components/error-handler';

import {
  requestPasswordRecoveryCode,
  validateRecoveryCode,
} from '../../../../modules/authentication/service';
import HttpError from '../../../../cross-cutting/errors/http-error';
import { HTTP_STATUS_CODES } from '../../../../enums';
import i18n from './content/i18n';

type Props = {
  email: string;
  onFinish: (code: number) => void;
};

function ValidateCode({ email, onFinish }: Props) {
  const [error, setError] = useState<HttpError>();
  const [loading, setLoading] = useState(false);

  const handleValidateCode = async (formValues: { recoveryCode: number }) => {
    setLoading(true);
    const payload = {
      code: formValues.recoveryCode,
      email,
    };
    try {
      await validateRecoveryCode(payload);
      onFinish(formValues.recoveryCode);
    } catch (err) {
      if (err instanceof HttpError) {
        setError(err);
      }
    }
    setLoading(false);
  };

  const handleResendCode = async () => {
    setLoading(true);
    const payload = {
      email,
    };
    try {
      await requestPasswordRecoveryCode(payload);
    } catch (err) {
      if (err instanceof HttpError) {
        setError(err);
      }
    }
    setLoading(false);
  };
  const options = [
    {
      code: HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY,
      types: {
        [error?.details?.[0]?.type]: {
          title: i18n.ptBR.UNPROCESSABLE_ENTITY_ERROR_MESSAGES[error?.details?.[0]?.type]?.TITLE,
          description: i18n.ptBR.UNPROCESSABLE_ENTITY_ERROR_MESSAGES[
            error?.details?.[0]?.type
          ]?.DESCRIPTION(dayjs(error?.details[0].expiresAt).diff(dayjs(), 'm').toString()),
        },
      },
    },
  ];

  return (
    <ErrorHandler error={error} options={options}>
      <Content
        onValidateCode={handleValidateCode}
        onResendCode={handleResendCode}
        email={email}
        loading={loading}
      />
    </ErrorHandler>
  );
}

export default ValidateCode;
