import { UploadProps } from 'antd';
import { CSSProperties } from 'react';

import Upload from '../upload';
import Icon from '../icon';

import styles from './index.module.css';
import i18n from './i18n';

type DraggerUploadProps<CustomUploadedFile> = Omit<
  UploadProps,
  'onChange' | 'onDownload' | 'onRemove'
> & {
  style?: CSSProperties;
  onRemove?: (file: CustomUploadedFile) => void;
  onChange?: (fileList: UploadedFile[]) => void;
  onFileChange?: (file: UploadedFile) => void;
  onDownload?: (file: CustomUploadedFile) => void;
  multiple?: boolean;
  maxCount?: number;
  className?: string;
  fileData?: CustomUploadedFile[];
  formats: string[];
  onlyDisplayList?: boolean;
  title?: string;
  loading?: boolean;
  newDesign?: boolean;
};

export type OriginFileObj = File & {
  uid: string;
  id?: string;
};

export type UploadedFile = {
  uid: string;
  type: string;
  lastModified: number;
  lastModifiedDate: Date;
  originFileObj: OriginFileObj;
  percent: number;
  size: number;
};

function DraggerUpload<CustomUploadedFile = UploadedFile>({
  style,
  onRemove,
  onChange,
  onFileChange,
  onDownload,
  multiple,
  maxCount,
  className,
  fileData,
  formats,
  title,
  onlyDisplayList,
  loading,
  newDesign,
  ...props
}: DraggerUploadProps<CustomUploadedFile>) {
  return (
    <div
      className={`${newDesign ? styles.newDesign : styles.uploadContainer} ${className} ${
        onlyDisplayList && styles.onlyDisplayList
      } $ 
      `}
      style={{ ...style }}
    >
      <Upload
        text={undefined}
        maxSize={undefined}
        disableUploadButton={undefined}
        formats={formats}
        onChange={onChange}
        onFileChange={onFileChange}
        onRemove={onRemove}
        onDownload={onDownload}
        loading={loading}
        multiple={multiple}
        maxCount={maxCount}
        className={`${styles.addAttachmentButton}`}
        fileList={fileData}
        {...props}
      >
        <div className={styles.draggerContainer} title={i18n.ptBR.BUTTONS.ADD_ATTACHMENT}>
          <div className={styles.messageContainer}>
            <Icon
              style={{
                width: '30px',
                display: 'inline-block',
                marginRight: '10px',
                paddingTop: '5px',
              }}
              color="gray2"
              name="upload"
            />
            <span className={styles.draggerMessage}>{title || i18n.ptBR.DRAGGER.MESSAGE}</span>
          </div>
        </div>
      </Upload>
    </div>
  );
}
export default DraggerUpload;

DraggerUpload.defaultProps = {
  style: undefined,
  maxCount: undefined,
  multiple: false,
  className: '',
};
