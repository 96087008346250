import FeedbackModal, { COLORS_BY_SEVERITY } from '../../../common-components/feedback-modal';
import Icon from '../../../common-components/icon';
import Button from '../../../common-components/button';
import Alert from '../../../common-components/alert';

import styles from './index.module.css';
import i18n from './i18n';
import convertBoldTextToJsx from '../../../cross-cutting/utils/convert-bold-text-to-jsx';
import { UNPROCESSABLE_ENTITY_ERROR_TYPES } from '../../../enums';
import HttpError from '../../../cross-cutting/errors/http-error';
import { INTERNAL_ERROR_TYPES } from './enums';

type ErrorDetail = {
  type: string;
  details: {
    row: number;
    legalName?: string;
    cnpj?: string;
    maxLengthAllowed?: number;
  };
};

type Props = {
  visible: boolean;
  error: HttpError | undefined;
  onCancel: () => void;
};

type InternalErrorType = typeof INTERNAL_ERROR_TYPES[keyof typeof INTERNAL_ERROR_TYPES];

const getDescriptions = (errorDetails: ErrorDetail[]) => {
  const errorTypesToInternalTypes = {
    [UNPROCESSABLE_ENTITY_ERROR_TYPES.COMPANY_PLAN_ASSOCIATED_COMPANIES_ATTACHMENT_CNPJ_INVALID]:
      INTERNAL_ERROR_TYPES.MISSING_OR_INVALID_DATA,
    [UNPROCESSABLE_ENTITY_ERROR_TYPES.COMPANY_PLAN_ASSOCIATED_COMPANIES_ATTACHMENT_CNPJ_MISSING]:
      INTERNAL_ERROR_TYPES.MISSING_OR_INVALID_DATA,
    [UNPROCESSABLE_ENTITY_ERROR_TYPES.COMPANY_PLAN_ASSOCIATED_COMPANIES_ATTACHMENT_LEGAL_NAME_GREATER_THAN_ALLOWED]:
      INTERNAL_ERROR_TYPES.LEGAL_NAME_GREATER_THAN_ALLOWED,
    [UNPROCESSABLE_ENTITY_ERROR_TYPES.COMPANY_PLAN_ASSOCIATED_COMPANIES_ATTACHMENT_LEGAL_NAME_MISSING]:
      INTERNAL_ERROR_TYPES.MISSING_OR_INVALID_DATA,
  };

  const groupedDescriptions: Partial<
    Record<
      InternalErrorType,
      {
        message: string;
        rows: number[];
      }
    >
  > = {};

  errorDetails.forEach(({ type, details }) => {
    const internalErrorType = errorTypesToInternalTypes[type];

    if (!groupedDescriptions[internalErrorType]) {
      groupedDescriptions[internalErrorType] = {
        message: i18n.ptBR.ERROR_DESCRIPTIONS[internalErrorType](details.maxLengthAllowed),
        rows: [details.row],
      };
    } else {
      groupedDescriptions[internalErrorType]?.rows.push(details.row);
    }
  });

  return Object.values(groupedDescriptions);
};

function AssociatedCompaniesAttachmentErrorModal({ visible, error, onCancel }: Props) {
  const descriptions = error?.details ? getDescriptions(error.details) : [];

  const color = COLORS_BY_SEVERITY.error;

  return (
    <FeedbackModal
      title={
        <div className={styles.title}>
          <div
            className={styles.iconContainer}
            style={{ backgroundColor: color.background, color: color.foreground }}
          >
            <Icon name="alertCircle" />
          </div>
          {i18n.ptBR.TITLE}
        </div>
      }
      footer={<Button type="text" text={i18n.ptBR.CLOSE_BUTTON} onClick={onCancel} />}
      visible={visible}
      onCancel={onCancel}
      severity="error"
    >
      <div style={{ padding: '40px 0 64px' }}>
        {descriptions.map(({ message, rows }, index) => {
          const key = index;
          return (
            <Alert
              key={key}
              type="error"
              message={
                <div>
                  {convertBoldTextToJsx(message)}
                  <ul style={{ listStyle: 'disc', paddingLeft: '32px', fontWeight: 'bold' }}>
                    <li>{rows.join('; ')}</li>
                  </ul>
                </div>
              }
              className={styles.message}
              style={{ marginBottom: '6px' }}
            />
          );
        })}
        <Alert
          type="error"
          message={i18n.ptBR.ERROR_DESCRIPTIONS.AFTER_SOLVING}
          className={styles.message}
        />
      </div>
    </FeedbackModal>
  );
}

export default AssociatedCompaniesAttachmentErrorModal;
