import Alert from '../../../../../common-components/alert';
import Button from '../../../../../common-components/button';
import FeedbackModal from '../../../../../common-components/feedback-modal';

import i18n from '../content/i18n';
import styles from './index.module.css';

type Props = {
  visible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  sendDeclarationLoading: boolean;
};

export default function ConfirmationModal({
  visible,
  onConfirm,
  onCancel,
  sendDeclarationLoading,
}: Props) {
  const footer = (
    <div className={styles.footer}>
      <Button
        type="text"
        text={i18n.ptBR.CONFIRMATION_MODAL.BUTTONS.CANCEL}
        onClick={onCancel}
        className={styles.cancelButton}
        loading={sendDeclarationLoading}
        disabled={sendDeclarationLoading}
      />
      <Button
        loading={sendDeclarationLoading}
        disabled={sendDeclarationLoading}
        text={i18n.ptBR.CONFIRMATION_MODAL.BUTTONS.CONFIRM}
        onClick={onConfirm}
      />
    </div>
  );

  return (
    <FeedbackModal
      title={i18n.ptBR.CONFIRMATION_MODAL.TITLE}
      visible={visible}
      severity="warning"
      onCancel={onCancel}
      footer={footer}
    >
      <div className={styles.content}>
        <p className={styles.description}>{i18n.ptBR.CONFIRMATION_MODAL.DESCRIPTION}</p>

        <Alert
          message={i18n.ptBR.CONFIRMATION_MODAL.ALERT_MESSAGE}
          type="warning"
          iconName="alertCircle"
          className={styles.alert}
        />
      </div>
    </FeedbackModal>
  );
}
