import masks from './masks';

const maskChar = '#';

const countMaskChars = (maskPattern: string) => {
  return maskPattern.match(new RegExp(maskChar, 'g'))?.length ?? 0;
};

export const getAlmostIdealMask = (maskPatterns: string | string[], text: string) => {
  if (!Array.isArray(maskPatterns)) {
    return maskPatterns;
  }

  const sortedByLength = maskPatterns.sort((a, b) => a.length - b.length);

  const closestMask = sortedByLength.find((m) => countMaskChars(m) >= text.length);
  const longestMask = sortedByLength[sortedByLength.length - 1];

  // longestMask acts as a limiter
  return closestMask ?? longestMask;
};

const getIdealMask = (maskPatterns: string | string[], text: string) => {
  if (!Array.isArray(maskPatterns)) {
    return maskPatterns;
  }

  return maskPatterns.find((maskPattern) => countMaskChars(maskPattern) === text.length);
};

export const applyMaskPattern = (
  text: string,
  maskPattern: string,
  { placeholderChar }: { placeholderChar?: string } = {},
) => {
  let result = '';
  let index = 0;

  // usual `maskChar` split would ignore some chars, such as "(##)" would become ["(", "", ")"]
  maskPattern.split(new RegExp(`(${maskChar})`)).forEach((maskPart) => {
    // avoid return other pattern chars if the text is too short and there is no `placeholderChar`
    // e.g. applyMaskPattern("123", "#####-###") ==> "123-"
    if (text.length === index && placeholderChar === undefined) {
      return;
    }

    const isLacuna = maskPart === '#';

    if (isLacuna) {
      result += text[index] ?? placeholderChar ?? '';
      index += 1;
    } else {
      result += maskPart;
    }
  });

  return result;
};

const mask = (
  text: string | number,
  maskName: keyof typeof masks['ptBR'],
  locale: keyof typeof masks = 'ptBR',
) => {
  const sanitizedText = String(text).replace(/\W/g, '');

  const maskPatterns = masks[locale][maskName];
  const idealMaskPattern = getIdealMask(maskPatterns, sanitizedText);

  if (!idealMaskPattern) {
    return sanitizedText;
  }

  return applyMaskPattern(sanitizedText, idealMaskPattern);
};

export default mask;
