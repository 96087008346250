import { Space } from 'antd';
import LogoGovBR from '../../../assets/logos/gov-br.png';
import MinMeioAmbiente from '../../../assets/logos/logo-mma-periodo-eleitoral.png';
import i18n from './i18n';

import styles from './index.module.css';

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <Space size={16}>
        <img
          src={LogoGovBR}
          alt={i18n.ptBR.GOVERNMENT_LOGO_ALT}
          style={{ width: 'auto', height: '24px' }}
        />
        <img
          src={MinMeioAmbiente}
          alt={i18n.ptBR.MIN_ENVIRONMENT_LOGO_ALT}
          style={{ width: 'auto', height: '24px' }}
        />
      </Space>
      <div>
        <span className={styles.footerText}>{i18n.ptBR.FOOTER_TEXT}</span>
        <span style={{ color: 'white' }}>{process.env.REACT_APP_VERSION}</span>
      </div>
    </footer>
  );
}
