import isObject from '../is-object';

function mergeDeep(...objects) {
  return objects.reduce((prev, obj) => {
    const copyPrev = { ...prev };
    const copyObj = { ...obj };

    Object.keys(copyObj).forEach((key) => {
      const pVal = copyPrev[key];
      const oVal = copyObj[key];

      if (isObject(pVal) && isObject(oVal)) {
        copyPrev[key] = mergeDeep(pVal, oVal);
      } else {
        copyPrev[key] = oVal;
      }
    });

    return copyPrev;
  }, {});
}

export default mergeDeep;
