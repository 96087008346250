import { UNPROCESSABLE_ENTITY_ERROR_TYPES } from '../../enums';

export default {
  TITLE: 'Nova senha',
  TEXT: (email: string) => `Olá, *${email}*! Digite sua nova senha e confirme para alterar.`,

  FORM_FIELDS: {
    PASSWORD: {
      LABEL: 'Nova senha',
      ERROR_MESSAGE: 'Por favor, digite sua nova senha',
      VALIDATION_MESSAGES: {
        INVALID_PASSWORD: 'Senha inválida',
        INVALID_PASSWORD_LENGTH: 'Mínimo de 8 caracteres',
        MISSING_LETTER: 'Deve conter pelo menos uma letra',
        MISSING_NUMBER: 'Deve conter pelo menos um número',
        MISSING_SPECIAL_CHARACTER: 'Deve conter pelo menos um caractere especial',
      },
    },
    CONFIRM_PASSWORD: {
      LABEL: 'Confirmar senha',
      VALIDATION_MESSAGES: {
        INVALID_CONFIRM_PASSWORD: 'Senhas não conferem',
        REQUIRED: 'Confirme sua senha',
      },
    },
  },
  BUTTONS: {
    SUBMIT: 'Alterar senha',
  },
  UNPROCESSABLE_ENTITY_ERROR_MESSAGES: {
    [UNPROCESSABLE_ENTITY_ERROR_TYPES.ACCOUNT_RECOVERY_CODE.INVALID]: {
      TITLE: 'Não foi possível alterar sua senha',
      DESCRIPTION: 'O código de recuperação da senha não está mais ativo',
    },
    [UNPROCESSABLE_ENTITY_ERROR_TYPES.ACCOUNT_RECOVERY_CODE.ATTEMPT_LIMIT]: {
      TITLE: 'Não foi possível alterar sua senha',
      DESCRIPTION:
        'Você atingiu o limite de tentativas de recuperação de senha para o código atual',
    },
  },
};
