import { createContext } from 'react';
import type { Dispatch, SetStateAction } from 'react';

import * as featureFlagsEnum from '../../../feature-flags';

export type FeatureFlagNames = typeof featureFlagsEnum[keyof typeof featureFlagsEnum];
export type FeatureFlagsObject = Partial<Record<FeatureFlagNames, boolean>>;

type FeatureFlagsContextType = {
  featureFlags: FeatureFlagsObject;
  setFeatureFlags: Dispatch<SetStateAction<FeatureFlagsObject>>;
};

const initialFeatureFlags = Object.values(featureFlagsEnum).reduce(
  (accumulator, featureFlagName) => ({ ...accumulator, [featureFlagName]: false }),
  {},
);

const FeatureFlagsContext = createContext<FeatureFlagsContextType>({
  featureFlags: initialFeatureFlags,
  setFeatureFlags: () => {},
});

export default FeatureFlagsContext;
