import { Breadcrumb } from 'antd';

import styles from './index.module.css';
import i18n from '../i18n';
import nextCrumb from '../../../assets/icons/next-crumb.svg';

export default function BreadcrumbItem({ route, title }: { route: string; title: string }) {
  return (
    <Breadcrumb.Item href={route} separator={<img src={nextCrumb} alt={i18n.ptBR.NEXT_CRUMB} />}>
      <span className={styles.title}>{title}</span>
    </Breadcrumb.Item>
  );
}
