/* eslint-disable class-methods-use-this */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { nanoid } from 'nanoid';

import ErrorModal from './error-modal';

import HttpError from '../../cross-cutting/errors/http-error';
import { HTTP_STATUS_CODES } from '../../enums';
import i18n from './i18n';
import mergeDeep from '../../cross-cutting/utils/merge-deep';
import removeUndefinedValues from '../../cross-cutting/utils/remove-undefined-values';
import routes from '../../routes';
import ErrorScreen from './error-screen';

const GENERIC_NOTIFICATION_OPTIONS = {
  title: i18n.ptBR.ERROR_BOUNDARY.TITLE,
  description: i18n.ptBR.ERROR_BOUNDARY.DESCRIPTION,
  severity: 'error',
  render: false,
};

const generateDefaultOptionsByI18n = () => {
  return Object.entries(i18n.ptBR.HTTP_ERRORS).map(([errorKey, errorI18n]) => {
    const hasErrorTypes = errorI18n.ERROR_TYPES && Object.keys(errorI18n.ERROR_TYPES).length > 0;

    // error type configs also inherits its parent object
    // configs (e.g. title, description)
    const errorTypes = hasErrorTypes
      ? Object.entries(errorI18n.ERROR_TYPES).reduce(
          (prevValues, [key, { TITLE, DESCRIPTION }]) => {
            return {
              ...prevValues,
              [key]: { title: TITLE, description: DESCRIPTION },
            };
          },
          {},
        )
      : undefined;

    return {
      code: Number(errorKey),
      title: errorI18n.TITLE,
      description: errorI18n.DESCRIPTION,
      types: errorTypes,
      severity: 'error',
      render: false,
    };
  });
};

const DEFAULT_OPTIONS = [
  ...generateDefaultOptionsByI18n(),
  {
    code: HTTP_STATUS_CODES.UNAUTHORIZED,
    render: () => <Navigate to={routes.LOGIN} />,
  },
];

const mergeOptions = (defaultErrorOptions, customErrorOptions = []) => {
  const options = defaultErrorOptions.map((defaultOption) => {
    const equivalentCustomOption = customErrorOptions.find(
      (customOption) => customOption.code === defaultOption.code,
    );

    return equivalentCustomOption
      ? mergeDeep(defaultOption, equivalentCustomOption)
      : defaultOption;
  });
  return options;
};

class ErrorHandler extends Component {
  constructor(props) {
    super(props);

    this.state = {
      renderError: null,
      errors: [],
    };
  }

  static getDerivedStateFromError(renderError) {
    return { renderError };
  }

  componentDidMount() {
    this.handleError();
  }

  componentDidUpdate(prevProps) {
    this.handleError(prevProps);
  }

  handleError = (prevProps) => {
    const { error } = this.props;

    // componentDidUpdate is triggered twice per error, so we have to compare the current
    // error with the previous one to avoid duplication
    if (!error || prevProps?.error === error) {
      return;
    }

    const currentErrorOptions = this.getCurrentErrorOptions();

    const unhandledStatusCode = !currentErrorOptions;
    if (!(error instanceof HttpError) || unhandledStatusCode) {
      if (unhandledStatusCode) {
        // eslint-disable-next-line no-console
        console.error(`Invalid error status code: ${error.statusCode}`);
      }

      this.handleNotificationError();
      return;
    }

    if (error.details) {
      error.details.forEach((detail) => {
        this.handleNotificationError(currentErrorOptions, detail);
      });
    } else {
      this.handleNotificationError(currentErrorOptions);
    }
  };

  getCurrentErrorOptions = () => {
    const { error, options: customOptions } = this.props;

    if (!error) {
      return undefined;
    }

    const customOptionsWithoutUndefinedValues = customOptions.map((customOptionObject) => {
      return removeUndefinedValues(customOptionObject);
    });

    const options = mergeOptions(DEFAULT_OPTIONS, customOptionsWithoutUndefinedValues);
    const currentErrorOptions = options.find((option) => option.code === error.statusCode);

    return currentErrorOptions;
  };

  handleNotificationError = (
    errorOptions = GENERIC_NOTIFICATION_OPTIONS,
    errorDetails = undefined,
  ) => {
    const errorTypeOptions = errorOptions.types?.[errorDetails?.type];

    // other error handling methods are covered on ErrorHandler's `render()` method
    if (errorOptions.render || errorTypeOptions?.render) {
      return;
    }

    if (errorTypeOptions) {
      const mergedOptions = mergeDeep(errorOptions, errorTypeOptions);

      this.showErrorModal(mergedOptions);
      return;
    }
    this.showErrorModal(errorOptions);
  };

  showErrorModal = (newError) => {
    this.setState((prevState) => ({
      errors: [
        ...prevState.errors,
        {
          key: nanoid(),
          title: newError.title,
          description: newError.description,
          severity: newError.severity,
        },
      ],
    }));
  };

  handleHttpError = (currentErrorOptions) => {
    const { error } = this.props;

    if (!error.details) {
      return this.handleSimpleHttpError(currentErrorOptions);
    }

    return this.handleHttpErrorWithDetails(currentErrorOptions);
  };

  handleSimpleHttpError = (currentErrorOptions) => {
    const { children, error } = this.props;

    if (typeof currentErrorOptions.render === 'function') {
      return currentErrorOptions.render(error, undefined, children);
    }

    return children;
  };

  handleHttpErrorWithDetails = (currentErrorOptions) => {
    const { children, error } = this.props;
    const errorElements = [];

    error.details.forEach((detail) => {
      const errorTypeOptions = currentErrorOptions.types[detail.type];
      const mergedOptions = mergeDeep(currentErrorOptions, errorTypeOptions);

      if (typeof mergedOptions.render === 'function') {
        // eslint-disable-next-line testing-library/render-result-naming-convention
        const errorElement = mergedOptions.render(error, detail, children);
        errorElements.push(errorElement);
      }
    });

    return errorElements.length > 0 ? errorElements : children;
  };

  renderContent = () => {
    const { children, error } = this.props;

    if (error instanceof HttpError) {
      const currentErrorOptions = this.getCurrentErrorOptions();

      if (currentErrorOptions) {
        return this.handleHttpError(currentErrorOptions);
      }
    }

    return children;
  };

  render() {
    const { renderError, errors } = this.state;

    if (renderError !== null) {
      // render errors must be handled first
      return <ErrorScreen error={errors} />;
    }

    return (
      <>
        {this.renderContent()}

        {errors.map((err) => (
          <ErrorModal
            key={err.key}
            visible
            title={err.title}
            description={err.description}
            severity={err.severity}
            onCancel={() =>
              this.setState((prevState) => ({
                errors: prevState.errors.filter((e) => e.key !== err.key),
              }))
            }
          />
        ))}
      </>
    );
  }
}

export default ErrorHandler;

ErrorHandler.propTypes = {
  children: PropTypes.node.isRequired,
  error: PropTypes.oneOfType([PropTypes.instanceOf(Error), PropTypes.instanceOf(HttpError)]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.number.isRequired,
      title: PropTypes.node,
      description: PropTypes.node,
      severity: PropTypes.oneOf(['error', 'warning']),
      btn: PropTypes.node,
      render: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    }),
  ),
};

ErrorHandler.defaultProps = {
  error: null,
  options: [],
};
