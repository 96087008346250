import type { FeatureFlagsObject } from '.';
import { FEATURE_FLAGS_STORAGE_KEY } from '../../../enums';

const STORAGE_FALLBACK_VALUE = '{}';

const getStorageValue = () => {
  let storageValue = localStorage.getItem(FEATURE_FLAGS_STORAGE_KEY);

  if (!storageValue) {
    storageValue = STORAGE_FALLBACK_VALUE;
    localStorage.setItem(FEATURE_FLAGS_STORAGE_KEY, STORAGE_FALLBACK_VALUE);
  }

  const parsedStorageValue: FeatureFlagsObject = JSON.parse(storageValue);
  return parsedStorageValue;
};

export default getStorageValue;
