export default {
  SUPPORT_SECTION: {
    TITLE: 'Contatos de dúvida e atendimento',
    ABOUT_APP: 'Atendimento referente ao sistema:',
  },

  SINIR_PLUS_LOGO_ALT: 'Logo do Sinir+',

  DOWNLOAD_USER_MANUAL: 'Manual do usuário',
} as const;
