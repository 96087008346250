import dataAccess from '../../cross-cutting/data-access';
import {
  DashboardFilters,
  GetTotalAmountDeclaredIndicatorResponse,
  GetTotalAndFreeStorageIndicatorResponse,
  GetTotalOfCompaniesIndicatorResponse,
  GetTotalOfCompaniesThatReachedTheGoalIndicator,
} from './types';

export const getTotalOfCompaniesIndicator = async ({
  programId,
  companyTypeId,
  region,
  year,
}: DashboardFilters) => {
  try {
    const response = await dataAccess.get(`/indicators/companies/total-of-companies`, {
      params: {
        programId,
        companyTypeId,
        region,
        year,
      },
    });
    return response.data as GetTotalOfCompaniesIndicatorResponse;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getTotalAndFreeStorageIndicator = async () => {
  try {
    const response = await dataAccess.get(`/indicators/upload/free-space`);
    return response.data as GetTotalAndFreeStorageIndicatorResponse;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getCollectedVolumeIndicator = async ({
  programId,
  companyTypeId,
  region,
  year,
  startYear,
  endYear,
}: DashboardFilters & { startYear: number; endYear: number }) => {
  try {
    const response = await dataAccess.get(`/indicators/declarations/total-amount-declared`, {
      params: {
        startYear,
        endYear,
        programId,
        companyTypeId,
        region,
        year,
      },
    });
    return response.data as GetTotalAmountDeclaredIndicatorResponse;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getTotalOfCompaniesThatReachedTheGoalIndicator = async ({
  programId,
  companyTypeId,
  region,
  year,
}: DashboardFilters) => {
  try {
    const response = await dataAccess.get(`/indicators/companies/weight-goal-fulfillment`, {
      params: {
        programId,
        companyTypeId,
        region,
        year,
      },
    });
    return response.data as GetTotalOfCompaniesThatReachedTheGoalIndicator;
  } catch (err) {
    return Promise.reject(err);
  }
};
