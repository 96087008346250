import AccessPage from '../../../common-components/access-page';
import ChangePassword from '../steps/change-password';
import RequestCode from '../steps/request-code';
import Success from '../steps/success-step';
import ValidateCode from '../steps/validate-code-step';

import i18n from './i18n';
import { STEPS } from '../enums';

type Props = {
  onFinishFirstStep: (email: string) => void;
  onFinishSecondStep: (code: number) => void;
  onFinishThirdStep: () => void;
  onCancelClick: () => void;
  currentStep: STEPS;
  email: string;
  code: string;
};

export default function Content({
  onFinishFirstStep,
  onFinishSecondStep,
  onFinishThirdStep,
  onCancelClick,
  currentStep,
  email,
  code,
}: Props) {
  let visibleStep;
  switch (currentStep) {
    case STEPS.REQUEST_CODE: {
      visibleStep = <RequestCode onCancel={onCancelClick} onFinish={onFinishFirstStep} />;
      break;
    }
    case STEPS.VALIDATE_CODE: {
      visibleStep = <ValidateCode email={email} onFinish={onFinishSecondStep} />;
      break;
    }
    case STEPS.RESET_PASSWORD: {
      visibleStep = <ChangePassword email={email} code={code} onFinish={onFinishThirdStep} />;
      break;
    }
    case STEPS.SUCCESS: {
      visibleStep = <Success />;
      break;
    }
    default: {
      visibleStep = null;
    }
  }
  return <AccessPage browserTabName={i18n.ptBR.BROWSER_TAB_NAME}>{visibleStep}</AccessPage>;
}
