import type { CSSProperties } from 'react';

type Options = { separator?: string; style?: CSSProperties };

const convertBoldTextToJsx = (text: string, options: Options = {}) => {
  const { separator = '*', style = {} } = options;

  return text.split(separator).map((textPart, index) => {
    const key = `${index}-${textPart}`;
    return index % 2 === 1 ? (
      <strong key={key} style={{ fontWeight: 'bold', ...style }}>
        {textPart}
      </strong>
    ) : (
      textPart
    );
  });
};

export default convertBoldTextToJsx;
