import { useState } from 'react';

import Content from './content';

import useFeatureFlags from '../cross-cutting/utils/use-feature-flags';

export default function DevTools() {
  const [panelVisible, setPanelVisible] = useState(false);

  const { featureFlags, toggleFeature } = useFeatureFlags();

  const activeFeatureFlagCount = Object.values(featureFlags).reduce(
    (prev: number, featureFlagActive) => (featureFlagActive ? prev + 1 : prev),
    0,
  );

  return (
    <Content
      panelVisible={panelVisible}
      openPanel={() => setPanelVisible(true)}
      closePanel={() => setPanelVisible(false)}
      featureFlags={featureFlags}
      toggleFeature={toggleFeature}
      activeFeatureFlagCount={activeFeatureFlagCount}
    />
  );
}
