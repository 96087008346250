import dataAccess from '../../cross-cutting/data-access';
import downloadFile from '../../cross-cutting/utils/download-file';

export type PlansAndDeclarationsFiltersType = {
  programIds: number[];
  year: number;
};

export const getPlansAndDeclarationsReport = async ({
  programIds,
  year,
}: PlansAndDeclarationsFiltersType) => {
  try {
    const response = await dataAccess.get(`/reports/plan-declaration`, {
      params: {
        programIds,
        year,
      },
      headers: {
        accept: 'text/csv',
        responseType: 'blob',
      },
    });

    const file = response.data;
    const type = response.headers['content-type'] ?? '';
    const originalName =
      response.headers['content-disposition']?.split('filename=')[1] ?? 'missing-file-name';

    downloadFile({ file, type, originalName });

    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
};
