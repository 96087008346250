import Table from '../../../../../../common-components/table';
import columns from './columns';

import { CompanyPlan, CompanyPlanDetails } from '../../../../../../modules/plans/types';
import i18n from './i18n';
import ViewDeclarationDetails from '../view-declaration-details';

type Props = {
  companyPlans: CompanyPlan[];
  loading: boolean;
  currentPage: number;
  totalCount: number;
  pageSize: number;
  onPageChange: (newPage: number) => void;
  onSelectDeclaration: (id: string) => void;
  selectedDeclaration?: CompanyPlanDetails;
  declarationLoading: boolean;
  onCancel: () => void;
  onDownloadFile: (file: { name: string; id: string; type: string }) => Promise<void>;
};

export default function Content({
  companyPlans,
  loading,
  currentPage,
  totalCount,
  pageSize,
  onPageChange,
  onSelectDeclaration,
  selectedDeclaration,
  declarationLoading,
  onDownloadFile,
  onCancel,
}: Props) {
  if (selectedDeclaration || declarationLoading) {
    return (
      <ViewDeclarationDetails
        onCancel={onCancel}
        declaration={selectedDeclaration}
        loading={declarationLoading}
        onDownloadFile={onDownloadFile}
      />
    );
  }
  return (
    <div style={{ marginTop: '40px' }}>
      <Table
        listTitle={i18n.ptBR.LIST_TITLE}
        rowKey="id"
        dataSource={companyPlans}
        currentPage={currentPage}
        totalItems={totalCount}
        onPageChange={onPageChange}
        newDesign
        loading={loading}
        columns={columns(onSelectDeclaration)}
        pageSize={pageSize}
      />
    </div>
  );
}
