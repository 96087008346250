import { Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';

import background from '../../../assets/images/not-found-page-background.png';
import Icon from '../../icon';
import i18n from './i18n';
import styles from './index.module.css';
import Button from '../../button';
import HeaderContent from '../../page/header/content';

export default function ErrorScreen({
  errors,
}: {
  errors?: {
    title: string;
    severity?: string;
    description?: string;
    key?: string;
  }[];
}) {
  const navigate = useNavigate();
  return (
    <>
      <HeaderContent />
      <div className={styles.page} style={{ display: 'block' }}>
        <div style={{ maxWidth: '1440px', margin: '0 auto' }}>
          <Row align="top" gutter={[16, 16]}>
            <Col span={13}>
              <img
                style={{ width: '90%', height: '700px', position: 'relative', left: '20%' }}
                src={background}
                alt=""
              />
            </Col>
            <Col span={11} style={{ marginTop: '180px' }}>
              <div className={styles.attentionBox}>
                <Icon name="alertCircle" />
                <p>{i18n.ptBR.ATTENTION}</p>
              </div>
              <h1 className={styles.errorName}>
                {errors?.[0]?.title || i18n.ptBR.GENERIC_ERROR.TITLE}
              </h1>
              <p className={styles.errorDescription}>
                {errors?.[0]?.description || i18n.ptBR.GENERIC_ERROR.DESCRIPTION}
              </p>
              <Button
                onClick={() => {
                  navigate(-1);

                  // reload window to remove all current errors
                  window.location.reload();
                }}
                icon={<Icon name="arrowLeft" />}
                text={i18n.ptBR.BUTTONS.RETURN}
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
