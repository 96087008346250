import { ProgramNamesUrl } from '../../../../../modules/programs/enums';

export default {
  BROWSER_TAB_NAME: 'Relatório de Planos e Declarações',
  PAGE_TITLE: 'Gerar Relatório de Planos e Declarações',
  PROGRAMS_NAME: {
    [ProgramNamesUrl[0]]: 'Agrotóxicos, seus Resíduos e Embalagens',
    [ProgramNamesUrl[1]]: 'Baterias de Chumbo Ácido',
    [ProgramNamesUrl[2]]: 'Eletroeletrônicos e Seus Componentes',
    [ProgramNamesUrl[3]]: 'Embalagens de Aço',
    [ProgramNamesUrl[4]]: 'Embalagens Plásticas de Óleos Lubrificantes',
    [ProgramNamesUrl[5]]: 'Embalagens em Geral',
    [ProgramNamesUrl[6]]: 'Lâmpadas Fluorescentes, de Vapor de Sódio e Mercúrio e de Luz Mista',
    [ProgramNamesUrl[7]]: 'Medicamentos, seus Resíduos e Embalagens',
    [ProgramNamesUrl[8]]: 'Óleos Lubrificantes Usados ou Contaminados (OLUC)',
    [ProgramNamesUrl[9]]: 'Pilhas e Baterias',
    [ProgramNamesUrl[10]]: 'Pneus Inservíveis',
    [ProgramNamesUrl[11]]: 'Latas de Alumínio para Bebidas',
  },

  SELECT_ALL: 'Todos os sistemas',

  FORM_FIELDS: {
    SYSTEM_TYPE: {
      LABEL: 'Sistema de Logística Reversa',
      PLACEHOLDER: 'Selecione o sistema de Logística Reversa',
    },
    YEAR: {
      LABEL: 'Ano',
      PLACEHOLDER: 'Selecione o ano',
    },
  },

  GENERATE_REPORT_BUTTON: 'Gerar relatório CSV',
};
