import { useState } from 'react';
import dayjs from 'dayjs';

import ErrorHandler from '../../../../common-components/error-handler';
import Content from './content';

import HttpError from '../../../../cross-cutting/errors/http-error';
import { requestPasswordRecoveryCode } from '../../../../modules/authentication/service';
import { HTTP_STATUS_CODES } from '../../../../enums';
import { UNPROCESSABLE_ENTITY_ERROR_TYPES } from './enums';
import i18n from './content/i18n';

type Props = {
  onFinish: (email: string) => void;
  onCancel: () => void;
};

function RequestCode({ onFinish, onCancel }: Props) {
  const [error, setError] = useState<HttpError>();

  const [loading, setLoading] = useState(false);

  const handleFinish = async (formValues: { email: string }) => {
    setLoading(true);
    try {
      await requestPasswordRecoveryCode(formValues);
      onFinish(formValues.email);
    } catch (err) {
      if (err instanceof HttpError) {
        setError(err);
      }
    }
    setLoading(false);
  };

  const options = [
    {
      code: HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY,
      types: {
        [UNPROCESSABLE_ENTITY_ERROR_TYPES.PREVIOUS_RECOVERY_CODE_STILL_VALID]: {
          title: i18n.ptBR.UNPROCESSABLE_ENTITY_ERROR_MESSAGES[error?.details?.[0]?.type]?.TITLE,
          description: i18n.ptBR.UNPROCESSABLE_ENTITY_ERROR_MESSAGES[
            error?.details?.[0]?.type
          ]?.DESCRIPTION(dayjs(error?.details?.[0]?.expiresAt).diff(dayjs(), 'm').toString()),
        },
      },
    },
  ];

  return (
    <ErrorHandler error={error} options={options}>
      <Content onCancelClick={onCancel} onFinish={handleFinish} loading={loading} />
    </ErrorHandler>
  );
}

export default RequestCode;
