export default {
  PAGE_HEADING: 'Configurando sistema de Logistica Reversa',

  STATUS: 'Status',
  STATUS_OPTIONS: {
    CONFIGURED: 'Configurado',
    NOT_CONFIGURED: 'Não configurado',
  },

  PROGRAM_YEAR: (year: number | string) => `Ano base ${year}`,
  PROGRAM_YEAR_NOT_AVAILABLE: 'não disponível',
  OVERDUE_AMOUNT_MESSAGE: (residueName: string, overdueAmount: string) =>
    `*${residueName}:* Além do informado existe um passivo de *${overdueAmount}*`,
  SECTIONS: {
    WEBSITE_LINK: 'Ações de divulgação e Educação Ambiental',
    GOALS: 'Metas',
  },
  FORM_FIELDS: {
    REVERSE_LOGISTIC_LINK: {
      LABEL: 'Link da página na internet com  divulgação da Logística Reversa',
    },

    PEVS_TEMPLATES: {
      LABEL: {
        GEOGRAPHIC_GOAL: 'Meta geográfica',
        PEVS: 'Pontos de coleta',
      },
      BUTTON: 'Baixar template dos PEVs',
      UPLOAD_TEXT: 'Upload dos pontos de coleta',
    },
  },
  TEMPLATE_NAMES: {
    PEVS: 'Pontos de Coleta',
  },
  ALERT_MESSAGES: {
    WARNING:
      '<b>Campos com * são obrigatórios.<b> Lembre-se de salvar o plano, se sair da página você perderá a configuração.',
    SUCCESS:
      'Aqui você pode adicionar documentos que quiser usados em ações de campanhas e divulgações.',
  },
  VALIDATE_MESSAGES: {
    ACCEPT_TERMS: {
      REQUIRED: 'Você deve aceitar os termos para continuar',
    },
  },
  SAVE_PROGRAM: 'Ok',
  EDIT_PROGRAM: 'Editar sistema',
  RETURN_BUTTON: 'Cancelar',
};
