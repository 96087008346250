import { useEffect, useState } from 'react';

import Content from './content';
import ErrorHandler from '../../../../../common-components/error-handler';

import HttpError from '../../../../../cross-cutting/errors/http-error';
import { getAllCompanyPlans, getOneCompanyPlan } from '../../../../../modules/plans/service';
import { CompanyPlan, CompanyPlanDetails } from '../../../../../modules/plans/types';
import { LIST_PAGE_SIZE } from './enums';
import { GetOneCompanyResponse } from '../../../../../modules/company/service';

export default function ReverseLogisticsPlans({
  company,
  companyPlanIdParam,
}: {
  company?: GetOneCompanyResponse;
  companyPlanIdParam: string | null;
}) {
  const [error, setError] = useState<HttpError>();
  const [loading, setLoading] = useState(true);
  const [companyPlans, setCompanyPlans] = useState<CompanyPlan[]>([]);
  const [selectedCompanyPlan, setSelectedCompanyPlan] = useState<CompanyPlanDetails | undefined>();
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [companyPlanId, setCompanyPlanId] = useState<string | null>(companyPlanIdParam);

  const [companyPlanLoading, setCompanyPlanLoading] = useState(false);

  useEffect(() => {
    async function fetchCompanyPlans() {
      setLoading(true);
      try {
        const response = await getAllCompanyPlans({
          companyId: company?.id,
          page,
          limit: LIST_PAGE_SIZE,
        });

        setCompanyPlans(response.companyPlans);
        setTotalCount(response.totalCount);
      } catch (err) {
        if (err instanceof HttpError) {
          setError(err);
        }
      }
      setLoading(false);
    }

    fetchCompanyPlans();
  }, [page, company]);

  useEffect(() => {
    async function fetchCompanyPlanDetails() {
      setCompanyPlanLoading(true);
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const response = await getOneCompanyPlan(companyPlanId!);

        setSelectedCompanyPlan(response);
      } catch (err) {
        if (err instanceof HttpError) {
          setError(err);
        }
      }
      setCompanyPlanLoading(false);
    }

    if (companyPlanId !== null) fetchCompanyPlanDetails();
  }, [companyPlanId]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleSelectPlan = (id: string | null) => {
    setCompanyPlanId(id);
  };

  const handleCancel = () => {
    setSelectedCompanyPlan(undefined);
    setCompanyPlanId(null);
  };

  return (
    <ErrorHandler error={error}>
      <Content
        companyPlans={companyPlans}
        loading={loading}
        currentPage={page}
        totalCount={totalCount}
        pageSize={LIST_PAGE_SIZE}
        onPageChange={handlePageChange}
        onSelectPlan={handleSelectPlan}
        selectedCompanyPlan={selectedCompanyPlan}
        companyPlanLoading={companyPlanLoading}
        onCancel={handleCancel}
      />
    </ErrorHandler>
  );
}
