import styles from './index.module.css';

type Props = {
  onClick: () => void;
};

export default function CloseButton({ onClick }: Props) {
  return (
    <button
      type="button"
      aria-label="Close panel"
      className={styles.button}
      onClick={onClick}
      data-testid="closePanelButton"
    >
      Close
    </button>
  );
}
