import colors from '../../../colors';
import { PLAN_STATUS_ID } from '../../../modules/plans/enums';

export const LIST_PAGE_SIZE = 20;

export const PLAN_STATUS_COLORS = {
  [PLAN_STATUS_ID.INCOMPLETE]: {
    color: colors.gray3,
    backgroundColor: colors.gray0,
  },
  [PLAN_STATUS_ID.NOT_SENT]: {
    color: colors.cobalt,
    backgroundColor: colors.sky,
  },
  [PLAN_STATUS_ID.SENT]: {
    color: colors.feedbackSuccessDark,
    backgroundColor: colors.feedbackSuccessLight,
  },
};
