import { GOAL_UNITS_OF_MEASUREMENT } from '../../../../../modules/plans/enums';

export default {
  GOAL_UNIT_OF_MEASURE: {
    [GOAL_UNITS_OF_MEASUREMENT.TON_PER_YEAR]: 't/ano',
  },
  FORM_FIELDS: {
    RESIDUE_OBJECT: {
      LABEL: 'Resíduo Objeto do Sistema',
      PLACEHOLDER: 'Selecione o objeto',
    },
    TON_QUANTITY: {
      LABEL: 'Quantidade (ton) colocada no mercado no ano base',
    },
    REQUIRED_GOAL: {
      LABEL: 'Meta exigida',
    },
    DECLARED_GOAL: {
      LABEL: 'Quantidade (ton) a ser coletada',
    },
    FINAL_GOAL: {
      LABEL: 'Minha meta final',
    },
  },
  LIST_FIELDS: {
    RESIDUE_OBJECT: {
      LABEL: 'Resíduo Objeto do Sistema',
    },
    KG_QUANTITY: {
      LABEL: 'Qnt. em Ton',
      ERRORS: {
        MIN_REQUIRED: 'A meta declarada deve ser igual ou maior que a meta exigida',
      },
    },
    REQUIRED_GOAL: {
      LABEL: 'Mínima exigida',
      ERRORS: {
        MIN_REQUIRED: 'A meta declarada deve ser igual ou maior que a meta exigida',
      },
    },
    KG_QUANTITY_COLLECTED: {
      LABEL: 'Qnt. (ton)coletada',
    },
    FINAL_GOAL: {
      LABEL: 'Final declarada',
    },
    ACTIONS: {
      LABEL: 'Ações',
    },
  },
  BUTTONS: {
    ADD: 'Incluir',
    REMOVE: 'Remover',
  },

  NOTIFICATIONS: {
    RESIDUE_ALREADY_EXISTS: {
      MESSAGE: 'Resíduo já listado',
      DESCRIPTION:
        'Não é possível adicionar o mesmo resíduo duas vezes, remova-o e adicione com os valores corretos',
    },
    NEED_ALL_RESIDUE_FIELDS: {
      MESSAGE: 'Faltam dados',
      DESCRIPTION: 'Insira todos os campos referentes ao resíduo',
    },
  },
};
