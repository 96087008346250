import { Link } from 'react-router-dom';
import { ReactComponent as LogoSinirWhite } from '../../../../assets/logos/logo-sinir-plus-white.svg';
import Button from '../../../button';

import useUser from '../../../../modules/user/use-user';
import styles from './index.module.css';
import i18n from './i18n';
import routes from '../../../../routes';
import AllowUserAccess from '../../../allow-user-access';
import globalEnums from '../../../../enums';

export default function Content({
  onClickLogout,
  onClickGenerateComplianceCertificate,
  complianceCertificateLoading,
}: {
  onClickLogout?: () => void;
  complianceCertificateLoading?: boolean;
  onClickGenerateComplianceCertificate?: () => void;
}) {
  const { user } = useUser();

  const isLoggedIn = !!user;

  return (
    <header className={styles.header}>
      <div className={styles.headerContent}>
        <div className={styles.logoContainer}>
          <Link to={routes.HOME}>
            <LogoSinirWhite style={{ width: 'auto', height: '60px' }} />
          </Link>
          {isLoggedIn && (
            <AllowUserAccess
              toPerform={globalEnums.ACTIONS.COMPLIANCE_CERTIFICATE.DOWNLOAD}
              toResource={globalEnums.RESOURCES.COMPLIANCE_CERTIFICATE}
              onAllow={
                <Button
                  loading={complianceCertificateLoading}
                  disabled={complianceCertificateLoading}
                  onClick={onClickGenerateComplianceCertificate}
                  style={{ color: 'white', padding: '0px 8px' }}
                  type="text"
                  text={i18n.ptBR.BUTTON.COMPLIANCE_CERTIFICATE}
                />
              }
            />
          )}
        </div>
        {onClickLogout && isLoggedIn && (
          <span className={styles.nameAndLogoutContainer}>
            {user?.company?.legalName} <span className={styles.divider}>|</span>
            <Button type="text" text={i18n.ptBR.BUTTON.LOGOUT} onClick={onClickLogout} />
          </span>
        )}
      </div>
    </header>
  );
}
